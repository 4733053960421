import instance from './DefaultApi';

export default class TertiaryReportService {
    ControllerName = 'TertiaryReport';

    // get all data
    GetTertiaryReport(tableRequest) {
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetTertiaryReport`, tableRequest).then(res => res)
    }
   //get export for report
    GetReportExport(tableRequest) {
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetReportExport`, tableRequest).then(res => res)
    }



}