import React, { Component } from "react";
import logo from "./../assets/images/logo.png";
import "bootstrap/dist/css/bootstrap.min.css";
//import { Growl } from 'primereact/growl';
import { Toast } from "primereact/toast";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import Toast from 'react-bootstrap/Toast';
import "./../App.css";
import { Dialog } from "primereact/dialog";
import LoginService from "../../src/services/LoginService";
import MessageComponent from "../template/Message";

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.LoginService = new LoginService();
    this.state = {
      userName: null,
      password: null,
      loggedIn: false,
      formError: {
        userName: null,
        password: null,
      },
      visible: false,
      formForgotHeading: "Forgot Password",
      buttonLabel: "Submit",
      Email: "",
      forgotFormError: {
        Email: null,
      },
    };
    this.formHandle = this.formHandle.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.hideFunc = this.hideFunc.bind(this);
    this.handleForgotChange = this.handleForgotChange.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.submitForgotForm = this.submitForgotForm.bind(this);
  }
  //
  componentDidMount() {
    console.log(`${sessionStorage.getItem("userType")} value of getitem`);
    //   sessionStorage.getItem("refreshToken")
    //   sessionStorage.getItem("token")
    //   sessionStorage.getItem("userType")
    //   sessionStorage.getItem("wareHouseId")
    //   sessionStorage.getItem("isAdmin")
    //   sessionStorage.getItem("CompanyId")
    //   sessionStorage.getItem("Prefix")
    // window.location = '/dashboard'
  }
  // handle form input
  formHandle(event) {
    const { name, value } = event.target;
    let errors = this.state.formError;
    //to validate each form field with required conditions
    switch (name) {
      case "userName":
        if (value == null || value.length < 1) {
          errors.userName = "Please enter your email-id.";
        } else if (value.length >= 1) {
          if (!value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
            errors.userName = "Please enter valid email-id.";
          } else errors.userName = null;
        } else errors.userName = null;
        break;
      case "password":
        errors.password = value.length < 1 ? "Please enter password" : null;
        break;
      default:
        break;
    }
    this.setState({ errors, [name]: value });
  }

  // submit form
  submitForm(event) {
    event.preventDefault();
    const formdata = {
      Email: this.state.userName,
      Password: this.state.password,
    };
    this.LoginService.CheckUser(formdata).then((data) => {
      const resdata = data.data;
      //if ResponseData not null
      if (
        resdata !== null &&
        resdata.ResponseData !== null &&
        resdata.ResponseData.length > 0
      ) {
        sessionStorage.setItem("LoggedInUser", JSON.stringify({ login: true }));
        sessionStorage.setItem(
          "refreshToken",
          resdata.ResponseData[0].RefreshToken
        );
        sessionStorage.setItem("token", resdata.ResponseData[0].Token);
        sessionStorage.setItem("userType", resdata.ResponseData[0].UserType);
        sessionStorage.setItem(
          "wareHouseId",
          resdata.ResponseData[0].WareHouseId
        );
        sessionStorage.setItem(
          "isAdmin",
          (resdata.ResponseData[0].UserId == 1) |
            (resdata.ResponseData[0].UserId == 2)
            ? true
            : false
        );
        sessionStorage.setItem("CompanyId", resdata.ResponseData[0].CompanyId);
        sessionStorage.setItem("Prefix", resdata.ResponseData[0].Prefix);
        window.location = "/dashboard";
      } else {
        this.setState({
          loggedIn: false,
        });
        this.refs.Messchild.showError(resdata.ResponseMessage);
      }
    });
  }

  // reset form
  formForgotReset() {
    this.setState({ Email: null });
    let errors = this.state.forgotFormError;
    Object.entries(errors).map(([key, value]) => {
      errors[key] = "";
    });
  }

  //open dialog
  openDialog() {
    this.setState({ visible: true });
    this.formForgotReset();
  }

  hideFunc() {
    this.setState({
      visible: false,
      buttonLabel: "Submit",
    });
    this.formForgotReset();
  }

  // submit forgot
  submitForgotForm(event) {
    event.preventDefault();

    let isfrmvalid = true;
    let checkerror = this.state.forgotFormError;

    Object.entries(checkerror).map(([key, value]) => {
      const val = this.state[key];
      let valid = this.validateForgotForm(val, key);
      //form not valid
      if (!valid) {
        this.setState({ checkerror: isfrmvalid });
        isfrmvalid = false;
      }
    });
    //form valid
    if (isfrmvalid) {
      const formData = {
        Email: this.state.Email,
      };

      setTimeout(() => {
        this.LoginService.ForgotPassword(formData).then((data) => {
          const resdata = data.data;
          //if ResponseCode is 200 success else error or warning message
          if (resdata !== null && resdata.ResponseCode === "200") {
            this.refs.Messchild.showSuccess(resdata.ResponseMessage);
            this.setState({
              loading: true,
              visible: false,
            });
            this.formForgotReset();
          } else if (resdata.ResponseCode === "400") {
            this.refs.Messchild.showWarn(resdata.ResponseMessage);
          } else {
            this.refs.Messchild.showError(resdata.ResponseMessage);
          }
        });
      }, 3000);
    }
  }

  handleForgotChange(event) {
    const { name, value } = event.target;
    let errors = this.state.forgotFormError;

    const isValid = this.validateForgotForm(value, name);
    this.setState({ errors, [name]: value });
  }

  // validate forgot form data
  validateForgotForm(value, name) {
    let errors = this.state.forgotFormError;
    let IsValid = true;
    //to validate each form field with required conditions
    switch (name) {
      case "Email":
        if (value == null || value.length < 1) {
          IsValid = false;
          errors.Email = "Please enter your email-id.";
        } else if (value.length >= 1) {
          if (!value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
            IsValid = false;
            errors.Email = "Please enter valid email-id.";
          } else errors.Email = "";
        } else errors.Email = "";
        break;
      default:
        break;
    }

    return IsValid;
  }

  // render HTML
  render() {
    return (
      <>
        <MessageComponent ref="Messchild" />

        <Dialog
          className="popup-modal"
          header={this.state.formForgotHeading}
          visible={this.state.visible}
          modal={true}
          onHide={this.hideFunc}
        >
          <form onSubmit={this.submitForgotForm}>
            <div className="row">
              <div className="col-md-4 text-right">
                <label>
                  Email<span>*</span>:
                </label>
              </div>
              <div className="col-md-8 mb-2">
                <input
                  type="text"
                  value={this.state.Email}
                  onChange={this.handleForgotChange}
                  className="form-control"
                  name="Email"
                />
                {this.state.forgotFormError.Email !== null ? (
                  <div className="error">
                    {this.state.forgotFormError.Email}
                  </div>
                ) : null}
              </div>

              <div className="col-md-12 text-center">
                <button type="submit" className="btn btn-primary">
                  {this.state.buttonLabel}
                </button>
              </div>
            </div>
          </form>
        </Dialog>
        {/* <Toast ref={(el) => this.toast = el} /> */}
        {/* <Growl ref={(el) => this.growl = el} /> */}
        <div className="login-wraper">
          <div className="logo"></div>
          <div className="login-container">
            <div className="login-logo">
              <strong>Welcome Back !</strong>
              <p>Sign in to continue to MSRM Organics Private Limited.</p>
            </div>
            <div className="form-wraper">
              <form onSubmit={this.submitForm}>
                <div className="form-group">
                  <label>Email Id</label>
                  <input
                    type="text"
                    placeholder="Enter Email Id"
                    onChange={this.formHandle}
                    name="userName"
                    className="form-control"
                    noValidate
                  />
                  {this.state.formError.userName != null ? (
                    <div className="error">{this.state.formError.userName}</div>
                  ) : null}
                </div>
                <div className="form-group">
                  <label>Password</label>
                  <input
                    type="password"
                    autoComplete="off"
                    placeholder="Enter Password"
                    onChange={this.formHandle}
                    name="password"
                    className="form-control"
                    noValidate
                  />
                  {this.state.formError.password != null ? (
                    <div className="error">{this.state.formError.password}</div>
                  ) : null}
                </div>
                <div className="row">
                  <div className="col-md-6 form-group">
                    <input type="checkbox" /> Remember me
                  </div>
                  <div
                    className="col-md-6 form-group text-right"
                    onClick={() => this.setState({ visible: true })}
                  >
                    Forgot your password?
                  </div>
                </div>

                <div>
                  <button type="submit" className="btn btn-primary">
                    Sign In
                  </button>
                </div>
                {/* <div>
                  <a onClick={this.openDialog}>  Forgot Password</a>
                </div> */}
                {/* <a href="/tnc">Term and Condition</a> */}
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}
