import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import DispatchService from '../../services/DispatchService';
import { InputText } from 'primereact/inputtext';
import tableRequest from '../../models/tableRequest.js';
import { BreadCrumb } from 'primereact/breadcrumb';
import { ConfirmationModal } from '../../template/ConfirmationModal';
import MessageComponent from '../../template/Message';
import Loaders from '../../template/Loaders';
import $ from 'jquery';


export default class DispatchReceive extends Component {
    constructor(props) {
        super(props);
        this.DispatchService = new DispatchService();
        this.tblResponse = new tableRequest();
        this.state = {
            DispatchId: "",
            globalFilter: null,
            visible: false,
            dispatchLoader: true,
            scanLoader: false,
            sortIcon: false,
            sortOrder: '1',
            formError: {
                DispatchId: ""
            },
            buttonLabel: 'Submit',
            sales: [],
            selectedStatus: null,
            receive: [],
            getrow: [],
            newrow: [],
            scanItems: [],
            stateover: [],
            loading: true,
            first: 0,
            rows: 10,
            totalRecords: 0,
            globalFilter: null,
            emptyMessage: 'No Data found'
        }
        this.actionTemplate = this.actionTemplate.bind(this);
        this.activeTemplate = this.activeTemplate.bind(this);


        this.submitForm = this.submitForm.bind(this);
        this.SubmitReceive = this.SubmitReceive.bind(this);

        this.handleChange = this.handleChange.bind(this);
        this.hideFunc = this.hideFunc.bind(this);
        this.onPage = this.onPage.bind(this);
        this.searchFilter = this.searchFilter.bind(this);
        this.openDialog = this.openDialog.bind(this);
        this.onSort = this.onSort.bind(this);
    }
    //trigger api function on page call
    componentDidMount() {
        setTimeout(() => {
            this.GetDispatchReceive();
        }, 1000);

        $('.p-column-title').on('click', function () {
            $(this).next().removeClass('pi-sort').toggleClass('pi-sort-up').addClass('pi-sort-down');
        });

        console.log("stateover1 :- ", this.state.stateover);
    }

    // PAGE EVENT CALL
    onPage(event) {
        this.setState({
            loading: true
        });

        //imitate delay of a backend call
        setTimeout(() => {
            

            this.getLazyLoadingValues(event);
            this.GetDispatchReceive();
            

        }, 250);


    }

    // set lazy loading values
    getLazyLoadingValues(event) {
        this.setState({ rows: event.rows });
        this.tblResponse.PageSize = event.rows;
        this.tblResponse.First = event.first + 1;
        this.tblResponse.IsActive = true;
        this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
        //this.tblResponse.SortColumn = event.sortField;
        this.tblResponse.SortOrder = event.sortOrder === 1 ? 'ASC' : 'DESC';
    }

    // get Dispatch Receive data list from api
    GetDispatchReceive() {
        
        this.tblResponse.SortColumn = 'DispatchId'
        this.DispatchService.GetDispatchReceive(this.tblResponse).then(data => {
            this.setState({ scanLoader: true });

            const resdata = data.data;
            //if ResponseData not null
            if (resdata !== null && resdata.ResponseData !== null && resdata.ResponseData.length > 0) {
                this.datasource = resdata.ResponseData;
                this.setState({
                    totalRecords: resdata.ResponseData[0].TotalRows,
                    first: (this.tblResponse.First - 1),
                    sales: this.datasource.slice(0, ((this.tblResponse.First - 1) + this.tblResponse.PageSize)),
                    loading: false
                });
            }
            //if ResponseData is null
            else {
                this.datasource = null;
                this.setState({
                    totalRecords: 0,
                    first: 1,
                    sales: null,
                    loading: false
                });
            }
        });
    }

    // get Dispatch Receive data list from api


    handleCheckboxChange = event => {
        let newArray = [...this.state.receive, event.target.id];
        this.setState({
            receive: newArray
        });
    };

    // call every change of form and validation part also
    handleChange(event) {

        const { name, value } = event.target;
        let errors = this.state.formError;

        this.setState({ errors, [name]: value });
    }

    // validate form
    validateForm(value, name) {
        let errors = this.state.formError;
        let IsValid = true;
        //to validate each form field with required conditions
        switch (name) {
            case 'DispatchId':
                if (value.length < 1) {
                    IsValid = false;
                    errors.DispatchId = "Please enter DispatchId.";
                }
                else if (!value.includes("DID")) {
                    IsValid = false;
                    errors.DispatchId = "Invalid DispatchId.";
                }
                else errors.DispatchId = ''
                break;

            default:
                break;
        }

        return IsValid;
    }

    // reset form text value
    formReset() {
        this.setState({
            DispatchId: "",


        });
        let errors = this.state.formError;
        Object.entries(errors).map(([key]) => {
            errors[key] = '';
        });
    }

    hideFunc() {
        this.setState({
            visible: false,
            buttonLabel: "Submit",
            formHeading: "Add Number Issue",
        });
        this.formReset();
    }

    // open Dialog
    openDialog() {
        this.setState({ visible: true });
        this.formReset();
    }

    // submit packing
    submitForm(e) {

        ;
        //   event.preventDefault(); 
        let isfrmvalid = true;
        let checkerror = this.state.formError;
        // this.setState({
        //     DispatchId : e.target.value
        // });

        Object.entries(checkerror).map(([key]) => {

            const val = this.state[key];
            let valid = this.validateForm(e.target.value, key);//KREP-59 defect
            //form is not valid
            if (!valid) {
                this.setState({ checkerror: isfrmvalid });
                isfrmvalid = false;
            }
        });



        //form is valid
        if (isfrmvalid) {
            const formData = {

                DispatchId: e.target.value,
            }




            setTimeout(() => {
                // this.DispatchService.SaveDispatchReceive(formData).then(data => {
                //     const resdata = data.data;
                //     if (resdata !== null && resdata.ResponseCode === '200') {

                //         this.refs.Messchild.showSuccess(resdata.ResponseMessage);

                //         this.setState({
                //             loading: true,
                //             visible: false
                //         });

                //        this.formReset();
                this.GetDispatchReceive();

                //     } else if (resdata.ResponseCode === '400') {
                //         this.refs.Messchild.showWarn(resdata.ResponseMessage);
                //     } else {
                //         this.refs.Messchild.showError(resdata.ResponseMessage);
                //     }
                // });
            }, 1000);


        }
    }


    // submit packing
    getDispatchCarton(e) {

        if (e.key === 'Enter') {
            e.preventDefault();
            let isfrmvalid = true;
            let checkerror = this.state.formError;

            Object.entries(checkerror).map(([key]) => {

                const val = this.state[key];
                let valid = this.validateForm(e.target.value, key);//KREP-59 defect
                //form is not valid
                if (!valid) {
                    this.setState({ checkerror: isfrmvalid });
                    isfrmvalid = false;
                }
            });
            //form is valid
            if (isfrmvalid) {
                const formData = {
                    DispatchId: e.target.value,
                }

                this.DispatchService.GetDispatchReceiveCarton(formData).then(data => {
                    
                    this.setState({ dispatchLoader: true });
                    const resdata = data.data;
                    //if response data is not null success message will be shown else will move to 'else statement'
                    if (resdata.ResponseCode == "200" && resdata !== null && resdata.ResponseData !== null && resdata.ResponseData.length > 0) {
                        this.datasource = resdata.ResponseData;

                        this.setState({
                            carton: this.datasource,
                            loading: false,
                            DispatchId: ''
                        });
                        if (resdata.ResponseData[0].ResponseMessage != null) {
                            this.refs.Messchild.showSuccess(resdata.ResponseData[0].ResponseMessage);
                        }
                        else {

                        }

                    } else {
                        this.refs.Messchild.showWarn(resdata.ResponseMessage);
                        this.datasource = null;
                        this.setState({
                            carton: null,
                            loading: false
                        });
                    }
                });

            }
        }

    }


    // seach table
    searchFilter(e) {
        this.setState({
            loading: true
        });
        this.tblResponse.SearchValue = e.target.value;
        this.GetDispatchReceive();
    }

    callbackFunction = (childData) => {
        this.setState({ message: childData })
    }


    // add checkbox function
    activeTemplate(rowData, column) {

        return (
            <input type="checkbox" name={rowData.Id} onChange={e => { this.actionTemplate(e, rowData) }} />
        )
    }


    // edit form
    actionTemplate(e, rowData) {
        const getid = rowData.Id;
        this.state.newrow.push(getid);
        this.state.getrow.push({
            "id": rowData.Id
        })

    }


    SubmitReceive() {
        // if (!this.state.stateover.length > 0) {
        //     this.refs.Messchild.showWarn("Please Select Cartons To Receive");
        //     return;
        // }
        
        this.setState({ dispatchLoader: false });
        const filterId = this.state.carton.filter(value => {

            this.state.stateover.push({ "id": value.DispatchId });
        });


        ;
        //if stateover is not null else will move to 'else statement'
        if (this.state.stateover != null) {
            this.DispatchService.DispatchCartonReceive(this.state.stateover).then(data => {
                const resdata = data.data;
                this.setState({ dispatchLoader: true });
                if (resdata !== null && resdata.ResponseCode === '200') {
                    this.refs.Messchild.showSuccess(resdata.ResponseMessage);

                    this.setState({
                        carton: null
                    });
                    this.GetDispatchReceive();


                } else {
                    this.refs.Messchild.showWarn(resdata.ResponseMessage);
                }


            });

        }
        else {


            this.DispatchService.DispatchCartonReceive(this.state.getrow).then(data => {
                const resdata = data.data;
                this.setState({ dispatchLoader: true });
                if (resdata !== null && resdata.ResponseCode === '200') {
                    this.refs.Messchild.showSuccess(resdata.ResponseMessage);
                } else {
                    this.refs.Messchild.showWarn(resdata.ResponseMessage);
                }
            });
        }
    }


    // Sort table
    onSort(event) {
        this.setState({ sortOrder: this.state.sortOrder === 1 ? 2 : 1, sortIcon: !this.state.sortIcon });
        const sortField = event.sortField == "CreatedDt" ? "CreatedDt" : event.sortField;
        this.tblResponse.SortColumn = sortField ? sortField : "";
        this.tblResponse.SortOrder = this.state.sortOrder === 1 ? 'DESC' : 'ASC';
        this.GetDispatchReceive();
        console.log(event);
    }

    // create html part
    //resolved KREP-134,KREP-114(CR)
    render() {
        const items = [
            { label: 'Transaction' },
            { label: 'Dispatch Receive' }
        ];

        const home = { icon: 'pi pi-home', url: '/dashboard' }
        let header = (
            <div className="row">
                <div className="col-md-4 text-left">
                    <i className="pi pi-search" style={{ margin: '4px 4px 0 0' }}></i>
                    <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Global Search" size="30" />
                </div>
            </div>
        );
        //console.log(this.state.selectedStatus);
        let subheader = (
            <div className="row">
                <div className="col-md-8 text-left">
                    <form >
                        <div className="row">
                            <div className="col-md-2 text-left" >Scan Item:</div>
                            <div className="col-md-6">
                                <input type="text" value={this.state.DispatchId} onChange={e => { this.handleChange(e) }} onKeyDown={e => { this.getDispatchCarton(e) }} className="custom-input" name="DispatchId" />
                                {this.state.formError.DispatchId !== null ?
                                    <div className='error'>{this.state.formError.DispatchId}</div> : null}</div>
                        </div>
                    </form>
                </div>
                <div className="col-md-4 text-right">
                    <i className="pi pi-search" style={{ margin: '4px 4px 0 0' }}></i>
                    <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Global Search" size="30" />
                </div>
            </div>
        );


        return (
            <>
                <MessageComponent ref="Messchild" />
                <ConfirmationModal parentCallback={this.callbackFunction} ref="child" />

                <div className="row mb-3">
                    <div className="col-md-6 heading">
                        <h1>Dispatch Receive</h1>
                    </div>
                    <div className="col-md-6 custom-breadcrumb">
                        <BreadCrumb model={items} home={home} />
                    </div>
                </div>
                <div className="heading mb-2">

                </div>

                <Loaders loader={this.state.scanLoader} classType="small" />
                <DataTable responsive={true} header={header} globalFilter={this.state.globalFilter}
                    emptyMessage={this.state.emptyMessage} value={this.state.sales} sortMode="multiple" paginator={true}
                    rows={this.state.rows} rowsPerPageOptions={[10, 50, 200,500]}
                    totalRecords={this.state.totalRecords} lazy={true} first={this.state.first}
                    onPage={this.onPage} onSort={this.onSort} className="tblAuto">
                    <Column field="DispatchId" header="Dispatch Id" sortable={true} />
                    <Column field="TotalCarton" header="Total Carton" sortable="custom" />
                    <Column field="Status" header="Status" sortable={true} />
                </DataTable>
                <div className="heading mb-1 mt-3">
                    <h1>Carton List</h1>
                </div>
                <Loaders loader={this.state.dispatchLoader} classType="small" />
                <DataTable selection={this.state.selectedStatus} onSelectionChange={(e) => this.setState({ selectedStatus: e.value })} responsive={true} header={subheader}
                    emptyMessage={this.state.emptyMessage} value={this.state.carton} sortMode="multiple"
                    rows={this.state.rows} rowsPerPageOptions={[10, 50, 200,500]}
                    lazy={true}
                    onPage={this.onPage} onSort={this.onSort} className="tblAuto">
                    {/* <Column field="DispatchId" header="Dispatch Id" sortable={true} /> */}
                    <Column field="TertiaryCartonId" header="Carton " />
                    <Column field="ProductName" header="Product Name" />
                    <Column field="Qty" header="Total Product Qty" />
                    <Column field="CreatedDt" header="Created Date" style={{ width: '200px' }} />
                    {/* <Column body={this.activeTemplate} header={this.selectAll} /> */}
                    {/* <Column selectionMode="multiple" header="Select all " headerStyle={{ width: '3rem' }}></Column> */}
                </DataTable>

                <div className="col-md-12 mt-3 text-center">
                    <button type="submit" onClick={this.SubmitReceive} className="btn btn-primary">
                        Receive
                    </button>
                    <br />
                    <br />
                    <br />
                </div>

            </>
        )
    }
}