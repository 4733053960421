import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';


export default class PrivacyPolicy extends Component {
  constructor(props) {
    super(props);

    this.state = {

      formError: {

      },

    }

  }

  // render HTML
  render() {


    return (

      <html xmlns="http://www.w3.org/1999/xhtml">
        <head>
          <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />

          <title>Privacy Policy</title>
        </head>

        <body style={{
          fontFamily: "Verdana, Geneva, sans-serif", margin: 0,
          padding: "10px", fontSize: "12px", textAlign: "justify", lineHeight: "20px"
        }}>

          <h2>Privacy Policy</h2>
        </body>
      </html>


    )
  }
}
