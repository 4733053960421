import instance from './DefaultApi';

export default class TertiaryPackingService {
    ControllerName = 'TertiaryPacking';


    // Save Packing 
    Save(formData) {
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/save`, formData).then(res => res)
    }

    // Save Packing 
    GetTertiaryPacking(tbl) {
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetTertiaryPacking`, tbl).then(res => res)
    }


    // Save Packing 
    GetScanSecondaryPacking(d,flag) {
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetScanSecondaryPacking?sid=` + d, flag).then(res => res)
    }


}