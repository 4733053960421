import React, { Component } from "react";
import PAService from "../services/PASevice";
import { Dialog } from "primereact/dialog";
import UserPointTransactionService from "../services/UserPointTransactionService";
import MessageComponent from "../template/Message";
import productPic from "./../assets/images/product-pic.png";
import loadingPic from "./../assets/images/loading.gif";
import ApiService from "../services/apiService";
import sucessIcon from "./../assets/images/success.png";
export default class ProductAuthentication extends Component {
  constructor(props) {
    super(props);
    this.PAService = new PAService();
    this.ApiService = new ApiService();
    this.UserPointTransactionService = new UserPointTransactionService();
    this.state = {
      formIsValid: false,
      formRedeemIsValid: false,
      Isloyalty: false,
      verifyProduct: {},
      verificationMode: "Web",
      qrData: window.location.href,
      verificationType: 2,
      UID: "",
      RedeemNumber: "",
      isLoading: true,
      Name: "",
      UsrId: "",
      Points: "",
      Totalpoints: "",
      ProductName: "",
      SchemeName: "",
      PaytmPoint: "",
      Location: "",
      email: "",
      MobNo: "",
      msg: "",
      UserName: "",
      //UsrFeedback: '',
      feedbackId: 0,
      formError: {
        Name: null,
        email: null,
        MobileNo: null,
        /// UsrFeedback: null
      },
      redeemError: {
        MobNo: null,
        UserName: null,
      },

      ProductImage: null,
      GenuineAudio: null,
      LoyaltyPoints: null,
      ProductConfiguration: {},
      CreatedDt: "",
    };

    this.submitForm = this.submitForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.redeemLoyalty = this.redeemLoyalty.bind(this);
    this.redeemhandleChange = this.redeemhandleChange.bind(this);
  }

  // basic page operation
  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);

    const parmUid = urlParams.get("U");
    this.setState({ UID: parmUid });
    navigator.geolocation.getCurrentPosition(
      (position) => {
        console.log(position);

        this.ApiService.reverseGeoCode(
          position.coords.latitude,
          position.coords.longitude
        )
          .then((res) => {
            let data = res.data;
            //console.log(data);
            const productAuthRequests = {
              verificationMode: this.state.verificationMode,
              qrData: this.state.qrData,
              verificationType: this.state.verificationType,
              UID: parmUid,
              UsrCity: data.address.city,
              UsrState: data.address.state,
              UsrCntry: data.address.country,
              UsrAdrs: data.display_name,
              UsrZip: data.address.postcode,
            };

            setTimeout(() => {
              this.GetVerifyProduct(productAuthRequests);
            }, 1000);
          })
          .catch((error) => {});
      },
      (error) => {
        console.error(error);

        const productAuthRequests = {
          verificationMode: this.state.verificationMode,
          qrData: this.state.qrData,
          verificationType: this.state.verificationType,
          UID: parmUid,
        };

        setTimeout(() => {
          this.GetVerifyProduct(productAuthRequests);
        }, 1000);
      },
      {
        // timeout: 1000,
        // maximumAge: 10000,
        // enableHighAccuracy: true
      }
    );
  }

  // get verify product list data
  GetVerifyProduct(productAuthReq) {
    this.setState({
      Isloyalty: false,
    });
    this.PAService.VerifyProduct(productAuthReq).then((data) => {
      ;
      const resdata = data.data.ResponseData;
      console.log(resdata);
      //if ResponseData is not null
      if (resdata !== null) {
        this.setState({
          isLoading: false,
          verifyProduct: resdata,
          feedbackId: resdata.FeedbackId,
          Isloyalty: resdata.Isloyalty,
          ProductImage: resdata.ProductImg,
          GenuineAudio: resdata.GenuineAudio,
          LoyaltyPoints: resdata.LoyaltyPoints,
          CreatedDt: resdata.CreatedDt,
          msg: resdata.ResponseMessage,
          // ProductConfiguration: JSON.parse(resdata.ProdConfiguration)
        });
        //if GenuineAudio is not null
        if (
          this.state.GenuineAudio != null &&
          this.state.verifyProduct.ResponseCode !== undefined
        ) {
          document.getElementById("pm3paly").play();
        }
      } else {
        if (data.data.ResponseCode === "-3") {
          this.setState({
            isLoading: false,
            verifyProduct: data.data,
            showDialog: true,
          });
        }
      }
    });
  }

  // submit feedback
  submitForm(event) {
    event.preventDefault();
    let isfrmvalid = true;
    let checkerror = this.state.formError;

    Object.entries(checkerror).map(([key, value]) => {
      const val = this.state[key];
      let valid = this.validateForm(val, key);
      //form is not valid
      if (!valid) {
        this.setState({ checkerror: isfrmvalid });
        isfrmvalid = false;
      }
    });

    if (isfrmvalid) {
      const urlParams = new URLSearchParams(window.location.search);

      const parmUid = urlParams.get("U");
      this.setState({ UID: parmUid });
      navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log(position);

          this.ApiService.reverseGeoCode(
            position.coords.latitude,
            position.coords.longitude
          )
            .then((res) => {
              let data = res.data;
              //console.log(data);
              const productAuthRequests = {
                Name: this.state.Name,
                email: this.state.email,
                MobileNo: this.state.MobileNo,
                MobNo: this.state.MobNo,
                isFormFilled: true,
                verificationMode: this.state.verificationMode,
                qrData: this.state.qrData,
                verificationType: this.state.verificationType,
                UID: parmUid,
                UsrCity: data.address.city,
                UsrState: data.address.state,
                UsrCntry: data.address.country,
                UsrAdrs: data.display_name,
                UsrZip: data.address.postcode,
              };

              setTimeout(() => {
                this.GetVerifyProduct(productAuthRequests);
              }, 1000);
            })
            .catch((error) => {});
        },
        (error) => {
          console.error(error);

          const productAuthRequests = {
            verificationMode: this.state.verificationMode,
            qrData: this.state.qrData,
            verificationType: this.state.verificationType,
            UID: parmUid,
          };

          setTimeout(() => {
            this.GetVerifyProduct(productAuthRequests);
          }, 1000);
        },
        {
          // timeout: 1000,
          // maximumAge: 10000,
          // enableHighAccuracy: true
        }
      );
    }
  }

  // redeemloyalty
  redeemLoyalty(event) {
    event.preventDefault();
    const userPoint = {
      UsrId: this.state.UsrId,
      Points: this.state.Points,
      Totalpoints: this.state.Totalpoints,
      ProductName: this.state.ProductName,
      SchemeName: this.state.SchemeName,
      PaytmPoint: this.state.PaytmPoint,
      Location: this.state.Location,
      MobNo: this.state.MobNo,
      UserName: this.state.UserName,
    };

    this.UserPointTransactionService.CreateUserPointTransaction(userPoint)
      .then((data) => {
        const resdata = data.data;
        //if ResponseCode is 200 success else error or warning message
        if (resdata.ResponseCode === "200") {
          this.refs.child.showSuccess(resdata.ResponseMessage);
        } else {
          this.refs.child.showError(resdata.ResponseMessage);
        }
      })
      .catch((error) => {
        const err = JSON.parse(error.request.response).errors;

        let errors = this.state.formError;
        let erlist = {};
        Object.entries(err).map(([key, value]) => {
          erlist[key] = errors[key.toLowerCase()] = value[0];
        });
        this.setState({ formError: erlist });
      });
  }

  // redeemhandle
  redeemhandleChange(event) {
    const { name, value } = event.target;
    let errors = this.state.redeemError;
    this.formRedeemIsValid = true;

    //to validate each form field with required conditions
    //     switch (name) {
    //         case 'MobNo':
    //             if (value.length < 1) {
    //                 this.formRedeemIsValid = false;
    //                 errors.MobNo = "Please enter your redeem mobile no.";
    //             }
    //             else if (value.length >= 1) {
    //                 if (!value.match(/^[0-9]{10}$/)) {
    //                     this.formRedeemIsValid = false;
    //                     errors.MobNo = "Please enter valid redeem mobile no.";
    //                 } else errors.MobNo = null
    //             } else errors.MobNo = null
    //             break;

    //         case 'UserName':
    //             if (value.length < 1) {
    //                 this.formRedeemIsValid = false;
    //                 errors.UserName = "Please enter your UserName";
    //             }
    //             else if (value.length >= 1) {
    //                 if (!value.match(/^[A-Za-z]+$/)) {
    //                     this.formRedeemIsValid = false;
    //                     errors.UserName = "alpha only";
    //                 } else errors.UserName = null
    //             } else errors.UserName = null
    //             break;
    //         default:
    //             break;
    //     }

    //     this.setState({ [name]: value });
    // }
  }

  // reset form text value
  formReset() {
    this.setState({
      Name: "",
      email: "",
      MobileNo: "",
      // UsrFeedback: '',
      formError: {
        Name: null,
        email: null,
        MobileNo: null,
        // UsrFeedback: null
      },
    });
    let errors = this.state.formError;
    Object.entries(errors).map(([key, value]) => {
      errors[key] = "";
    });
  }

  // call when input
  handleChange(event) {
    const { name, value } = event.target;
    let errors = this.state.formError;

    this.validateForm(value, name);
    this.setState({ errors, [name]: value });
  }

  // validate form
  //resolved KREP-17,KREP-21
  validateForm(value, name) {
    let errors = this.state.formError;
    let IsValid = true;

    //to validate each form field with required conditions
    switch (name) {
      case "Name":
        if (value.length < 1) {
          IsValid = false;
          errors.Name = "Please enter your name.";
        } else if (value.length >= 1) {
          if (!value.match(/^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/)) {
            this.IsValid = false;
            errors.Name = "Please enter only alphabet.";
          } else errors.Name = null;
        } else errors.Name = null;
        break;
      case "email":
        if (value.length < 1) {
          IsValid = false;
          errors.email = "Please enter your email-id.";
        } else if (value.length >= 1) {
          if (!value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
            IsValid = false;
            errors.email = "Please enter valid email-id.";
          } else errors.email = "";
        } else errors.email = "";
        break;
      case "MobileNo":
        if (value.length < 1) {
          IsValid = false;
          errors.MobileNo = "Please enter your mobile no.";
        } else if (value.length >= 1) {
          if (!value.match(/^[6-9]\d{9}$/)) {
            IsValid = false;
            errors.MobileNo = "Please enter valid mobile no.";
          } else errors.MobileNo = "";
        } else errors.MobileNo = "";
        break;

      default:
        break;
    }

    return IsValid;
  }

  // return HTML
  //resolved KREP-115
  render() {
    let data = "";
    const res = {};
    res["Product Image"] = this.state.verifyProduct.ProductImg;
    res["Product Name"] = this.state.verifyProduct.ProductName;
    res["Product Code"] = this.state.verifyProduct.ProductCd;
    res["Manufacture Name"] = this.state.verifyProduct.ManufactureName;
    // res["UID"] = this.state.verifyProduct.SeqNo;
    res["Product Description"] = this.state.verifyProduct.ProductDscrptn;
    res["UOM"] = this.state.verifyProduct.ProductUOM;
    res["Marketed By"] = this.state.verifyProduct.MarketedBy;
    res["Customer Care Details"] = this.state.verifyProduct.CustomerCareDetails;
    res["Expiry Date"] = this.state.verifyProduct.ExpireDate;
    res["Registration No"] = this.state.verifyProduct.RegistrationNo;
    res["D.O.M."] = this.state.verifyProduct.DOM;
    res["Batch No."] = this.state.verifyProduct.BatchNo;
    res["Cautionary Symbol"] = this.state.verifyProduct.CautionarySymbol;
    res["Antidote Statement"] = this.state.verifyProduct.AntidoteStatement;
    //if res not null
    // if (this.state.verifyProduct.ResponseCode == '1' && res != null) {
    //     data = Object.entries(res).map(([key, value], index) => {
    //         return (
    //             <div key={index}>
    //                 <div className="row"><div className="col-md-3"><strong>{key}</strong></div>
    //                     <div className="col-md-9 mb-3"> {value}</div></div>
    //             </div>
    //         );
    //     });
    // }
    // else {
    //     if (this.state.verifyProduct.ResponseCode != undefined && this.state.verifyProduct.ResponseCode != '1') {
    //         data = (
    //             <div className="row">
    //                 <div className="col-md-12 err-mess text-left">It seems you might have bought a fake product.</div>
    //             </div>
    //         );

    //     }
    // }

    return (
      <div className="pa-bg">
        <MessageComponent ref="child" />
        <div className="container" style={{ alignItems: "center" }}>
          <div>
            <audio src={this.state.GenuineAudio} id="pm3paly" autoPlay hidden />
          </div>
          {this.state.verifyProduct.ResponseCode !== "-3" ? (
            this.state.verifyProduct.ResponseCode !== "-1" ? (
              this.state.isLoading ? (
                <div className="block text-center pt-3">
                  <img alt="loader" src={loadingPic} width="120" />
                </div>
              ) : (
                <div className="block">
                  <h2 className="text-center mt-4 mb-5">
                    Product Verification Detail
                  </h2>
                  <div className="row">
                    {(this.state.ProductConfiguration != null &&
                      this.state.ProductConfiguration.IsProductImage) ===
                    true ? (
                      <div className="col-md-4 pb-3">
                        <img src={this.state.ProductImage} width="80%" />
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="col-md-8">
                      <div className="row">
                        <img
                          alt="Success"
                          src={sucessIcon}
                          height="40px"
                          width="40px"
                        />{" "}
                        <span style={{ fontSize: "15px", padding: "2vh" }}>
                          {this.state.msg}
                        </span>
                      </div>
                      <br />
                      <div className="row">
                        {this.state.verifyProduct.ProductImg ? (
                          <div className="col-md-4 font-weight-bold"></div>
                        ) : null}
                        {this.state.verifyProduct.ProductImg ? (
                          <div className="col-md-8 mb-3">
                            <img
                              alt="product Snapshot"
                              src={this.state.verifyProduct.ProductImg}
                              style={{
                                maxWidth: "200px",
                                maxHeight: "200px",
                                paddingLeft: "10vw",
                              }}
                            />
                          </div>
                        ) : null}
                        {this.state.verifyProduct.ProductName ? (
                          <div className="col-md-4 font-weight-bold">
                            Product Name
                          </div>
                        ) : null}
                        {this.state.verifyProduct.ProductName ? (
                          <div className="col-md-8 mb-3">
                            {this.state.verifyProduct.ProductName}
                            {/* &nbsp;  {this.state.verifyProduct.ProductValue}
                                                {this.state.verifyProduct.ProductUOM} */}
                          </div>
                        ) : null}
                        {this.state.verifyProduct.ProductDscrptn ? (
                          <div className="col-md-4 font-weight-bold">
                            Description
                          </div>
                        ) : null}
                        {this.state.verifyProduct.ProductDscrptn ? (
                          <div className="col-md-8 mb-3">
                            {this.state.verifyProduct.ProductDscrptn}
                          </div>
                        ) : null}
                        {this.state.verifyProduct.TechnicalName ? (
                          <div className="col-md-4 font-weight-bold">
                            Technical Name
                          </div>
                        ) : null}
                        {this.state.verifyProduct.TechnicalName ? (
                          <div className="col-md-8 mb-3">
                            {this.state.verifyProduct.TechnicalName}
                          </div>
                        ) : null}
                        <div className="col-md-4 font-weight-bold">
                          Unique Identifier
                        </div>
                        <div className="col-md-8 mb-3">{this.state.UID}</div>
                        {this.state.verifyProduct.GTIN ? (
                          <div className="col-md-4 font-weight-bold">GTI No.</div>
                        ) : null}
                        {this.state.verifyProduct.GTIN ? (
                          <div className="col-md-8 mb-3">
                            {this.state.verifyProduct.GTIN}
                          </div>
                        ) : null}
                        {this.state.verifyProduct.BatchNo ? (
                          <div className="col-md-4 font-weight-bold">Batch No.</div>
                        ) : null}
                        {this.state.verifyProduct.BatchNo ? (
                          <div className="col-md-8 mb-3">
                            {this.state.verifyProduct.BatchNo}
                          </div>
                        ) : null}
                        {this.state.verifyProduct.MFGDate ? (
                          <div className="col-md-4 font-weight-bold">MFG Date</div>
                        ) : null}
                        {this.state.verifyProduct.MFGDate ? (
                          <div className="col-md-8 mb-3">
                            {this.state.verifyProduct.MFGDate}
                          </div>
                        ) : null}
                        {this.state.verifyProduct.ExpireDate ? (
                          <div className="col-md-4 font-weight-bold">
                            Expiry Date
                          </div>
                        ) : null}
                        {this.state.verifyProduct.ExpireDate ? (
                          <div className="col-md-8 mb-3">
                            {this.state.verifyProduct.ExpireDate}
                          </div>
                        ) : null}
                        {this.state.verifyProduct.Pamphlet ? (
                          <div className="col-md-4 font-weight-bold">Leaflet</div>
                        ) : null}
                        {this.state.verifyProduct.Pamphlet ? (
                          <div className="col-md-8 mb-3">
                            <a
                              href={this.state.verifyProduct.Pamphlet}
                              target="_blank"
                            >
                              Download
                            </a>
                          </div>
                        ) : null}
                        {this.state.verifyProduct.Label ? (
                          <div className="col-md-4 font-weight-bold">Label</div>
                        ) : null}
                        {this.state.verifyProduct.Label ? (
                          <div className="col-md-8 mb-3">
                            <a
                              href={this.state.verifyProduct.Label}
                              target="_blank"
                            >
                              Download
                            </a>
                          </div>
                        ) : null}
                        {this.state.verifyProduct.CustomerCareTollfreeNo ? (
                          <div className="col-md-4 font-weight-bold">
                            Toll Free Number
                          </div>
                        ) : null}
                        {this.state.verifyProduct.CustomerCareTollfreeNo ? (
                          <div className="col-md-8 mb-3">
                            {this.state.verifyProduct.CustomerCareTollfreeNo}
                          </div>
                        ) : null}
                        {this.state.verifyProduct.CustomerCareNo ? (
                          <div className="col-md-4 font-weight-bold">
                            Customer Care Number
                          </div>
                        ) : null}
                        {this.state.verifyProduct.CustomerCareNo ? (
                          <div className="col-md-8 mb-3">
                            {this.state.verifyProduct.CustomerCareNo}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              )
            ) : (
              <div className="block">
                <div className="pt-3 err-mess text-center">
                  {this.state.msg}
                </div>
              </div>
            )
          ) : (
            <>
              <Dialog visible={this.state.showDialog}>
                <div className="block mb-5">
                  <h2 className="text-center mt-4 mb-5">Details Form</h2>
                  <form onSubmit={this.submitForm}>
                    <div className="form-group">
                      <label>
                        Name<span style={{ color: "red" }}>*</span>:
                      </label>
                      <input
                        type="text"
                        name="Name"
                        value={this.state.Name}
                        onChange={this.handleChange}
                        className="form-control"
                      />
                      {this.state.formError.Name !== null ? (
                        <div className="error">{this.state.formError.Name}</div>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <label>Email Id</label>
                      <input
                        type="email"
                        name="email"
                        value={this.state.email}
                        onChange={this.handleChange}
                        className="form-control"
                      />
                      {this.state.formError.email !== null ? (
                        <div className="error">
                          {this.state.formError.email}
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <label>
                        Mobile Number<span style={{ color: "red" }}>*</span>:
                      </label>
                      <input
                        type="text"
                        maxLength={10}
                        value={this.state.MobileNo}
                        name="MobileNo"
                        onChange={this.handleChange}
                        className="form-control"
                      />
                      {this.state.formError.MobileNo !== null ? (
                        <div className="error">
                          {this.state.formError.MobileNo}
                        </div>
                      ) : null}
                    </div>

                    <div className="text-center">
                      <button type="submit" className="btn btn-primary">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </Dialog>
            </>
          )}
        </div>
      </div>
    );
  }
}
