import React, { Component } from 'react';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { BreadCrumb } from 'primereact/breadcrumb';
import $ from "jquery";
import NumberGenerationService from '../../services/NumberGenerationService';
import MessageComponent from '../../template/Message';
import tableRequest from '../../models/tableRequest';
import ApiService from '../../services/apiService';
import Loaders from '../../template/Loaders';
// TO Generate NUmber
export default class NumberGeneration extends Component {
    constructor(props) {
        super(props);
        this.NumberGenerationService = new NumberGenerationService();
        this.ApiService = new ApiService();
        this.tblResponse = new tableRequest();
        this.state = {
            visible: false,
            updateLoader: false,
            sortOrder: '',
            FromNo: '',
            ToNo: '',
            Qty: '',
            formError: {
                FromNo: null,
                ToNo: null,
                Qty: null,

            },
            prefix: '',
            downloadLink: '',
            sales: [],
            loading: true,
            first: 0,
            rows: 10,
            totalRecords: 0,
            globalFilter: null,
            emptyMessage: 'No Data found'
        }
        this.tblResponse.isAdmin = this.ApiService.getUserType() == 0 ? true : false
        this.actionTemplate = this.actionTemplate.bind(this);
        this.handleChnage = this.handleChnage.bind(this);
        this.onPage = this.onPage.bind(this);
        this.searchFilter = this.searchFilter.bind(this);
        this.onSort = this.onSort.bind(this);
    }
    //trigger api function on page call
    componentDidMount() {
        setTimeout(() => {
            this.setState({
                prefix: this.ApiService.getFactoryPrefix()
            });
            this.GetGeneratedNumbers();
        }, 1000);
        $(".p-column-title").on("click", function () {
            $(this)
                .next()
                .removeClass("pi-sort")
                .toggleClass("pi-sort-up")
                .addClass("pi-sort-down");
        });
    }

    // call page event
    onPage(event) {
        this.setState({
            loading: true
        });

        //imitate delay of a backend call
        setTimeout(() => {

            this.getLazyLoadingValues(event);
            this.GetGeneratedNumbers();
        }, 250);
    }

    // get product issue number list data
    GetGeneratedNumbers() {
        
        this.tblResponse.SortColumn = 'CreatedDt';
        this.tblResponse.Prefix = this.ApiService.getFactoryPrefix();
        this.NumberGenerationService.GetGeneratedNumbers(this.tblResponse).then(data => {
            const resdata = data.data;
            
            //if response data is not null
            if (resdata !== null && resdata.ResponseData !== null && resdata.ResponseData.length > 0) {
                this.datasource = resdata.ResponseData;
                this.setState({
                    totalRecords: resdata.ResponseData[0].TotalRows,
                    first: (this.tblResponse.First - 1),
                    sales: this.datasource.slice(0, ((this.tblResponse.First - 1) + this.tblResponse.PageSize)),
                    loading: false,
                    updateLoader: true

                });
            }
            ///if response data is null
            else {
                this.datasource = null;
                this.setState({
                    totalRecords: 0,
                    first: 1,
                    sales: null,
                    loading: false, updateLoader: true
                });
            }
        });
    }

    // show when edit record
    actionTemplate(rowData, column) {
        //to print
        const editMode = (e) => {
            this.NumberGenerationService.CreateGeneratedNumber(rowData).then(data => {

                const resdata = data.data;
                
                if (resdata.ResponseCode === '200') {
                    
                    this.refs.child.showSuccess(resdata.ResponseMessage);
                    this.setState({
                        loading: true,
                        visible: false,
                        downloadLink: resdata.downloadLinK,
                        updateLoader: true
                    });

                    const link = document.createElement('a');
                    link.href = resdata.downloadLinK;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    this.GetGeneratedNumbers();

                } else if (resdata.ResponseCode === '400') {
                    this.refs.child.showWarn(resdata.ResponseMessage);
                } else {
                    this.refs.child.showError(resdata.ResponseMessage);
                }

            });
            ////PRINT SLIP
        }
        return <span>
            <Button type="button" icon="pi pi-print" onClick={editMode} className="p-button-warning" style={{ marginRight: '3px' }}></Button>

        </span>;
    }


    //validate form
    //resolved KREP-17

    // call when input data on form
    handleChnage(event) {
        const { name, value } = event.target;
        let errors = this.state.formError;

        this.setState({ errors, [name]: value });
    }



    // submit form to api

    ś
    // Get Next fromNO


    // set Lazy Loading Values
    getLazyLoadingValues(event) {
        this.setState({ rows: event.rows });
        this.tblResponse.PageSize = event.rows;
        this.tblResponse.First = event.first + 1;
        this.tblResponse.IsActive = '1';
        this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
        this.tblResponse.SortOrder = event.sortOrder === 1 ? 'ASC' : 'DESC';
    }

    // search Filter table 
    searchFilter(e) {
        this.setState({
            loading: true
        });
        this.tblResponse.SearchValue = e.target.value;
        this.GetGeneratedNumbers();
    }

    // Sort table
    onSort(event) {
        this.setState({ sortOrder: this.state.sortOrder === 1 ? 2 : 1 });
        const sortField = event.sortField == "CreatedDt" ? "CreatedDt" : event.sortField;
        this.tblResponse.SortColumn = sortField ? sortField : "";
        this.tblResponse.SortOrder = this.state.sortOrder === 1 ? 'DESC' : 'ASC';
        this.GetGeneratedNumbers();
    }

    // Render html
    //resolved KREP-19
    render() {

        const items = [
            { label: 'Transaction' },
            { label: 'Number Generation' }
        ];

        const home = { icon: 'pi pi-home', url: '/dashboard' }
        let header = (
            <div style={{ 'textAlign': 'right' }}><a href={this.state.downloadLink} download hidden={!this.state.downloadLink}>DownLoad Generated FIle</a>
                <i className="pi pi-search" style={{ margin: '4px 4px 0 0' }}></i>
                <InputText type="search" onInput={this.searchFilter} placeholder="Global Search" size="30" />
            </div>
        );
        return (
            <>
                <MessageComponent ref="child" />
                <div className="row mb-3">
                    <div className="col-md-6 heading">
                        <h1>Number Download</h1>
                    </div>
                    <div className="col-md-6 custom-breadcrumb">
                        <BreadCrumb model={items} home={home} />
                    </div>
                </div>
                {<Loaders loader={this.state.updateLoader} />}


                <DataTable responsive={true} header={header} globalFilter={this.state.globalFilter}
                    emptyMessage={this.state.emptyMessage} value={this.state.sales} sortMode="multiple" paginator={true}
                    rows={this.state.rows} rowsPerPageOptions={[10, 50, 200,500]} totalRecords={this.state.totalRecords} lazy={true} first={this.state.first}
                    onPage={this.onPage} onSort={this.onSort}>
                    <Column field="ProductName" header="Product Name" sortable={true} />
                    <Column field="FromNo" header="From No." sortable={true} />
                    <Column field="BatchNo" header="Batch No" sortable={true} />
                    <Column field="Qty" header="Quantity" sortable={true} />
                    <Column field="MFGDate" header="MFG Date" sortable={true} />
                    <Column field="EXPDate" header="EXP Date" sortable={true} />
                    <Column field="FirmName" header="Firm Name" sortable={true} />
                    <Column body={this.actionTemplate} header="Action" />
                </DataTable>


            </>
        )
    }
}