import React, { Component } from "react";
import { PanelMenu } from "primereact/panelmenu";
import UserService from "../services/UserService";
import MultilevelSidebar from "react-multilevel-sidebar";
import { Menubar } from "primereact/menubar";

import "react-multilevel-sidebar/src/Sidebar.css";

let options = [];

// Side bar creation
export class Sidebar extends Component {
  constructor() {
    super();

    this.UserService = new UserService();
    this.state = {
      isOpen: true,
      isPersist: false,
      menuItems: [],
      demoMenu: [
        {
          label: "Dashboard",
          icon: "pi pi-fw pi-home",
          url: "/Dashboard",
        },
        {
          label: "Masters",
          icon: "pi pi-fw pi-user",
          items: [
            {
              label: "User Master",
              icon: "pi pi-fw pi-angle-right",
              url: "/masters/user-master",
            },
            {
              label: "Factory Master",
              icon: "pi pi-fw pi-angle-right",
              url: "/masters/factory-master",
            },
            {
              label: "Category Master",
              icon: "pi pi-fw pi-angle-right",
              url: "/masters/category",
            },
            {
              label: "company Master",
              icon: "pi pi-fw pi-angle-right",
              url: "/masters/company-master",
            },
            {
              label: "Product Master",
              icon: "pi pi-fw pi-angle-right",
              url: "/masters/product-master/",
            },
            {
              label: "Scheme Master",
              icon: "pi pi-fw pi-angle-right",
              url: "/masters/scheme-master/",
            },
            {
              label: "Retailer Master",
              icon: "pi pi-fw pi-angle-right",
              url: "/masters/dealer-master/",
            },
            {
              label: "Dealer Master",
              icon: "pi pi-fw pi-angle-right",
              url: "/masters/distributor-master/",
            },
            {
              label: "List Master",
              icon: "pi pi-fw pi-angle-right",
              url: "/masters/list-master/",
            },
            {
              label: "Carton Master",
              icon: "pi pi-fw pi-angle-right",
              url: "/masters/carton-master/",
            },
            {
              label: "Warehouse Master",
              icon: "pi pi-fw pi-angle-right",
              url: "/masters/warehouse-master/",
            },
            {
              label: "Role Management",
              icon: "pi pi-fw pi-angle-right",
              url: "/masters/access-right/",
            },
          ],
        },
        {
          label: "Transactions",
          icon: "pi pi-fw pi-pencil",
          items: [
            {
              label: "Mapping",
              icon: "pi pi-fw pi-angle-right",
              url: "/transaction/mapping/",
            },
            {
              label: "Number Generation ",
              icon: "pi pi-fw pi-angle-right",
              url: "/transaction/number-generation/",
            },
            {
              label: "Number Issued",
              icon: "pi pi-fw pi-angle-right",
              url: "/transaction/number-issued/",
            },
            {
              label: "De Mapping",
              icon: "pi pi-fw pi-angle-right",
              url: "/transaction/de-mapping/",
            },
            {
              label: "Static Number Mapping",
              icon: "pi pi-fw pi-angle-right",
              url: "/transaction/map-static-number/",
            },
            {
              label: "Secondary Packing",
              icon: "pi pi-fw pi-angle-right",
              url: "/transaction/secondary-packing/",
            },
            {
              label: "Scheme Configuration ",
              icon: "pi pi-fw pi-angle-right",
              url: "/transaction/scheme-configuration/",
            },
            {
              label: "Stock Management",
              icon: "pi pi-fw pi-angle-right",
              url: "/transaction/stock-management/",
            },
            {
              label: "Number Download",
              icon: "pi pi-fw pi-angle-right",
              url: "/transaction/admin-number-download/",
            },
            {
              label: "Dispatch",
              icon: "pi pi-fw pi-angle-right",
              url: "/transaction/dispatch/",
            },
            {
              label: "Dispatch Receive",
              icon: "pi pi-fw pi-angle-right",
              url: "/transaction/dispatch-receive/",
            },
            {
              label: "Product Damage",
              icon: "pi pi-fw pi-angle-right",
              url: "/transaction/product-damage/",
            },
            {
              label: "Tertiary Packing",
              icon: "pi pi-fw pi-angle-right",
              url: "/transaction/tertiary-packing/",
            },
          ],
        },
        {
          label: "Reports",
          icon: "pi pi-fw pi-file",
          items: [
            {
              label: "Dispatch Report",
              icon: "pi pi-fw pi-angle-right",
              url: "/reports/dispatch-report/",
            },
            {
              label: "QR Tracking Report",
              icon: "pi pi-fw pi-angle-right",
              url: "/reports/qr-code-tracking-report/",
            },
            {
              label: "Secondary Packing Report",
              icon: "pi pi-fw pi-angle-right",
              url: "/reports/secondary-packing-report/",
            },
            {
              label: "Feedback Report",
              icon: "pi pi-fw pi-angle-right",
              url: "/reports/feedback-report/",
            },
            {
              label: "Mapping Report",
              icon: "pi pi-fw pi-angle-right",
              url: "/reports/mapping-report/",
            },
            {
              label: "Missing Carton Report",
              icon: "pi pi-fw pi-angle-right",
              url: "/reports/missing-carton-report/",
            },
            {
              label: "Stock Report",
              icon: "pi pi-fw pi-angle-right",
              url: "/reports/stock-report/",
            },
            {
              label: "New Stock Report",
              icon: "pi pi-fw pi-angle-right",
              url: "/reports/new-Stock/",
            },
            {
              label: "Loyalty Points Report",
              icon: "pi pi-fw pi-angle-right",
              url: "/reports/redemption-report/",
            },
            {
              label: "Item Ledger",
              icon: "pi pi-fw pi-angle-right",
              url: "/reports/item-ledger/",
            },
            {
              label: "Scheme Report",
              icon: "pi pi-fw pi-angle-right",
              url: "/reports/scheme-report/",
            },
            {
              label: "Tertiary Packing Report",
              icon: "pi pi-fw pi-angle-right",
              url: "/reports/tertiary-packing-report/",
            },
            {
              label: "Secondary Packing Report",
              icon: "pi pi-fw pi-angle-right",
              url: "/reports/secondary-packing-report/",
            },
          ],
        },
      ],
    };
  }
  //to handle sidebar toggle
  handleSidebarToggle = (isOpen) => {
    this.setState({ isOpen });
  };
  //trigger api function on page call
  componentDidMount() {
    this.getMenu();
  }

  // get Menu by User
  getMenu() {
    this.UserService.getUserMenu().then((data) => {
      let childcheck = [];
      //if ResponseCode is 200
      if (data.data.ResponseCode === "200") {
        const arraydata = data.data.ResponseData;
        let arryFinal = [];
        //if arraydata not null
        if (arraydata != null)
          // console.log("Server Res");
          //  console.log(arraydata);
          arraydata.forEach((element) => {
            let contents = {};
            let obj = {};
            element.content.forEach((econ) => {
              obj.label = econ.name;
              obj.icon = econ.icon;
              obj.url = econ.to;

              if (econ.children != null && econ.children.length > 0) {
                let chlddata = [];

                econ.children.forEach((econchld) => {
                  let ob = {};

                  econchld.content.forEach((element) => {
                    ob.label = element.name;
                    ob.url = element.to;
                    childcheck.push(element.to);
                  });

                  chlddata.push(ob);
                });

                obj.items = chlddata;
              }
              contents = obj;
            });

            arryFinal.push(contents);

            //  console.log(element.content)
          });
        console.log(arryFinal);
        this.setState({
          menuItems: arryFinal,
        });
        ;
        //if window.location.pathname not valid
        if (
          childcheck.find((x) => x === window.location.pathname) ===
            undefined &&
          window.location.pathname != "/dashboard" &&
          window.location.pathname != "/profile" &&
          window.location.pathname != "/change-password"
        ) {
          sessionStorage.removeItem("LoggedInUser");
          sessionStorage.removeItem("refreshToken");
          sessionStorage.removeItem("token");
          sessionStorage.removeItem("userType");
          sessionStorage.removeItem("wareHouseId");
          window.location = "/login";
        }
      } else {
        sessionStorage.removeItem("LoggedInUser");
        sessionStorage.removeItem("refreshToken");
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("userType");
        sessionStorage.removeItem("wareHouseId");

        window.location = "/login";
      }
    });
  }

  stripUndefined(arr) {
    return arr.reduce(function (result, item) {
      result.push(
        Array.isArray(item) && !item.length ? this.stripUndefined(item) : item
      );
      return result;
    }, []);
  }

  // Return HTML of Side bar

  render() {
    const menuItems = this.state.menuItems;
    //console.log(this.state.demoMenu);

    //     const sidebarMenu = menuItems.map((items, key) => {
    //     const subMenu = items.items;
    //     <li key={key}><a>{items.label}</a>
    //     {/* {subMenu.map((subItem, key) => <li>
    //         {subMenu.label}
    //     </li>)} */}
    //     </li>
    // })

    return (
      <div className="sidebar-wraper">
        <div className="full-width">
          <Menubar
            style={{ justifyContent: "center" }}
            model={this.state.menuItems}
          />
        </div>
      </div>
    );
  }
}

export default Sidebar;
