import React, { Component } from "react";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import MappingService from "../../services/MappingService";
import tableRequest from "../../models/tableRequest.js";
import DeMappingService from "../../services/DeMappingService";
import MessageComponent from "../../template/Message";
import $ from "jquery";
import { Calendar } from "primereact/calendar";
import { SelectButton } from "primereact/selectbutton";
import Loaders from "../../template/Loaders";
import { BreadCrumb } from "primereact/breadcrumb";
import { Card } from "primereact/card";
export default class DeMapping extends Component {
  constructor(props) {
    super(props);
    this.MappingService = new MappingService();
    this.DeMappingService = new DeMappingService();
    this.tblResponse = new tableRequest();

    this.state = {
      visible: false,
      formIsValid: true,
      updateLoader: false,
      sortOrder: "",
      refrenceFromNo: 0,
      refrenceToNo: 0,
      MappingId: 0,
      ProductId: null,
      ProductName: "",
      FromNo: "",
      ToNo: "",
      Qty: "",
      editMapping: "",
      formError: {
        ProductName: null,
        FromNo: null,
        ToNo: null,
        Qty: null,
      },
      sales: [],
      loading: true,
      first: 0,
      rows: 10,
      totalRecords: 0,
      globalFilter: null,
      emptyMessage: "No Data found",
      isPreprintedValue: "Static Qr Mapping",
      isPreprinted: false,
      dummy: [
        {
          FirmName: "Holostik",
          FromNo: "1",
          ProductName: "Sample",
          ToNo: "100",
          Qty: "100",
          CreatedDt: "26-04-2021",
          MFGDate: "20-04-2021",
          BatchNo: "001",
          Remark: "sample",
        },
        {
          FirmName: "Holostik",
          FromNo: "101",
          ProductName: "Sample",
          ToNo: "200",
          Qty: "100",
          CreatedDt: "26-04-2021",
          MFGDate: "20-04-2021",
          BatchNo: "001",
          Remark: "sample",
        },
        {
          FirmName: "Holostik",
          FromNo: "201",
          ProductName: "Sample",
          ToNo: "300",
          Qty: "100",
          CreatedDt: "26-04-2021",
          MFGDate: "20-04-2021",
          BatchNo: "001",
          Remark: "sample",
        },
        {
          FirmName: "Holostik",
          FromNo: "301",
          ProductName: "Sample",
          ToNo: "400",
          Qty: "100",
          CreatedDt: "26-04-2021",
          MFGDate: "20-04-2021",
          BatchNo: "001",
          Remark: "sample",
        },
      ],
    };

    this.brandTemplate = this.brandTemplate.bind(this);
    this.actionTemplate = this.actionTemplate.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.hideFunc = this.hideFunc.bind(this);
    this.onPage = this.onPage.bind(this);
    this.searchFilter = this.searchFilter.bind(this);
    this.onSort = this.onSort.bind(this);
    this.setPreprintedValue = this.setPreprintedValue.bind(this);
  }
  //trigger api function on page call
  componentDidMount() {
    setTimeout(() => {
      this.GetMappings();
    }, 1000);

    $(".p-column-title").on("click", function () {
      $(this)
        .next()
        .removeClass("pi-sort")
        .toggleClass("pi-sort-up")
        .addClass("pi-sort-down");
    });
  }

  // on page event call
  onPage(event) {
    this.setState({
      loading: true,
    });

    //imitate delay of a backend call
    setTimeout(() => {
      this.getLazyLoadingValues(event);
      this.GetMappings();
    }, 250);
  }

  // get product mapping list data
  GetMappings() {
    this.tblResponse.SortColumn = "MFGDate";
    this.MappingService.GetMappings(this.tblResponse).then((data) => {
      const resdata = data.data;
      //if response data is not null sales state wiill be set to response data
      if (
        resdata !== null &&
        resdata.ResponseData !== null &&
        resdata.ResponseData.length > 0
      ) {
        this.datasource = resdata.ResponseData;
        this.setState({
          totalRecords: resdata.ResponseData[0].TotalRows,
          first: this.tblResponse.First - 1,
          sales: this.datasource.slice(
            0,
            this.tblResponse.First - 1 + this.tblResponse.PageSize
          ),
          loading: false,
          updateLoader: true,
        });
      }
      //if response data is  null sales state will be set null
      else {
        this.datasource = null;
        this.setState({
          totalRecords: 0,
          first: 1,
          sales: null,
          loading: false,
        });
      }
    });
  }
  hideFunc() {
    this.setState({
      visible: false,
      buttonLabel: "Submit",
      formHeading: "Add Number Issue",
    });
    this.formReset();
  }

  // submit form
  submitForm(event) {
    event.preventDefault();

    let isfrmvalid = true;
    let checkerror = this.state.formError;

    Object.entries(checkerror).map(([key, value]) => {
      const val = this.state[key];
      let valid = this.validateForm(val, key);
      //form is not valid
      if (!valid) {
        this.setState({ checkerror: isfrmvalid });
        isfrmvalid = false;
      }
    });
    // form is valid
    if (isfrmvalid) {
      this.setState({ updateLoader: false });
      const formData = {
        MappingId: this.state.MappingId,
        ProductId:
          this.state.ProductId === "" ? 0 : parseInt(this.state.ProductId),
        FromNo: this.state.FromNo === "" ? 0 : parseInt(this.state.FromNo),
        ToNo: this.state.ToNo === "" ? 0 : parseInt(this.state.ToNo),
        Qty: this.state.Qty === "" ? 0 : parseInt(this.state.Qty),
        isPreprinted: this.state.isPreprinted,
      };

      setTimeout(() => {
        this.DeMappingService.CreateDemapping(formData).then((data) => {
          const resdata = data.data;
          this.setState({ updateLoader: true });
          //if ResponseCode is 200 success message will be shown else will move to 'else statement'
          if (resdata.ResponseCode === "200") {
            this.refs.child.showSuccess(resdata.ResponseMessage);
            this.setState({
              loading: true,
              visible: false,
            });
            this.formReset();
            this.GetMappings();
          } else if (resdata.ResponseCode === "400") {
            this.refs.child.showWarn(resdata.ResponseMessage);
          } else {
            this.refs.child.showError(resdata.ResponseMessage);
          }
        });
      }, 1000);
    }
  }

  // set product pic
  brandTemplate(rowData, column) {
    var src = rowData.ProductPic;
    return <img src={src} width="100" alt={rowData.ProductPic} />;
  }

  // Create Demapping
  DeMapData(deMapdata) {
    setTimeout(() => {
      this.DeMappingService.CreateDemapping(deMapdata).then((data) => {
        const resdata = data.data;
        //if ResponseCode is 200 success message will be shown else will move to 'else statement'
        if (resdata.ResponseCode === "200") {
          this.refs.child.showSuccess(resdata.ResponseMessage);
          this.GetMappings();
        } else {
          this.refs.child.showError(resdata.ResponseMessage);
        }
      });
    }, 1000);
  }

  // set lazy loading
  getLazyLoadingValues(event) {
    this.setState({ rows: event.rows });
    this.tblResponse.PageSize = event.rows;
    this.tblResponse.First = event.first + 1;
    this.tblResponse.IsActive = "1";
    this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
    //this.tblResponse.SortColumn = event.sortField;
    this.tblResponse.SortOrder = event.sortOrder === 1 ? "ASC" : "DESC";
  }

  // search data filter
  searchFilter(e) {
    this.setState({
      loading: true,
    });
    this.tblResponse.SearchValue = e.target.value;
    this.GetMappings();
  }

  // call when input
  handleChange(event) {
    const { name, value } = event.target;
    let errors = this.state.formError;

    const isValid = this.validateForm(value, name);
    this.setState({ errors, [name]: value });
  }

  // validate form
  //resolved KREP-17,KREP-21
  validateForm(value, name) {
    let errors = this.state.formError;
    let IsValid = true;

    //to validate each form field with required conditions
    switch (name) {
      case "ProductName":
        if (value == null) {
          IsValid = false;
          errors.ProductName = "Please select product.";
        } else errors.ProductName = null;
        break;
      case "FromNo":
        this.setState({ Qty: 0 });

        value = parseInt(value);
        if (value < 1) {
          IsValid = false;
          errors.FromNo = "Please enter +ve from number.";
        } else {
          if (value === this.state.editMapping.ToNo) {
            IsValid = false;
            errors.FromNo = "From No. should be less than To no.";
            this.setState({ Qty: 0 });
          } else if (
            value < this.state.editMapping.FromNo ||
            value > this.state.editMapping.ToNo
          ) {
            IsValid = false;
            errors.FromNo = "Numbers must exist between mapped series";
            this.setState({ Qty: 0 });
          } else {
            errors.FromNo = null;
            errors.ToNo = null;
            errors.Qty = null;
            const fromNumber = parseInt(value); //value.trim() === '' ? 0 : parseInt(value.trim());
            const toNumber =
              this.state.ToNo === "" ? 0 : parseInt(this.state.ToNo);
            if (
              fromNumber >= this.state.refrenceFromNo &&
              fromNumber <= this.state.refrenceToNo &&
              toNumber >= this.state.refrenceFromNo &&
              toNumber <= this.state.refrenceToNo
            ) {
              if (fromNumber <= toNumber) {
                const qty = toNumber - fromNumber + 1;
                if (qty >= 0) {
                  this.setState({ Qty: qty });
                } else {
                  IsValid = false;
                  errors.FromNo = "From No. should be less than to number.";
                  this.setState({ Qty: 0 });
                }
              }
              // else {
              //     IsValid = false;
              //     errors.FromNo = "From No. should be less than to number.";
              //     this.setState({ Qty: 0 })
              // }
            } else {
              IsValid = false;
              errors.FromNo =
                "From No. should be less than To no. and numbers must exist between mapped series";
              this.setState({ Qty: 0 });
            }
          }
        }
        break;
      case "ToNo":
        if (value < 1) {
          IsValid = false;
          errors.ToNo = "Please enter To Number.";
        } else errors.ToNo = null;
        break;
      case "Qty":
        value = parseInt(value);

        if (value < 1) {
          IsValid = false;
          errors.Qty = "Please enter +ve quantity.";
        }
        // else
        // if(value.length>10){
        //    IsValid=false;
        //   errors.Qty="enter valid quantity.";
        // }
        else {
          if (value > this.state.editMapping.Qty) {
            errors.Qty =
              "Qty can not greater than " + this.state.editMapping.Qty;
            this.setState({ Qty: 0 });
          } else {
            // if (value < this.state.editMapping.FromNo || value > this.state.editMapping.ToNo)
            // {  IsValid = false;
            //     errors.Qty = "Numbers must exist between mapped series";
            //     this.setState({ Qty: 0 })
            // }
            //   else  if (this.state.FromNo > this.state.editMapping.FromNo)
            //     {
            //
            //         IsValid = false;
            //         errors.Qty = "From Number can not greater than "+this.state.editMapping.ToNo;
            //         this.setState({ Qty: 0 })
            //     }
            if (value > this.state.editMapping.Qty) {
              IsValid = false;
              errors.Qty =
                "Qty can not greater than " + this.state.editMapping.Qty;
              this.setState({ Qty: 0 });
            } else {
              errors.ToNo = null;
              errors.Qty = null;
              errors.FromNo = null;
              const qty = parseInt(value); //value.toString().trim() === '' ? 0 : parseInt(value.toString().trim());
              const fromNumber =
                this.state.FromNo.toString().trim() === ""
                  ? 0
                  : parseInt(this.state.FromNo.toString().trim());
              if (fromNumber >= 1 && qty >= 1) {
                const toNumber = qty + fromNumber - 1;

                if (toNumber > this.state.editMapping.ToNo) {
                  IsValid = false;
                  errors.Qty =
                    "To number should not be greater than." +
                    this.state.editMapping.ToNo;
                  this.setState({ Qty: 0 });
                  // this.setState({ ToNo: toNumber })
                } else if (toNumber >= 0) {
                  this.setState({ ToNo: toNumber });
                } else {
                  IsValid = false;
                  errors.Qty =
                    "To number should be greater than or equal to from number.";
                  this.setState({ ToNo: 0 });
                }
              } else {
                // IsValid = false;
                // errors.ToNo = "To number should be greater than from number.";
                this.setState({ ToNo: 0 });
              }
            }
          }
        }
        break;

      default:
        break;
    }

    return IsValid;
  }

  // form reset
  formReset() {
    this.setState({
      MappingId: 0,
      ProductId: null,
      ProductName: "",
      FromNo: "",
      ToNo: "",
      Qty: "",
      // Remarks: ''
    });

    let errors = this.state.formError;
    Object.entries(errors).map(([key, value]) => {
      errors[key] = "";
    });
  }

  // call when edit
  actionTemplate(rowData, column) {
    const editMapping = { ...rowData };

    // const DeMapping = (e) => {
    //     this.DeMapData(rowData);
    // }

    const editMode = (e) => {
      this.setState({
        buttonLabel: "Demap",
        formHeading: "Demap Numbers",
        visible: true,
        MappingId: rowData.MappingId,
        ProductId: rowData.ProductID,
        ProductName: "" + rowData.ProductName,
        FromNo: "" + rowData.FromNo,
        ToNo: "" + rowData.ToNo,
        Qty: rowData.Qty,
        refrenceFromNo: rowData.FromNo,
        refrenceToNo: rowData.ToNo,
        editMapping: rowData,
        //  Remarks: rowData.Remarks
      });

      console.log(this.state.editMapping);
    };

    return (
      <span>
        <Button
          type="button"
          onClick={editMode}
          label="De-map"
          className="p-button-warning"
          style={{ marginRight: "3px" }}
        ></Button>
      </span>
    );
  }

  // call when sort data
  onSort(event) {
    this.setState({ sortOrder: this.state.sortOrder === 1 ? 2 : 1 });
    const sortField = event.sortField;
    this.tblResponse.SortColumn = sortField ? sortField : "";
    this.tblResponse.SortOrder = this.state.sortOrder === 1 ? "DESC" : "ASC";
    this.GetMappings();
  }

  /// set isPreprinted or not
  setPreprintedValue(e) {
    this.setState(
      {
        isPreprintedValue: e.value,
        isPreprinted: e.value == "Static Qr Mapping" ? false : true,
      },
      () => {
        this.tblResponse.isPreprinted = this.state.isPreprinted;
        this.GetMappings();
      }
    );
  }

  //return html data
  //resolved KREP-20,KREP-38
  render() {
    const items = [{ label: "Transaction" }, { label: "De Mapping" }];

    const home = { icon: "pi pi-home", url: "/dashboard" };
    const options = ["Static Qr Mapping"]; //, 'PrePrinted Qr Mapping'];
    let header = (
      <div className="row">
        <div className="col-md-6 text-left">
          {/*} <SelectButton value={this.state.isPreprintedValue} className="custom-tab" options={options} onChange={this.setPreprintedValue} />
           */}
        </div>
        <div className="col-md-6 text-right">
          <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>
          <InputText
            type="search"
            onInput={this.searchFilter}
            placeholder="Global Search"
            size="30"
          />
        </div>
      </div>
    );
    return (
      <>
        <MessageComponent ref="child" />
        <Loaders loader={this.state.updateLoader} />
        <Card>
          <div className="row mb-3">
            <div className="col-md-6 heading">
              <h1>De Mapping</h1>
            </div>
            <div className="col-md-6 custom-breadcrumb">
              <BreadCrumb model={items} home={home} />
            </div>
          </div>

          <DataTable
            responsive={true}
            header={header}
            globalFilter={this.state.globalFilter}
            emptyMessage={this.state.emptyMessage}
            value={this.state.sales}
            sortMode="multiple"
            paginator={true}
            rows={this.state.rows}
            rowsPerPageOptions={[10, 50, 200, 500]}
            totalRecords={this.state.totalRecords}
            lazy={true}
            first={this.state.first}
            onPage={this.onPage}
            onSort={this.onSort}
          >
            <Column field="ProductName" header="Product Name" sortable={true} />
            <Column field="FromNo" header="From No." sortable={true} />
            <Column field="ToNo" header="To No." sortable={true} />
            <Column field="Qty" header="Quantity" sortable={true} />
            <Column field="FirmName" header="WareHouse" sortable={false} />
            <Column field="MFGDate" header="MFG Date" sortable={true} />
            <Column field="BatchNo" header="Batch No" sortable={true} />

            <Column body={this.actionTemplate} header="Action" />
          </DataTable>
        </Card>
        <Dialog
          className="popup-modal"
          header={this.state.formHeading}
          visible={this.state.visible}
          modal={true}
          onHide={this.hideFunc}
        >
          <form onSubmit={this.submitForm}>
            <div className="form-padding">
              <div className="row">
                <div className="col-md-4 text-right">
                  <label>
                    Product Name<span>*</span>:
                  </label>
                </div>
                <div className="col-md-8 mb-2">
                  <input
                    type="text"
                    readOnly
                    value={this.state.ProductName}
                    onChange={this.handleChange}
                    className="form-control"
                    name="ProductName"
                  />
                  {this.state.formError.ProductName !== null ? (
                    <div className="error">
                      {this.state.formError.ProductName}
                    </div>
                  ) : null}
                </div>
                <div className="col-md-4 text-right">
                  <label>
                    From No.<span>*</span>:
                  </label>
                </div>
                <div className="col-md-8 mb-2">
                  <input
                    type="number"
                    id="txtProductId"
                    value={this.state.FromNo}
                    onChange={this.handleChange}
                    className="form-control"
                    name="FromNo"
                  />
                  {this.state.formError.FromNo !== null ? (
                    <div className="error">{this.state.formError.FromNo}</div>
                  ) : null}
                </div>

                <div className="col-md-4 text-right">
                  <label>
                    Quantity<span>*</span>:
                  </label>
                </div>
                <div className="col-md-8 mb-2">
                  <input
                    type="number"
                    value={this.state.Qty}
                    onChange={this.handleChange}
                    className="form-control"
                    name="Qty"
                  />
                  {this.state.formError.Qty !== null ? (
                    <div className="error">{this.state.formError.Qty}</div>
                  ) : null}
                </div>

                <div className="col-md-4 text-right">
                  <label>
                    To No.<span>*</span>:
                  </label>
                </div>
                <div className="col-md-8 mb-2">
                  <input
                    type="number"
                    readOnly
                    value={this.state.ToNo}
                    onChange={this.handleChange}
                    className="form-control"
                    name="ToNo"
                  />
                  {this.state.formError.ToNo !== null ? (
                    <div className="error">{this.state.formError.ToNo}</div>
                  ) : null}
                </div>
                <div className="col-md-12 text-center">
                  <button type="submit" className="btn btn-primary">
                    {this.state.buttonLabel}
                  </button>
                </div>
              </div>
              <div>
                <label>
                  Note: Fields marked as (
                  <span style={{ color: "red" }}>*</span>) are required
                </label>
              </div>
            </div>
          </form>
        </Dialog>
      </>
    );
  }
}
