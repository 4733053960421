import React, { Component } from "react";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import UserService from "../../services/UserService";
import CompanyMasterService from "../../services/CompanyMasterService";
import WareHouseMaterService from "../../services/WareHouseMaterService";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import tableRequest from "../../models/tableRequest.js";
import { confirmAlert } from "react-confirm-alert"; // Import
import { BreadCrumb } from "primereact/breadcrumb";
import { Card } from "primereact/card";
import { ConfirmationModal } from "../../template/ConfirmationModal";
import MessageComponent from "../../template/Message";
import $ from "jquery";
import ApiService from "../../services/apiService";
import Loaders from "../../template/Loaders";
import { CSVLink } from "react-csv";

export default class UserMaster extends Component {
  constructor(props) {
    super(props);
    this.ApiService = new ApiService();
    this.UserService = new UserService();
    this.CompanyMasterService = new CompanyMasterService();
    this.WareHouseMaterService = new WareHouseMaterService();
    this.tblResponseExport = new tableRequest();
    this.tblResponse = new tableRequest();
    this.state = {
      heads: [
        "CompanyName",
        "FirmName",
        "Username",
        "FirstName",
        "LastName",
        "Email",
        "MobileNo",
        "CreatedDt",
      ],
      exportData: [],
      compDisabled: false,
      visible: false,
      ReferenceID: "",
      updateLoader: false,
      sortIcon: false,
      UserName: "",
      FirstName: "",
      LastName: "",
      Email: "",
      MobileNo: "",
      Password: "",
      ConfirmPassword: "",
      IsActive: false,
      sortOrder: "",
      CompanyId: "",
      WareHouseId: "",
      IsFactory: "",
      formError: {
        UserName: "",
        FirstName: "",
        LastName: "",
        Password: "",
        Email: "",
        ConfirmPassword: "",
        MobileNo: "",
        CompanyId: "",
        WareHouseId: "",
      },

      formHeading: "Add User",
      buttonLabel: "Submit",
      sales: [],
      companyDDL: [],
      wareHouseDDL: [],
      wareHouseFactoryDDL: [],
      loading: true,
      first: 0,
      rows: 5,
      totalRecords: 0,
      globalFilter: null,
      emptyMessage: "No Data found",
      UserType: 0,
    };

    this.actionTemplate = this.actionTemplate.bind(this);
    this.emailTemplate = this.emailTemplate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.hideFunc = this.hideFunc.bind(this);
    this.onPage = this.onPage.bind(this);
    this.searchFilter = this.searchFilter.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.onSort = this.onSort.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.GetUserExport = this.GetUserExport.bind(this);
  }
  //trigger api function on page call
  componentDidMount() {
    setTimeout(() => {
      this.GetUsers();
      this.GetCompany();
      this.GetWareHouse(0);
    }, 1000);

    $(".p-column-title").on("click", function () {
      $(this)
        .next()
        .removeClass("pi-sort")
        .toggleClass("pi-sort-up")
        .addClass("pi-sort-down");
    });

    this.tblResponseExport.PageSize = -1;
    this.tblResponseExport.First = 1;
    this.tblResponseExport.IsActive = true;
    this.tblResponseExport.SearchValue = "";
    this.tblResponseExport.SortOrder = "ASC";
    this.GetUserExport();
  }

  // get user data list from api
  GetUserExport() {
    this.tblResponseExport.SortColumn = "CreatedDt";
    this.UserService.GetUsers(this.tblResponseExport).then((data) => {
      const resdata = data.data;
      //when response data is not null
      if (
        resdata !== null &&
        resdata.ResponseData !== null &&
        resdata.ResponseData.length > 0
      ) {
        this.setState({
          exportData: resdata.ResponseData,
        });
      }
    });
  }

  //on page loading
  onPage(event) {
    this.setState({
      loading: true,
    });

    //imitate delay of a backend call
    setTimeout(() => {
      this.getLazyLoadingValues(event);
      this.GetUsers();
    }, 250);
  }

  getLazyLoadingValues(event) {
    this.setState({ rows: event.rows });
    this.tblResponse.PageSize = event.rows;
    this.tblResponse.First = event.first + 1;
    this.tblResponse.IsActive = true;
    this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
    //this.tblResponse.SortColumn = event.sortField;
    this.tblResponse.SortOrder = event.sortOrder === 1 ? "ASC" : "DESC";
    this.tblResponse.UserType = 0;
  }

  // get users data list from api
  GetUsers() {
    this.tblResponse.SortColumn = "CreatedDt";
    this.UserService.GetUsers(this.tblResponse).then((data) => {
      const resdata = data.data;
      //if response data is not null sales state will be set with ResponseData
      if (
        resdata !== null &&
        resdata.ResponseData !== null &&
        resdata.ResponseData.length > 0
      ) {
        this.datasource = resdata.ResponseData;
        this.setState({
          totalRecords: resdata.ResponseData[0].TotalRows,
          first: this.tblResponse.First - 1,
          sales: this.datasource.slice(
            0,
            this.tblResponse.First - 1 + this.tblResponse.PageSize
          ),
          loading: false,
          updateLoader: true,
        });
      }
      //if response data is null sales state will be set null
      else {
        this.datasource = null;
        this.setState({
          totalRecords: 0,
          first: 1,
          sales: null,
          loading: false,
          updateLoader: true,
        });
      }
    });
  }

  // call every change of form and validation part also
  handleChange(event) {
    const { name, value } = event.target;
    let errors = this.state.formError;

    this.validateForm(value, name);
    this.setState({ errors, [name]: value });

    //to set state of IsActive
    if (name == "IsActive")
      this.setState({
        IsActive: event.target.checked,
      });
  }

  //to validate form
  validateForm(value, name) {
    let errors = this.state.formError;
    let IsValid = true;
    //to validate each form field with required conditions
    switch (name) {
      case "UserName":
        if (value.length < 1) {
          IsValid = false;
          errors.UserName = "Please enter your username.";
        } else errors.UserName = "";
        break;
      case "FirstName":
        if (value.length < 1) {
          IsValid = false;
          errors.FirstName = "Please enter your first name.";
        } else if (value.length >= 1) {
          if (!value.match(/^[A-Za-z]+$/)) {
            IsValid = false;
            errors.FirstName = "FirstName should be alpha only.";
          } else errors.FirstName = "";
        } else errors.FirstName = "";
        break;
      case "LastName":
        if (value.length < 1) {
          IsValid = false;
          errors.LastName = "Please enter your last name.";
        } else if (value.length >= 1) {
          if (!value.match(/^[A-Za-z]+$/)) {
            IsValid = false;
            errors.LastName = "LastName should be alpha only.";
          } else errors.LastName = "";
        } else errors.LastName = "";
        break;
      case "Email":
        if (value.length < 1) {
          IsValid = false;
          errors.Email = "Please enter your email-id.";
        } else if (value.length >= 1) {
          if (!value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
            IsValid = false;
            errors.Email = "Please enter valid email-id.";
          } else errors.Email = "";
        } else errors.Email = "";
        break;
      case "MobileNo":
        if (value.length < 1) {
          IsValid = false;
          errors.MobileNo = "Please enter your mobile no.";
        } else if (value.length >= 1) {
          if (!value.match(/^[6-9]\d{9}$/)) {
            IsValid = false;
            errors.MobileNo = "Please enter valid mobile no.";
          } else errors.MobileNo = "";
        } else errors.MobileNo = "";
        break;
      case "Password":
        if (value.length < 1) {
          IsValid = false;
          errors.Password = "Please enter password.";
        }
        //^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{4,10}$
        else if (!value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{4,10}$/)) {
          IsValid = false;
          errors.Password =
            "Password should be minimum 4 characters long, 1 uppercase & 1 lowercase character with 1 number.";
        } else if (
          value.length >= 1 &&
          this.state.ConfirmPassword.length >= 1
        ) {
          errors.Password = "";
          errors.ConfirmPassword = "";
          if (!(value === this.state.ConfirmPassword)) {
            IsValid = false;
            errors.Password = "password does not match";
          } else errors.Password = "";
        } else errors.Password = "";
        break;

      case "ConfirmPassword":
        if (value.length < 1) {
          IsValid = false;
          errors.ConfirmPassword = "Please enter confirm password.";
        } else if (!value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{4,10}$/)) {
          IsValid = false;
          errors.ConfirmPassword =
            "Password should be minimum 4 characters long, 1 uppercase & 1 lowercase character with 1 number.";
        } else if (value.length >= 1 && this.state.Password.length >= 1) {
          errors.Password = "";
          errors.ConfirmPassword = "";
          if (!(value === this.state.Password)) {
            IsValid = false;
            errors.ConfirmPassword = "Confirm password does not match";
          } else errors.ConfirmPassword = "";
        } else errors.ConfirmPassword = "";
        break;

      case "CompanyId":
        if (value == undefined || value == "") {
          IsValid = false;
          errors.CompanyId = "Please select Company";
        } else {
          errors.CompanyId = "";
          //this.GetWareHouse(value.CompId)
        }
        break;
      case "WareHouseId":
        if (value.ID == undefined || value.ID == "") {
          IsValid = false;
          errors.WareHouseId = "Please select WareHouse / Factory";
        } else {
          errors.WareHouseId = "";
        }
        break;
      default:
        break;
    }

    return IsValid;
  }

  // reset form text value
  formReset() {
    this.setState({
      ReferenceID: "",
      UserName: "",
      FirstName: "",
      LastName: "",
      Email: "",
      MobileNo: "",
      Password: "",
      ConfirmPassword: "",
      IsActive: true,
      //CompanyId :'',
      WareHouseId: "",
    });
    let errors = this.state.formError;
    Object.entries(errors).map(([key, value]) => {
      errors[key] = "";
    });
  }

  hideFunc() {
    this.setState({
      visible: false,
      buttonLabel: "Submit",
      formHeading: "Add User",
    });
    this.formReset();
  }
  openDialog() {
    this.setState({ visible: true });
    this.formReset();
  }
  // submit form and create or update user
  submitForm(event) {
    event.preventDefault();

    let isfrmvalid = true;
    let checkerror = this.state.formError;

    Object.entries(checkerror).map(([key, value]) => {
      const val = this.state[key];
      let valid = this.validateForm(val, key);
      //form is not valid
      if (!valid) {
        this.setState({ checkerror: isfrmvalid });
        isfrmvalid = false;
      }
    });
    //form is valid
    if (isfrmvalid) {
      this.setState({ updateLoader: false });
      const formData = {
        UserName: this.state.UserName,
        FirstName: this.state.FirstName,
        LastName: this.state.LastName,
        Email: this.state.Email,
        MobileNo: this.state.MobileNo,
        Password: this.state.Password,
        ReferenceID: this.state.ReferenceID,
        IsActive: this.state.IsActive,
        CompanyId: this.state.CompanyId.CompId,
        WareHouseId: this.state.WareHouseId.ID,
        UserType: this.state.UserType,
      };

      setTimeout(() => {
        this.UserService.CreateUpdateUser(formData).then((data) => {
          const resdata = data.data;
          //if responsecode is 200 success message willl be shown else it will move to 'else statement'
          if (resdata !== null && resdata.ResponseCode === "200") {
            this.refs.Messchild.showSuccess(resdata.ResponseMessage);
            this.setState({
              loading: true,
              visible: false,
              updateLoader: true,
            });
            this.formReset();
            this.GetUsers();
          } else if (resdata.ResponseCode === "400") {
            this.setState({
              updateLoader: true,
            });
            this.refs.Messchild.showWarn(resdata.ResponseMessage);
          } else {
            this.setState({
              updateLoader: true,
            });
            this.refs.Messchild.showError(resdata.ResponseMessage);
          }
        });
      }, 100);
    }
  }

  // delete user data
  deleteUser(referenceID) {
    this.UserService.DeleteUser(referenceID).then((data) => {
      const resdata = data.data;
      //if responsecode is 200 success message willl be shown else it will move to 'else statement'
      if (resdata !== null && resdata.ResponseCode === "200") {
        this.refs.Messchild.showSuccess(resdata.ResponseMessage);
        this.GetUsers();
        const totlrecord = this.state.totalRecords - 1;
        const row = this.state.rows;

        if (this.tblResponse.First >= totlrecord) {
          const _curntCursor = Math.round(totlrecord / row);
          this.tblResponse.First = _curntCursor + 1;
        }
      } else if (resdata.ResponseCode === "400") {
        this.refs.Messchild.showWarn(resdata.ResponseMessage);
      } else {
        this.refs.Messchild.showError(resdata.ResponseMessage);
      }
    });
  }
  //to get  company data for ddl
  GetCompany() {
    this.CompanyMasterService.GetCompanyDDL().then((data) => {
      const resdata = data.data;
      //if resdata is not null state of companyDDL will be set with ResponseData
      if (
        resdata !== null &&
        resdata.ResponseData !== null &&
        resdata.ResponseData.length > 0
      ) {
        this.setState({
          companyDDL: resdata.ResponseData,
        });
      }

      if (this.ApiService.getIsAdmin() == "false") {
        const CompanyObj = this.state.companyDDL.filter(
          (x) => x.CompId == this.ApiService.getCompanyId()
        );
        this.setState({
          CompanyId: CompanyObj[0],
          compDisabled: true,
        });
        this.GetWareHouse(this.state.CompanyId.CompId);
      }
    });
  }

  //to get warehouse data for ddl
  GetWareHouse(compId) {
    this.WareHouseMaterService.GetWareHouseDDL(compId).then((data) => {
      const resdata = data.data;
      //if resdata is not null state of wareHouseDDL will be set with ResponseData
      if (
        resdata !== null &&
        resdata.ResponseData !== null &&
        resdata.ResponseData.length > 0
      ) {
        this.setState({
          wareHouseFactoryDDL: resdata.ResponseData,
          wareHouseDDL: [],
        });
      }
    });
  }

  searchFilter(e) {
    this.setState({
      loading: true,
    });
    this.tblResponse.SearchValue = e.target.value;
    this.GetUsers();
  }

  callbackFunction = (childData) => {
    this.setState({ message: childData });
  };

  onValueChange(event) {
    const val = event.target.value;

    if (val == "0") {
      const wareHouse = this.state.wareHouseFactoryDDL.filter(
        (x) => x.IsFactory == 0
      );
      this.setState({
        IsFactory: false,
        wareHouseDDL: wareHouse,
        WareHouseId: "",
        UserType: 5,
        // warehouseVisible: true,
      });
    }
    if (val == "1") {
      const Factory = this.state.wareHouseFactoryDDL.filter(
        (x) => x.IsFactory == 1
      );

      this.setState({
        IsFactory: true,
        WareHouseId: "",
        wareHouseDDL: Factory,
        UserType: 4,
        //  warehouseVisible: true,
      });
    }
  }

  // disabled checkbox
  activeTemplate(rowData, column) {
    return <input type="checkbox" disabled checked={rowData.IsActive} />;
  }

  // sort data
  onSort(event) {
    this.setState({ sortOrder: this.state.sortOrder === 1 ? 2 : 1 });
    const sortField =
      event.sortField == "CreatedDt" ? "CreatedDt" : event.sortField;
    this.tblResponse.SortColumn = sortField ? sortField : "";
    this.tblResponse.SortOrder = this.state.sortOrder === 1 ? "DESC" : "ASC";
    this.GetUsers();
    const clickData = document.getElementsByClassName(
      "p-sortable-column-icon pi pi-fw pi-sort"
    );
    console.log(event);
  }
  emailTemplate = (rowData) => {
    let emails = rowData.Email;
    return <div className="scroll-content">{emails}</div>;
  };
  // edit form
  actionTemplate(rowData, column) {
    const editMode = (e) => {
      this.setState({
        buttonLabel: "Update",
        formHeading: "Update User",
        visible: true,
        ReferenceID: rowData.ReferenceID,
        UserName: rowData.Username,
        FirstName: rowData.FirstName,
        LastName: rowData.LastName,
        Email: rowData.Email,
        UserType: rowData.UserType,
        MobileNo: rowData.MobileNo,
        Password: rowData.Password,
        ConfirmPassword: rowData.Password,
        IsActive: rowData.IsActive,
      });

      const CompanyObj = this.state.companyDDL.filter(
        (x) => x.CompId == rowData.CompanyId
      );
      const wareHouse = this.state.wareHouseFactoryDDL.filter(
        (x) => x.IsFactory == 0
      );
      const Factory = this.state.wareHouseFactoryDDL.filter(
        (x) => x.IsFactory == 1
      );
      const wareHouseObj = this.state.wareHouseFactoryDDL.filter(
        (x) => x.ID == rowData.WareHouseId
      );
      if (wareHouseObj[0]?.IsFactory) {
        this.setState({
          IsFactory: true,
          wareHouseDDL: Factory,
        });
      } else if (!wareHouseObj[0]?.IsFactory) {
        this.setState({
          IsFactory: false,
          wareHouseDDL: wareHouse,
        });
      }

      this.setState({
        CompanyId: CompanyObj[0],
        WareHouseId: wareHouseObj[0],
        //wareHouseDDL: wareHouse ?? Factory | wareHouse
      });
    };

    const deleteFunc = () => {
      confirmAlert({
        title: "Confirm to delete",
        message: "Are you sure to do this.",
        buttons: [
          {
            label: "Yes",
            onClick: () => this.deleteUser(rowData.ReferenceID),
          },
          {
            label: "No",
            onClick: () => null,
          },
        ],
      });
    };

    return (
      <span>
        <Button
          type="button"
          icon="pi pi-pencil"
          onClick={editMode}
          className="p-button-warning"
          style={{ marginRight: "3px" }}
        ></Button>
        {/* <Button
          type="button"
          icon="pi pi-trash"
          onClick={deleteFunc}
          className="p-button-danger"
        ></Button> */}
      </span>
    );
  }

  // create html part
  //resolved KREP-85
  render() {
    const items = [{ label: "Master" }, { label: "User Master" }];

    const home = { icon: "pi pi-home", url: "/dashboard" };
    let header = (
      <div style={{ textAlign: "right" }}>
        <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>
        <InputText
          type="search"
          onInput={this.searchFilter}
          placeholder="Global Search"
          size="30"
        />
        <Button
          label="Add User"
          style={{ marginLeft: "8px" }}
          icon="pi pi-plus-circle"
          onClick={this.openDialog}
        />
        <CSVLink
          data={this.state.exportData}
          headers={this.state.heads}
          filename={"User Data.csv"}
          target="_blank"
        >
          <button
            disabled={this.state.exportData.length == 0 ? true : false}
            className="btn btn-success p-2 ml-2 buttone1"
          >
            <i className="pi pi-file-excel mr-2"></i>
            Export
          </button>
        </CSVLink>
      </div>
    );

    return (
      <>
        <MessageComponent ref="Messchild" />
        {<Loaders loader={this.state.updateLoader} />}
        <ConfirmationModal parentCallback={this.callbackFunction} ref="child" />
        <Card>
          <div className="row mb-3">
            <div className="col-md-6 heading">
              <h1>User Master</h1>
            </div>
            <div className="col-md-6 custom-breadcrumb">
              <BreadCrumb model={items} home={home} />
            </div>
          </div>

          <DataTable
            responsive={true}
            header={header}
            style={{ width: "100%" }}
            scrollable
            globalFilter={this.state.globalFilter}
            emptyMessage={this.state.emptyMessage}
            value={this.state.sales}
            sortMode="multiple"
            paginator={true}
            rows={this.state.rows}
            rowsPerPageOptions={[5, 10, 20]}
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            totalRecords={this.state.totalRecords}
            lazy={true}
            first={this.state.first}
            onPage={this.onPage}
            onSort={this.onSort}
            className="tblAuto"
          >
            <Column
              field="Username"
              headerStyle={{ width: "180px" }}
              header="User Name"
              sortable={true}
            />
            <Column
              field="FirmName"
              headerStyle={{ width: "200px" }}
              header="Factory / WareHouse"
              sortable={true}
            />
            <Column
              field="CompanyName"
              headerStyle={{ width: "200px" }}
              header="Company"
              sortable={true}
            />
            <Column
              field="FirstName"
              headerStyle={{ width: "160px" }}
              header="First Name"
              sortable={true}
            />
            <Column
              field="LastName"
              headerStyle={{ width: "160px" }}
              header="Last Name"
              sortable={true}
            />
            <Column
              body={this.emailTemplate}
              headerStyle={{ width: "200px" }}
              header="Email"
              sortable={true}
            />
            <Column
              field="MobileNo"
              headerStyle={{ width: "150px" }}
              header="Mobile No."
              sortable={true}
            />
            <Column
              field="CreatedDate"
              headerStyle={{ width: "150px" }}
              header="Created Date"
              sortable={true}
            />
            <Column
              body={this.activeTemplate}
              headerStyle={{ width: "80px" }}
              header="Status"
            />
            <Column
              body={this.actionTemplate}
              headerStyle={{ width: "100px" }}
              header="Action"
            />
          </DataTable>
        </Card>
        <Dialog
          className="popup-modal"
          header={this.state.formHeading}
          visible={this.state.visible}
          modal={true}
          onHide={this.hideFunc}
        >
          <form
            onSubmit={this.submitForm}
            className="form-padding"
            autoComplete="off"
          >
            <div className="row">
              <div className="col-md-4 text-right">
                <label>
                  User Name<span>*</span>:
                </label>
              </div>
              <div className="col-md-8 mb-2">
                <input
                  type="text"
                  value={this.state.UserName}
                  onChange={this.handleChange}
                  className="form-control"
                  name="UserName"
                />
                {/* <TextField id="outlined-basic" label="User Name" size="small" value={this.state.UserName} onChange={this.handleChange} name="UserName" variant="outlined" /> */}
                {this.state.formError.UserName !== "" ? (
                  <div className="error">{this.state.formError.UserName}</div>
                ) : (
                  ""
                )}
              </div>

              <div className="col-md-4 text-right">
                <label>
                  First Name<span>*</span>:
                </label>
              </div>
              <div className="col-md-8 mb-2">
                <input
                  type="text"
                  value={this.state.FirstName}
                  onChange={this.handleChange}
                  className="form-control"
                  name="FirstName"
                />
                <div className="error">{this.state.formError.FirstName}</div>
              </div>
              <div className="col-md-4 text-right">
                <label>
                  Last Name<span>*</span>:
                </label>
              </div>
              <div className="col-md-8 mb-2">
                <input
                  type="text"
                  value={this.state.LastName}
                  onChange={this.handleChange}
                  className="form-control"
                  name="LastName"
                />
                {this.state.formError.LastName !== "" ? (
                  <div className="error">{this.state.formError.LastName}</div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-4 text-right">
                <label>
                  Email<span>*</span>:
                </label>
              </div>
              <div className="col-md-8 mb-2">
                <input
                  type="text"
                  autoComplete="off"
                  value={this.state.Email}
                  onChange={this.handleChange}
                  className="form-control"
                  name="Email"
                />
                {this.state.formError.Email !== null ? (
                  <div className="error">{this.state.formError.Email}</div>
                ) : null}
              </div>
              <div className="col-md-4 text-right">
                <label>
                  Mobile Number<span>*</span>:
                </label>
              </div>
              <div className="col-md-8 mb-2">
                <input
                  type="text"
                  autoComplete="off"
                  maxLength={10}
                  value={this.state.MobileNo}
                  onChange={this.handleChange}
                  className="form-control"
                  name="MobileNo"
                />
                {this.state.formError.MobileNo !== null ? (
                  <div className="error">{this.state.formError.MobileNo}</div>
                ) : null}
              </div>
              <div className="col-md-4 text-right">
                <label>
                  Password<span>*</span>:
                </label>
              </div>
              <div className="col-md-8 mb-2">
                <input
                  type="password"
                  autoComplete="new-password"
                  value={this.state.Password}
                  onChange={this.handleChange}
                  className="form-control"
                  name="Password"
                  noValidate
                />
                {this.state.formError.Password !== null ? (
                  <div className="error">{this.state.formError.Password}</div>
                ) : null}
              </div>
              <div className="col-md-4 text-right">
                <label>
                  Confirm Password<span>*</span>:
                </label>
              </div>
              <div className="col-md-8 mb-2">
                <input
                  type="password"
                  autoComplete="off"
                  value={this.state.ConfirmPassword}
                  onChange={this.handleChange}
                  className="form-control"
                  name="ConfirmPassword"
                />
                {this.state.formError.ConfirmPassword !== null ? (
                  <div className="error">
                    {this.state.formError.ConfirmPassword}
                  </div>
                ) : null}
              </div>
              <div className="col-md-4 text-right">
                <label>
                  Company<span>*</span>:
                </label>
              </div>
              <div className="col-md-8 mb-2">
                <Dropdown
                  appendTo={document.getElementById("root")}
                  disabled={this.state.compDisabled}
                  optionLabel="CompanyName"
                  onChange={(e) => {
                    this.handleChange(e);
                  }}
                  value={this.state.CompanyId}
                  className="form-control"
                  placeholder="Select Company"
                  name="CompanyId"
                  options={this.state.companyDDL}
                />
                {this.state.formError.CompanyId !== null ? (
                  <div className="error">{this.state.formError.CompanyId}</div>
                ) : null}
              </div>

              {/* <div className="col-md-4 text-right">
                                <label>WareHouse<span>*</span>:</label>
                            </div> */}

              <div className="col-md-4">
                <input
                  checked={this.state.IsFactory === true}
                  type="radio"
                  value="1"
                  name="ddlType"
                  onChange={this.onValueChange}
                />{" "}
                Factory
                <input
                  checked={this.state.IsFactory === false}
                  type="radio"
                  value="0"
                  className="mr-2 ml-2"
                  name="ddlType"
                  onChange={this.onValueChange}
                />
                WareHouse
              </div>

              <div className="col-md-6 mb-2">
                <Dropdown
                  appendTo={document.getElementById("root")}
                  optionLabel="FirmName"
                  onChange={(e) => {
                    this.handleChange(e);
                  }}
                  value={this.state.WareHouseId}
                  className="form-control"
                  placeholder="-Select-"
                  name="WareHouseId"
                  options={this.state.wareHouseDDL}
                />
                {this.state.formError.WareHouseId !== null ? (
                  <div className="error">
                    {this.state.formError.WareHouseId}
                  </div>
                ) : null}
              </div>

              <div className="col-md-4 text-right">
                <label>IsActive :</label>
              </div>
              <div className="col-md-8 mb-2">
                <input
                  type="checkbox"
                  checked={this.state.IsActive}
                  className="ml-2"
                  name="IsActive"
                  onChange={this.handleChange}
                />
              </div>
              <div className="col-md-12 text-center">
                <button type="submit" className="btn btn-primary">
                  {this.state.buttonLabel}
                </button>
              </div>
              <div className="col-md-12 text-left">
                <label>
                  Note: Fields marked as (
                  <span style={{ color: "red" }}>*</span>) are required
                </label>
              </div>
            </div>
          </form>
        </Dialog>
      </>
    );
  }
}
