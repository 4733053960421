import React, { Component } from "react";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import ProductService from "../../services/ProductService";
import Loaders from "../../template/Loaders";
import MappingService from "../../services/MappingService";
import UploaderFileService from "../../services/UploaderFileService";
import tableRequest from "../../models/tableRequest.js";
import MessageComponent from "../../template/Message";

import CategoryService from "../../services/CategoryService";
import $ from "jquery";
import { Calendar } from "primereact/calendar";
import { BreadCrumb } from "primereact/breadcrumb";
import { Card } from "primereact/card";
// Mapping
export default class Mapping extends Component {
  constructor(props) {
    super(props);
    this.fileInput = React.createRef();
    this.ProductService = new ProductService();
    this.MappingService = new MappingService();
    this.tblResponse = new tableRequest();
    this.CategoryService = new CategoryService();
    this.UploaderFileService = new UploaderFileService();

    this.state = {
      visible: false,
      show: false,
      edit: false,
      DaysLife: "",
      DDLMG1: [],
      DDLMG2: [],
      DDLMG3: [],
      DDLUOM: [],
      DDLPsize: [],
      formIsValid: true,
      updateLoader: false,
      oldEXPDate: "",
      sortOrder: "",
      buttonLabel: "Submit",
      formHeading: "Add Mapping",
      errorHeading: "Number series not valid",
      uploadImagPath: "",
      ProductImg: "",
      MappingId: null,
      MGId1: null,
      MGId2: null,
      MGId3: null,
      PUOM: null,
      Psize: null,

      ProductId: null,
      ProductName: "",
      FromNo: "",
      ToNo: "",
      Qty: "",
      Remark: "",
      Mrp: "",
      BatchNo: "",
      MFGDate: "",
      EXPDate: "",
      formError: {
        ProductId: null,
        ProductName: "",

        MGId1: null,
        MGId2: null,
        MGId3: null,
        PUOM: null,
        Psize: null,
        Mrp: null,
        FromNo: null,
        ToNo: null,
        Qty: null,
        BatchNo: null,
        MFGDate: null,
        EXPDate: null,
      },
      allData: [],
      Products: [],
      sales: [],
      errorAll: [],
      loading: true,
      first: 0,
      rows: 10,
      totalRecords: 0,
      globalFilter: null,
      emptyMessage: "No Data found",
      isPreprinted: false,
      isPreprintedValue: "Static Qr Mapping",
    };

    this.brandTemplate = this.brandTemplate.bind(this);
    this.actionTemplate = this.actionTemplate.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onSortFunction = this.onSortFunction.bind(this);
    this.myUploader = this.myUploader.bind(this);
    this.hideFunc = this.hideFunc.bind(this);
    this.hideError = this.hideError.bind(this);
    this.GetFromNo = this.GetFromNo.bind(this);
    this.onPage = this.onPage.bind(this);
    this.searchFilter = this.searchFilter.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.errorDialog = this.errorDialog.bind(this);

    this.onSort = this.onSort.bind(this);
    this.setPreprintedValue = this.setPreprintedValue.bind(this);
  }
  //trigger api function on page call
  componentDidMount() {
    setTimeout(() => {
      this.GetProductDDL();
      this.GetMappings();

      //this.GetallDDL();
      // this.GetMappingTemplate();
    }, 1000);
    $(".p-column-title").on("click", function () {
      $(this)
        .next()
        .removeClass("pi-sort")
        .toggleClass("pi-sort-up")
        .addClass("pi-sort-down");
    });
  }

  // On page call event
  onPage(event) {
    this.setState({
      loading: true,
    });

    //imitate delay of a backend call
    setTimeout(() => {
      this.getLazyLoadingValues(event);
      this.GetMappings();
    }, 250);
  }

  // use for image
  brandTemplate(rowData, column) {
    var src = rowData.ProductImg;
    return <img src={src} width="100" alt={rowData.ProductImg} />;
  }

  // set Image Path
  myUploader(event, t) {
    const fileExt = event.files[0].name.split(".").pop();
    // let errors = this.state.formError;
    // errors.ProductImg = "";
    // if (event.xhr) {
    //   if (event.xhr.response) {
    //     const response = JSON.parse(event.xhr.response);
    //     console.log(response.ResponseMessage);
    //     if (response) {
    //       if (response.ResponseCode === "200") {
    //        this.refs.child.showSuccess(response.ResponseMessage);

    //         this.setState({
    //           uploadImagPath: response.Source,
    //         });
    //       } else if (response.ResponseCode !== "200") {
    //         this.setState({
    //                   show: true,

    //                 errorAll: response.ResponseData.ErrorList
    //             });

    //        console.log("error:-",this.state.errorAll);

    //         errors.ProductImg = "Only image format is accepted.";
    //         this.setState({
    //           uploadImagPath: "",
    //         });
    //       } else {
    //       }
    //     } else {
    //       //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
    //     }
    //   } else {
    //     //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
    //   }
    // } else {
    //   //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
    // }

    let formData = new FormData();
    let fileToUpload = event.files[0];
    formData.append("file", fileToUpload);

    if (fileExt == "xls" || fileExt == "xlsx") {
      this.setState({ updateLoader: false });
      this.UploaderFileService.UploadMappingExcel(formData).then((data) => {
        const response = data.data;
        this.setState({ updateLoader: true });
        //if response code is 200 success message else warning message
        if (response.ResponseCode === "200") {
          this.refs.child.showSuccess(response.ResponseMessage);
          //event.files= null;
          // this.setState({
          //   uploadImagPath: response.Source,
          // });

          this.GetMappings();
          this.fileInput.current.clear();
        } else if (response.ResponseCode !== "200") {
          this.setState({
            show: true,
            errorAll: response.ResponseData.ErrorList,
            updateLoader: true,
          });
          //this.refs.child.showError(response.ErrorMessage);

          this.fileInput.current.clear();
        }
      });
    } else {
      this.refs.child.showWarn("File extension are not acceptable.");
      this.setState({ updateLoader: true });
    }
  }

  // get product ddl list data
  GetProductDDL() {
    this.ProductService.GetProductsDDL().then((data) => {
      const resdata = data.data;

      //if resdata is not null
      if (resdata !== null && resdata.ResponseCode === "200") {
        this.setState({
          ProductDDL: resdata.ResponseData,
        });
      }
    });
  }
  GetProductLife(val) {
    this.ProductService.GetProductLife(val).then((data) => {
      const resdata = data.data;

      //if resdata is not null
      if (resdata !== null && resdata.ResponseCode === "200") {
        this.setState({
          DaysLife: resdata.ResponseData.CategoryId,
        });
      }
    });
  }

  // // get data list from api
  // GetMappingTemplate() {
  //     this.MappingService.GetMappingTemplate(this.tblResponse).then(data => {
  //         const resdata = data.data;
  //         if (resdata !== null && resdata.ResponseData !== null && resdata.ResponseData.length > 0) {
  //             this.datasource = resdata.ResponseData;
  //             this.setState({
  //                 allData: resdata.ResponseData
  //             });
  //         } else {
  //             // this.setState({
  //             //     allData: null
  //             // });
  //         }
  //     });
  // }
  // onErrorImage() {}

  // get product mapping list data
  GetMappings() {
    this.tblResponse.SortColumn = "MFGDate";
    this.MappingService.GetMappings(this.tblResponse).then((data) => {
      const resdata = data.data;
      //if response code is 200 success message else error or warning message
      
      if (
        resdata !== null &&
        resdata.ResponseData !== null &&
        resdata.ResponseData.length > 0
      ) {
        this.datasource = resdata.ResponseData;
        this.setState({
          totalRecords: resdata.ResponseData[0].TotalRows,
          first: this.tblResponse.First - 1,
          sales: this.datasource.slice(
            0,
            this.tblResponse.First - 1 + this.tblResponse.PageSize
          ),
          loading: false,
          updateLoader: true,
        });
      } else {
        this.datasource = null;
        this.setState({
          totalRecords: 0,
          first: 1,
          sales: null,
          loading: false,
          updateLoader: true,
        });
      }
    });
  }

  // set image
  brandTemplate(rowData, column) {
    var src = rowData.ProductPic;
    return <img src={src} width="100" alt={rowData.ProductPic} />;
  }

  // edit mapping
  actionTemplate(rowData, column) {
    const filterCatDDL = this.state.DDLMG1.filter(
      (x) => x.value == rowData.CategoryIdMG1
    );
    const filterCatDDLMG2 = this.state.DDLMG2.filter(
      (x) => x.value == rowData.CategoryIdMG2
    );
    const filterCatDDLMG3 = this.state.DDLMG3.filter(
      (x) => x.value == rowData.CategoryIdMG3
    );

    const editMode = (e) => {
      this.setState({
        buttonLabel: "Update",
        formHeading: "Update Mapping",
        visible: true,
        edit: true,
        MappingId: rowData.MappingId,
        MGId1: filterCatDDL.length != 0 ? rowData.CategoryIdMG1.toString() : "",
        MGId2:
          filterCatDDLMG2.length != 0 ? rowData.CategoryIdMG2.toString() : "",
        MGId3:
          filterCatDDLMG3.length != 0 ? rowData.CategoryIdMG3.toString() : "",
        PUOM: rowData.PUOM,
        Psize: rowData.PSize,
        ProductName: "" + rowData.ProductName,
        ProductId: "" + rowData.ProductId,
        FromNo: rowData.FromNo,
        ToNo: rowData.ToNo,
        Qty: "" + rowData.Qty,
        BatchNo: rowData.BatchNo,
        MFGDate: rowData.MFGDate,
        EXPDate: rowData.EXPDate,
        oldEXPDate: rowData.EXPDate,
        Remark: rowData.Remark,
        Mrp: rowData.Mrp,
      });
    };
    return (
      <div>
        <Button
          type="button"
          icon="pi pi-pencil"
          onClick={editMode}
          className="p-button-warning"
          style={{ marginRight: "3px" }}
        ></Button>
        {/* <Button
          type="button"
          icon="pi pi-trash"
          className="p-button-danger"
        ></Button> */}
      </div>
    );
  }

  setPreprintedValue(e) {
    this.setState(
      {
        isPreprintedValue: e.value,
        isPreprinted: e.value == "Static Qr Mapping" ? false : true,
      },
      () => {
        this.tblResponse.isPreprinted = this.state.isPreprinted;
        this.GetMappings();
      }
    );
  }
  // call when input
  handleChange(event) {
    const { name, value } = event.target;
    let errors = this.state.formError;
    const isValid = this.validateForm(value, name);

    this.setState({ errors, [name]: value });

    // if (name == 'PUOM' || name == 'Psize' || name == 'MGId1' || name == 'MGId2' || name == 'MGId3') {
    //   this.setState({

    //     ProductDDL: '',
    //   })
    // }
    // if (name == 'PUOM' && value.length > 0 && this.state.formError.MGId1 == null && this.state.formError.MGId2 == null && this.state.formError.MGId3 == null) {
    //   this.ProductService.GetProductSize(value).then((data) => {
    //     const resdata = data.data;
    //     if (resdata !== null && resdata.ResponseCode === "200") {
    //       this.setState({
    //         PUOM: value,

    //         DDLPsize: resdata.ResponseData == null ? [] : resdata.ResponseData,
    //       });
    //     }
    //   });
    // }
    // if (this.state.formError.PUOM == null && name == 'Psize') {

    //   this.GetProductDDL(value)
    // }
    // if (this.state.formError.Psize == null && name == 'ProductId') {
    //   this.GetProductLife(value)
    // }
    if (name == "MFGDate") {
      let exdate = new Date(value);

      exdate.setDate(exdate.getDate() + this.state.DaysLife);
      this.setState({
        EXPDate: exdate,
      });
    }
  }

  // validate form
  //resolved KREP-17
  validateForm(value, name) {
    let errors = this.state.formError;
    let IsValid = true;

    //to validate each form field with required conditions
    switch (name) {
      case "ProductId":
        if (value == null) {
          IsValid = false;
          errors.ProductId = "Please select product.";
        } else {
          this.GetProductLife(value);
          errors.ProductId = null;
        }
        break;
      case "Mrp":
        if (value == null || isNaN(value)) {
          IsValid = false;
          errors.Mrp = "Please Enter Valid product MRP.";
        } else errors.Mrp = null;
        break;
      // case "PUOM":
      //   if (value == null) {
      //     IsValid = false;
      //     errors.PUOM = "Please select product's UOM.";
      //   } else errors.PUOM = null;
      //   break;
      // case "MGId1":
      //   if (value == null) {
      //     IsValid = false;
      //     errors.MGId1 = "Please select Main Category";
      //   } else errors.MGId1 = null;
      //   break;
      // case "MGId2":
      //   if (value == null) {
      //     IsValid = false;
      //     errors.MGId2 = "Please select Sub Category.";
      //   } else errors.MGId2 = null;
      //   break;
      // case "MGId3":
      //   if (value == null) {
      //     IsValid = false;
      //     errors.MGId3 = "Please select Sub Division Category.";
      //   } else errors.MGId3 = null;
      //   break;
      case "FromNo":
        if (value.trim().length < 1) {
          IsValid = false;
          errors.FromNo = "Please enter your from number.";
        } else if (!value.match(/^[0-9]+$/)) {
          IsValid = false;
          errors.FromNo = "Please enter only number.";
          this.setState({ Qty: 0 });
        } else {
          errors.FromNo = null;
          errors.ToNo = null;
          errors.Qty = null;
          const fromNumber = value.trim() === "" ? 0 : parseInt(value.trim());
          const toNumber =
            this.state.ToNo === "" ? 0 : parseInt(this.state.ToNo);
          if (fromNumber >= 1 && toNumber >= 1) {
            if (fromNumber <= toNumber) {
              const qty = toNumber - fromNumber + 1;
              if (qty >= 0) {
                this.setState({ Qty: qty });
              } else {
                IsValid = false;
                errors.FromNo = "From number should be less than to number.";
                this.setState({ Qty: 0 });
              }
            } else {
              IsValid = false;
              errors.FromNo = "From number should be less than to number.";
              this.setState({ Qty: 0 });
            }
          } else {
            // IsValid = false;
            // errors.FromNo = "From number should be less than to number.";
            this.setState({ Qty: 0 });
          }
        }
        break;
      case "ToNo":
        if (value < 1) {
          IsValid = false;
          errors.ToNo = "Please enter To Number.";
        } else errors.ToNo = null;
        break;
      case "Qty":
        if (value < 1) {
          IsValid = false;
          errors.Qty = "Please enter +ve quantity.";
        }
        //else
        //     if(value.toString().trim().length>10){
        //     IsValid=false;
        //     errors.Qty="enter valid quantity.";
        // }
        else {
          // if (!value.toString().match(/^[0-9]+$/)) {
          //   IsValid = false;
          //   errors.Qty = "Please enter only number.";
          //   this.setState({ Qty: 0 });
          // } else {
          errors.ToNo = null;
          errors.Qty = null;
          errors.FromNo = null;
          const qty = parseInt(value); //.toString().trim() === "" ? 0 : parseInt(value.toString().trim());
          const fromNumber =
            this.state.FromNo.toString().trim() === ""
              ? 0
              : parseInt(this.state.FromNo.toString().trim());
          if (fromNumber >= 1 && qty >= 1) {
            const toNumber = qty + fromNumber - 1;
            if (toNumber >= 0) {
              this.setState({ ToNo: toNumber });
            } else {
              IsValid = false;
              errors.Qty =
                "To number should be greater than or equal to  from number.";
              this.setState({ ToNo: 0 });
            }
          } else {
            // IsValid = false;
            // errors.ToNo = "To number should be greater than from number.";
            this.setState({ ToNo: 0 });
          }
        }
        //}
        break;
      case "BatchNo":
        if (value.trim().length < 1) {
          IsValid = false;
          errors.BatchNo = "Please enter Batch No";
        } else errors.BatchNo = null;
        break;
      case "MFGDate":
        if (value == null || !(value instanceof Date)) {
          IsValid = false;
          errors.MFGDate = "Please select MFG Date";
        } else if (
          !value.toLocaleDateString().match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/)
        ) {
          IsValid = false;
          errors.MFGDate = "Please enter only date.";
        } else errors.MFGDate = null;
        break;
      case "EXPDate":
        let a = new Date(this.state.MFGDate);
        if (value == null || value < a) {
          IsValid = false;
          errors.EXPDate = "Please select Date after MFG Date";
        } else if (
          value != "" &&
          !value.toLocaleDateString().match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/)
        ) {
          IsValid = false;
          errors.EXPDate = "Please enter only date.";
        } else errors.EXPDate = null;
        break;
      default:
        break;
    }

    return IsValid;
  }

  // open dialog
  openDialog() {
    this.setState({ visible: true });
    this.formReset();
  }

  // open dialog
  errorDialog() {
    this.setState({ show: true });
  }

  hideFunc() {
    this.setState({
      visible: false,
      edit: false,
      buttonLabel: "Submit",
      formHeading: "Add Mapping",
    });
    this.formReset();
  }

  hideError() {
    this.setState({
      show: false,
    });
  }

  // form Reset
  formReset() {
    this.setState({
      MappingId: 0,
      oldEXPDate: "",

      DaysLife: "",
      edit: false,
      ProductId: null,
      MGId1: null,
      MGId2: null,
      MGId3: null,
      PUOM: null,
      Psize: null,
      FromNo: "",
      ToNo: "",
      Qty: "",
      BatchNo: "",
      Remark: "",
      Mrp: "",
      MFGDate: "",
      EXPDate: "",
      // Remarks: ''
    });
    let errors = this.state.formError;
    Object.entries(errors).map(([key, value]) => {
      errors[key] = "";
    });
  }

  // submit form
  submitForm(event) {
    event.preventDefault();

    let isfrmvalid = true;
    if (this.state.buttonLabel != "Update") {
      let checkerror = this.state.formError;

      Object.entries(checkerror).map(([key, value]) => {
        const val = this.state[key];
        let valid = this.validateForm(val, key);
        //form is not valid

        if (!valid) {
          this.setState({ checkerror: isfrmvalid });
          isfrmvalid = false;
        }
      });
    }
    console.log(this.state.formError);
    //form is valid
    if (isfrmvalid) {
      this.setState({ updateLoader: false });
      let formData = null;
      if (
        this.state.buttonLabel == "Update" &&
        this.state.oldEXPDate != this.state.EXPDate
      ) {
        formData = {
          MappingId: this.state.MappingId,
          EXPDate: this.state.EXPDate.toLocaleDateString(),
        };
      } else if (this.state.buttonLabel == "Submit") {
        formData = {
          MappingId: this.state.MappingId,
          ProductId:
            this.state.ProductId === "" ? 0 : parseInt(this.state.ProductId),
          // CategoryIdMG1: this.state.MGId1 === "" ? 0 : parseInt(this.state.MGId1),
          // CategoryIdMG2: this.state.MGId2 === "" ? 0 : parseInt(this.state.MGId2),
          // CategoryIdMG3: this.state.MGId3 === "" ? 0 : parseInt(this.state.MGId3),
          FromNo: this.state.FromNo === "" ? 0 : parseInt(this.state.FromNo),
          ToNo: this.state.ToNo === "" ? 0 : parseInt(this.state.ToNo),
          Qty: this.state.Qty === "" ? 0 : parseInt(this.state.Qty),
          MFGDate: this.state.MFGDate.toLocaleDateString(),
          BatchNo: this.state.BatchNo,
          EXPDate: this.state.EXPDate.toLocaleDateString(),
          Remark: this.state.Remark,
          Mrp: this.state.Mrp,
          isPreprinted: this.state.isPreprinted,
        };
      }

      setTimeout(() => {
        if (formData != null) {
          this.MappingService.CreateMapping(formData).then((data) => {
            const resdata = data.data;
            //if ResponseCode is 200 then success message else error or warning message
            if (resdata.ResponseCode === "200") {
              this.refs.child.showSuccess(resdata.ResponseMessage);
              this.setState({
                loading: true,
                visible: false,
                updateLoader: true,
              });
              this.formReset();
              this.GetMappings();
            } else if (resdata.ResponseCode === "400") {
              this.refs.child.showWarn(resdata.ResponseMessage);
            } else {
              this.refs.child.showError(resdata.ResponseMessage);
            }
          });
        } else {
          this.hideFunc();
        }
      }, 1000);
    }
  }

  // get next fromNO
  GetFromNo() {
    this.formReset();
    this.setState({ visible: true });

    this.MappingService.GetFromNumberMap(this.state.isPreprinted).then(
      (data) => {
        const resdata = data.data;
        //if ResponseCode is 200 FromNo state will be set
        if (resdata.ResponseCode === "200" && resdata.ResponseData != null) {
          let frmNumber = resdata.ResponseData.FrmNo;
          frmNumber = frmNumber ?? 0 + 1;
          this.setState({ FromNo: "" + frmNumber });
        }
      }
    );
  }
  // set lazy load values
  getLazyLoadingValues(event) {
    this.setState({ rows: event.rows });
    this.tblResponse.PageSize = event.rows;
    this.tblResponse.First = event.first + 1;
    this.tblResponse.IsActive = "1";
    this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
    //this.tblResponse.SortColumn = event.sortField;
    this.tblResponse.SortOrder = event.sortOrder === 1 ? "ASC" : "DESC";
  }

  // serach table list
  searchFilter(e) {
    this.setState({
      loading: true,
    });
    this.tblResponse.SearchValue = e.target.value;
    this.GetMappings();
  }
  onSortFunction(event) {
    console.log(event);
  }
  GetallDDL() {
    this.CategoryService.GetCategoryDDLMG1().then((data) => {
      const resdata = data.data;
      if (resdata !== null && resdata.ResponseCode === "200") {
        this.setState({
          DDLMG1: resdata.ResponseData == null ? [] : resdata.ResponseData,
        });
      }
    });
    this.CategoryService.GetCategoryDDLMG2(1).then((data) => {
      const resdata = data.data;
      if (resdata !== null && resdata.ResponseCode === "200") {
        this.setState({
          DDLMG2: resdata.ResponseData == null ? [] : resdata.ResponseData,
        });
      }
    });

    this.CategoryService.GetCategoryDDLMG3(1).then((data) => {
      const resdata = data.data;
      if (resdata !== null && resdata.ResponseCode === "200") {
        this.setState({
          DDLMG3: resdata.ResponseData == null ? [] : resdata.ResponseData,
        });
      }
    });
    this.ProductService.GetProductUOM().then((data) => {
      const resdata = data.data;
      if (resdata !== null && resdata.ResponseCode === "200") {
        this.setState({
          DDLUOM: resdata.ResponseData == null ? [] : resdata.ResponseData,
        });
      }
    });
  }

  // sort data
  onSort(event) {
    this.setState({ sortOrder: this.state.sortOrder === 1 ? 2 : 1 });
    const sortField =
      event.sortField == "MFGDate" ? "MFGDate" : event.sortField;
    this.tblResponse.SortColumn = sortField ? sortField : "";
    this.tblResponse.SortOrder = this.state.sortOrder === 1 ? "DESC" : "ASC";
    this.GetMappings();
  }

  // return html
  //resolved KREP-19,KREP-126,KREP-125,KREP-122,KREP-112(CR)
  render() {
    const items = [{ label: "Transaction" }, { label: "Mapping" }];

    const home = { icon: "pi pi-home", url: "/dashboard" };
    const options = ["Static Qr Mapping"]; //, 'PrePrinted Qr Mapping'];
    let header = (
      <div className="row">
        <div className="col-md-6">
          {/* <SelectButton className="custom-tab" value={this.state.isPreprintedValue} options={options} onChange={this.setPreprintedValue} /> */}
        </div>
        <div className="col-md-6" style={{ textAlign: "right" }}>
          <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>
          <InputText
            type="search"
            onInput={this.searchFilter}
            placeholder="Global Search"
            size="30"
          />
          <Button
            label="Product Mapping"
            style={{ marginLeft: "8px" }}
            icon="pi pi-plus-circle"
            onClick={this.GetFromNo}
          />
          {/* <CSVLink data={this.state.allData} filename={"Mapping Template.csv"}
                        className="btn btn-primary ml-2"
                        target="_blank">Download Template</CSVLink> */}
        </div>
      </div>
    );
    return (
      <>
        <MessageComponent ref="child" />
        <Loaders loader={this.state.updateLoader} />
        <Card>
          <div className="row mb-3">
            <div className="col-md-6 heading">
              <h1>Mapping</h1>
            </div>
            <div className="col-md-6 custom-breadcrumb">
              <BreadCrumb model={items} home={home} />
            </div>
          </div>

          <DataTable
            responsive={true}
            header={header}
            scrollable={true}
            style={{ width: "100%" }}
            globalFilter={this.state.globalFilter}
            emptyMessage={this.state.emptyMessage}
            value={this.state.sales}
            sortMode="multiple"
            paginator={true}
            rows={this.state.rows}
            rowsPerPageOptions={[10, 50, 200, 500]}
            totalRecords={this.state.totalRecords}
            lazy={true}
            first={this.state.first}
            onPage={this.onPage}
            onSort={this.onSort}
          >
            <Column
              field="ProductName"
              header="Product Name"
              style={{ width: "200px" }}
              sortable={true}
            />
            <Column
              field="FromNo"
              header="From No."
              style={{ width: "120px" }}
              sortable={true}
            />
            <Column
              field="ToNo"
              header="To No."
              style={{ width: "120px" }}
              sortable={true}
            />
            <Column
              field="Qty"
              header="Quantity"
              style={{ width: "120px" }}
              sortable={true}
            />
            <Column
              field="CreatedDt"
              header="Created Date"
              style={{ width: "170px" }}
              sortable={true}
            />
            <Column
              field="MFGDate"
              header="MFG Date"
              style={{ width: "170px" }}
              sortable={true}
            />
            <Column
              field="EXPDate"
              header="EXP Date"
              style={{ width: "170px" }}
              sortable={true}
            />
            <Column
              field="BatchNo"
              header="Batch No"
              style={{ width: "170px" }}
              sortable={true}
            />
            <Column field="Mrp" header="MRP" style={{ width: "170px" }} />
            <Column
              field="Remark"
              header="Remarks"
              style={{ width: "200px" }}
              sortable={true}
            />
            <Column
              body={this.actionTemplate}
              header="Action"
              style={{ textAlign: "center", width: "8em" }}
            />
          </DataTable>
          <div className="row">
            <div className="col-md-12 mt-5">
              {/* <label>Upload Excel(.xlsx or .xls):</label>

              <FileUpload ref={this.fileInput} name="mappingfile[]" value={this.state.mappingfile}
                customUpload uploadHandler={e => { this.myUploader(e, this) }}
                accept=".xlsx" /> */}
              {/* {this.state.uploadImagPath ? (
                      <div className="p-fileupload-row">
                        <div>
                          <img
                            alt="file.xlsx"
                            role="presentation"
                            src={this.state.uploadImagPath}
                            width="50"
                          />
                        </div>
                      </div>
                    ) : null} 
              <a className="btn btn-primary mt-3 pt-2 pb-2" href={require('../../assets/ExcelTemplate/Mapping_details.xlsx')} download="Mapping_details.xlsx">Download Template </a>
        */}
            </div>

            {/* <div className="col-md-8 mt-5 pt-5">
        <button className="btn btn-primary">Save</button>
         </div>           */}
          </div>
          {/* <div>
response.ErrorMessage
</div> */}
        </Card>

        <Dialog
          disabled={this.state.edit}
          className="popup-modal"
          header={this.state.formHeading}
          visible={this.state.visible}
          modal={true}
          onHide={this.hideFunc}
        >
          <form onSubmit={this.submitForm} className="form-padding">
            <div className="row">
              {/* <div className="col-md-4 text-right">
                <label>Main Category <span>*</span>:</label>
              </div>
              <div className="col-md-8 mb-2">
                <Dropdown disabled={this.state.edit} options={this.state.DDLMG1} placeholder="Select Main Category" className="form-control" value={this.state.MGId1} onChange={this.handleChange} name="MGId1" filter />
                {this.state.formError.MGId1 !== null ?
                  <div className='error'>{this.state.formError.MGId1}</div> : null}
              </div>
              <div className="col-md-4 text-right">
                <label>Sub Category <span>*</span>:</label>
              </div>
              <div className="col-md-8 mb-2">
                <Dropdown disabled={this.state.edit} options={this.state.DDLMG2} placeholder="Sub Category" className="form-control" value={this.state.MGId2} onChange={this.handleChange} name="MGId2" filter />
                {this.state.formError.MGId2 !== null ?
                  <div className='error'>{this.state.formError.MGId2}</div> : null}
              </div>
              <div className="col-md-4 text-right">
                <label>Sub Division Category <span>*</span>:</label>
              </div>
              <div className="col-md-8 mb-2">
                <Dropdown disabled={this.state.edit} options={this.state.DDLMG3} placeholder="Sub Division Category" className="form-control" value={this.state.MGId3} onChange={this.handleChange} name="MGId3" filter />
                {this.state.formError.MGId3 !== null ?
                  <div className='error'>{this.state.formError.MGId3}</div> : null}
              </div>
              <div className="col-md-4 text-right">
                <label>Product UOM<span>*</span>:</label>
              </div>
              <div className="col-md-8 mb-2">
                <Dropdown disabled={this.state.edit} options={this.state.DDLUOM} className="form-control" placeholder={this.state.PUOM != null ? this.state.PUOM : "Select UOM"} value={this.state.PUOM} onChange={this.handleChange} name="PUOM" />
                {this.state.formError.PUOM !== null ?
                  <div className='error'>{this.state.formError.PUOM}</div> : null}
              </div> <div className="col-md-4 text-right">
                <label>Product  Size <span>*</span>:</label>
              </div>
              <div className="col-md-8 mb-2">
                <Dropdown disabled={this.state.edit} options={this.state.DDLPsize} placeholder={this.state.Psize ? this.state.Psize : "Select Product Size"} className="form-control" value={this.state.Psize} onChange={this.handleChange} name="Psize" />
                {this.state.formError.Psize !== null ?
                  <div className='error'>{this.state.formError.Psize}</div> : null}
              </div> */}
              <div className="col-md-4 text-right">
                <label>
                  Product Name <span>*</span>:
                </label>
              </div>
              <div className="col-md-8 mb-2">
                <Dropdown
                  filter
                  disabled={this.state.edit}
                  options={this.state.ProductDDL}
                  placeholder={
                    this.state.ProductName
                      ? this.state.ProductName
                      : "Select Product"
                  }
                  className="form-control"
                  value={this.state.ProductId}
                  onChange={this.handleChange}
                  name="ProductId"
                />
                {this.state.formError.ProductId !== null ? (
                  <div className="error">{this.state.formError.ProductId}</div>
                ) : null}
              </div>
              <div className="col-md-4 text-right">
                <label>
                  From No.<span>*</span>:
                </label>
              </div>
              <div className="col-md-8 mb-2">
                <input
                  type="text"
                  id="txtProductId"
                  readOnly={this.state.edit}
                  value={this.state.FromNo}
                  onChange={this.handleChange}
                  className="form-control"
                  name="FromNo"
                />
                {this.state.formError.FromNo !== null ? (
                  <div className="error">{this.state.formError.FromNo}</div>
                ) : null}
              </div>

              <div className="col-md-4 text-right">
                <label>
                  Product Quantity in Units (Bottles/Pauchs)<span>*</span>:
                </label>
              </div>
              <div className="col-md-8 mb-2">
                <input
                  type="number"
                  readOnly={this.state.edit}
                  value={this.state.Qty}
                  onChange={this.handleChange}
                  className="form-control"
                  name="Qty"
                />
                {this.state.formError.Qty !== null ? (
                  <div className="error">{this.state.formError.Qty}</div>
                ) : null}
              </div>

              <div className="col-md-4 text-right">
                <label>
                  To No.<span>*</span>:
                </label>
              </div>
              <div className="col-md-8 mb-2">
                <input
                  type="text"
                  readOnly
                  value={this.state.ToNo}
                  onChange={this.handleChange}
                  className="form-control"
                  name="ToNo"
                />
                {this.state.formError.ToNo !== null ? (
                  <div className="error">{this.state.formError.ToNo}</div>
                ) : null}
              </div>

              <div className="col-md-4 text-right">
                <label>
                  D.O.M.<span>*</span>:
                </label>
              </div>
              <div className="col-md-8 mb-2">
                {this.state.edit ? (
                  this.state.MFGDate
                ) : (
                  <Calendar
                    className="custom-calender"
                    value={this.state.MFGDate}
                    onChange={this.handleChange}
                    showIcon={true}
                    placeholder={this.state.MFGDate}
                    appendTo={document.getElementById("root")}
                    name="MFGDate"
                  />
                )}
                {/* <input type="text" value={this.state.ValidFrm} onChange={this.handleChange} className="form-control" name="ValidFrm" /> */}
                {this.state.formError.MFGDate !== null ? (
                  <div className="error">{this.state.formError.MFGDate}</div>
                ) : null}
              </div>
              <div className="col-md-4 text-right">
                <label>
                  Expiry<span>*</span>:
                </label>
              </div>
              <div
                disabled={this.state.MFGDate.length == ""}
                className="col-md-8 mb-2"
              >
                <Calendar
                  className="custom-calender"
                  // minDate={this.state.MFGDate!=""?this.state.MFGDate:}
                  value={this.state.EXPDate}
                  onChange={this.handleChange}
                  showIcon={true}
                  placeholder={this.state.EXPDate}
                  appendTo={document.getElementById("root")}
                  name="EXPDate"
                />
                {/* <input type="text" value={this.state.ValidFrm} onChange={this.handleChange} className="form-control" name="ValidFrm" /> */}
                {this.state.formError.EXPDate !== null ? (
                  <div className="error">{this.state.formError.EXPDate}</div>
                ) : null}
              </div>

              <div className="col-md-4 text-right">
                <label>
                  Batch No<span>*</span>:
                </label>
              </div>
              <div className="col-md-8 mb-2">
                <input
                  type="text"
                  readOnly={this.state.edit}
                  value={this.state.BatchNo}
                  onChange={this.handleChange}
                  className="form-control"
                  name="BatchNo"
                />
                {this.state.formError.BatchNo !== null ? (
                  <div className="error">{this.state.formError.BatchNo}</div>
                ) : null}
              </div>

              <div className="col-md-4 text-right">
                <label>
                  Product MRP<span>*</span>:
                </label>
              </div>
              <div className="col-md-8 mb-2">
                <input
                  type="text"
                  readOnly={this.state.edit}
                  value={this.state.Mrp}
                  onChange={this.handleChange}
                  className="form-control"
                  name="Mrp"
                />{" "}
                {this.state.formError.Mrp !== null ? (
                  <div className="error">{this.state.formError.Mrp}</div>
                ) : null}
              </div>
              <div className="col-md-4 text-right">
                <label>Remark:</label>
              </div>
              <div className="col-md-8 mb-2">
                <input
                  type="text"
                  readOnly={this.state.edit}
                  value={this.state.Remark}
                  onChange={this.handleChange}
                  className="form-control"
                  name="Remark"
                />
              </div>

              <div className="col-md-12 text-center">
                <button type="submit" className="btn btn-primary">
                  {this.state.buttonLabel}
                </button>
              </div>
            </div>
            <div>
              <label>
                Note: Fields marked as (<span style={{ color: "red" }}>*</span>)
                are required
              </label>
            </div>
          </form>
        </Dialog>

        <Dialog
          visible={this.state.show}
          style={{ width: "60%" }}
          onHide={this.hideError}
          className="popup-modal"
          header={this.state.errorHeading}
        >
          <DataTable
            responsive={true}
            style={{ width: "100%" }}
            emptyMessage={this.state.emptyMessage}
            value={this.state.errorAll}
            sortMode="multiple"
            rows={this.state.rows}
            rowsPerPageOptions={[10, 50, 200, 500]}
            lazy={true}
            first={this.state.first}
            onPage={this.onPage}
            loading={this.state.loading}
          >
            <Column
              field="ProductCd"
              header="Product Code"
              style={{ width: "100px" }}
            />

            <Column
              field="FromNo"
              header="From No."
              style={{ width: "120px" }}
            />
            <Column field="ToNo" header="To No." style={{ width: "120px" }} />
            <Column field="Qty" header="Quantity" style={{ width: "100px" }} />

            <Column
              field="Error"
              header="Error Message"
              style={{ width: "170px" }}
            />

            <Column
              field="BatchNo"
              header="Batch No"
              style={{ width: "100px" }}
            />
          </DataTable>
        </Dialog>
      </>
    );
  }
}
