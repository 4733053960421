import React, { Component } from "react";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import MappingService from "../../services/MappingService";
import tableRequest from "../../models/tableRequest.js";
import DeMappingService from "../../services/DeMappingService";
import MessageComponent from "../../template/Message";
import Loaders from "../../template/Loaders";
import $ from "jquery";
import { Calendar } from "primereact/calendar";

export default class StaticMapping extends Component {
  constructor(props) {
    super(props);

    this.textInput0 = React.createRef();
    this.textInput = React.createRef();
    this.MappingService = new MappingService();
    this.tblResponse = new tableRequest();

    this.state = {
      visible: false,
      scanPrePrinted: true,
      formIsValid: true,
      updateLoader: false,
      sortOrder: "",
      refrenceFromNo: 0,
      refrenceToNo: 0,
      staticQr: "",
      prePrintedQr: "",
      formError: {
        ProductName: null,
        FromNo: null,
        ToNo: null,
        Qty: null,
      },

      sales: [],
      loading: true,
      first: 0,
      rows: 10,
      totalRecords: 0,
      globalFilter: null,
      emptyMessage: "No Data found",
    };

    //this.brandTemplate = this.brandTemplate.bind(this);
    // this.actionTemplate = this.actionTemplate.bind(this);
    // this.submitForm = this.submitForm.bind(this);
    // this.handleChange = this.handleChange.bind(this);
    // this.hideFunc = this.hideFunc.bind(this);
    this.onPage = this.onPage.bind(this);
    this.searchFilter = this.searchFilter.bind(this);
    this.onSort = this.onSort.bind(this);
  }
  //trigger api function on page call
  componentDidMount() {
    setTimeout(() => {
      this.GetMappings();
    }, 1000);

    $(".p-column-title").on("click", function () {
      $(this)
        .next()
        .removeClass("pi-sort")
        .toggleClass("pi-sort-up")
        .addClass("pi-sort-down");
    });
  }

  // on page event call
  onPage(event) {
    this.setState({
      loading: true,
    });

    //imitate delay of a backend call
    setTimeout(() => {
      this.getLazyLoadingValues(event);
      this.GetMappings();
    }, 250);
  }

  // get product mapping list data
  GetMappings() {
    this.MappingService.GetStaticQrMapping(this.tblResponse).then((data) => {
      const resdata = data.data;
      this.setState({
        updateLoader: true,
      });
      //if response data is not null sales state wiill be set to response data
      if (
        resdata !== null &&
        resdata.ResponseData !== null &&
        resdata.ResponseData.length > 0
      ) {
        this.datasource = resdata.ResponseData;
        this.setState({
          totalRecords: resdata.ResponseData[0].TotalRows,
          first: this.tblResponse.First - 1,
          sales: this.datasource.slice(
            0,
            this.tblResponse.First - 1 + this.tblResponse.PageSize
          ),
          loading: false,
          updateLoader: true,
        });
      }
      //if response data is  null sales state will be set null
      else {
        this.datasource = null;
        this.setState({
          totalRecords: 0,
          first: 1,
          sales: null,
          loading: false,
        });
      }
    });
  }

  // Create Demapping
  SaveMapping(data) {
    setTimeout(() => {
      this.MappingService.SaveStaticQrMapping(data).then((data) => {
        const resdata = data.data;
        //if ResponseCode is 200 success message will be shown else will move to 'else statement'
        if (resdata.ResponseCode === "200") {
          this.textInput0.current.focus();
          this.textInput0.current.value = "";
          this.textInput.current.value = "";

          this.refs.child.showSuccess(resdata.ResponseMessage);
          this.GetMappings();
        } else {
          this.refs.child.showError(resdata.ResponseMessage);
        }
      });
    }, 1000);
  }

  ScanStaticItem(e) {
    if (e.key === "Enter") {
      let qr = e.target.value;
      /////////for multiline qr code on KREPL//
      const splitQr = qr.split("QRLink:");
      console.log(splitQr[1]);
      /////////for multiline qr code on KREPL//

      /////////for multiline qr code on KREPL//
      qr = splitQr[1] === undefined ? qr : splitQr[1];
      /////////for multiline qr code on KREPL//

      if (qr.includes("uid")) {
        this.setState({ staticQr: qr, scanPrePrinted: false });
        // document.getElementsByName('scanPrePrinted').focus();
        this.textInput.current.focus();
      } else {
        e.target.value = "";
        this.refs.child.showWarn("Invalid scanned Qr");
      }
    }
  }
  ScanAndSave(e) {
    if (e.key === "Enter") {
      let qr = e.target.value;

      if (qr.includes("uid")) {
        const formData = {
          StaticEncryptedQr: this.state.staticQr,
          PreprintedEncryptedQr: qr,
        };
        this.SaveMapping(formData);
        e.target.value = "";
      } else this.refs.child.showWarn("Invalid scanned Qr");
      e.target.value = "";
    }
  }
  // set lazy loading
  getLazyLoadingValues(event) {
    this.setState({ rows: event.rows });
    this.tblResponse.PageSize = event.rows;
    this.tblResponse.First = event.first + 1;
    this.tblResponse.IsActive = "1";
    this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
    //this.tblResponse.SortColumn = event.sortField;
    this.tblResponse.SortOrder = event.sortOrder === 1 ? "ASC" : "DESC";
  }

  // search data filter
  searchFilter(e) {
    this.setState({
      loading: true,
    });
    this.tblResponse.SearchValue = e.target.value;
    this.GetMappings();
  }

  // form reset
  formReset() {
    this.setState({
      Id: 0,
      ProductId: null,
      ProductName: "",
      FromNo: "",
      ToNo: "",
      Qty: "",
      // Remarks: ''
    });
    let errors = this.state.formError;
    Object.entries(errors).map(([key, value]) => {
      errors[key] = "";
    });
  }
  // call when sort data
  onSort(event) {
    this.setState({ sortOrder: this.state.sortOrder === 1 ? 2 : 1 });
    const sortField = event.sortField;
    this.tblResponse.SortColumn = sortField ? sortField : "";
    this.tblResponse.SortOrder = this.state.sortOrder === 1 ? "DESC" : "ASC";
    this.GetMappings();
  }

  //return html data
  //resolved KREP-20,KREP-38
  render() {
    let header = (
      <div className="row">
        <div className="col-md-8 text-left">
          <form className="form-inline">
            <div className="form-group">
              <input
                ref={this.textInput0}
                placeholder="Scan Static Qr:"
                autoFocus
                name="scanStatic"
                type="search"
                className="form-control ml-2"
                id="scan"
                onKeyDown={(e) => {
                  this.ScanStaticItem(e);
                }}
              />
            </div>
            <div className="form-group arrow-class">
              <input
                ref={this.textInput}
                placeholder="Scan PrePrinted Qr:"
                name="scanPrePrinted"
                type="search"
                className="form-control ml-2"
                id="scan"
                onKeyDown={(e) => {
                  this.ScanAndSave(e);
                }}
              />
            </div>
          </form>
        </div>
        <div className="col-md-4">
          <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>
          <InputText
            type="search"
            onInput={(e) => this.setState({ globalFilter: e.target.value })}
            placeholder="Global Search"
            className="search-space"
            size="30"
          />
        </div>
      </div>
    );
    return (
      <>
        <MessageComponent ref="child" />
        <Loaders loader={this.state.updateLoader} />
        <div className="heading">
          <h1>Static Qr Mapping</h1>
        </div>

        <DataTable
          responsive={true}
          header={header}
          globalFilter={this.state.globalFilter}
          emptyMessage={this.state.emptyMessage}
          value={this.state.sales}
          sortMode="multiple"
          paginator={true}
          rows={this.state.rows}
          rowsPerPageOptions={[10, 50, 200, 500]}
          totalRecords={this.state.totalRecords}
          lazy={true}
          first={this.state.first}
          onPage={this.onPage}
          onSort={this.onSort}
        >
          <Column field="ProductName" header="Product Name" sortable={true} />
          <Column field="StaticSeqNo" header="Static SeqNo." sortable={true} />
          <Column
            field="PreprintedSeqNo"
            header="Preprinted SeqNo"
            sortable={true}
          />
          <Column field="Prefix" header="Prefix" sortable={true} />
        </DataTable>
      </>
    );
  }
}
