import instance from './DefaultApi';

export default class StockManagementService {
    ControllerName = 'StockManagement';

    // create new Stock Management  
    CreateStockManagement(stockData) {
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/CreateStockManagement`, stockData).then(res => res)
    }

    // get all stock  data
    GetStock(tableRequest) {
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetStock`, tableRequest).then(res => res)
    }
}