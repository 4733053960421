import React, { Component } from "react";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import PriceMasterService from "../../services/PriceMasterService";
import ProductService from "../../services/ProductService";
import Loaders from "../../template/Loaders";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { BreadCrumb } from "primereact/breadcrumb";
import tableRequest from "../../models/tableRequest.js";
import { Dropdown } from "primereact/dropdown";
import { confirmAlert } from "react-confirm-alert"; // Import
import { ConfirmationModal } from "../../template/ConfirmationModal";
import MessageComponent from "../../template/Message";
import $ from "jquery";

// Manage Price Master
export default class PriceMaster extends Component {
  constructor(props) {
    super(props);
    this.PriceMasterService = new PriceMasterService();
    this.ProductService = new ProductService();

    this.tblResponse = new tableRequest();
    this.state = {
      visible: false,
      sortIcon: false,
      updateLoader: false,
      PriceId: "",
      ProductId: "",
      Price: "",
      ProductDDL: [],
      sortOrder: "1",
      formError: {
        PriceId: "",
        ProductId: "",
        Price: "",
      },

      formHeading: "Add Price",
      buttonLabel: "Submit",
      sales: [],
      loading: true,
      first: 0,
      rows: 5,
      totalRecords: 0,
      globalFilter: null,
      emptyMessage: "No Data found",
    };

    this.actionTemplate = this.actionTemplate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.hideFunc = this.hideFunc.bind(this);
    this.onPage = this.onPage.bind(this);
    this.searchFilter = this.searchFilter.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.onSort = this.onSort.bind(this);
  }
  //trigger api function on page call
  componentDidMount() {
    setTimeout(() => {
      this.GetPrice();
      this.GetProductDDL();
    }, 1000);

    $(".p-column-title").on("click", function () {
      $(this)
        .next()
        .removeClass("pi-sort")
        .toggleClass("pi-sort-up")
        .addClass("pi-sort-down");
    });
  }

  //loading page
  onPage(event) {
    this.setState({
      loading: true,
    });

    //imitate delay of a backend call
    setTimeout(() => {
      this.getLazyLoadingValues(event);
      this.GetPrice();
    }, 250);
  }

  // get list data
  getLazyLoadingValues(event) {
    this.setState({ rows: event.rows });
    this.tblResponse.PageSize = event.rows;
    this.tblResponse.First = event.first + 1;
    this.tblResponse.IsActive = true;
    this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
    this.tblResponse.SortColumn = event.sortField;
    this.tblResponse.SortOrder = event.sortOrder === 1 ? "ASC" : "DESC";
  }

  // get price data list from api
  GetPrice() {
    this.tblResponse.SortColumn = "CreatedDate";
    this.PriceMasterService.GetPrice(this.tblResponse).then((data) => {
      const resdata = data.data;
      // when response data is not null
      if (resdata.ResponseCode === "200") {
        if (
          resdata !== null &&
          resdata.ResponseData !== null &&
          resdata.ResponseData.length > 0
        ) {
          this.datasource = resdata.ResponseData;
          this.setState({
            totalRecords: resdata.ResponseData[0].TotalRows,
            first: this.tblResponse.First - 1,
            sales: this.datasource.slice(
              0,
              this.tblResponse.First - 1 + this.tblResponse.PageSize
            ),
            loading: false,
          });
        }
        // when response data is null
        else {
          this.datasource = null;
          this.setState({
            totalRecords: 0,
            first: 1,
            sales: null,
            loading: false,
          });
        }
        this.setState({ updateLoader: true });
      }
    });
  }

  // Get Product for drop down list
  GetProductDDL() {
    this.ProductService.GetProductsDDL().then((data) => {
      const resdata = data.data;
      if (resdata !== null && resdata.ResponseCode === "200") {
        this.setState({
          ProductDDL: resdata.ResponseData,
        });
      }
    });
  }

  // call every change of form and validation part also
  handleChange(event) {
    const { name, value } = event.target;
    let errors = this.state.formError;

    const isValid = this.validateForm(value, name);
    this.setState({ errors, [name]: value });
  }

  //Validate form
  validateForm(value, name) {
    let errors = this.state.formError;
    let IsValid = true;
    //to validate each form field
    switch (name) {
      case "ProductId":
        if (value.length < 1) {
          IsValid = false;
          errors.ProductId = "Please select Product.";
        } else errors.ProductId = "";
        break;
      case "Price":
        if (value < 1) {
          IsValid = false;
          errors.Price = "Please enter +ve Price.";
        }
        // else if (value.length >= 1) {
        //     if (!value.match(/^[A-Za-z]+$/)) {
        //         IsValid = false;
        //         errors.Price = "Price should be alpha only."
        //     } else errors.Price = ''
        // }
        else errors.Price = "";
        break;

      default:
        break;
    }

    return IsValid;
  }

  // reset form text value
  formReset() {
    this.setState({
      PriceId: 0,
      ProductId: "",
      Price: "",
    });
    let errors = this.state.formError;
    Object.entries(errors).map(([key, value]) => {
      errors[key] = "";
    });
  }

  hideFunc() {
    this.setState({
      visible: false,
      buttonLabel: "Submit",
      formHeading: "Add Price",
    });
    this.formReset();
  }

  // open Dialog
  openDialog() {
    this.setState({ visible: true });
    this.formReset();
  }
  // submit form and create or update user
  submitForm(event) {
    event.preventDefault();

    let isfrmvalid = true;
    let checkerror = this.state.formError;

    Object.entries(checkerror).map(([key, value]) => {
      const val = this.state[key];
      let valid = this.validateForm(val, key);
      //form is not valid
      if (!valid) {
        this.setState({ checkerror: isfrmvalid });
        isfrmvalid = false;
      }
    });
    //form is valid
    if (isfrmvalid) {
      this.setState({ updateLoader: false });
      const formData = {
        PriceId: this.state.PriceId,
        ProductId: this.state.ProductId,
        Price: this.state.Price,
      };

      setTimeout(() => {
        this.PriceMasterService.CreatePrice(formData).then((data) => {
          const resdata = data.data;
          //response data not null then success else warning or error
          if (resdata !== null && resdata.ResponseCode === "200") {
            this.refs.Messchild.showSuccess(resdata.ResponseMessage);
            this.setState({
              loading: true,
              visible: false,
              updateLoader: true,
            });
            this.formReset();
            this.GetPrice();
          } else if (resdata.ResponseCode === "400") {
            this.setState({
              updateLoader: true,
            });
            this.refs.Messchild.showWarn(resdata.ResponseMessage);
          } else {
            this.setState({
              updateLoader: true,
            });
            this.refs.Messchild.showError(resdata.ResponseMessage);
          }
        });
      }, 100);
    }
  }

  // delete user data
  // deleteUser(referenceID) {
  //     this.UserService.DeleteUser(referenceID).then(data => {
  //         const resdata = data.data;
  //         if (resdata !== null && resdata.ResponseCode === '200') {
  //             this.refs.Messchild.showSuccess(resdata.ResponseMessage);
  //             const totlrecord = this.state.totalRecords - 1;
  //             const row = this.state.rows;

  //             if (this.tblResponse.First >= totlrecord) {
  //                 const _curntCursor = Math.round(totlrecord / row);
  //                 this.tblResponse.First = _curntCursor + 1;
  //             }
  //             this.GetUsers();
  //         } else if (resdata.ResponseCode === '400') {
  //             this.refs.Messchild.showWarn(resdata.ResponseMessage);
  //         } else {
  //             this.refs.Messchild.showError(resdata.ResponseMessage);
  //         }
  //     });
  // }

  // Search Filter
  searchFilter(e) {
    this.setState({
      loading: true,
    });
    this.tblResponse.SearchValue = e.target.value;
    this.GetPrice();
  }

  // activeTemplate(rowData, column) {
  //     return (
  //         <input type="checkbox" disabled checked={rowData.IsActive} />
  //     )
  // }

  // Sort list
  onSort(event) {
    this.setState({
      sortOrder: this.state.sortOrder === 1 ? 2 : 1,
      sortIcon: !this.state.sortIcon,
    });
    const sortField =
      event.sortField == "CreatedDate" ? "CreatedDate" : event.sortField;
    this.tblResponse.SortColumn = sortField ? sortField : "";
    this.tblResponse.SortOrder = this.state.sortOrder === 1 ? "DESC" : "ASC";
    this.GetPrice();
    const clickData = document.getElementsByClassName(
      "p-sortable-column-icon pi pi-fw pi-sort"
    );
    console.log(event);
  }

  // edit form
  actionTemplate(rowData, column) {
    const editMode = (e) => {
      this.setState({
        buttonLabel: "Update",
        formHeading: "Update User",
        visible: true,
        PriceId: rowData.PriceId,
        ProductId: rowData.ProductId,
        Price: rowData.Price3,
      });
    };

    // const deleteFunc = () => {

    //     confirmAlert({
    //         title: 'Confirm to delete',
    //         message: 'Are you sure to do this.',
    //         buttons: [
    //             {
    //                 label: 'Yes',
    //                 onClick: () => this.deleteUser(rowData.ReferenceID)

    //             },
    //             {
    //                 label: 'No',
    //                 onClick: () => null
    //             }
    //         ]
    //     });
    // }

    return (
      <span>
        <Button
          type="button"
          icon="pi pi-pencil"
          onClick={editMode}
          className="p-button-warning"
          style={{ marginRight: "3px" }}
        ></Button>
        {/* <Button type="button" icon="pi pi-trash" onClick={deleteFunc} className="p-button-danger"></Button> */}
      </span>
    );
  }

  // create html part
  render() {
    const items = [{ label: "Masters" }, { label: "Price Master" }];

    const home = { icon: "pi pi-home", url: "/dashboard" };
    let header = (
      <div style={{ textAlign: "right" }}>
        <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>
        <InputText
          type="search"
          onInput={this.searchFilter}
          placeholder="Global Search"
          size="30"
        />
      </div>
    );

    return (
      <>
        <MessageComponent ref="Messchild" />
        {<Loaders loader={this.state.updateLoader} />}
        <div className="row mb-3">
          <div className="col-md-6 heading">
            <h1>Price Master</h1>
          </div>
          <div className="col-md-6 custom-breadcrumb">
            <BreadCrumb model={items} home={home} />
          </div>
        </div>
        {/* <ConfirmationModal parentCallback={this.callbackFunction} ref="child" /> */}
        <div className="heading">
          <Button
            label="Add Price"
            icon="pi pi-plus-circle"
            onClick={this.openDialog}
          />
        </div>

        <DataTable
          responsive={true}
          header={header}
          globalFilter={this.state.globalFilter}
          emptyMessage={this.state.emptyMessage}
          value={this.state.sales}
          sortMode="multiple"
          paginator={true}
          rows={this.state.rows}
          rowsPerPageOptions={[5, 10, 20]}
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          totalRecords={this.state.totalRecords}
          lazy={true}
          first={this.state.first}
          onPage={this.onPage}
          onSort={this.onSort}
          className="tblAuto"
        >
          <Column field="ProductName" header="Product Name" sortable={true} />
          <Column field="Price" header="Price" sortable={true} />
          <Column
            field="CreatedDate"
            style={{ width: "200px" }}
            header="Created Date"
            sortable={true}
          />
          {/* <Column body={this.actionTemplate} header="Action" /> */}
        </DataTable>

        <Dialog
          className="popup-modal"
          header={this.state.formHeading}
          visible={this.state.visible}
          modal={true}
          onHide={this.hideFunc}
        >
          <form onSubmit={this.submitForm}>
            <div className="form-padding">
              <div className="row">
                <div className="col-md-4 text-right">
                  <label>
                    Product <span>*</span>:
                  </label>
                </div>
                <div className="col-md-8 mb-2">
                  <Dropdown
                    options={this.state.ProductDDL}
                    placeholder="Select Product"
                    className="form-control"
                    value={this.state.ProductId}
                    onChange={this.handleChange}
                    name="ProductId"
                  />
                  {this.state.formError.ProductId !== null ? (
                    <div className="error">
                      {this.state.formError.ProductId}
                    </div>
                  ) : null}
                </div>
                <div className="col-md-4 text-right">
                  <label>
                    Price <span>*</span>:
                  </label>
                </div>
                <div className="col-md-8 mb-2">
                  <input
                    type="number"
                    value={this.state.Price}
                    onChange={this.handleChange}
                    className="form-control"
                    name="Price"
                  />
                  {this.state.formError.Price !== "" ? (
                    <div className="error">{this.state.formError.Price}</div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="col-md-12 text-center">
                  <button type="submit" className="btn btn-primary">
                    {this.state.buttonLabel}
                  </button>
                </div>
                <div className="col-md-5 text-right">
                  <label>
                    Note: Fields marked as (
                    <span style={{ color: "red" }}>*</span>) are required
                  </label>
                </div>
              </div>
            </div>
          </form>
        </Dialog>
      </>
    );
  }
}
