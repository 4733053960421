import instance from "./DefaultApi";

export default class SchemeService {
  ControllerName = "Scheme";

  // get all product Scheme data
  GetSchemes(tableRequest) {
    return instance
      .post(
        `${process.env.REACT_APP_API_URL}${this.ControllerName}/GetSchemes`,
        tableRequest
      )
      .then((res) => res);
  }
  GetuserDDL() {
    return instance.get(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetuserDDL`).then(res => res)
  }

  // create new product Scheme
  CreateScheme(SchemeData) {
    return instance
      .post(
        `${process.env.REACT_APP_API_URL}${this.ControllerName}/CreateScheme`,
        SchemeData
      )
      .then((res) => res);
  }

  // Get Product Mpping
  GetPrdctMpping(tableRequest) {
    return instance
      .post(
        `${process.env.REACT_APP_API_URL}${this.ControllerName}/GetPrdctMpping`,
        tableRequest
      )
      .then((res) => res);
  }
  // create new product Scheme
  CreatePrdctMpping(SchemeData) {
    return instance
      .post(
        `${process.env.REACT_APP_API_URL}${this.ControllerName}/CreatePrdctMpping`,
        SchemeData
      )
      .then((res) => res);
  }

  // Get GetScheme Drop Down List
  GetSchemeDDL() {
    return instance
      .get(
        `${process.env.REACT_APP_API_URL}${this.ControllerName}/GetSchemeDDL`
      )
      .then((res) => res);
  }

  // In Active Scheme Config
  InActiveSchemeConfig(SchemeData) {
    return instance
      .post(
        `${process.env.REACT_APP_API_URL}${this.ControllerName}/InActiveSchemeConfig`,
        SchemeData
      )
      .then((res) => res);
  }

  GetSchemeDataById(Id) {
    return instance
      .get(
        `${process.env.REACT_APP_API_URL}${this.ControllerName}/GetSchemeDataById/${Id}`
      )
      .then((res) => res);
  }
}
