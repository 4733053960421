import instance from './DefaultApi';

export default class CategoryService {
    ControllerName = 'Category';

    // get all category for ddl
    GetCategoryDDLMG1() {
        return instance.get(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetCategoryDDLMG1`).then(res => res)
    }
    GetCategoryDDLMG2(MG1) {
        ;
        return instance.get(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetCategoryDDLMG2/${MG1}`).then(res => res)
    }
    GetCategoryDDLMG3(MG2) {
        ;
        return instance.get(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetCategoryDDLMG3/${MG2}`).then(res => res)
    }
    // get all sub category for ddl
    GetSubCategoryDDL(categoryId) {
        return instance.get(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetSubCategoryDDL/${categoryId}`).then(res => res)
    }
    // get all subcategories
    GetSubCategories(tableRequest) {
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetSubCategories`, tableRequest).then(res => res)
    }
    // create new sub categgory 
    CreateUpdateSubCategory(subCategryData) {
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/CreateUpdateSubCategory`, subCategryData).then(res => res);
    }
    // delete sub category
    DeleteSubCategory(subCategoryId) {
        return instance.get(`${process.env.REACT_APP_API_URL}${this.ControllerName}/DeleteSubCategory/${subCategoryId}`).then(res => res);
    }

    // get all categories
    GetCategoriesMG1(tableRequest) {
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetCategoriesMG1`, tableRequest).then(res => res)
    }
    GetCategoriesMG2(tableRequest) {
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetCategoriesMG2`, tableRequest).then(res => res)
    }
    GetCategoriesMG3(tableRequest) {
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetCategoriesMG3`, tableRequest).then(res => res)
    }
    // create new categgory 
    CreateUpdateCategoryMG1(subCategryData) {
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/CreateUpdateCategoryMG1`, subCategryData).then(res => res);
    }
    CreateUpdateCategoryMG2(subCategryData) {
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/CreateUpdateCategoryMG2`, subCategryData).then(res => res);
    }
    CreateUpdateCategoryMG3(subCategryData) {
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/CreateUpdateCategoryMG3`, subCategryData).then(res => res);
    }
    // get subgroup for binding level MG1 
    Getmaingrp() {
        return instance.get(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetMaingrp`).then(res => res);
    }   // get subgroup for binding level MG2 
    Getsubgrp(MG1) {
        return instance.get(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetSubgrp`, MG1).then(res => res);
    }


}