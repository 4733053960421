import React, { Component } from "react";
import { Chart } from "primereact/chart";
import { Dropdown } from "primereact/dropdown";
import DashboardService from "../services/DashboardService";
import WareHouseMaterService from "../services/WareHouseMaterService";
import ApiService from "../services/apiService";
import dashboradimg from "./../assets/images/dashboard.png";
import Loaders from "./../template/Loaders";
import { Chart as GoogleChart } from "react-google-charts";
import "./Dashboard.css";

export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Dashdata: false,
      TotalMapping: 0,
      TotalDeMapping: 0,
      TotalDamage: 0,
      TotalGenerate: 0,
      TotalPacked: 0,
      TotalDispatched: 0,
      TotalCartonsPacked: 0,
      TotalCartonsDispatched: 0,
      TotalReceived: 0,
      TotalCartonsReceived: 0,
      updateLoader: false,
      TotalGenerated: 0,
      Months: [],
      selval: "Loading",
      DeMappings: [],
      Mappings: [],
      dropDownValue: "",
      NumberIssue: [],
      Damage: [],
      largestNumber: 0,
      factoryDDL: [],
      isAdmin: false,
      isPreprintedValue: "Static Qr Mapping",
      isPreprinted: false,
      options: {
        pieSliceText: "none",
        plugins: {
          labels: {
            render: () => {},
          },
        },
      },
    };
    this.WareHouseMaterService = new WareHouseMaterService();
    this.DashboardService = new DashboardService();
    this.ApiService = new ApiService();
    this.setPreprintedValue = this.setPreprintedValue.bind(this);
  }
  //trigger api function on page call

  componentDidMount() {
    const val = sessionStorage.getItem("userType");
    this.setState({
      UserType: val,
    });
    if (val == 2 || val == 1) {
      this.setState({
        Dashdata: true,
      });
    } else {
      setTimeout(() => {
        if (sessionStorage.getItem("userType") == 0) {
          this.setState({
            isAdmin: true,
          });
        }
        this.GetAllFactory(0);
        this.GetDashboardData("", false);
      }, 200);
      setTimeout(() => {
        if (this.state.isAdmin) {
          this.GetDashboardData(this.state.factoryDDL[0]?.ID, false);

          this.setState({
            selval: this.state.factoryDDL[0]?.FirmName,
          });
        }
      }, 2000);
    }
  }

  handleChange(e) {
    const Id = e.target.value;
    this.setState({
      dropDownValue: e.target.value,
    });
    debugger;
    let IsFactory = this.state.factoryDDL.filter((x) => x.ID == Id)[0]
      .IsFactory;
    if (IsFactory) {
      this.setState({ UserType: 4 });
    } else {
      this.setState({ UserType: 5 });
    }
    this.GetDashboardData(Id, false);
  }
  // Get Dashboard Data
  GetDashboardData(Id, isPreprinted) {
    const formdata = { Id: Id, isPreprinted: isPreprinted };
    this.DashboardService.GetDashboardData(formdata).then((data) => {
      const resdata = data.data;
      //if ResponseCode is 200
      if (resdata.ResponseCode === "200") {
        this.setState({ updateLoader: true });
        const res = resdata.ResponseData;
        const DashboardCount = res.DashboardCount;
        const MonthName = res.MonthName;
        const DeMappingList = res.DeMappingList;
        const MappingList = res.MappingList;
        const NumberList = res.NumberList;
        const DamageNumberList = res.DamageList;
        //DashboardCount not null
        if (DashboardCount != null) {
          this.setState({
            TotalMapping: DashboardCount.TotalMapping,
            TotalDeMapping: DashboardCount.TotalDeMapping,
            TotalDamage: DashboardCount.TotalDamage,
            TotalGenerated: DashboardCount.TotalGenerate,
            TotalPacked: DashboardCount.TotalPacked,
            TotalDispatched: DashboardCount.TotalDispatched,
            TotalCartonsPacked: DashboardCount.TotalCartonsPacked,
            TotalCartonsDispatched: DashboardCount.TotalCartonsDispatched,
            TotalReceived: DashboardCount.TotalReceived,
            TotalCartonsReceived: DashboardCount.TotalCartonsReceived,
          });

          var largest = 0;
          const array = [
            DashboardCount.TotalMapping,
            DashboardCount.TotalDeMapping,
            DashboardCount.TotalDamage,
            DashboardCount.TotalIssue,
          ];
          for (let i = 0; i <= largest; i++) {
            if (array[i] > largest) {
              var largest = array[i];
            }
          }

          this.setState({
            largestNumber: largest,
          });
        }
        //MonthName not null
        if (MonthName != null && MonthName.length > 0) {
          this.setState({
            Months: MonthName,
          });
        }
        //DeMappingList not null
        if (DeMappingList != null && DeMappingList.length > 0) {
          this.setState({
            DeMappings: DeMappingList,
          });
        }
        //MappingList not null
        if (MappingList != null && MappingList.length > 0) {
          this.setState({
            Mappings: MappingList,
          });
        }
        //NumberList not null
        if (NumberList != null && NumberList.length > 0) {
          this.setState({
            NumberIssue: NumberList,
          });
        }
        //DamageNumberList not null
        if (DamageNumberList != null && DamageNumberList.length > 0) {
          this.setState({
            Damage: DamageNumberList,
          });
        }
      }
    });
  }
  GetAllFactory(compId) {
    this.WareHouseMaterService.GetWareHouseDDL(compId).then((data) => {
      const resdata = data.data;
      //if resdata is not null state of wareHouseDDL will be set with ResponseData
      if (
        resdata !== null &&
        resdata.ResponseData !== null &&
        resdata.ResponseData.length > 0
      ) {
        debugger;
        var factory = resdata.ResponseData.filter((x) => x.IsFactory == true);
        const warehouse = resdata.ResponseData.filter(
          (x) => x.IsFactory == false
        );
        const mergedArr = [...factory, ...warehouse];
        this.setState({
          factoryDDL: mergedArr,
        });
      }
    });
  }
  /// to set isPreprinted
  setPreprintedValue(e) {
    this.setState(
      {
        isPreprintedValue: e.value,
        isPreprinted: e.value == "Static Qr Mapping" ? false : true,
      },
      () => {
        this.GetDashboardData("", this.state.isPreprinted);
      }
    );
  }
  // return HTML
  render() {
    const data = {
      labels: this.state.Months,
      datasets: [
        {
          label: "Number Issued",
          data: this.state.NumberIssue,
          fill: false,
          borderColor: "#4bc0c0",
        },

        {
          label: "Damage",
          data: this.state.Damage,
          fill: false,
          borderColor: "#c74e1a",
        },

        {
          label: "Product Mapping",
          data: this.state.Mappings,
          fill: false,
          borderColor: "#97A5A5",
        },
        // {
        //   label: 'Product De-Mapping',
        //   data: this.state.DeMappings,
        //   fill: false,
        //   borderColor: '#150e0b'
        // },
      ],
    };

    return (
      <>
        {/* <SelectButton value={this.state.isPreprintedValue} className="custom-tab" options={options} onChange={this.setPreprintedValue} /> */}
        {this.state.isAdmin ? (
          <div>
            <div className="row">
              <div
                style={{ margin: "0px 0px 30px 5px" }}
                className="col-md-8 mb-2"
              >
                <span className="pl-4 pt-2">Select Entity</span>
                {/* <Dropdown
                  virtualScroll="true"
                  appendTo={document.getElementById("body")}
                  style={{
                    margin: "0px 300px 0px 300px",
                    position: "fixed",
                    left: "unset !important",
                    right: 0,
                  }}
                  optionLabel="FirmName"
                  value={this.state.dropDownValue}
                  onChange={(e) => {
                    this.handleChange(e);
                  }}
                  className="form-control"
                  placeholder={this.state.selval}
                  name="WareHouseId"
                  options={this.state.factoryDDL}
                /> */}

                <select
                  className="form-control"
                  value={this.state.dropDownValue}
                  onChange={(e) => {
                    this.handleChange(e);
                  }}
                  placeholder={this.state.selval}
                  style={{
                    width: "300px",
                    display: "inline-block",
                    marginLeft: "10px",
                  }}
                >
                  {this.state.factoryDDL.map((e, key) => {
                    return (
                      <option key={e.ID} value={e.ID}>
                        {e.FirmName}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        {this.state.Dashdata ? (
          <div>
            <img
              style={{ borderRadius: "10px", width: "100%", height: "10%" }}
              src={dashboradimg}
              alt="MSRM Organics Dashboard"
            />
          </div>
        ) : (
          <section className=" warehouse-dash container-fluid">
            <div className="container-fluid mt-1">
              <Loaders loader={this.state.updateLoader} />
              <div
                className="row no-gutterss chart-sec"
                hidden={
                  this.state.UserType == 1 || this.state.UserType == 5
                    ? true
                    : false
                }
              >
                <div className="box-space chart-sec">
                  <div className="col-md-4 mt-3">
                    <div className="mybox">Mapped vs Packed</div>
                    {this.state.isAdmin ? (
                      <div className="admin-info"></div>
                    ) : (
                      ""
                    )}
                    <GoogleChart
                      chartType="PieChart"
                      data={[
                        ["Task", "Product"],
                        ["Mapped", this.state.TotalMapping],
                        ["Packed", this.state.TotalPacked],
                      ]}
                      options={this.state.options}
                      width={"100%"}
                      height={"300px"}
                    />
                  </div>

                  <div className="col-md-4 mt-3">
                    <div className="mybox1">Packed vs Dispatched</div>
                    {this.state.isAdmin ? (
                      <div className="admin-info"></div>
                    ) : (
                      ""
                    )}
                    <GoogleChart
                      chartType="PieChart"
                      data={[
                        ["Task", "Product"],
                        ["Packed", this.state.TotalPacked],
                        ["Dispatched", this.state.TotalDispatched],
                      ]}
                      options={this.state.options}
                      width={"100%"}
                      height={"300px"}
                    />
                  </div>

                  <div className="col-md-4 mt-3">
                    <div className="mybox2">
                      Cartons Packed vs Cartons Dispatched
                    </div>
                    {this.state.isAdmin ? (
                      <div className="admin-info"></div>
                    ) : (
                      ""
                    )}
                    <GoogleChart
                      chartType="PieChart"
                      data={[
                        ["Task", "Scan"],
                        ["Cartons Packed", this.state.TotalCartonsPacked],
                        [
                          "Cartons Dispatched",
                          this.state.TotalCartonsDispatched,
                        ],
                      ]}
                      options={this.state.options}
                      width={"100%"}
                      height={"300px"}
                    />
                  </div>
                </div>
              </div>

              <div
                className="row no-gutterss chart-sec"
                hidden={this.state.UserType == 5 ? false : true}
              >
                <div className="box-space chart-sec">
                  <div className="col-md-6 mt-3">
                    <div className="mybox1">Received vs Dispatched</div>
                    {this.state.isAdmin ? (
                      <div className="admin-info"></div>
                    ) : (
                      ""
                    )}
                    <GoogleChart
                      chartType="PieChart"
                      data={[
                        ["Task", "Product"],
                        ["Received", this.state.TotalReceived],
                        ["Dispatched", this.state.TotalDispatched],
                      ]}
                      options={this.state.options}
                      width={"100%"}
                      height={"300px"}
                    />
                  </div>

                  <div className="col-md-6 mt-3">
                    <div className="mybox2">
                      Cartons Received vs Cartons Dispatched
                    </div>
                    {this.state.isAdmin ? (
                      <div className="admin-info"></div>
                    ) : (
                      ""
                    )}
                    <GoogleChart
                      chartType="PieChart"
                      data={[
                        ["Task", "Scan"],
                        ["Cartons Received", this.state.TotalCartonsReceived],
                        [
                          "Cartons Dispatched",
                          this.state.TotalCartonsDispatched,
                        ],
                      ]}
                      options={this.state.options}
                      width={"100%"}
                      height={"300px"}
                    />
                  </div>
                </div>
              </div>

              <div className="row chart-sec">
                <div
                  className=" box-space chart-sec"
                  hidden={
                    this.state.UserType == 1 || this.state.UserType == 5
                      ? true
                      : false
                  }
                >
                  <div
                    className="col-md-4 box-space"
                    style={{ margin: "20px" }}
                  >
                    <div className="row">
                      <div className="col-md-12">
                        <div className="full-width1  box-shadowe mb-4">
                          <div className="card radius-10 border-start border-0 border-3 border-success box-side3">
                            <div className="card-body">
                              <div className="d-flex align-items-center">
                                <div>
                                  <p className="font-bold uppercase text-gray-600 text-p">
                                    Total Number Issued
                                  </p>
                                  <h2 className="font-bold text-3xl">
                                    {" "}
                                    {this.state.TotalGenerated}
                                  </h2>
                                </div>
                                <div className="box1 widgets-icons-2 rounded-circle bg-gradient-ohhappiness text-white ms-auto">
                                  <i className="fa fa-list-ol" />
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr></hr>
                        </div>
                      </div>
                    </div>
                    <div className="row no-gutter">
                      <div className="col-md-12 ">
                        <div className="full-width1 box-shadowe">
                          <div className="card radius-10 border-start border-0 border-3 border-info box-side1">
                            <div className="card-body media-body">
                              <div className="d-flex align-items-center">
                                <div>
                                  <p className="font-bold uppercase text-gray-400 text-p">
                                    Total Numbers Mapped
                                  </p>
                                  <h2 className="font-bold text-3xl">
                                    {this.state.TotalMapping}
                                  </h2>
                                </div>
                                <div className=" box1 widgets-icons-2 rounded-circle bg-gradient-scooter text-white ms-auto">
                                  <i className="fa fa-cube" />
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr></hr>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <div className="full-width1 box-shadowe">
                          <div className="card radius-10 border-start border-0 border-3 border-danger box-side2">
                            <div className="card-body">
                              <div className="d-flex align-items-center">
                                <div>
                                  <p className="font-bold uppercase text-gray-400 text-p">
                                    Total Numbers Damaged
                                  </p>
                                  <h2 className="font-bold text-3xl">
                                    {this.state.TotalDamage}
                                  </h2>
                                </div>
                                <div className=" box1 widgets-icons-2 rounded-circle bg-gradient-bloody text-white ms-auto">
                                  <i className="fa fa-exclamation-triangle" />
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr></hr>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-md-7"
                    style={{ margin: "7px 7px 7px 59px" }}
                  >
                    <Loaders loader={this.state.updateLoader} />
                    <div className="full-width1 box box-space box-shadowe">
                      <Chart height="124" type="line" data={data} />
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  {this.state.Dashdata ? (
                    <div
                      id="carousel"
                      className="carousel slide hero-slides"
                      data-ride="carousel"
                    >
                      <div className="carousel-inner" role="listbox">
                        <div className="carousel-item active boat">
                          <div className="container h-100  d-md-block">
                            <div className="row align-items-center h-100">
                              <div className="col-12 col-md-10 col-lg-10 col-xl-12">
                                <div className="caption animated fadeIn">
                                  <h2 className="animated fadeInLeft">
                                    <img src="img/slide-1.png" alt="logo" />
                                  </h2>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="carousel-item sea">
                          <div className="container h-100 d-md-block">
                            <div className="row align-items-center h-100">
                              <div className="col-12 col-md-10 col-lg-10 col-xl-12">
                                <div className="caption animated fadeIn">
                                  <h2 className="animated fadeInLeft">
                                    <img src="img/slide-2.png" alt="logo" />
                                  </h2>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="carousel-item river">
                          <div className="container h-100  d-md-block">
                            <div className="row align-items-center h-100">
                              <div className="col-12 col-md-10 col-lg-10 col-xl-12">
                                <div className="caption animated fadeIn">
                                  <h2 className="animated fadeInLeft">
                                    <img src="img/slide-3.png" alt="logo" />
                                  </h2>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <a
                        className="carousel-control-prev"
                        href="#carousel"
                        role="button"
                        data-slide="prev"
                      >
                        <span
                          className="carousel-control-prev-icon"
                          aria-hidden="true"
                        />
                        <span className="sr-only">Previous</span>
                      </a>
                      <a
                        className="carousel-control-next"
                        href="#carousel"
                        role="button"
                        data-slide="next"
                      >
                        <span
                          className="carousel-control-next-icon"
                          aria-hidden="true"
                        />
                        <span className="sr-only">Next</span>
                      </a>
                    </div>
                  ) : (
                    <div className=""></div>
                  )}
                </div>
              </div>
            </div>
          </section>
        )}
      </>
    );
  }
}
