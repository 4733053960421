import instance from './DefaultApi';

export default class MappingReportService {
    ControllerName = 'MappingReport';

    // get all data
    GetMappingReport(tableRequest) {
            return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetMappingReport`, tableRequest).then(res => res)
        }
        // Get Excel  Export Mapping data
    GetMappingExport(tableRequest) {
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetMappingExport`,tableRequest).then(res => res)
    }
}