import React, { Component } from "react";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import ReplaceService from "../../services/ReplaceService";
import MessageComponent from "../../template/Message";
import { Button } from "primereact/button";
import { BreadCrumb } from "primereact/breadcrumb";
import { Card } from "primereact/card";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import Loaders from "../../template/Loaders";

export default class Replace extends Component {
  constructor(props) {
    super(props);
    this.ReplaceService = new ReplaceService();
    this.state = {
      FistQrCode: "",
      type: "",
      formError: {
        FistQrCode: "",
      },
      scanItems: [],
      visible: false,
      updateLoader: true,
    };

    this.onValueChange = this.onValueChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.formReset = this.formReset.bind(this);
  }
  //to trigger api function o page call
  componentDidMount() {}

  submitForm() {
    let isfrmvalid = true;
    let checkerror = this.state.formError;

    // Object.entries(checkerror).map(([key, value]) => {
    //     const val = this.state[key];
    //     let valid = this.validateForm(val, key);
    //     //form is not valid

    //     if (!valid) {
    //         this.setState({ checkerror: isfrmvalid });
    //         isfrmvalid = false;
    //     }
    // });

    if (this.state.scanItems.length > 0) {
      if (isfrmvalid) {
        this.setState({ visible: true });
      }
    } else {
      this.refs.Messchild.showWarn(
        "first scan primary qr code whome you want to replace"
      );
    }
  }

  brekQrCode(qr) {
    if (
      qr.includes("msrmorganics") ||
      qr.includes("localhost") ||
      qr.includes("104")
    ) {
      /////////for multiline qr code on KREPL//
      const splitQr = qr.split("=");
      console.log(splitQr[1]);
      /////////for multiline qr code on KREPL//

      /////////for multiline qr code on KREPL//  splitQr[splitQr.length-1].trim()
      qr = splitQr[1] === undefined ? qr : splitQr[1];
      /////////for multiline qr code on KREPL//
    }
    return qr;
  }
  // submit packing
  submitFormFinal(event) {
    event.preventDefault();
    this.setState({ updateLoader: false });
    if (
      this.state.scanItems == null ||
      this.state.scanItems == undefined ||
      this.state.scanItems.length == 0
    ) {
      this.refs.Messchild.showWarn(
        "first scan primary qr code whome you want to replace"
      );
      return;
    }
    if (this.state.type == "SID") {
      if (!event.target.value.includes("SID")) {
        this.refs.Messchild.showWarn("valid only SID");
        return;
      }
    }
    const first = this.state.scanItems.find(Boolean);

    let firstqr = this.brekQrCode(first.FistQrCode);
    let secondqr = this.brekQrCode(event.target.value);

    const formData = {
      //createdBy
      FistQrCode: firstqr,
      Type: this.state.type,
      SecondQrCode: secondqr,
    };

    this.setState({ CartonName: "" });
    setTimeout(() => {
      this.ReplaceService.ReplaceQrCode(formData).then((data) => {
        const resdata = data.data;
        this.setState({ updateLoader: true });
        //if ResponseCode is 200 success else error or warning message
        if (resdata !== null && resdata.ResponseCode === "200") {
          this.refs.Messchild.showSuccess(resdata.ResponseMessage);
          // this.PrintSecondaryPackagingService.formPreview1([resdata.ResponseData]);

          this.formReset();
        } else if (resdata.ResponseCode === "400") {
          this.refs.Messchild.showWarn(resdata.ResponseMessage);
        } else {
          this.setState({ CartonName: "" });
          this.refs.Messchild.showError(resdata.ResponseMessage);
        }
      });
    }, 100);
  }

  formReset() {
    this.setState({
      FistQrCode: "",
      scanItems: [],
      visible: false,
      CartonName: "",
    });

    let errors = this.state.formError;
    Object.entries(errors).map(([key, value]) => {
      errors[key] = "";
    });
  }

  ScanItem(e) {
    if (e.key === "Enter") {
      let qr = e.target.value;

      if (
        qr.includes("msrmorganics") ||
        qr.includes("localhost") ||
        qr.includes("104")
      ) {
        /////////for multiline qr code on KREPL//
        const splitQr = qr.split("=");
        console.log(splitQr[1]);
        /////////for multiline qr code on KREPL//

        /////////for multiline qr code on KREPL//  splitQr[splitQr.length-1].trim()
        qr = splitQr[1] === undefined ? qr : splitQr[1];
        /////////for multiline qr code on KREPL//
      }
      const fObj = {
        FistQrCode: qr,
        Type: this.state.type,
      };
      this.ReplaceService.GetQrCodeDetails(fObj).then((data) => {
        const resdata = data.data;
        //if ResponseCode is 200 success else error or warning message
        if (resdata !== null && resdata.ResponseCode === "200") {
          this.setState({
            scanItems: resdata.ResponseData,
            visible: true,
          });
        } else this.refs.Messchild.showWarn(resdata.ResponseMessage);
      });

      this.setState({
        FistQrCode: "",
      });
    }
  }

  onValueChange(e) {
    this.setState({
      type: e.target.value,
    });
  }

  // call every change of form and validation part also
  handleChange(event) {
    const { name, value } = event.target;
    let errors = this.state.formError;

    const isValid = this.validateForm(value, name);
    this.setState({ errors, [name]: value });
  }

  // validate form
  validateForm(value, name) {
    let errors = this.state.formError;
    let IsValid = true;
    //to validate each form field with required conditions
    switch (name) {
      case "FistQrCode":
        if (!value || value.length < 1) {
          IsValid = false;
          errors.FistQrCode = "Please enter QrCode.";
        } else errors.FistQrCode = "";
        break;

      default:
        break;
    }

    return IsValid;
  }

  //sessionStorage.getItem("Prefix")
  render() {
    const items = [{ label: "Transactions" }, { label: "Replace" }];
    const home = { icon: "pi pi-home", url: "/dashboard" };
    let header = (
      <div className="row">
        <div className="col-md-8 text-left"></div>
        <div className="col-md-4">
          <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>

          <InputText
            type="search"
            onInput={(e) => this.setState({ globalFilter: e.target.value })}
            placeholder="Global Search"
            size="30"
          />
        </div>
      </div>
    );
    const TabView = (
      <Dialog
        className="popup-modal py-3 text-center"
        style={{ width: "30%" }}
        header="Scan the Primary QR/Carton Slip For Replacing"
        visible={this.state.visible}
        modal={true}
        onHide={() => {
          this.setState({ visible: false });
        }}
      >
        <div className="py-3 text-center">
          {" "}
          <input
            type="text"
            value={this.state.CartonName}
            name="CartonName"
            onChange={(e) => {
              this.setState({ CartonName: e.target.value });
            }}
            onKeyDown={(e) => {
              if (e.key == "Enter") {
                if (e.target.value.includes(sessionStorage.getItem("Prefix"))) {
                  this.submitFormFinal(e);
                } else {
                  this.refs.Messchild.showWarn("Invalid Cartons");
                  this.setState({ CartonName: "" });
                }
              }
            }}
            autoFocus
          />
        </div>
      </Dialog>
    );
    return (
      <>
        <MessageComponent ref="Messchild" />
        {<Loaders loader={this.state.updateLoader} />}
        <Card>
          <div className="row mb-3">
            <div className="col-md-6 heading">
              <h1>Replace</h1>
            </div>
            <div className="col-md-6 custom-breadcrumb">
              <BreadCrumb model={items} home={home} />
            </div>
          </div>
          <br />
          <br />
          <div className="col-md-6 text-center">
            <input
              type="radio"
              value="PID"
              className="mr-3 ml-3"
              name="type"
              onChange={this.onValueChange}
            />
            Primary Qr Code
            <input
              type="radio"
              value="SID"
              className="mr-3 ml-3"
              name="type"
              onChange={this.onValueChange}
            />
            Secondary Carton Slips
            {/* <input
                            type="radio"
                            value="TID"
                            className="ml-3"
                            name="type"
                            onChange={this.onValueChange}
                        />{" "}
                        Tertiary Carton Slip */}
          </div>

          <div className="usearea">
            <div className="col-md-2 text-right mb-8 ">
              <label>
                Qr Code<span>*</span>:
              </label>
            </div>
            <div className="col-md-2 mb-2 ">
              <input
                type="text"
                value={this.state.FistQrCode}
                onChange={(e) => {
                  this.handleChange(e);
                }}
                onKeyDown={(e) => {
                  this.ScanItem(e);
                }}
                className="form-control"
                name="FistQrCode"
              />

              {this.state.formError.FistQrCode !== null ? (
                <div className="error">{this.state.formError.FistQrCode}</div>
              ) : null}
            </div>
            <br />
          </div>
          <div className="row mb-3">
            <div className="text-center my-5 ">
              <button
                className="btn btn-primary mt-5"
                disabled={!this.state.type}
                onClick={this.submitForm}
              >
                Replace
              </button>
            </div>
          </div>
        </Card>

        <DataTable
          responsive={true}
          header={header}
          emptyMessage={this.state.emptyMessage}
          value={this.state.scanItems}
          sortMode="multiple"
          paginator={true}
          rows={this.state.rows}
          className="tblAuto"
          globalFilter={this.state.globalFilter}
        >
          <Column field="RandomNumber" header="Visible No" sortable={true} />
          <Column field="ProductName" header="Product Name" sortable="custom" />
          <Column field="ProductDscrptn" header="Product Dscrptn" />
          <Column
            field="SecondaryCarton"
            header="Secondary Carton"
            sortable={true}
          />
          {/* <Column
            field="TertiaryCarton"
            header="Tertiary Carton"
            sortable={true}
          /> */}
        </DataTable>
        {TabView}
      </>
    );
  }
}
