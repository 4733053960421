import instance from './DefaultApi';

export default class SecondaryPackingService {
    ControllerName = 'SecondaryPacking';


    // Save Packing 
    Save(formData) {
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/save`, formData).then(res => res)
    }

    // Save Packing 
    GetSecondaryPacking(tbl) {
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetSecondaryPacking`, tbl).then(res => res)
    }




}