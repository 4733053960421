import React, { Component } from "react";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { FileUpload } from "primereact/fileupload";
import { BreadCrumb } from "primereact/breadcrumb";
import tableRequest from "../../models/tableRequest.js";
import { confirmAlert } from "react-confirm-alert"; // Import
import { ConfirmationModal } from "../../template/ConfirmationModal";
import CompanyMasterService from "../../services/CompanyMasterService";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import MessageComponent from "../../template/Message";
import Loaders from "../../template/Loaders";
import $ from "jquery";

// Company  Master
export default class CompanyMaster extends Component {
  constructor(props) {
    super(props);
    this.CompanyMasterService = new CompanyMasterService();
    this.tblResponse = new tableRequest();
    this.state = {
      CCode: "",
      visible: false,
      CompId: 0,
      sortIcon: false,
      CompanyName: "",
      CompanyDisplayName: "",
      updateLoader: false,
      Email: "",
      Address: "",
      Mobile: "",
      GTIN:"",
      City: "",
      Pincode: "",
      IsActive: false,
      CompanyLogo: "",
      sortOrder: "1",
      formError: {
        CCode: "",
        CompanyName: "",
        CompanyDisplayName: "",
        Email: "",
        CompanyLogo: "",
        Address: "",
        Mobile: "",
        GTIN:"",
        City: "",
        Pincode: "",
      },
      uploadImagPath: "",
      formHeading: "Add Company",
      buttonLabel: "Submit",
      sales: [],
      loading: true,
      first: 0,
      rows: 5,
      totalRecords: 0,
      globalFilter: null,
      emptyMessage: "No Data found",
    };
    this.brandTemplate = this.brandTemplate.bind(this);
    this.actionTemplate = this.actionTemplate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.setImagePath = this.setImagePath.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.hideFunc = this.hideFunc.bind(this);
    this.onPage = this.onPage.bind(this);
    this.searchFilter = this.searchFilter.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.onSort = this.onSort.bind(this);
  }

  //trigger api function on page call
  componentDidMount() {
    setTimeout(() => {
      this.GetCompany();
    }, 100);

    $(".p-column-title").on("click", function () {
      $(this)
        .next()
        .removeClass("pi-sort")
        .toggleClass("pi-sort-up")
        .addClass("pi-sort-down");
    });
  }
  //loading page
  onPage(event) {
    this.setState({
      loading: true,
    });

    //imitate delay of a backend call
    setTimeout(() => {
      this.getLazyLoadingValues(event);
      this.GetCompany();
    }, 250);
  }
  // use for image
  brandTemplate(rowData, column) {
    var src = rowData.CompanyLogo;
    return src.length > 0 ? (
      <img src={src} width="100" alt="compang Logo" />
    ) : (
      ""
    );
  }

  // set Image Path
  setImagePath(event) {
    let errors = this.state.formError;
    errors.CompanyLogo = "";
    if (event.xhr) {
      if (event.xhr.response) {
        const response = JSON.parse(event.xhr.response);
        //if event response is 200 then image path will be added to state else it will move to 'else statement'
        if (response) {
          if (response.ResponseCode === "200") {
            this.setState({
              uploadImagPath: response.Source,
            });
          } else if (response.ResponseCode === "400") {
            errors.CompanyLogo = "Only image format is accepted.";
            this.setState({
              uploadImagPath: "",
            });
          } else {
            // this.messageService.add({ severity: 'error', summary: 'Oops', detail: response.ResponseMessage });
          }
        } else {
          //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
        }
      } else {
        //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
      }
    } else {
      //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
    }
  }

  deleteFunc() {
    this.refs.child.confirmModal();
  }

  onErrorImage() {}

  //  call back Function
  callbackFunction = (childData) => {
    this.setState({ message: childData });
  };

  // Get list of data
  getLazyLoadingValues(event) {
    this.setState({ rows: event.rows });
    this.tblResponse.PageSize = event.rows;
    this.tblResponse.First = event.first + 1;
    this.tblResponse.IsActive = true;
    this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
    this.tblResponse.SortColumn = event.sortField;
    this.tblResponse.SortOrder = event.sortOrder === 1 ? "ASC" : "DESC";
  }

  // get company data list from api
  GetCompany() {
    this.tblResponse.SortColumn = "CreatedDt";
    this.CompanyMasterService.GetCompany(this.tblResponse).then((data) => {
      ;
      const resdata = data.data;
      //when response data is not null
      if (
        resdata !== null &&
        resdata.ResponseData !== null &&
        resdata.ResponseData.length > 0
      ) {
        this.datasource = resdata.ResponseData;
        this.setState({
          totalRecords: resdata.ResponseData[0].TotalRows,
          updateLoader: true,
          first: this.tblResponse.First - 1,
          sales: this.datasource.slice(
            0,
            this.tblResponse.First - 1 + this.tblResponse.PageSize
          ),
          loading: false,
        });
      }
      //when response data is null
      else {
        this.datasource = null;
        this.setState({
          totalRecords: 0,
          first: 1,
          sales: null,
          loading: false,
          updateLoader: true,
        });
      }
    });
  }

  // call every change of form and validation part also
  handleChange(event) {
    const { name, value } = event.target;
    let errors = this.state.formError;

    const isValid = this.validateForm(value, name);
    this.setState({ errors, [name]: value });

    //this statement will set state value for IsActive
    if (name == "IsActive")
      this.setState({
        IsActive: event.target.checked,
      });
  }

  // Open Dialog
  openDialog() {
    this.setState({ visible: true });
    this.formReset();
  }

  // validate form
  validateForm(value, name) {
    let errors = this.state.formError;
    let IsValid = true;
    //this switch case is used for each form field to validate
    switch (name) {
      case "CCode":
        if (value.length < 1) {
          IsValid = false;
          errors.CCode = "Please enter  Company Code.";
        } else errors.CCode = "";
        break;
      case "City":
        if (value.length < 1) {
          IsValid = false;
          errors.City = "Please enter City.";
        } else errors.City = "";
        break;

      case "Pincode":
        if (value.length < 6 || value.match(/^[A-Za-z]+$/)) {
          IsValid = false;
          errors.Pincode = "Please enter Valid Pincode.";
        } else if (!value.match(/^[0-9]+$/)) {
          IsValid = false;
          errors.Pincode = "Please enter valid pincode.";
        } else if (value.length != 6) {
          IsValid = false;
          errors.Pincode = "Please enter valid pincode.";
        } else errors.Pincode = "";
        break;
      case "CompanyName":
        if (value.length < 1) {
          IsValid = false;
          errors.CompanyName = "Please enter  Company name.";
        } else errors.CompanyName = "";
        break;
      case "CompanyDisplayName":
        if (value.length < 1) {
          IsValid = false;
          errors.CompanyDisplayName = "Please enter  Company display name.";
        }
        // else if (value.length >= 1) {
        //     if (!value.match(/^[A-Za-z]+$/)) {
        //         IsValid = false;
        //         errors.CartonSize = "FirstName should be alpha only."
        //     } else errors.CartonSize = ''
        // }
        else errors.CompanyDisplayName = "";
        break;
      case "Mobile":
        if (value.length < 1) {
          IsValid = false;
          errors.Mobile = "Please enter Mobile.";
        } else if (value.length >= 1) {
          if (!value.match(/^[6-9]\d{9}$/)) {
            IsValid = false;
            errors.Mobile = "Please enter valid mobile no.";
          } else errors.Mobile = "";
        } else errors.Mobile = "";
        break;

      case "Address":
        if (value.length < 1) {
          IsValid = false;
          errors.Address = "Please enter Address.";
        } else errors.Address = "";
        break;

      case "Email":
        if (value.length < 1) {
          IsValid = false;
          errors.Email = "Please enter Email.";
        } else if (value.length >= 1) {
          if (!value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
            IsValid = false;
            errors.Email = "Please enter valid email-id.";
          } else errors.Email = "";
        } else errors.Email = "";
        break;
      // case 'IsActive':
      //     this.setState({
      //         IsActive: value
      //     });
      //     break;

      default:
        break;
    }

    return IsValid;
  }

  // reset form text value
  formReset() {
    this.setState({
      CompId: 0,
      sortIcon: false,
      CompanyName: "",
      CompanyDisplayName: "",
      Email: "",
      Address: "",
      Mobile: "",
      GTIN:"",
      City: "",
      Pincode: "",
      IsActive: false,
      Mobile: "",
      Email: "",
      GTIN:"",
      CompanyLogo: "",
      IsActive: true,
      CreatedBy: "",
      uploadImagPath: "",
      CCode: "",
    });
    let errors = this.state.formError;
    Object.entries(errors).map(([key, value]) => {
      errors[key] = "";
    });
  }

  // add text
  hideFunc() {
    this.setState({
      visible: false,
      buttonLabel: "Submit",
      formHeading: "Add Company",
    });
    this.formReset();
  }

  // submit form and create or     x
  submitForm(event) {
    event.preventDefault();

    let isfrmvalid = true;
    let checkerror = this.state.formError;

    Object.entries(checkerror).map(([key, value]) => {
      const val = this.state[key];
      let valid = this.validateForm(val, key);
      //when form validation is not valid
      if (!valid) {
        this.setState({ checkerror: isfrmvalid });
        isfrmvalid = false;
      }
    });
    let checkbox = document.getElementsByName("IsActive");
    //when isfrmvalid is true
    if (isfrmvalid) {
      const formData = {
        CCode: this.state.CCode,
        CompId: this.state.CompId,
        CompanyName: this.state.CompanyName,
        CompanyDisplayName: this.state.CompanyDisplayName,
        Mobile: this.state.Mobile,
        GTIN:this.state.GTIN,
        Email: this.state.Email,
        Address: this.state.Address,
        CompanyLogo: this.state.uploadImagPath,
        IsActive: this.state.IsActive,
        City: this.state.City,
        Pincode: this.state.Pincode,
      };
      this.setState({ updateLoader: false });
      setTimeout(() => {
        this.CompanyMasterService.CreateCompany(formData).then((data) => {
          ;
          const resdata = data.data;
          //when data is not null it will give success message else will move to 'else statement'
          if (resdata !== null && resdata.ResponseCode === "200") {
            this.refs.Messchild.showSuccess(resdata.ResponseMessage);
            this.setState({
              loading: true,
              visible: false,
              updateLoader: true,
            });
            this.formReset();
            this.GetCompany();
          } else if (resdata.ResponseCode === "400") {
            this.setState({
              updateLoader: true,
            });
            this.refs.Messchild.showWarn(resdata.ResponseMessage);
          } else {
            this.setState({
              updateLoader: true,
            });
            this.refs.Messchild.showError(resdata.ResponseMessage);
          }
        });
      }, 100);
    }
  }

  // delete user data
  // deleteUser(referenceID) {
  //     this.UserService.DeleteUser(referenceID).then(data => {
  //         const resdata = data.data;
  //         if (resdata !== null && resdata.ResponseCode === '200') {
  //             this.refs.Messchild.showSuccess(resdata.ResponseMessage);
  //             const totlrecord = this.state.totalRecords - 1;
  //             const row = this.state.rows;

  //             if (this.tblResponse.First >= totlrecord) {
  //                 const _curntCursor = Math.round(totlrecord / row);
  //                 this.tblResponse.First = _curntCursor + 1;
  //             }
  //             this.GetUsers();
  //         } else if (resdata.ResponseCode === '400') {
  //             this.refs.Messchild.showWarn(resdata.ResponseMessage);
  //         } else {
  //             this.refs.Messchild.showError(resdata.ResponseMessage);
  //         }
  //     });
  // }

  // Search Filter data
  searchFilter(e) {
    this.setState({
      loading: true,
    });
    this.tblResponse.SearchValue = e.target.value;
    this.GetCompany();
  }

  // callbackFunction = (childData) => {
  //     this.setState({ message: childData })
  // }

  //return checkbox disabled
  activeTemplate(rowData, column) {
    return <input type="checkbox" disabled checked={rowData.IsActive} />;
  }

  // Sorting Data
  onSort(event) {
    this.setState({
      sortOrder: this.state.sortOrder === 1 ? 2 : 1,
      sortIcon: !this.state.sortIcon,
    });
    const sortField =
      event.sortField == "CreatedDt" ? "CreatedDt" : event.sortField;
    this.tblResponse.SortColumn = sortField ? sortField : "";
    this.tblResponse.SortOrder = this.state.sortOrder === 1 ? "DESC" : "ASC";
    this.GetCompany();
    const clickData = document.getElementsByClassName(
      "p-sortable-column-icon pi pi-fw pi-sort"
    );
    console.log(event);
  }

  // edit form
  actionTemplate(rowData, column) {
    const editMode = (e) => {
      this.setState({
        buttonLabel: "Update",
        formHeading: "Update Company",
        visible: true,
        CompId: rowData.CompId,
        CompanyName: rowData.CompanyName,
        CompanyDisplayName: rowData.CompanyDisplayName,
        uploadImagPath: rowData.CompanyLogo,
        Email: rowData.Email,
        Address: rowData.Address,
        Mobile: rowData.Mobile,
        GTIN: rowData.GTIN,
        IsActive: rowData.IsActive,
        CCode: rowData.CCode,
        City: rowData.City,
        Pincode: rowData.Pincode,
      });
      // let checkbox = document.getElementsByName('IsActive');
      // checkbox[0].checked = rowData.IsActive;
    };

    return (
      <span>
        <Button
          type="button"
          icon="pi pi-pencil"
          onClick={editMode}
          className="p-button-warning"
          style={{ marginRight: "3px" }}
        ></Button>
        {/* <Button type="button" icon="pi pi-trash" onClick={deleteFunc} className="p-button-danger"></Button> */}
      </span>
    );
  }

  // create html part
  //resolved-84
  render() {
    const items = [{ label: "Masters" }, { label: "Company Master" }];

    const home = { icon: "pi pi-home", url: "/dashboard" };
    let header = (
      <div style={{ textAlign: "right" }}>
        <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>
        <InputText
          type="search"
          onInput={this.searchFilter}
          placeholder="Global Search"
          size="30"
        />
      </div>
    );

    return (
      <>
        <MessageComponent ref="Messchild" />
        <Loaders loader={this.state.updateLoader} />
        {/* <ConfirmationModal parentCallback={this.callbackFunction} ref="child" /> */}
        <div className="row mb-3">
          <div className="col-md-6 heading">
            <h1>Company Master</h1>
          </div>
          <div className="col-md-6 custom-breadcrumb">
            <BreadCrumb model={items} home={home} />
          </div>
        </div>
        <div className="heading">
          <Button
            label="Add Company"
            icon="pi pi-plus-circle"
            onClick={this.openDialog}
          />
        </div>

        <DataTable
          selection={this.state.selectedProducts}
          onSelectionChange={(e) =>
            this.setState({ selectedProducts: e.value })
          }
          responsive={true}
          header={header}
          globalFilter={this.state.globalFilter}
          emptyMessage={this.state.emptyMessage}
          value={this.state.sales}
          sortMode="multiple"
          paginator={true}
          rows={this.state.rows}
          rowsPerPageOptions={[5, 10, 20]}
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          totalRecords={this.state.totalRecords}
          lazy={true}
          first={this.state.first}
          onPage={this.onPage}
          onSort={this.onSort}
          className="tblAuto"
        >
          <Column field="CCode" header="Company code" sortable={true} />
          <Column field="CompanyName" header="Company Name" sortable={true} />
          <Column
            field="CompanyDisplayName"
            header="Company Display Name"
            sortable={true}
          />
          <Column field="Email" header="Email" sortable={true} />
          <Column
            field="CompanyLogo"
            style={{ width: "100px" }}
            header="Company Logo"
            body={this.brandTemplate}
          />
          <Column body={this.activeTemplate} header="Status" />
          <Column body={this.actionTemplate} header="Action" />
        </DataTable>

        <Dialog
          className="popup-modal"
          header={this.state.formHeading}
          visible={this.state.visible}
          modal={true}
          onHide={this.hideFunc}
        >
          <form onSubmit={this.submitForm} className="pl-5 pr-5 pt-3 pb-3">
            <div className="row">
              <div className="col-md-4 text-right">
                <label>
                  Company Code<span>*</span>:
                </label>
              </div>
              <div className="col-md-8 mb-2">
                <input
                  type="text"
                  value={this.state.CCode}
                  onChange={this.handleChange}
                  className="form-control"
                  name="CCode"
                  noValidate
                />
                {this.state.formError.CCode !== "" ? (
                  <div className="error">{this.state.formError.CCode}</div>
                ) : (
                  ""
                )}
              </div>

              <div className="col-md-4 text-right">
                <label>
                  Company Name<span>*</span>:
                </label>
              </div>
              <div className="col-md-8 mb-2">
                <input
                  type="text"
                  value={this.state.CompanyName}
                  onChange={this.handleChange}
                  className="form-control"
                  name="CompanyName"
                  noValidate
                />
                {this.state.formError.CompanyName !== "" ? (
                  <div className="error">
                    {this.state.formError.CompanyName}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-4 text-right">
                <label>
                  Company Display Name<span>*</span>:
                </label>
              </div>
              <div className="col-md-8 mb-2">
                <input
                  type="text"
                  value={this.state.CompanyDisplayName}
                  onChange={this.handleChange}
                  className="form-control"
                  name="CompanyDisplayName"
                />
                {this.state.formError.CompanyDisplayName !== "" ? (
                  <div className="error">
                    {this.state.formError.CompanyDisplayName}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-4 text-right">
                <label>
                GTI No<span>*</span>:
                </label>
              </div>
              <div className="col-md-8 mb-2">
                <input
                  type="text"
                  value={this.state.GTIN}
                  onChange={this.handleChange}
                  className="form-control"
                  name="GTIN"
                />
                {this.state.formError.GTIN !== "" ? (
                  <div className="error">{this.state.formError.GTIN}</div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-4 text-right">
                <label>
                  Email<span>*</span>:
                </label>
              </div>
              <div className="col-md-8 mb-2">
                <input
                  type="text"
                  value={this.state.Email}
                  onChange={this.handleChange}
                  className="form-control"
                  name="Email"
                />
                {this.state.formError.Email !== "" ? (
                  <div className="error">{this.state.formError.Email}</div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-4 text-right">
                <label>
                  City<span>*</span>:
                </label>
              </div>
              <div className="col-md-8 mb-2">
                <input
                  type="text"
                  value={this.state.City}
                  onChange={this.handleChange}
                  className="form-control"
                  name="City"
                />
                {this.state.formError.City !== "" ? (
                  <div className="error">{this.state.formError.City}</div>
                ) : (
                  ""
                )}
              </div>

              <div className="col-md-4 text-right">
                <label>
                  PinCode<span>*</span>:
                </label>
              </div>
              <div className="col-md-8 mb-2">
                <input
                  type="number"
                  minlength="6"
                  maxlength="6"
                  value={this.state.Pincode}
                  onChange={this.handleChange}
                  className="form-control"
                  name="Pincode"
                />
                {this.state.formError.Pincode !== "" ? (
                  <div className="error">{this.state.formError.Pincode}</div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-4 text-right">
                <label>
                  Address<span>*</span>:
                </label>
              </div>
              <div className="col-md-8 mb-2">
                <input
                  type="text"
                  value={this.state.Address}
                  onChange={this.handleChange}
                  className="form-control"
                  name="Address"
                />
                {this.state.formError.Address !== "" ? (
                  <div className="error">{this.state.formError.Address}</div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-4 text-right">
                <label>
                  Mobile<span>*</span>:
                </label>
              </div>
              <div className="col-md-8 mb-2">
                <input
                  type="text"
                  value={this.state.Mobile}
                  onChange={this.handleChange}
                  className="form-control"
                  name="Mobile"
                />
                {this.state.formError.Mobile !== "" ? (
                  <div className="error">{this.state.formError.Mobile}</div>
                ) : (
                  ""
                )}
              </div>
             
              <div className="col-md-4 text-right">
                <label>Company Logo:</label>
              </div>
              <div className="col-md-8 mb-2">
                <FileUpload
                  name="productfile"
                  url={process.env.REACT_APP_API_URL + "Upload/FileUpload"}
                  auto={true}
                  onUpload={this.setImagePath}
                  onError={this.onErrorImage}
                  accept="image/*"
                />

                {this.state.uploadImagPath ? (
                  <div className="p-fileupload-row">
                    <div>
                      <img
                        alt="pro.jfif"
                        role="presentation"
                        src={this.state.uploadImagPath}
                        width="50"
                      />
                    </div>
                  </div>
                ) : null}

                {this.state.formError.CompanyLogo !== null ? (
                  <div className="error">
                    {this.state.formError.CompanyLogo}
                  </div>
                ) : null}
              </div>

              <div className="col-md-4 text-right">
                <label>IsActive :</label>
              </div>
              <div className="col-md-8 mb-2">
                <input
                  type="checkbox"
                  checked={this.state.IsActive}
                  className="ml-2"
                  name="IsActive"
                  onChange={this.handleChange}
                />
              </div>
              <div className="col-md-12 text-center">
                <button type="submit" className="btn btn-primary">
                  {this.state.buttonLabel}
                </button>
              </div>
              <div className="col-md-12 text-left">
                <label>
                  Note: Fields marked as (
                  <span style={{ color: "red" }}>*</span>) are required
                </label>
              </div>
            </div>
          </form>
        </Dialog>
      </>
    );
  }
}
