import React, { Component } from "react";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { BreadCrumb } from "primereact/breadcrumb";
import tableRequest from "../../models/tableRequest.js";
import { ConfirmationModal } from "../../template/ConfirmationModal";
import CategoryService from "../../services/CategoryService";
import MessageComponent from "../../template/Message";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import Loaders from "../../template/Loaders";
import $ from "jquery";
import { CSVLink } from "react-csv";

// create Category
export default class Category extends Component {
  constructor(props) {
    super(props);
    this.CategoryService = new CategoryService();
    this.tblResponse = new tableRequest();
    this.tblResponseExport = new tableRequest();
    this.state = {
      headsMG1: ["NameMG1", "CreatedDate"],
      headsMG2: ["NameMG2", "CreatedDate"],
      headsMG3: ["NameMG3", "CreatedDate"],
      exportDataMG1: [],
      exportDataMG2: [],
      exportDataMG3: [],
      MG1: false,
      MG2: false,
      MG3: false,
      updateLoader: false,
      sortOrder: "",
      NameMG1: "",
      MG1Id: "",
      MG2Id: "",
      MG3Id: "",
      val: "",
      // CategoryDDLMG1: [],
      // CategoryDDLMG2: [],
      IsActiveMG1: false,
      IsActiveMG2: false,
      IsActiveMG3: false,

      formErrorMG1: {
        NameMG1: "",
      },
      formErrorMG2: {
        NameMG2: "",
      },
      formErrorMG3: {
        NameMG3: "",
      },

      formHeadingMG1: "Category",
      buttonLabelMG1: "Submit",
      // formHeadingMG2: "Sub Group Category",
      buttonLabelMG2: "Submit",
      // formHeadingMG3: "Sub Divison Category",
      buttonLabelMG3: "Submit",
      salesMG1: [],
      salesMG2: [],
      salesMG3: [],
      loading: true,
      firstMG1: 0,
      firstMG2: 0,
      firstMG3: 0,
      rowsMG1: 5,
      rowsMG2: 5,
      rowsMG3: 5,
      totalRecordsMG1: 0,
      totalRecordsMG2: 0,
      totalRecordsMG3: 0,
      globalFilterMG1: null,
      globalFilterMG2: null,
      globalFilterMG3: null,
      emptyMessage: "No Data found",
    };

    this.actionTemplate = this.actionTemplate.bind(this);
    this.actionTemplateMG2 = this.actionTemplateMG2.bind(this);
    this.actionTemplateMG3 = this.actionTemplateMG3.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.submitFormMG1 = this.submitFormMG1.bind(this);
    this.submitFormMG2 = this.submitFormMG2.bind(this);
    this.submitFormMG3 = this.submitFormMG3.bind(this);
    this.hideFuncMG1 = this.hideFuncMG1.bind(this);
    //    this.hideFuncMG2 = this.hideFuncMG2.bind(this);
    // this.hideFuncMG3 = this.hideFuncMG3.bind(this);
    this.onPage = this.onPage.bind(this);
    //this.formReset = this.formReset.bind(this);
    this.searchFilter = this.searchFilter.bind(this);
    this.onPageMG2 = this.onPageMG2.bind(this);
    this.searchFilterMG2 = this.searchFilterMG2.bind(this);
    this.onPageMG3 = this.onPageMG3.bind(this);
    this.searchFilterMG3 = this.searchFilterMG3.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.subgroup = this.subgroup.bind(this);
    this.subdivgroup = this.subdivgroup.bind(this);
    this.onSort = this.onSort.bind(this);
    this.onSortMG2 = this.onSortMG2.bind(this);
    this.onSortMG3 = this.onSortMG3.bind(this);

    this.GetMG1Export = this.GetMG1Export.bind(this);
    this.GetMG2Export = this.GetMG2Export.bind(this);
    this.GetMG3Export = this.GetMG3Export.bind(this);
  }

  //trigger api function on page call
  componentDidMount() {
    setTimeout(() => {
      //this.GetDDL()
      this.GetCategoriesMG1();
      this.GetCategoriesMG2();
      this.GetCategoriesMG3();
    }, 1000);
    $(".p-column-title").on("click", function () {
      $(this)
        .next()
        .removeClass("pi-sort")
        .toggleClass("pi-sort-up")
        .addClass("pi-sort-down");
    });

    this.tblResponseExport.PageSize = -1;
    this.tblResponseExport.First = 1;
    this.tblResponseExport.IsActive = true;
    this.tblResponseExport.SearchValue = "";
    this.tblResponseExport.SortOrder = "ASC";
    this.tblResponseExport.IsActiveMG3 = "%";
    this.GetMG1Export();
    this.GetMG2Export();
    this.GetMG3Export();
  }

  // get carton data list from api
  GetMG1Export() {
    this.tblResponseExport.SortColumn = "CreatedDate";
    this.CategoryService.GetCategoriesMG1(this.tblResponseExport).then(
      (data) => {
        const resdata = data.data;
        //when response data is not null
        if (
          resdata !== null &&
          resdata.ResponseData !== null &&
          resdata.ResponseData.length > 0
        ) {
          this.setState({
            exportDataMG1: resdata.ResponseData,
          });
        }
      }
    );
  }

  // get carton data list from api
  GetMG2Export() {
    this.tblResponseExport.SortColumn = "CreatedDate";
    this.CategoryService.GetCategoriesMG2(this.tblResponseExport).then(
      (data) => {
        const resdata = data.data;
        //when response data is not null
        if (
          resdata !== null &&
          resdata.ResponseData !== null &&
          resdata.ResponseData.length > 0
        ) {
          this.setState({
            exportDataMG2: resdata.ResponseData,
          });
        }
      }
    );
  }

  // get carton data list from api
  GetMG3Export() {
    this.tblResponseExport.SortColumn = "CreatedDate";
    this.tblResponse.IsActiveMG3 = "%";
    this.CategoryService.GetCategoriesMG3(this.tblResponseExport).then(
      (data) => {
        const resdata = data.data;
        //when response data is not null
        if (
          resdata !== null &&
          resdata.ResponseData !== null &&
          resdata.ResponseData.length > 0
        ) {
          this.setState({
            exportDataMG3: resdata.ResponseData,
          });
        }
      }
    );
  }

  //loading page
  onPage(event) {
    this.setState({
      rowsMG1: event.rows,
      totalRecordsMG1: this.state.salesMG1.length,
    });

    //imitate delay of a backend call
    setTimeout(() => {
      this.getLazyLoadingValues(event);
      this.GetCategoriesMG1();
    }, 250);
  }
  onPageMG2(event) {
    this.setState({
      loading: true,
    });

    //imitate delay of a backend call
    setTimeout(() => {
      this.getLazyLoadingValuesMG2(event);
      this.GetCategoriesMG2();
    }, 250);
  }
  onPageMG3(event) {
    this.setState({
      loading: true,
    });

    //imitate delay of a backend call
    setTimeout(() => {
      this.getLazyLoadingValuesMG3(event);
      this.GetCategoriesMG3();
    }, 250);
  }

  // get list of data
  getLazyLoadingValues(event) {
    this.setState({ rowsMG1: event.rows });
    this.tblResponse.PageSize = event.rows;
    this.tblResponse.First = event.first + 1;
    this.tblResponse.PageNo = event.pageCount;
    this.tblResponse.IsActiveMG1 = "%";
    this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
    // this.tblResponse.SortColumn = event.sortField;
    this.tblResponse.SortOrder = event.sortOrder === 1 ? "ASC" : "DESC";
  }
  getLazyLoadingValuesMG2(event) {
    this.setState({ rowsMG2: event.rows });
    this.tblResponse.PageSize = event.rows;
    this.tblResponse.First = event.first + 1;
    this.tblResponse.PageNo = event.pageCount;
    this.tblResponse.IsActiveMG1 = "%";
    this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
    // this.tblResponse.SortColumn = event.sortField;
    this.tblResponse.SortOrder = event.sortOrder === 1 ? "ASC" : "DESC";
  }
  getLazyLoadingValuesMG3(event) {
    this.setState({ rowsMG3: event.rows });
    this.tblResponse.PageSize = event.rows;
    this.tblResponse.first = event.first + 1;
    this.tblResponse.PageNo = event.pageCount;
    this.tblResponse.IsActiveMG3 = "%";
    this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
    // this.tblResponse.SortColumn = event.sortField;
    this.tblResponse.SortOrder = event.sortOrder === 1 ? "ASC" : "DESC";
  }

  // get users data list from api
  GetCategoriesMG1() {
    this.tblResponse.SortColumn = "CreatedDt";
    this.CategoryService.GetCategoriesMG1(this.tblResponse).then((data) => {
      const resdata = data.data;
      if (
        resdata !== null &&
        resdata.ResponseData !== null &&
        resdata.ResponseData.length > 0
      ) {
        this.datasource = resdata.ResponseData;
        //when response data is not null
        this.setState({
          totalRecordsMG1: resdata.ResponseData[0].TotalRows,
          firstMG1: this.tblResponse.First - 1,
          salesMG1: this.datasource.slice(
            0,
            this.tblResponse.First - 1 + this.tblResponse.PageSize
          ),
          loading: false,
          updateLoader: true,
        });
      }
      // when responsedata is null
      else {
        this.datasource = null;
        this.setState({
          totalRecordsMG1: 0,
          firstMG1: 1,
          salesMG1: null,
          loading: false,
          updateLoader: true,
        });
      }
      console.log("dcsd" + this.state.totalRecordsMG1);
    });
  }
  GetCategoriesMG2() {
    this.tblResponse.SortColumn = "CreatedDt";
    this.CategoryService.GetCategoriesMG2(this.tblResponse).then((data) => {
      const resdata = data.data;
      if (
        resdata !== null &&
        resdata.ResponseData !== null &&
        resdata.ResponseData.length > 0
      ) {
        this.datasource = resdata.ResponseData;
        //when response data is not null
        this.setState({
          totalRecordsMG2: resdata.ResponseData[0].TotalRows,
          firstMG2: this.tblResponse.First - 1,
          salesMG2: this.datasource.slice(
            0,
            this.tblResponse.First - 1 + this.tblResponse.PageSize
          ),
          loading: false,
          updateLoader: true,
        });
      }

      // when responsedata is null
      else {
        this.datasource = null;
        this.setState({
          totalRecordsMG2: 0,
          firstMG2: 1,
          salesMG2: null,
          loading: false,
          updateLoader: true,
        });
      }

      console.log("dcsd   2" + this.state.totalRecordsMG2);
    });
  }
  GetCategoriesMG3() {
    this.tblResponse.SortColumn = "CreatedDt";
    this.CategoryService.GetCategoriesMG3(this.tblResponse).then((data) => {
      const resdata = data.data;
      if (
        resdata !== null &&
        resdata.ResponseData !== null &&
        resdata.ResponseData.length > 0
      ) {
        this.datasource = resdata.ResponseData;
        //when response data is not null
        this.setState({
          totalRecordsMG3: resdata.ResponseData[0].TotalRows,
          firstMG3: this.tblResponse.First - 1,
          salesMG3: this.datasource.slice(
            0,
            this.tblResponse.First - 1 + this.tblResponse.PageSize
          ),
          loading: false,
          updateLoader: true,
        });
      }
      // when responsedata is null
      else {
        this.datasource = null;
        this.setState({
          totalRecordsMG3: 0,
          firstMG3: 1,
          salesMG3: null,
          loading: false,
          updateLoader: true,
        });
      }
    });
  }

  /// validate form
  validateForm(value, name, error) {
    let errors = error;
    let IsValid = true;
    //this switch case is used for each form field to validate
    switch (name) {
      // case 'CategoryId':
      //     if (value.length < 1) {
      //         IsValid = false;
      //         errors.CategoryId = "Please select category.";
      //     } else errors.CategoryId = ''
      //     break;
      case "NameMG1":
        if (value.length < 1) {
          IsValid = false;
          errors.NameMG1 = "Please enter Category.";
        } else errors.NameMG1 = "";
        break;
      case "NameMG2":
        if (value.length < 1) {
          IsValid = false;
          errors.NameMG2 = "Please enter Sub category.";
        } else errors.NameMG2 = "";
        break;

      case "NameMG3":
        if (value == undefined || value == "") {
          IsValid = false;
          errors.NameMG3 = "Please Enter Sub Division category.";
        } else errors.NameMG3 = null;
        break;

      default:
        break;
    }

    return IsValid;
  }

  // call every change of form and validation part also
  handleChange(event) {
    if (typeof event == "undefined") {
      return;
    }
    const { name, value } = event.target;
    // if (name == 'MG1Id') {
    //     this.CategoryService.GetCategoryDDLMG2(value).then((data) => {
    //         const resdata = data.data;
    //         ;
    //         if (resdata !== null && resdata.ResponseCode === "200") {
    //             this.setState({

    //                 MG2Id: '',
    //                 CategoryDDLMG2: resdata.ResponseData
    //             });

    //         }
    //     });
    // }
    let errors = this.state.formError;
    //this.validateForm(value, name);
    this.setState({ errors, [name]: value });
    //this statement will set state value for IsActive
    if (name == "IsActiveMG1")
      this.setState({
        IsActiveMG1: event.target.checked,
      });
    if (name == "IsActiveMG2")
      this.setState({
        IsActiveMG2: event.target.checked,
      });
    if (name == "IsActiveMG3")
      this.setState({
        IsActiveMG3: event.target.checked,
      });
  }

  // Open Dialog
  openDialog() {
    this.setState({
      MG1: true,
      NameMG1: "",
      IsActiveMG1: true,
      formErrorMG1: {
        NameMG1: "",
      },
    });
    let errors = this.state.formErrorMG1;
    Object.entries(errors).map(([key, value]) => {
      errors[key] = "";
    });
  }
  // GetDDL() {

  //     this.CategoryService.GetCategoryDDLMG1().then((data) => {
  //         const resdata = data.data;
  //         if (resdata !== null && resdata.ResponseCode === "200") {
  //             this.setState({
  //                 CategoryDDLMG1: resdata.ResponseData
  //             });

  //         }
  //     })

  // }
  subgroup() {
    // this.CategoryService.GetCategoryDDLMG1().then((data) => {
    //     const resdata = data.data;
    //     if (resdata !== null && resdata.ResponseCode === "200") {
    //         this.setState({
    //             CategoryDDLMG1: resdata.ResponseData
    //         });

    //     }
    // });
    this.setState({
      MG2: true,
      MG2Id: "",
      NameMG2: "",
      IsActiveMG2: true,
      formErrorMG2: {
        NameMG2: "",
      },
    });
    let errors = this.state.formErrorMG2;
    Object.entries(errors).map(([key, value]) => {
      errors[key] = "";
    });
  }
  subdivgroup() {
    // this.CategoryService.GetCategoryDDLMG1().then((data) => {
    //     const resdata = data.data;
    //     if (resdata !== null && resdata.ResponseCode === "200") {
    //         this.setState({
    //             CategoryDDLMG1: resdata.ResponseData
    //         });

    //     }
    // });
    this.setState({
      MG3: true,
      MG3Id: "",
      NameMG3: "",
      IsActiveMG3: true,
      formErrorMG3: {
        NameMG3: "",
      },
    });
    let errors = this.state.formErrorMG2;
    Object.entries(errors).map(([key, value]) => {
      errors[key] = "";
    });
  }
  // reset form text value

  // Hide Func
  hideFuncMG1() {
    this.setState({
      MG1: false,
      buttonLabelMG1: "Submit ",
      formHeadingMG1: "Add Category",
    });
    // this.formReset();
  }
  // hideFuncMG2() {
  //   this.setState({
  //     MG2: false,
  //     buttonLabelMG2: "Submit ",
  //     formHeadingMG2: "Add Sub Group Category",
  //   });
  //   // this.formReset();
  // }
  // hideFuncMG3() {
  //   this.setState({
  //     MG3: false,
  //     buttonLabelMG3: "Submit",
  //     formHeadingMG3: "Add Sub Divison Category",
  //   });
  //   //  this.formReset();
  // }
  submitFormMG3(event) {
    event.preventDefault();

    let isfrmvalid = true;
    let checkerror = this.state.formErrorMG3;

    Object.entries(checkerror).map(([key, value]) => {
      const val = this.state[key];
      let valid = this.validateForm(val, key, checkerror);
      //when form validation is not valid
      if (!valid) {
        this.setState({ checkerror: isfrmvalid });
        isfrmvalid = false;
      }
    });
    if (isfrmvalid) {
      this.setState({ updateLoader: false });
      const formData = {
        CategoryIdMG3: this.state.MG3Id === "" ? 0 : parseInt(this.state.MG3Id),
        NameMG3: this.state.NameMG3,
        IsActiveMG3: this.state.IsActiveMG3,
      };

      setTimeout(() => {
        this.CategoryService.CreateUpdateCategoryMG3(formData).then((data) => {
          const resdata = data.data;
          //when data is not null it will give success message else will move to 'else statement'
          if (resdata !== null && resdata.ResponseCode === "200") {
            this.refs.Messchild.showSuccess(
              resdata.ResponseMessage + " Successfully"
            );
            this.GetCategoriesMG3();
            this.setState({
              loading: true,
              MG3: false,
              updateLoader: true,
            });
            // this.formReset();
          } else if (resdata.ResponseCode === "400") {
            this.setState({
              updateLoader: true,
            });
            this.refs.Messchild.showWarn(resdata.ResponseMessage);
          } else {
            this.setState({
              updateLoader: true,
            });
            this.refs.Messchild.showError(resdata.ResponseMessage);
          }
        });
      }, 100);
    }
  }

  submitFormMG2(event) {
    event.preventDefault();
    let isfrmvalid = true;
    let checkerror = this.state.formErrorMG2;

    Object.entries(checkerror).map(([key, value]) => {
      const val = this.state[key];
      let valid = this.validateForm(val, key, checkerror);
      //when form validation is not valid
      if (!valid) {
        this.setState({ checkerror: isfrmvalid });
        isfrmvalid = false;
      }
    });
    if (isfrmvalid) {
      this.setState({ updateLoader: false });
      const formData = {
        CategoryIdMG2: this.state.MG2Id === "" ? 0 : parseInt(this.state.MG2Id),
        NameMG2: this.state.NameMG2,
        IsActiveMG2: this.state.IsActiveMG2,
      };

      setTimeout(() => {
        this.CategoryService.CreateUpdateCategoryMG2(formData).then((data) => {
          const resdata = data.data;
          //when data is not null it will give success message else will move to 'else statement'
          if (resdata !== null && resdata.ResponseCode === "200") {
            this.refs.Messchild.showSuccess(
              resdata.ResponseMessage + " Successfully"
            );
            this.GetCategoriesMG2();
            this.setState({
              loading: true,
              MG2: false,
              updateLoader: true,
            });
            // this.formReset();
          } else if (resdata.ResponseCode === "400") {
            this.setState({
              updateLoader: true,
            });
            this.refs.Messchild.showWarn(resdata.ResponseMessage);
          } else {
            this.setState({
              updateLoader: true,
            });
            this.refs.Messchild.showError(resdata.ResponseMessage);
          }
        });
      }, 100);
    }
  }
  // submit form and create or update user
  submitFormMG1(event) {
    event.preventDefault();

    let isfrmvalid = true;
    let checkerror = this.state.formErrorMG1;

    Object.entries(checkerror).map(([key, value]) => {
      const val = this.state[key];
      let valid = this.validateForm(val, key, checkerror);
      //when form validation is not valid
      if (!valid) {
        this.setState({ checkerror: isfrmvalid });
        isfrmvalid = false;
      }
    });

    //when isfrmvalid is true
    if (isfrmvalid) {
      this.setState({ updateLoader: false });
      const formData = {
        CategoryIdMG1: this.state.MG1Id === "" ? 0 : parseInt(this.state.MG1Id),
        NameMG1: this.state.NameMG1,
        IsActiveMG1: this.state.IsActiveMG1,
      };

      setTimeout(() => {
        this.CategoryService.CreateUpdateCategoryMG1(formData).then((data) => {
          const resdata = data.data;
          //when data is not null it will give success message else will move to 'else statement'
          if (resdata !== null && resdata.ResponseCode === "200") {
            this.refs.Messchild.showSuccess(
              resdata.ResponseMessage + " Successfully"
            );
            this.GetCategoriesMG1();
            this.setState({
              loading: true,
              MG1: false,
              updateLoader: true,
            });
            // this.formReset();
          } else if (resdata.ResponseCode === "400") {
            this.setState({
              updateLoader: true,
            });
            this.refs.Messchild.showWarn(resdata.ResponseMessage);
          } else {
            this.setState({
              updateLoader: true,
            });
            this.refs.Messchild.showError(resdata.ResponseMessage);
          }
        });
      }, 100);
    }
  }

  // delete user data
  // deleteSubCategory(subCategoryId) {
  //     setTimeout(() => {
  //         this.CategoryService.DeleteSubCategory(subCategoryId).then(data => {
  //             const resdata = data.data;
  //             if (resdata !== null && resdata.ResponseCode === '200') {
  //                 this.refs.Messchild.showSuccess(resdata.ResponseMessage);
  //                 const totlrecord = this.state.totalRecords - 1;
  //                 const row = this.state.rows;

  //                 if (this.tblResponse.First >= totlrecord) {
  //                     const _curntCursor = Math.round(totlrecord / row);
  //                     this.tblResponse.First = _curntCursor + 1;
  //                 }
  //                 this.GetSubCategories();
  //             } else {
  //                 this.refs.Messchild.showError(resdata.ResponseMessage);
  //             }
  //         });
  //     }, 1000);
  // }

  // Search Filter data
  searchFilter(e) {
    this.setState({
      loading: true,
    });
    this.tblResponse.SearchValue = e.target.value;
    this.GetCategoriesMG1();
  }
  searchFilterMG2(e) {
    this.setState({
      loading: true,
    });
    this.tblResponse.SearchValue = e.target.value;
    this.GetCategoriesMG2();
  }
  searchFilterMG3(e) {
    this.setState({
      loading: true,
    });
    this.tblResponse.SearchValue = e.target.value;
    this.GetCategoriesMG3();
  }

  // message
  callbackFunction = (childData) => {
    this.setState({ message: childData });
  };

  // disabled Checkbox
  activeTemplate(rowData, column) {
    return <input type="checkbox" disabled checked={rowData.IsActiveMG1} />;
  }
  activeTemplate2(rowData, column) {
    return <input type="checkbox" disabled checked={rowData.IsActiveMG2} />;
  }
  activeTemplate3(rowData, column) {
    return <input type="checkbox" disabled checked={rowData.IsActiveMG3} />;
  }
  // edit form
  actionTemplate(rowData, column) {
    const editMode = (e) => {
      this.setState({
        buttonLabelMG1: "Update",
        formHeadingMG1: "Update Category",
        MG1: true,
        MG1Id: rowData.CategoryIdMG1.toString(),
        NameMG1: rowData.NameMG1,
        IsActiveMG1: rowData.IsActiveMG1,
      });

      // let checkbox = document.getElementsByName('IsActive');

      //checkbox[0].checked = rowData.IsActive;
    };

    const deleteFunc = () => {
      // this.refs.child.confirmModal();
      // if (this.refs.child.state.confirm == true) {
      //     this.deleteSubCategory(rowData.SubCategoryId);
      // } else {
      // }
      confirmAlert({
        title: "Confirm to delete",
        message: "Are you sure to do this.",
        buttons: [
          {
            label: "Yes",
            onClick: () => this.deleteSubCategory(rowData.SubCategoryId),
          },
          {
            label: "No",
            onClick: () => null,
          },
        ],
      });
    };

    return (
      <span>
        <Button
          type="button"
          icon="pi pi-pencil"
          onClick={editMode}
          className="p-button-warning"
          style={{ marginRight: "3px" }}
        ></Button>
        {/* <Button type="button" icon="pi pi-trash" onClick={deleteFunc} className="p-button-danger"></Button> */}
      </span>
    );
  }
  actionTemplateMG2(rowData, column) {
    //const filterCatDDL = this.state.CategoryDDLMG1.filter(x => x.value == rowData.CategoryIdMG1);
    const editMode = (e) => {
      this.setState({
        buttonLabelMG2: "Update",
        formHeadingMG2: "Update Sub Category",
        MG2: true,
        // MG1Id: filterCatDDL.length != 0 ? rowData.CategoryIdMG1.toString() : "",
        MG2Id: rowData.CategoryIdMG2.toString(),
        NameMG2: rowData.NameMG2,
        IsActiveMG2: rowData.IsActiveMG2,
      });

      // let checkbox = document.getElementsByName('IsActive');
      //checkbox[0].checked = rowData.IsActive;
    };
    return (
      <span>
        <Button
          type="button"
          icon="pi pi-pencil"
          onClick={editMode}
          className="p-button-warning"
          style={{ marginRight: "3px" }}
        ></Button>
        {/* <Button type="button" icon="pi pi-trash" onClick={deleteFunc} className="p-button-danger"></Button> */}
      </span>
    );
  }
  actionTemplateMG3(rowData, column) {
    const editMode = (e) => {
      //  const filterCatDDL = this.state.CategoryDDLMG1.filter(x => x.value == rowData.CategoryIdMG1);

      this.setState({
        buttonLabelMG3: "Update",
        formHeadingMG3: "Update Sub Category",
        MG3: true,
        //  MG1Id: filterCatDDL.length != 0 ? rowData.CategoryIdMG1.toString() : "",

        // MG2Id: rowData.CategoryIdMG2.toString(),
        // NameMG2: rowData.NameMG2,
        MG3Id: rowData.CategoryIdMG3.toString(),
        NameMG3: rowData.NameMG3,
        IsActiveMG3: rowData.IsActiveMG3,
      });

      // let checkbox = document.getElementsByName('IsActive');
      //checkbox[0].checked = rowData.IsActive;
    };
    return (
      <span>
        <Button
          type="button"
          icon="pi pi-pencil"
          onClick={editMode}
          className="p-button-warning"
          style={{ marginRight: "3px" }}
        ></Button>
        {/* <Button type="button" icon="pi pi-trash" onClick={deleteFunc} className="p-button-danger"></Button> */}
      </span>
    );
  }

  onSort(event) {
    this.setState({ sortOrder: this.state.sortOrder === 1 ? 2 : 1 });
    const sortField =
      event.sortField == "CreatedDt" ? "CreatedDt" : event.sortField;
    this.tblResponse.SortColumn = sortField ? sortField : "";
    this.tblResponse.SortOrder = this.state.sortOrder === 1 ? "DESC" : "ASC";
    this.GetCategoriesMG1();
  }
  onSortMG2(event) {
    this.setState({ sortOrder: this.state.sortOrder === 1 ? 2 : 1 });
    const sortField =
      event.sortField == "CreatedDt" ? "CreatedDt" : event.sortField;
    this.tblResponse.SortColumn = sortField ? sortField : "";
    this.tblResponse.SortOrder = this.state.sortOrder === 1 ? "DESC" : "ASC";
    this.GetCategoriesMG2();
  }
  onSortMG3(event) {
    this.setState({ sortOrder: this.state.sortOrder === 1 ? 2 : 1 });
    const sortField =
      event.sortField == "CreatedDt" ? "CreatedDt" : event.sortField;
    this.tblResponse.SortColumn = sortField ? sortField : "";
    this.tblResponse.SortOrder = this.state.sortOrder === 1 ? "DESC" : "ASC";
    this.GetCategoriesMG3();
  }

  // create html part
  render() {
    const items = [{ label: "Masters" }, { label: "Category Master" }];

    const home = { icon: "pi pi-home", url: "/dashboard" };
    let header = (
      <div style={{ textAlign: "right" }}>
        <h3 className="col-md-6">Category Master</h3>

        <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>
        <InputText
          type="search"
          onInput={this.searchFilter}
          placeholder="Search"
          size="30"
        />
      </div>
    );
    // let headerMG3 = (
    //   <div style={{ textAlign: "right" }}>
    //     <h3 className="col-md-6">Sub Division Category Master</h3>

    //     <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>
    //     <InputText
    //       type="search"
    //       onInput={this.searchFilterMG3}
    //       placeholder="Search"
    //       size="30"
    //     />
    //   </div>
    // );
    // let headerMG2 = (
    //   <div style={{ textAlign: "right" }}>
    //     <h3 className="col-md-6">Sub Category Master</h3>

    //     <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>
    //     <InputText
    //       type="search"
    //       onInput={this.searchFilterMG2}
    //       placeholder="Search"
    //       size="30"
    //     />
    //   </div>
    // );

    // const categoryOption = this.state.CategoryDDL.map((category) =>
    //     <option value={category.value} >{category.label}</option>
    // );

    return (
      <>
        <MessageComponent ref="Messchild" />
        {<Loaders loader={this.state.updateLoader} />}
        <ConfirmationModal parentCallback={this.callbackFunction} ref="child" />
        <div className="row mb-3">
          <div className="col-md-6 heading">
            <h1>Category Master</h1>
          </div>
          <div className="col-md-6 custom-breadcrumb">
            <BreadCrumb model={items} home={home} />
          </div>
        </div>{" "}
        <div className="heading">
          <Button
            style={{ marginRight: "10px" }}
            label="Add Category"
            icon="pi pi-plus-circle"
            onClick={this.openDialog}
          />
          {/* <Button
            style={{ marginRight: "10px" }}
            label="Add Sub Group"
            icon="pi pi-plus-circle"
            onClick={this.subgroup}
          /> */}
          {/* <Button
            style={{ marginRight: "10px" }}
            label="Add Sub Divison Group"
            icon="pi pi-plus-circle"
            onClick={this.subdivgroup}
          /> */}
        </div>
        <div
          style={{
            textAlign: "right",
            marginBottom: "10px",
            marginRight: "10px",
          }}
        >
          {/* <CSVLink
            data={this.state.exportDataMG3}
            headers={this.state.headsMG3}
            filename={"Sub Divison Group Data.csv"}
            target="_blank"
          >
            <button
              style={{ marginRight: "5px" }}
              className="btn btn-success p-2 ml-2 buttone1"
            >
              <i className="pi pi-file-excel mr-2"></i>
              Export Sub Divison Group
            </button>
          </CSVLink> */}
          {/* <CSVLink
            data={this.state.exportDataMG2}
            headers={this.state.headsMG2}
            filename={"Sub Group Data.csv"}
            target="_blank"
          >
            <button
              style={{ marginRight: "5px" }}
              className="btn btn-success p-2 ml-2 buttone1"
            >
              <i className="pi pi-file-excel mr-2"></i>
              Export Sub Group
            </button>
          </CSVLink> */}
          <CSVLink
            data={this.state.exportDataMG1}
            headers={this.state.headsMG1}
            filename={"Category Data.csv"}
            target="_blank"
          >
            <button className="btn btn-success p-2 ml-2 buttone1">
              <i className="pi pi-file-excel mr-2"></i>
              Export Categories
            </button>
          </CSVLink>
        </div>
        <DataTable
          responsive={true}
          header={header}
          globalFilter={this.state.globalFilterMG1}
          emptyMessage={this.state.emptyMessage}
          value={this.state.salesMG1}
          sortMode="multiple"
          paginator={true}
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          rows={this.state.rowsMG1}
          rowsPerPageOptions={[5, 10, 20]}
          totalRecords={this.state.totalRecordsMG1}
          lazy={true}
          first={this.state.firstMG1}
          onPage={this.onPage}
          onSort={this.onSort}
        >
          <Column field="NameMG1" header="Category Name" sortable={true} />
          <Column field="CreatedDate" header="Created Date" sortable={true} />
          <Column body={this.activeTemplate} header="Status" />
          <Column
            body={this.actionTemplate}
            header="Action"
            className="custom-center"
          />
        </DataTable>
        {/* <DataTable
          responsive={true}
         // header={headerMG2}
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          globalFilter={this.state.globalFilterMG2}
          emptyMessage={this.state.emptyMessage}
          value={this.state.salesMG2}
          sortMode="multiple"
          paginator={true}
          rows={this.state.rowsMG2}
          rowsPerPageOptions={[5, 10, 20]}
          totalRecords={this.state.totalRecordsMG2}
          lazy={true}
          first={this.state.firstMG2}
          onPage={this.onPageMG2}
          onSort={this.onSortMG2}
        >
          <Column field="NameMG2" header="Sub Category" sortable={true} />
          <Column field="CreatedDate" header="Created Date" sortable={true} />
          <Column body={this.activeTemplate2} header="Status" />
          <Column
            body={this.actionTemplateMG2}
            header="Action"
            className="custom-center"
          />
        </DataTable> */}
        {/* <DataTable
          responsive={true}
         // header={headerMG3}
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          globalFilter={this.state.globalFilterMG3}
          emptyMessage={this.state.emptyMessage}
          value={this.state.salesMG3}
          sortMode="multiple"
          paginator={true}
          rows={this.state.rowsMG3}
          rowsPerPageOptions={[5, 10, 20]}
          totalRecords={this.state.totalRecordsMG3}
          lazy={true}
          first={this.state.firstMG3}
          onPage={this.onPageMG3}
          onSort={this.onSortMG3}
        >
          <Column
            field="NameMG3"
            header="Sub Divison Category"
            sortable={true}
          />
          <Column field="CreatedDate" header="Created Date" sortable={true} />
          <Column field="" body={this.activeTemplate3} header="Status" />
          <Column
            body={this.actionTemplateMG3}
            header="Action"
            className="custom-center"
          />
        </DataTable> */}
        <Dialog
          header={this.state.formHeadingMG1}
          className="popup-modal"
          visible={this.state.MG1}
          modal={true}
          onHide={this.hideFuncMG1}
        >
          <form onSubmit={this.submitFormMG1}>
            <div className="row">
              <div className="col-md-4 text-right">
                <label>
                  Category<span>*</span>:
                </label>
              </div>
              <div className="col-md-8 mb-2">
                <input
                  type="text"
                  value={this.state.NameMG1}
                  onChange={this.handleChange}
                  className="form-control"
                  name="NameMG1"
                />
                {this.state.formErrorMG1.NameMG1 !== "" ? (
                  <div className="error">{this.state.formErrorMG1.NameMG1}</div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-4 text-right">
                <label>IsActive :</label>
              </div>
              <div className="col-md-8 mb-2">
                <input
                  type="checkbox"
                  checked={this.state.IsActiveMG1}
                  className="ml-2"
                  name="IsActiveMG1"
                  onChange={this.handleChange}
                />
              </div>
              <div className="col-md-12 text-center">
                <button type="submit" className="btn btn-primary">
                  {this.state.buttonLabelMG1}
                </button>
              </div>
            </div>
            <div>
              <label>
                Note: Fields marked as (<span style={{ color: "red" }}>*</span>)
                are required
              </label>
            </div>
          </form>
        </Dialog>
        <Dialog
          header={this.state.formHeadingMG2}
          className="popup-modal"
          visible={this.state.MG2}
          modal={true}
          onHide={this.hideFuncMG2}
        >
          <form onSubmit={this.submitFormMG2}>
            <div className="row">
              {/* <div className="col-md-4 text-right">
                            <label>Select the Category<span>*</span>:</label>
                        </div>
                            <div className="col-md-8 mb-2">

                                <Dropdown
                                    value={this.state.MG1Id}
                                    options={this.state.CategoryDDLMG1}
                                    onChange={this.handleChange}
                                    className="form-control"
                                    name="MG1Id"
                                    placeholder="Select Main Category" />
                                {this.state.formErrorMG2.MG1Id !== null ? (
                                    <div className="error">{this.state.formErrorMG2.MG1Id}</div>
                                ) : null}
                            </div> */}

              <div className="col-md-4 text-right">
                <label>
                  Sub Category<span>*</span>:
                </label>
              </div>
              <div className="col-md-8 mb-2">
                <input
                  type="text"
                  value={this.state.NameMG2}
                  onChange={this.handleChange}
                  className="form-control"
                  name="NameMG2"
                />
                {this.state.formErrorMG2.NameMG2 !== "" ? (
                  <div className="error">{this.state.formErrorMG2.NameMG2}</div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-4 text-right">
                <label>IsActive :</label>
              </div>
              <div className="col-md-8 mb-2">
                <input
                  type="checkbox"
                  checked={this.state.IsActiveMG2}
                  className="ml-2"
                  name="IsActiveMG2"
                  onChange={this.handleChange}
                />
              </div>
              <div className="col-md-12 text-center">
                <button type="submit" className="btn btn-primary">
                  {this.state.buttonLabelMG2}
                </button>
              </div>
            </div>
            <div>
              <label>
                Note: Fields marked as (<span style={{ color: "red" }}>*</span>)
                are required
              </label>
            </div>
          </form>
        </Dialog>
        <Dialog
          header={this.state.formHeadingMG3}
          className="popup-modal"
          visible={this.state.MG3}
          modal={true}
          onHide={this.hideFuncMG3}
        >
          <form onSubmit={this.submitFormMG3}>
            <div className="row">
              {/* <div className="col-md-4 text-right">
                            <label>Main Category<span>*</span>:</label>
                        </div>
                            <div className="col-md-8 mb-2">

                                <Dropdown
                                    value={this.state.MG1Id}
                                    options={this.state.CategoryDDLMG1}
                                    onChange={this.handleChange}
                                    className="form-control"
                                    name="MG1Id"
                                    placeholder="Select Main Category" />
                                {this.state.formErrorMG3.MG1Id !== null ? (
                                    <div className="error">{this.state.formErrorMG3.MG1Id}</div>
                                ) : null}
                            </div>
                            <div className="col-md-4 text-right">
                                <label>Select Sub Category<span>*</span>:</label>
                            </div>
                            <div className="col-md-8 mb-2">
                                <Dropdown
                                    value={this.state.MG2Id}
                                    options={this.state.CategoryDDLMG2}
                                    onChange={this.handleChange}
                                    className="form-control"
                                    name="MG2Id"
                                    placeholder={this.state.ontime ? this.state.NameMG2
                                        : "Select Sub Category "} />
                                {this.state.formErrorMG3.MG2Id !== null ? (
                                    <div className="error">{this.state.formErrorMG3.MG2Id}</div>
                                ) : null}
                            </div> */}
              <div className="col-md-4 text-right">
                <label>
                  Sub Divison Category<span>*</span>:
                </label>
              </div>
              <div className="col-md-8 mb-2">
                <input
                  type="text"
                  value={this.state.NameMG3}
                  onChange={this.handleChange}
                  className="form-control"
                  name="NameMG3"
                />
                {this.state.formErrorMG3.NameMG3 !== "" ? (
                  <div className="error">{this.state.formErrorMG3.NameMG3}</div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-4 text-right">
                <label>IsActive :</label>
              </div>
              <div className="col-md-8 mb-2">
                <input
                  type="checkbox"
                  checked={this.state.IsActiveMG3}
                  className="ml-2"
                  name="IsActiveMG3"
                  onChange={this.handleChange}
                />
              </div>
              <div className="col-md-12 text-center">
                <button type="submit" className="btn btn-primary">
                  {this.state.buttonLabelMG3}
                </button>
              </div>
            </div>
            <div>
              <label>
                Note: Fields marked as (<span style={{ color: "red" }}>*</span>)
                are required
              </label>
            </div>
          </form>
        </Dialog>
      </>
    );
  }
}
