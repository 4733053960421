import instance from './DefaultApi';

export default class ItemLedgerReportService {
    ControllerName = 'ItemLedgerReport';

    // get all data
    GetReport(tableRequest) {
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetReport`, tableRequest).then(res => res)
    }
    // get stock report
    GetStockReport(tableRequest) {
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetStockReport`, tableRequest).then(res => res)
    }

    GetNewStockReport(tableRequest) {
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetNewStockReport`, tableRequest).then(res => res)
    }

    // Get Excel  Export Mapping data
    GetExport(tableRequest) {
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetExport`, tableRequest).then(res => res)
    }
}