import React, { Component } from 'react'
import UserService from '../services/UserService';
import MessageComponent from '../template/Message';

// Manage Profile
export default class Profile extends Component {
    constructor(props) {
        super(props);

        this.state = {
             
            Email: '',
            MobileNo: '',
            

            formError: {
                Email: null,
                MobileNo: null
            }
        }

        this.UserService = new UserService();
        this.handleChange = this.handleChange.bind(this);
        this.submitForm = this.submitForm.bind(this);
    }
    //trigger api function on Page call
    componentDidMount() {
        this.getUser();
    }

    //get user list
    getUser() {
        this.UserService.getUser().then(data => {
            const resdata = data.data;
            //if ResponseCode is 200
            if (resdata.ResponseCode === '200') {
                const usr = resdata.ResponseData;
                this.setState({
                    Email: usr.Email,
                    MobileNo: usr.MobileNo
                });
            }
        });
    }

    // submit form and create or update user
    submitForm(event) {
        event.preventDefault();

        let isfrmvalid = true;
        let checkerror = this.state.formError;

        Object.entries(checkerror).map(([key, value]) => {
            const val = this.state[key]
            let valid = this.validateForm(val, key);
            //form not valid
            if (!valid) {
                this.setState({ checkerror: isfrmvalid });
                isfrmvalid = false;
            }
        });
        //form valid
        if (isfrmvalid) {
            const formData = {               
                MobileNo: this.state.MobileNo,
                Email: this.state.Email,                 
            }

            this.UserService.UpdateProfile(formData).then(data => {
                const resdata = data.data;
              //if ResponseCode is 200 success else error or warning message 
                if (resdata !== null && resdata.ResponseCode === '200') {
                    this.setState({
                        loading: true,
                        visible: false
                    });
                    
                    this.refs.Messchild.showSuccess(resdata.ResponseMessage);

                    // const res = this.refs.Messchild.showSuccess(resdata.ResponseMessage);
                    // if (res) {
                    //     setTimeout(() => {
                    //         sessionStorage.clear();
                    //         window.location = '/login';
                    //     }, 2000);
                    // }

                } else if (resdata.ResponseCode === '400') {
                    this.refs.Messchild.showWarn(resdata.ResponseMessage);
                } else {
                    this.refs.Messchild.showError(resdata.ResponseMessage);
                }
            });
        }
    }

    // form Reset
    formReset() {
        this.setState({
            Email: '',
            MobileNo: ''
        });
    }

    // call every change of form and validation part also
    handleChange(event) {

        const { name, value } = event.target;
        let errors = this.state.formError;

        const isValid = this.validateForm(value, name);
        this.setState({ errors, [name]: value });
    }

    // validate Form
    validateForm(value, name) {
        let errors = this.state.formError;
        let IsValid = true;
        //to validate each form field with required conditions
        switch (name) {

            case 'Email':
                if (value.length < 1) {
                    IsValid = false;
                    errors.Email = "Please enter your email-id.";
                }
                else if (value.length >= 1) {
                    if (!value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
                        IsValid = false;
                        errors.Email = "Please enter valid email-id.";
                    } else errors.Email = ''
                } else errors.Email = ''
                break;
            case 'MobileNo':
                if (value.length < 1) {
                    IsValid = false;
                    errors.MobileNo = "Please enter your mobile no.";
                }
                else if (value.length >= 1) {
                    if (!value.match(/^[6-9]\d{9}$/)) {
                        IsValid = false;
                        errors.MobileNo = "Please enter valid mobile no.";
                    } else errors.MobileNo = ''
                } else errors.MobileNo = ''
                break;

             
            default:
                break;
        }

        return IsValid;
    }

    // return html
    render() {
        return (
            <>
                <MessageComponent ref="Messchild" />
                <form onSubmit={this.submitForm}>
                    <div className="heading">
                        <h1>Profile</h1>
                    </div>
                    <div className="row">

                           <div className="col-md-3">
                            <label>Email<span>*</span>:</label>
                            <input type="text" value={this.state.Email} onChange={this.handleChange} className="form-control" name="Email" />
                                {this.state.formError.Email !== null ?
                                    <div className='error'>{this.state.formError.Email}</div> : null}
                            </div>
                            <div className="col-md-3">
                            <label>Mobile Number<span>*</span>:</label>
                            <input type="text" maxLength={10} value={this.state.MobileNo} onChange={this.handleChange} className="form-control" name="MobileNo" />
                                {this.state.formError.MobileNo !== null ?
                                    <div className='error'>{this.state.formError.MobileNo}</div> : null}
                            </div>

                        
                        
                            <div className="col-md-9 mt-3">
                            <button className="btn btn-primary">Update</button>
                        </div>
                    </div>
                    {/* <div className="row profile-frm">
                        <div className="col-md-3">Mobile No</div>
                        <div className="col-md-9">
                        <input type="text" maxLength={10} value={this.state.MobileNo} onChange={this.handleChange} className="form-control" name="MobileNo" /></div>
                        {this.state.formError.MobileNo !== '' ?
                                <div className='error'>{this.state.formError.MobileNo}</div> : ''}
                       
                        <div className="col-md-3">Email</div>
                        <div className="col-md-9">
                        <input type="text" value={this.state.Email} onChange={this.handleChange} className="form-control" name="Email" />
                                {this.state.formError.Email !== null ?
                                    <div className='error'>{this.state.formError.Email}</div> : null}
                        </div>
                        <div className="col-md-3"></div>
                        <div className="col-md-9">
                            <button className="btn btn-primary">Update</button>
                        </div>
                    </div>
                 */}
                
                </form>
            </>
        )
    }
}
