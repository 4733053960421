import React, { Component } from "react";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import UserService from "../../services/UserService";
import DispatchService from "../../services/DispatchService";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import tableRequest from "../../models/tableRequest.js";
import { confirmAlert } from "react-confirm-alert"; // Import
import { ConfirmationModal } from "../../template/ConfirmationModal";
import MessageComponent from "../../template/Message";
import $ from "jquery";
import ApiService from "../../services/apiService";
import Loaders from '../../template/Loaders';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Card } from 'primereact/card';
export default class ReturnCarton extends Component
 {
  constructor(props) {
    super(props);
    this.UserService = new UserService();
    this.DispatchService = new DispatchService();
    this.ApiService = new ApiService();
    this.tblResponse = new tableRequest();
    this.state = {

      scanTxt: '',
      
      radio: false,
      buttonLabel: "Submit",
      sales: [],
      scanItems: [],
      scanDisp: [],
      loading: false,
      first: 0,
      rows: 10,
      totalRecords: 0,
      Remark: "",
      globalFilter: null

    };
    this.hideFunc = this.hideFunc.bind(this);
    this.actionTemplate = this.actionTemplate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    //this.sendToEvent = this.sendToEvent.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.submitRemark = this.submitRemark.bind(this);
    this.onPage = this.onPage.bind(this);
    this.searchFilter = this.searchFilter.bind(this);
    this.onSort = this.onSort.bind(this);
    this.ScanItem = this.ScanItem.bind(this);
  }
  //trigger api function on page call
  componentDidMount() {
  
  }

  // PAGE EVENT CALL
  onPage(event) {
    this.setState({
      loading: false,
    });

   
  }
  hideFunc() {
    this.setState({
      remarkVisible: false,
      olderstockVisible: false
      // formHeading: 'Add User'
    })

  }
 
  // get users data list from api
 

  // call every change of form and validation part also
  handleChange(event) {
    const { name, value } = event.target;
    let errors = this.state.formError;
    this.setState({ errors, [name]: value });
  }

  openDialog() {
    this.setState({ visible: true });
    this.formReset();
  }

  submitForm(event) {


    event.preventDefault();
    //if SendTo length is 0 
   
    //if scanItems length is 0
     if (this.state.scanItems.length == 0) {
      this.refs.Messchild.showWarn("Scan First");
      return;
    }

    //if(this.state.CartonId.ca)
    else {

      this.setState({
          remarkVisible: true
        });
      }
      
    }


  


  submitRemark(event) {
    let loader=this.state.loading;
    this.setState({
      loading:!loader
    })
    event.preventDefault();
    let formData =  {
      scanned: this.state.scanItems,
      IsActive:this.state.IsActive,
      Remark:this.state.Remark,
    };
    
    this.SaveDispatch(formData);
  }


  SaveDispatch(formData) {


    setTimeout(() => {
      this.DispatchService.ReturnCarton(formData).then((data) => {
        
        const resdata = data.data;
        let loader=this.state.loading;
        //if ResponseCode is 200 success message will be shown else will move to 'else statement'
        if (resdata !== null && resdata.ResponseCode === "200") {

          this.setState({
            scanItems: [],
            scanDisp:[],
            //KREP-55
            remarkVisible: false,
            needtoSaveDispatch: "",
            Remark:'',
            radio: false,IsActive:false,
            loading:!loader,
            

          });
          
          this.refs.Messchild.showSuccess(resdata.ResponseMessage);
        } else {
          this.setState({
            scanItems: [],
            scanDisp:[],
            IsActive:false,
            Remark:'',
            loading:!loader,
            //KREP-55
            remarkVisible: false,

          }); this.refs.Messchild.showWarn(resdata.ResponseMessage);
       
        }
      });
    }, 100);

  }

  // scan qr code
  ScanItem(e) {

    if (e.key === 'Enter') {
     
      let qr = e.target.value;
     
      if (qr.includes("TID") || qr.includes("SID")) {



        this.setCartonDetails(qr, true);



      } else {
        this.refs.Messchild.showWarn("Invalid Scanned");
      }
      this.setState({
        scanTxt: ""
      });

    }
  }


  // set tertiary details in grid

  setCartonDetails(qr, checkItemDate) {
;
let id=1;
   
    (this.state.scanItems).forEach(element => {
      if (element.CartonId == qr) {

        this.refs.Messchild.showWarn("Already Scanned..!");
        id=2;
        return;
      }

    });
    
setTimeout(() => {
  if(id==1){
    let flag=this.state.IsActive?true:false;
    this.DispatchService.GetReturnInfo(qr,flag).then((data) => {

      const resdata = data.data;
    
      //if ResponseCode is 200 else will move to 'else statement'
      if (resdata !== null && resdata.ResponseCode === "200") {
        //disable

        (resdata.ResponseData).forEach(element => {
          
          element.MFGDate = (element.MFGDate != null || undefined) ? element.MFGDate.substring(0, 10) : element.MFGDate
          element.EXPDate = (element.EXPDate != null || undefined) ? element.EXPDate.substring(0, 10) : element.EXPDate
         
          if (this.state.scanItems.length > 0) {
            
            let check = this.state.scanItems.filter(
              (y) => y.Id == element.Id
            );

            if (check.length > 0) {
             
            //   this.refs.Messchild.showWarn("already Scanned");
              
            }
          }

          this.state.scanItems.push(element);
          
          if (this.state.scanDisp.length > 0) {
            
            let checkseqNo = this.state.scanDisp.filter(
              (y) => y.CartonId == element.CartonId
            );

            if (checkseqNo.length > 0) {

              return
            }
          
          }
          this.state.scanDisp.push(element);
          
        
          
          this.setState({
            scanItems: this.state.scanItems,
            scanTxt: "",
            radio: true

          });
        });
      }
    
      else {
        this.refs.Messchild.showWarn(resdata.ResponseMessage);
      }
    });
  }
  

}, 50);
  }
  // delete user data
  deleteScanned(TertiaryCartonId) {
    
    let arrayfilter = this.state.scanItems.filter(
      (x) => x.CartonId != TertiaryCartonId
    );

    this.setState({
      scanItems: arrayfilter,

    });
    let array = this.state.scanDisp.filter(
      (x) => x.CartonId != TertiaryCartonId
    );

    this.setState({
      scanDisp: array,
    });
  }


  // seach table
  searchFilter(e) {
    this.setState({
      loading: false,
    });
    this.tblResponse.SearchValue = e.target.value;
    this.GetDispatch();
  }

  callbackFunction = (childData) => {
    this.setState({ message: childData });
  };

 
  // Sort table
  onSort(event) {
    this.setState({
      sortOrder: this.state.sortOrder === 1 ? 2 : 1,
      sortIcon: !this.state.sortIcon,
    });
    const sortField =
      event.sortField == "CreatedDt" ? "CreatedDt" : event.sortField;
    this.tblResponse.SortColumn = sortField ? sortField : "";
    this.tblResponse.SortOrder = this.state.sortOrder === 1 ? "DESC" : "ASC";
   
    const clickData = document.getElementsByClassName(
      "p-sortable-column-icon pi pi-fw pi-sort"
    );
    console.log(event);
  }

  // edit form
  actionTemplate(rowData, column) {
    const deleteFunc = () => {
      confirmAlert({
        title: "Confirm to delete",
        message: "Are you sure to do this.",
        buttons: [
          {
            label: "Yes",
            onClick: () => this.deleteScanned(rowData.CartonId),
          },
          {
            label: "No",
            onClick: () => null,
          },
        ],
      });
    };

    return (
      <span>
        <Button type="button" icon="pi pi-trash" onClick={deleteFunc} className="p-button-danger"></Button>
      </span>
    );
  }

  // create html part
  render() {
    const items = [
      { label: 'Transaction' },
      { label: 'Dispatch' }
    ];

    const home = { icon: 'pi pi-home', url: '/dashboard' }
    let header = (
      <div className="row">
        <div className="col-md-8 text-left"></div>
       
      </div>
    );

 ;

    return (
      <>
        <MessageComponent ref="Messchild" />
        <ConfirmationModal parentCallback={this.callbackFunction} ref="child" />
        <Card>
          <div className="row mb-3">
            <div className="col-md-6 heading">
              <h1>Carton Return</h1>
            </div>
            <div className="col-md-6 custom-breadcrumb">
              <BreadCrumb model={items} home={home} />
            </div>
          </div>
          <div>


            <form className="form-inline dispatch-label row mb-3">
             
              <div className="form-group col-mb-4">
               

                <div hidden={true} className="custom-drop">
                  <Dropdown optionLabel="FirmName"
                    className="form-control" placeholder="-Select-" name="SendTo" />
                 
                </div>

              </div>
<div className="form-group col-md-2">
<label>Return From Transit &nbsp;</label> 
<input type="checkbox"checked={this.state.IsActive} style={this.state.radio ? { pointerEvents: 'none' } : {}} onChange={(e)=>{let val=this.state.IsActive; this.setState({IsActive:!val})}}/>
</div>
              <div className="form-group col-md-4">
                <label>Scan items:</label>
                <input onChange={e => { this.handleChange(e) }} name="scanTxt" value={this.state.scanTxt} type="search"
                  className="form-control ml-2" id="scanTxt" onKeyDown={e =>  this.ScanItem(e) } />

                {/* <input type="search" style={{ width: "calc(100% - 80px)" }} className="custom-input ml-2" id="scan"
            value={this.state.Scanvalue} onChange={(e)=> {
          this.ScanItem(e);
          }}
          /> */}
              </div>
            </form>
          
          </div>
          <Loaders loader={!this.state.loading} />
          <DataTable responsive={true} header={header} emptyMessage={this.state.emptyMessage}  value={this.state.scanDisp} paginator={true}
            sortMode="multiple" rowsPerPageOptions={[10, 50, 200,500]} rows={this.state.rows} className="tblAuto">
            <Column field="CartonId" header="Carton Id" />
            <Column field="ProductName" header="Product Name" />
            {/* <Column field="Qty" header="Total Product Qty" /> */}
            <Column field="MFGDate" header="MFG Date" />
            <Column field="EXPDate" header="Expire Date" />
            <Column body={this.actionTemplate} header="Action" />
          </DataTable>

          <div className="text-center">
            <button className="btn btn-primary mt-4" onClick={this.submitForm}>
              Submit
            </button>
          </div>
        </Card>
        
        <Dialog className="popup-modal" style={{ width: '30%' }} header="Add return remarks"
          visible={this.state.remarkVisible} modal={true} onHide={this.hideFunc}>
          <form onSubmit={this.submitRemark}>
            <div className="row">
              <div className="col-md-12">
                <label>Remark<span>*</span>:</label>
                <input type="text" style={{ width: '95%', height: '30px' }} value={this.state.Remark} onChange={this.handleChange}
                  className="form-control" name="Remark" />
              </div>
            </div>
            <div className="col-md-12 text-center mt-4">
              <button type="submit" className="btn btn-primary">Submit</button>
            </div>
          </form>
        </Dialog>
      

      </>
    );
  }
}
