import instance from './DefaultApi';

export default class SecondaryReportService {
    ControllerName = 'SecondaryReport';

    // get all data
    GetSecondaryReport(tableRequest) {
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetSecondaryReport`, tableRequest).then(res => res)
    }

   //get export for report
    GetReportExport(tableRequest) {
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetReportExport`, tableRequest).then(res => res)
    }

}