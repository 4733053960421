import React, { Component } from "react";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import tableRequest from "../../models/tableRequest.js";
import { confirmAlert } from "react-confirm-alert"; // Import
import { ConfirmationModal } from "../../template/ConfirmationModal";
import CartonMasterService from "../../services/CartonMasterService";
import MessageComponent from "../../template/Message";
import Loaders from "../../template/Loaders";
import { BreadCrumb } from "primereact/breadcrumb";
import { Card } from "primereact/card";
import $ from "jquery";
import { CSVLink } from "react-csv";

// Carton Size Master
export default class CartonMaster extends Component {
  constructor(props) {
    super(props);
    this.CartonMasterService = new CartonMasterService();
    this.tblResponseExport = new tableRequest();
    this.tblResponse = new tableRequest();
    this.state = {
      visible: false,
      CartonId: "",
      heads: ["CartonName", "CartonSize", "Description"],
      exportData: [],
      updateLoader: false,
      sortIcon: false,
      CartonName: "",
      CartonSize: "",
      Description: "",
      IsActive: false,
      sortOrder: "",
      formError: {
        CartonName: "",
        CartonSize: "",
        Description: "",
      },
      formHeading: "Add Carton",
      buttonLabel: "Submit",
      sales: [],
      loading: true,
      first: 0,
      rows: 5,
      totalRecords: 0,
      globalFilter: null,
      emptyMessage: "No Data found",
    };

    this.actionTemplate = this.actionTemplate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.hideFunc = this.hideFunc.bind(this);
    this.onPage = this.onPage.bind(this);
    this.searchFilter = this.searchFilter.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.onSort = this.onSort.bind(this);
    this.GetCartonExport = this.GetCartonExport.bind(this);
  }

  //trigger api function on page call
  componentDidMount() {
    setTimeout(() => {
      this.GetCarton();
    }, 1000);

    $(".p-column-title").on("click", function () {
      $(this)
        .next()
        .removeClass("pi-sort")
        .toggleClass("pi-sort-up")
        .addClass("pi-sort-down");
    });

    this.tblResponseExport.PageSize = -1;
    this.tblResponseExport.First = 1;
    this.tblResponseExport.IsActive = true;
    this.tblResponseExport.SearchValue = "";
    this.tblResponseExport.SortOrder = "ASC";
    this.GetCartonExport();
  }

  //loading page
  onPage(event) {
    this.setState({
      loading: true,
    });

    //imitate delay of a backend call
    setTimeout(() => {
      this.getLazyLoadingValues(event);
      this.GetCarton();
    }, 250);
  }

  // Get list of data
  getLazyLoadingValues(event) {
    this.setState({ rows: event.rows });
    this.tblResponse.PageSize = event.rows;
    this.tblResponse.First = event.first + 1;
    this.tblResponse.IsActive = true;
    this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
    //this.tblResponse.SortColumn = event.sortField;
    this.tblResponse.SortOrder = event.sortOrder === 1 ? "ASC" : "DESC";
  }

  // get carton data list from api
  GetCartonExport() {
    this.tblResponseExport.SortColumn = "CreatedDate";
    this.CartonMasterService.GetCarton(this.tblResponseExport).then((data) => {
      const resdata = data.data;
      //when response data is not null
      if (
        resdata !== null &&
        resdata.ResponseData !== null &&
        resdata.ResponseData.length > 0
      ) {
        this.setState({
          exportData: resdata.ResponseData,
        });
      }
    });
  }

  // get carton data list from api
  GetCarton() {
    this.tblResponse.SortColumn = "CreatedDate";
    this.CartonMasterService.GetCarton(this.tblResponse).then((data) => {
      const resdata = data.data;
      //when response data is not null
      if (
        resdata !== null &&
        resdata.ResponseData !== null &&
        resdata.ResponseData.length > 0
      ) {
        this.datasource = resdata.ResponseData;
        this.setState({
          totalRecords: resdata.ResponseData[0].TotalRows,
          first: this.tblResponse.First - 1,
          sales: this.datasource.slice(
            0,
            this.tblResponse.First - 1 + this.tblResponse.PageSize
          ),
          loading: false,
          updateLoader: true,
        });
      }
      // when responsedata is null
      else {
        this.datasource = null;
        this.setState({
          totalRecords: 0,
          first: 1,
          sales: null,
          loading: false,
          updateLoader: true,
        });
      }
    });
  }

  // call every change of form and validation part also
  handleChange(event) {
    const { name, value } = event.target;
    let errors = this.state.formError;

    const isValid = this.validateForm(value, name);
    this.setState({ errors, [name]: value });

    //this statement will set state value for IsActive
    if (name == "IsActive")
      this.setState({
        IsActive: event.target.checked,
      });
  }

  // Open Dialog
  openDialog() {
    this.setState({ visible: true });
    this.formReset();
  }

  // validate form
  validateForm(value, name) {
    let errors = this.state.formError;
    let IsValid = true;
    //this switch case is used for each form field to validate
    switch (name) {
      case "CartonName":
        if (value.length < 1) {
          IsValid = false;
          errors.CartonName = "Please enter your carton name.";
        } else errors.CartonName = "";
        break;
      case "CartonSize":
        if (value < 1) {
          IsValid = false;
          errors.CartonSize = "Please enter your carton size quantity.";
        } else errors.CartonSize = "";
        break;
      case "Description":
        if (value.length < 1) {
          IsValid = false;
          errors.Description = "Please enter your Description.";
        } else errors.Description = "";
        break;

      default:
        break;
    }

    return IsValid;
  }

  // reset form text value
  formReset() {
    this.setState({
      CartonId: 0,
      CartonName: "",
      CartonSize: "",
      Description: "",
      IsActive: true,
    });
    let errors = this.state.formError;
    Object.entries(errors).map(([key, value]) => {
      errors[key] = "";
    });
  }

  // add text
  hideFunc() {
    this.setState({
      visible: false,
      buttonLabel: "Submit",
      formHeading: "Add Carton",
    });
    this.formReset();
  }

  // Open Dialog
  openDialog() {
    this.setState({ visible: true });
    this.formReset();
  }
  // submit form
  submitForm(event) {
    event.preventDefault();

    let isfrmvalid = true;
    let checkerror = this.state.formError;

    Object.entries(checkerror).map(([key, value]) => {
      const val = this.state[key];
      let valid = this.validateForm(val, key);

      //when form validation is not valid
      if (!valid) {
        this.setState({ checkerror: isfrmvalid });
        isfrmvalid = false;
      }
    });
    let checkbox = document.getElementsByName("IsActive");
    //when isfrmvalid is true
    if (isfrmvalid) {
      this.setState({ updateLoader: false });
      const formData = {
        CartonId: this.state.CartonId,
        CartonName: this.state.CartonName,
        CartonSize: this.state.CartonSize,
        Description: this.state.Description,
        IsActive: this.state.IsActive,
      };

      setTimeout(() => {
        this.CartonMasterService.CreateCarton(formData).then((data) => {
          ;
          const resdata = data.data;
          //when data is not null it will give success message else will move to 'else statement'
          if (resdata !== null && resdata.ResponseCode === "200") {
            this.refs.Messchild.showSuccess(resdata.ResponseMessage);
            this.setState({
              loading: true,
              visible: false,
              updateLoader: true,
            });
            this.formReset();
            this.GetCarton();
            this.GetCartonExport();
          } else if (resdata.ResponseCode === "400") {
            this.setState({
              updateLoader: true,
            });
            this.refs.Messchild.showWarn(resdata.ResponseMessage);
          } else {
            this.setState({
              updateLoader: true,
            });
            this.refs.Messchild.showError(resdata.ResponseMessage);
          }
        });
      }, 100);
    }
  }

  // delete user data
  // deleteUser(referenceID) {
  //     this.UserService.DeleteUser(referenceID).then(data => {
  //         const resdata = data.data;
  //         if (resdata !== null && resdata.ResponseCode === '200') {
  //             this.refs.Messchild.showSuccess(resdata.ResponseMessage);
  //             const totlrecord = this.state.totalRecords - 1;
  //             const row = this.state.rows;

  //             if (this.tblResponse.First >= totlrecord) {
  //                 const _curntCursor = Math.round(totlrecord / row);
  //                 this.tblResponse.First = _curntCursor + 1;
  //             }
  //             this.GetUsers();
  //         } else if (resdata.ResponseCode === '400') {
  //             this.refs.Messchild.showWarn(resdata.ResponseMessage);
  //         } else {
  //             this.refs.Messchild.showError(resdata.ResponseMessage);
  //         }
  //     });
  // }

  // Search Filter data
  searchFilter(e) {
    this.setState({
      loading: true,
    });
    this.tblResponse.SearchValue = e.target.value;
    this.GetCarton();
  }

  // callbackFunction = (childData) => {
  //     this.setState({ message: childData })
  // }

  //return checkbox disabled
  activeTemplate(rowData, column) {
    return <input type="checkbox" disabled checked={rowData.IsActive} />;
  }

  // Sorting Data
  onSort(event) {
    this.setState({ sortOrder: this.state.sortOrder === 1 ? 2 : 1 });
    const sortField =
      event.sortField == "CreatedDate" ? "CreatedDate" : event.sortField;
    this.tblResponse.SortColumn = sortField ? sortField : "";
    this.tblResponse.SortOrder = this.state.sortOrder === 1 ? "DESC" : "ASC";
    this.GetCarton();
    // const clickData = document.getElementsByClassName("p-sortable-column-icon pi pi-fw pi-sort");
    // console.log(event);
  }

  // edit form
  actionTemplate(rowData, column) {
    const editMode = (e) => {
      this.setState({
        buttonLabel: "Update",
        formHeading: "Update Carton",
        visible: true,
        CartonId: rowData.CartonId,
        CartonName: rowData.CartonName,
        CartonSize: rowData.CartonSize,
        Description: rowData.Description,
        IsActive: rowData.IsActive,
      });
    };

    return (
      <span>
        <Button
          type="button"
          icon="pi pi-pencil"
          onClick={editMode}
          className="p-button-warning"
          style={{ marginRight: "3px" }}
        ></Button>
        {/* <Button type="button" icon="pi pi-trash" onClick={deleteFunc} className="p-button-danger"></Button> */}
      </span>
    );
  }

  // create html part
  render() {
    const items = [{ label: "Masters" }, { label: "Carton Size Master" }];
    const home = { icon: "pi pi-home", url: "/dashboard" };
    let header = (
      <div style={{ textAlign: "right" }}>
        <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>
        <InputText
          type="search"
          onInput={this.searchFilter}
          placeholder="Global Search"
          size="30"
        />
        <Button
          label="Add Carton"
          icon="pi pi-plus-circle"
          onClick={this.openDialog}
        />
        <CSVLink
          data={this.state.exportData}
          headers={this.state.heads}
          filename={"Carton Data.csv"}
          target="_blank"
        >
          <button className="btn btn-success p-2 ml-2 buttone1">
            <i className="pi pi-file-excel mr-2"></i>
            Export
          </button>
        </CSVLink>
      </div>
    );

    return (
      <>
        <MessageComponent ref="Messchild" />
        {<Loaders loader={this.state.updateLoader} />}
        {/* <ConfirmationModal parentCallback={this.callbackFunction} ref="child" /> */}

        <Card>
          <div className="row mb-3">
            <div className="col-md-6 heading">
              <h1>Carton Size Master</h1>
            </div>
            <div className="col-md-6 custom-breadcrumb">
              <BreadCrumb model={items} home={home} />
            </div>
          </div>

          <DataTable
            responsive={true}
            header={header}
            globalFilter={this.state.globalFilter}
            emptyMessage={this.state.emptyMessage}
            value={this.state.sales}
            sortMode="multiple"
            paginator={true}
            rows={this.state.rows}
            rowsPerPageOptions={[5, 10, 20]}
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            totalRecords={this.state.totalRecords}
            lazy={true}
            first={this.state.first}
            onPage={this.onPage}
            onSort={this.onSort}
            className="tblAuto"
          >
            <Column field="CartonName" header="Carton Name" sortable={true} />
            <Column field="CartonSize" header="Carton Size" sortable={true} />
            <Column field="Description" header="Description" sortable={true} />
            <Column field="CreatedDate" header="Created Date" sortable={true} />
            <Column body={this.activeTemplate} header="Status" />
            <Column body={this.actionTemplate} header="Action" />
          </DataTable>
        </Card>
        <Dialog
          className="popup-modal"
          header={this.state.formHeading}
          visible={this.state.visible}
          modal={true}
          onHide={this.hideFunc}
        >
          <form onSubmit={this.submitForm}>
            <div className="form-padding">
              <div className="row">
                <div className="col-md-4 text-right">
                  <label>
                    Carton Name<span>*</span>:
                  </label>
                </div>
                <div className="col-md-8 mb-2">
                  <input
                    type="text"
                    value={this.state.CartonName}
                    onChange={this.handleChange}
                    className="form-control"
                    name="CartonName"
                    noValidate
                  />
                  {this.state.formError.CartonName !== "" ? (
                    <div className="error">
                      {this.state.formError.CartonName}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-md-4 text-right">
                  <label>
                    Carton Size<span>*</span>:
                  </label>
                </div>
                <div className="col-md-8 mb-2">
                  <input
                    type="number"
                    value={this.state.CartonSize}
                    onChange={this.handleChange}
                    className="form-control"
                    name="CartonSize"
                  />
                  {this.state.formError.CartonSize !== "" ? (
                    <div className="error">
                      {this.state.formError.CartonSize}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-md-4 text-right">
                  <label>
                    Description<span>*</span>:
                  </label>
                </div>
                <div className="col-md-8 mb-2">
                  <input
                    type="text"
                    value={this.state.Description}
                    onChange={this.handleChange}
                    className="form-control"
                    name="Description"
                  />
                  {this.state.formError.Description !== "" ? (
                    <div className="error">
                      {this.state.formError.Description}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-md-4 text-right">
                  <label>IsActive :</label>
                </div>
                <div className="col-md-8 mb-2">
                  <input
                    type="checkbox"
                    checked={this.state.IsActive}
                    className="ml-2"
                    name="IsActive"
                    onChange={this.handleChange}
                  />
                </div>
                <div className="col-md-12 text-center">
                  <button type="submit" className="btn btn-primary">
                    {this.state.buttonLabel}
                  </button>
                </div>
                <div className="col-md-12 text-left">
                  <label>
                    Note: Fields marked as (
                    <span style={{ color: "red" }}>*</span>) are required
                  </label>
                </div>
              </div>
            </div>
          </form>
        </Dialog>
      </>
    );
  }
}
