import React from "react";
import "./../App.css";

//Return Footer HTML
const Footer = () => {
  return (
    <div className="footer">
      <div className="full-width" style={{ height: "3vh" }}>
        Copyright © 2023 MSRM Organics Private Limited - All Rights Reserved.
      </div>
    </div>
  );
};

export default Footer;
