import instance from './DefaultApi';

export default class MappingService {
    ControllerName = 'Mapping';

    // get all product mapping data
    GetMappings(tableRequest) {
            return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetMappings`, tableRequest).then(res => res)
        }
        // create new product mapping  
    CreateMapping(mappingData) {
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/CreateMapping`, mappingData).then(res => res)
    }

    // get Next From Number
    GetFromNumberMap(isPreprinted) {
        return instance.get(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetFromNumberMap/${isPreprinted}`).then(res => res)
    }

     // get Next From Number
    GetMappingTemplate() {
        return instance.get(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetMappingTemplate`).then(res => res)
    }

    // delete product mapping data
    DeleteMapping(mappingId) {
        return instance.get(`${process.env.REACT_APP_API_URL}${this.ControllerName}/DeleteMapping/${mappingId}`).then(res => res)
    }

    // Get Product Details by Qr code Scanning
    GetProductByQrScan(qr) {
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetProductByQrScan`, qr).then(res => res)
    }

    SaveStaticQrMapping(mappingData) {
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/CreateStaticQrMapping`, mappingData).then(res => res)
    }

    GetStaticQrMapping(tableRequest) {
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetStaticQrMappings`, tableRequest).then(res => res)
    }
    
}