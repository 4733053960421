import React, { Component } from 'react'
import Loader from "react-loader";
// import Loader from "react-loader-spinner";
import loaderPic from './../assets/images/garden-loader.gif';
export default class Loaders extends Component {
    
    render() {
        return (
            <>
            {this.props.loader? null : <div className={this.props.classType ==='small' ? 'loader-small':'loader'}><img src={loaderPic} /></div>}
            {/* <Loader
        loaded={this.props.loader}
        lines={13}
        length={20}
        width={10}
        radius={30}
        corners={1}
        rotate={0}
        direction={1}
        color="#000"
        speed={1}
        trail={60}
        shadow={false}
        hwaccel={false}        
        className="spinner"
        zIndex={2e9}
        top="50%"
        left="50%"
        scale={1.0}
        loadedClassName="loadedContent"
      /> */}
      </>
        )
    }
}
