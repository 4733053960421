import instance from './DefaultApi';

export default class PAService {
    ControllerName = 'ProductAuthentication';

    // get verify product
    VerifyProduct(prductData) {        
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/VerifyProduct`, prductData).then(res => res)
    }   
     
    // create feed back
    UpdateFeedback(feedbackdata){
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/UpdateFeedback`, feedbackdata).then(res => res)
    }
}