import instance from './DefaultApi';

export default class UserService {
    ControllerName = 'User';

    // get all user data
    GetUsers(tableRequest) {
            return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetUsers`, tableRequest).then(res => res);
        }
        // create new user 
    CreateUpdateUser(Payload) {
            return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/CreateUpdateUser`, Payload).then(res => res);
        }
        // updat user password
    ChangePassword(Payload) {
            return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/ChangePassword`, Payload).then(res => res);
        }
        // delete user data
    DeleteUser(referenceID) {
            return instance.get(`${process.env.REACT_APP_API_URL}${this.ControllerName}/DeleteUser/${referenceID}`).then(res => res);
        }
        // get menu according user
    getUserMenu() {
            return instance.get(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetUserMenu`).then(res => res);
        }
        // user list for ddl
    GetUsersDDL() {
        return instance.get(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetUsersDDL`).then(res => res);
    }

    // user list for ddl
    GetAllActiveUsers() {
        return instance.get(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetAllActiveUsers`).then(res => res);
    }

    // get user data by id
    getUser() {
        return instance.get(`${process.env.REACT_APP_API_URL}${this.ControllerName}/getUser`).then(res => res);
    }
        // updat profile
        UpdateProfile(Payload) {
            return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/UpdateProfile`, Payload).then(res => res);
        }

}