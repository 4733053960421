import instance from './DefaultApi';

export default class ProductConfigurationService {
    ControllerName = 'ProductConfiguration';

    // get all product configuration data
    getConfigrationData(productId) {
        return instance.get(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetConfigrationData/${productId}`).then(res => res)
    }

    /// Update ProductConfigration
    UpdateProductConfigration(data) {
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/UpdateProductConfigration`, data).then(res => res)
    }
}