const axios = require("axios");

// Create an instance using the config defaults provided by the library
// At this point the timeout config value is `0` as is the default for the library
const instance = axios.create();
// Override timeout default for the library
// Now all requests using this instance will wait 2.5 seconds before timing out
//instance.defaults.timeout = 2500;

// Alter defaults after instance has been created
const token = sessionStorage.getItem("token");
const Curenturl = window.location.pathname;
const nNumber = Curenturl.lastIndexOf("/");
const pageName = Curenturl.substring(nNumber + 1);
instance.defaults.headers.common["page"] = pageName;
if (token) {
  instance.defaults.headers.common["Authorization"] = `bearer ${token}`;
} else {
  
  const curl = window.location.pathname;
  if (
    !curl.includes("/") &&
    !curl.includes("/login") &&
    !curl.includes("/P") &&
    !curl.includes("/privacy-policy")
  ) {
    window.location = "/login";
  }
}

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    const originalReq = err.config;
    const curl = window.location.pathname;
    if (
      err.response !== undefined &&
      err.response.status === 418 &&
      !curl.includes("/") &&
      !curl.includes("/login") &&
      !curl.includes("/P") &&
      !curl.includes("/privacy-policy")
    ) {
      // sessionStorage.removeItem("LoggedInUser");
      // sessionStorage.removeItem("refreshToken");
      // sessionStorage.removeItem("token");
      //window.location = '/login'

      const obj = {
        ResponseMessage:
          "User unauthorized, Please Login again or contact admin.",
        ResponseCode: err.response.status,
      };
      const retudata = {
        data: obj,
      };
      return retudata;
    } else if (
      err.response !== undefined &&
      err.response.status === 401 &&
      !curl.includes("/") &&
      !curl.includes("/login") &&
      !curl.includes("/P") &&
      !curl.includes("/privacy-policy")
    ) {
      if (err.response.status === 401) {
        const token = sessionStorage.getItem("token");
        const refreshToken = sessionStorage.getItem("refreshToken");

        const data = {
          Token: sessionStorage.getItem("token"),
          Refresh_Token: sessionStorage.getItem("refreshToken"),
        };

        sessionStorage.removeItem("LoggedInUser");
        sessionStorage.removeItem("refreshToken");
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("assignedPermissionsForUser");
        sessionStorage.removeItem("UserData");
        window.location = '/login'

        // axios.post(`${process.env.REACT_APP_API_URL}Login/Refresh`, data).then(
        //   (response) => {
        //     const resdata = response.data;
        //     if (
        //       resdata !== null &&
        //       resdata.ResponseCode === "200" &&
        //       resdata.ResponseData !== null &&
        //       resdata.ResponseData.RefreshToken
        //     ) {
        //       sessionStorage.setItem(
        //         "refreshToken",
        //         resdata.ResponseData.RefreshToken
        //       );
        //       sessionStorage.setItem("token", resdata.ResponseData.Token);

        //       instance.defaults.headers.common[
        //         "Authorization"
        //       ] = `bearer ${sessionStorage.getItem("token")}`;
        //       return axios(originalReq);
        //     } else {
        //       // sessionStorage.removeItem("LoggedInUser");
        //       // sessionStorage.removeItem("refreshToken");
        //       // sessionStorage.removeItem("token");
        //       // sessionStorage.removeItem("assignedPermissionsForUser");
        //       // sessionStorage.removeItem("UserData");
        //       // window.location = '/login'
        //     }
        //   },
        //   (error) => {
        //     ////console.log(error);
        //   }
        // );
      }

      // const obj = {
      //     ResponseCode: err.response.status
      // }
      // const retudata = {
      //     data: obj
      // }
      // return retudata;
    } else if (err.name === "Error") {
      // sessionStorage.removeItem("LoggedInUser");
      // sessionStorage.removeItem("refreshToken");
      // sessionStorage.removeItem("token");

      const obj = {
        ResponseMessage: err.response.statusText,
        ResponseCode: err.response.status,
      };
      const retudata = {
        data: obj,
      };
      return retudata;
    }
    return err;
  }
);
// Override timeout for this request as it's known to take a long time
// instance.get('/longRequest', {
//     timeout: 5000
// });

export default instance;
