import instance from './DefaultApi';

export default class AccessRightService {
    ControllerName = 'AccessRight';
  
     // get menu according user
     GetAccessRight(userId) {
        return instance.get(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetAccessRight/${userId}`).then(res => res)
    }
     // get create and update access right
     CreateUpdateAccessRight(accessdata) {
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/CreateUpdateAccessRight`,accessdata).then(res => res)
    }     
} 