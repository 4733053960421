import instance from './DefaultApi';

export default class MissingCartonRptService {
    ControllerName = 'MissingCartonRpt';

    // get all data
    GetMissingCartonReport(tableRequest) {
            return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetMissingCartonReport`, tableRequest).then(res => res)
        }

    // Get Excel  Export  data
    GetMissingCartonExport(tableRequest) {
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetMissingCartonExport`,tableRequest).then(res => res)
    }
       
}