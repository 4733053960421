import React, { Component } from "react";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { DataTable } from "primereact/datatable";
import { CSVLink, CSVDownload } from "react-csv";
import { Calendar } from "primereact/calendar";
import tableRequest from "../../models/tableRequest.js";
import DispatchReportService from "../../services/DispatchReportService";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import MessageComponent from "../../template/Message";
import Loaders from "../../template/Loaders";
import $ from "jquery";
import PrintDispatchService from "../../services/PrintDispatchService";
import { Button } from "primereact/button";
import { BreadCrumb } from "primereact/breadcrumb";
import { Card } from "primereact/card";
export default class DispatchReport extends Component {
  constructor(props) {
    super(props);
    this.DispatchReportService = new DispatchReportService();
    this.PrintDispatchService = new PrintDispatchService();
    this.tblResponse = new tableRequest();
    this.state = {
      FromDate: new Date(),
      ToDate: new Date(),
      success: false,
      updateLoader: false,
      visible: false,
      sortOrder: "Desc",
      sales: [],
      loading: true,
      first: 0,
      rows: 10,
      totalRecords: 0,
      globalFilter: null,
      emptyMessage: "No Data found",
      allData: [],
    };

    this.onPage = this.onPage.bind(this);
    this.FilterData = this.FilterData.bind(this);
    this.searchFilter = this.searchFilter.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.actionTemplate = this.actionTemplate.bind(this);
    this.onSort = this.onSort.bind(this);
    this.GetReportExport = this.GetReportExport.bind(this);
  }
  //to trigger api function o page call
  componentDidMount() {
    setTimeout(() => {
      var date = new Date();
      date.setDate(date.getDate() - 7);
      this.tblResponse.FromDate = date.toLocaleDateString();
      this.tblResponse.ToDate = new Date().toLocaleDateString();
      this.setState({
        FromDate: date,
        ToDate: new Date(),
      });
      this.GetDispatch();
    }, 1000);
    $(".p-column-title").on("click", function () {
      $(this)
        .next()
        .removeClass("pi-sort")
        .toggleClass("pi-sort-up")
        .addClass("pi-sort-down");
    });
  }
  //to search data
  searchFilter(e) {
    this.setState({
      loading: true,
    });
    this.tblResponse.SearchValue = e.target.value;
    this.GetDispatch();
  }

  onChange = (date) => this.setState({ FromDate: "", ToDate: "" });
  //to export report data
  GetReportExport() {
    this.DispatchReportService.GetReportExportDispatch(this.tblResponse).then(
      (data) => {
        const resdata = data.data;
        //if response data not null
        if (
          resdata !== null &&
          resdata.ResponseData !== null &&
          resdata.ResponseData.length > 0
        ) {
          this.setState({
            allData: resdata.ResponseData,
          });
          this.setState({ allData: resdata.ResponseData }, () => {
            this.surveyLink.link.click();
          });
        }
      }
    );
  }
  //on page loading
  onPage(event) {
    this.setState({
      loading: true,
    });

    //imitate delay of a backend call
    setTimeout(() => {
      this.getLazyLoadingValues(event);
      this.GetDispatch();
    }, 250);
  }

  // call every change of form and validation part also

  handleChange(event) {
    const { name, value } = event.target;
    //to validate each form field with required conditions
    switch (name) {
      case "FromDate":
        if (value == null || !(value instanceof Date)) {
          this.formIsValid = false;
          this.refs.Messchild.showError("Please select from date.");
          return;
        } else if (
          !value.toLocaleDateString().match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/)
        ) {
          this.formIsValid = false;
          this.refs.Messchild.showError("Please enter only date.");
          return;
        } else if (value instanceof Date && this.state.ToDate instanceof Date) {
          const startDate = value;
          const endDate = this.state.ToDate;
          if (!(Date.parse(startDate) <= Date.parse(endDate))) {
            this.formIsValid = false;
            this.refs.Messchild.showError(
              "From date must be less than to date."
            );
            return;
          }
        }

        break;
      case "ToDate":
        if (value == null || !(value instanceof Date)) {
          this.formIsValid = false;
          this.refs.Messchild.showError("Please select to date.");
          return;
        } else if (
          !value.toLocaleDateString().match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/)
        ) {
          this.formIsValid = false;
          this.refs.Messchild.showError("Please enter only date.");
          return;
        } else if (
          value instanceof Date &&
          this.state.FromDate instanceof Date
        ) {
          const startDate = this.state.FromDate;
          const endDate = value;
          if (!(Date.parse(startDate) <= Date.parse(endDate))) {
            this.formIsValid = false;
            this.refs.Messchild.showError(
              "To date must be greater than from date."
            );
            return;
          }
        }

        break;
      default:
        break;
    }

    this.setState({ [name]: value });
  }

  //to filter data
  FilterData() {
    this.tblResponse.FromDate = this.state.FromDate.toLocaleDateString();
    this.tblResponse.ToDate = this.state.ToDate.toLocaleDateString();
    this.GetDispatch();
  }

  getLazyLoadingValues(event) {
    this.setState({ rows: event.rows });
    this.tblResponse.PageSize = event.rows;
    this.tblResponse.First = event.first + 1;
    this.tblResponse.IsActive = "1";
    this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
    //this.tblResponse.SortColumn = event.sortField;
    this.tblResponse.SortOrder = event.sortOrder === 1 ? "ASC" : "DESC";
    this.tblResponse.FromDate = this.state.FromDate;
    this.tblResponse.ToDate = this.state.ToDate;
  }
  //to get dispatch data
  GetDispatch() {
    this.tblResponse.SortColumn = "CartonId";

    this.DispatchReportService.GetDispatchReport(this.tblResponse).then(
      (data) => {
        const resdata = data.data;
        this.setState({ updateLoader: true });
        //if response data not null
        if (
          resdata !== null &&
          resdata.ResponseData !== null &&
          resdata.ResponseData.length > 0
        ) {
          this.datasource = resdata.ResponseData;
          this.setState({
            totalRecords: resdata.ResponseData[0].TotalRows,
            first: this.tblResponse.First - 1,
            sales: this.datasource.slice(
              0,
              this.tblResponse.First - 1 + this.tblResponse.PageSize
            ),
            loading: false,
          });
        }
        //if response data null
        else {
          this.datasource = null;
          this.setState({
            totalRecords: 0,
            first: 1,
            sales: null,
            loading: false,
          });
        }
      }
    );
    // this.datasource = null;
    // this.setState({
    //     totalRecords: 0,
    //     first: 1,
    //     sales: null,
    //     loading: false
    // });
  }
  //for sorting
  onSort(event) {
    this.setState({ sortOrder: this.state.sortOrder === 1 ? 2 : 1 });
    const sortField =
      event.sortField == "CreatedDt" ? "CreatedDt" : event.sortField;
    this.tblResponse.SortColumn = sortField ? sortField : "";
    this.tblResponse.SortOrder = this.state.sortOrder === 1 ? "DESC" : "ASC";
    this.GetDispatch();
  }
  actionTemplate(rowData, column) {
    //to print
    const editMode = (e) => {
      this.DispatchReportService.GetDispatchToPrint(rowData).then((data) => {
        var options = {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
        };
        const resdata = data.data;
        this.PrintDispatchService.Printpdf(
          resdata.ResponseData,
          new Date(rowData.CreatedDt).toLocaleDateString("en-US", options)
        );
      });

      ////PRINT SLIP
    };
    return (
      <span>
        <Button
          type="button"
          icon="pi pi-print"
          onClick={editMode}
          className="p-button-warning"
          style={{ marginRight: "3px" }}
        ></Button>
      </span>
    );
  }
  //to render html part
  render() {
    const items = [{ label: "Reports" }, { label: ">Dispatch Report" }];
    const home = { icon: "pi pi-home", url: "/dashboard" };
    let header = (
      <div className="row">
        <div className="col-md-8">
          <form className="form-inline">
            <div className="form-group mr-2">
              <Calendar
                value={this.state.FromDate}
                name="FromDate"
                onChange={this.handleChange}
                appendTo={document.getElementById("root")}
                placeholder="From Date"
              />
            </div>
            <div className="form-group">
              <Calendar
                value={this.state.ToDate}
                name="ToDate"
                onChange={this.handleChange}
                appendTo={document.getElementById("root")}
                placeholder="To Date"
              />
            </div>
            <button
              type="button"
              onClick={this.FilterData}
              className="btn btn-primary ml-3"
            >
              Submit
            </button>
          </form>
        </div>
        <div className="text-right col-md-4">
          <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>
          <InputText
            type="search"
            onInput={this.searchFilter}
            placeholder="Global Search"
            size="22"
          />
          {/* <CSVLink data={this.state.allData} filename={"Verification report.csv"}
                        className="btn btn-primary ml-2"
                        target="_blank">Export</CSVLink> */}

          <button
            className="btn btn-primary ml-2 export-disabled"
            target="_blank"
            disabled={this.state.sales == null}
            onClick={this.GetReportExport}
          >
            Export
          </button>
          <CSVLink
            hidden={true}
            data={this.state.allData}
            ref={(r) => (this.surveyLink = r)}
            filename={"Dispatch Packing report.csv"}
          >
            Export
          </CSVLink>
        </div>
      </div>
    );
    return (
      <>
        <MessageComponent ref="Messchild" />
        {<Loaders loader={this.state.updateLoader} />}
        <Card>
          <div className="row mb-3">
            <div className="col-md-6 heading">
              <h1>Dispatch Report</h1>
            </div>
            <div className="col-md-6 custom-breadcrumb">
              <BreadCrumb model={items} home={home} />
            </div>
          </div>

          <DataTable
            exportFilename="Dispatch Report"
            responsive={true}
            header={header}
            globalFilter={this.state.globalFilter}
            emptyMessage={this.state.emptyMessage}
            value={this.state.sales}
            sortMode="multiple"
            paginator={true}
            rows={this.state.rows}
            rowsPerPageOptions={[10, 50, 200, 500]}
            totalRecords={this.state.totalRecords}
            lazy={true}
            first={this.state.first}
            onPage={this.onPage}
            onSort={this.onSort}
            ref={(el) => {
              this.dt = el;
            }}
          >
            <Column field="DispatchId" header="Dispatch No" sortable={true} />
            {/* <Column field="TotalTertiaryCarton" header="Total Tertiary Carton" sortable="custom" /> */}
            <Column field="CreatedDt" header="Date" sortable={true} />
            {/* fromUserName */}

            {/* <Column field="FromName" header="From Retailer/Dealer" sortable={true} /> */}

            <Column
              field="FromWareHouse"
              header="By Factory/Warehouse"
              sortable={false}
            />
            {/* <Column field="firmName" header="To WareHouse/Factory" sortable={true} /> */}
            <Column
              field="Name"
              header="To Customer"
              sortable={true}
              style={{ width: "150px" }}
            />
            <Column body={this.actionTemplate} header="Action" />
          </DataTable>
        </Card>
      </>
    );
  }
}
