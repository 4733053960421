import instance from './DefaultApi';

export default class CartonMasterService {
    ControllerName = 'CartonMaster';

    //Get Carton List
    GetCarton(tableRequest) {
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetCarton`, tableRequest).then(res => res)
    }

    // Save Carton Master Data
    CreateCarton(CartonData) {
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/CreateCarton`, CartonData).then(res => res)
    }

    // Get Active Carton of Drop Down List
    GetActiveCartonDDL() {
        return instance.get(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetActiveCartonDDL`).then(res => res)
    }




}