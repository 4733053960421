import instance from './DefaultApi';

export default class WareHouseMaterService {
    ControllerName = 'WareHouser';

    // get all user data
    Get(tableRequest) {
            return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/Get`, tableRequest).then(res => res);
        }
        // create new user 
    Save(Payload) {
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/Save`, Payload).then(res => res);
    }
    
    //get all warehouse for ddl
    GetWareHouseDDL(CompId) {
        return instance.get(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetWareHouseDDL?CompId=` + CompId).then(res => res);
    }
}