import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import NumberIssuedService from '../../services/NumberIssuedService';
import tableRequest from '../../models/tableRequest.js';
import StockManagementService from '../../services/StockManagementService';
import MessageComponent from '../../template/Message';
import $ from 'jquery';

export default class StockManagement extends Component {
    constructor(props) {
        super(props);
        this.NumberIssuedService = new NumberIssuedService();
        this.StockManagementService = new StockManagementService();
        this.tblResponse = new tableRequest();

        this.state = {
            visible: false,
            formIsValid: true,
            sortOrder: '',
            refrenceFromNo: 0,
            refrenceToNo: 0,

            StockId: 0,
            FromNo: '',
            ToNo: '',
            IssueQty: '',
            FrmNo: '',
            Qty: '',

            formError: {
                FromNo: null,
                Qty: null,
                FrmNo: null,
                ToNo: null,
                IssueQty: null
            },

            sales: [],
            sale: [],
            loading: true,
            first: 0,
            frst: 0,
            rows: 10,
            totalRecords: 0,
            globalFilter: null,
            totalRecord: 0,

            emptyMessage: 'No Data found'
        }

        this.brandTemplate = this.brandTemplate.bind(this);
        this.actionTemplate = this.actionTemplate.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.hideFunc = this.hideFunc.bind(this);
        this.onPage = this.onPage.bind(this);
        this.searchFilter = this.searchFilter.bind(this);
        this.onSort = this.onSort.bind(this);
    }
    //trigger api function on page call
    componentDidMount() {
        setTimeout(() => {
            this.GetIssueNumbers();
            this.GetStock();
        }, 700);

        $('.p-column-title').on('click', function () {
            $(this).next().removeClass('pi-sort').toggleClass('pi-sort-up').addClass('pi-sort-down');
        });
    }

    onPage(event) {
        this.setState({
            loading: true
        });

        //imitate delay of a backend call
        setTimeout(() => {
            this.getLazyLoadingValues(event);
            this.GetIssueNumbers();
        }, 250);
    }

    // get issued number  data
    GetIssueNumbers() {
        this.tblResponse.SortColumn = 'CreatedDt';
        this.tblResponse.IsAccepted = 0;    
        this.NumberIssuedService.GetIssueNumbers(this.tblResponse).then(data => {
            const resdata = data.data;
            //if ResponseData is not null
            if (resdata !== null && resdata.ResponseData !== null && resdata.ResponseData.length > 0) {
                this.datasource = resdata.ResponseData;
                this.setState({
                    totalRecords: resdata.ResponseData[0].TotalRows,
                    first: (this.tblResponse.First - 1),
                    sales: this.datasource.slice(0, ((this.tblResponse.First - 1) + this.tblResponse.PageSize)),
                    loading: false
                });
            }
            //if ResponseData is null
            else {
                this.datasource = null;
                this.setState({
                    totalRecords: 0,
                    first: 1,
                    sales: null,
                    loading: false
                });
            }
        });
    }
    // get data list from api

    GetStock() {

        
        this.tblResponse.SortColumn = 'CreatedDt';
        this.StockManagementService.GetStock(this.tblResponse).then(data => {
            const resdata = data.data;
            //if ResponseData is not null
            if (resdata !== null && resdata.ResponseData !== null && resdata.ResponseData.length > 0) {
                this.datasource = resdata.ResponseData;
                this.setState({
                    totalRecord: resdata.ResponseData[0].TotalRows,
                    frst: (this.tblResponse.First - 1),
                    sale: this.datasource.slice(0, ((this.tblResponse.First - 1) + this.tblResponse.PageSize)),
                    loading: false
                });
            }
            //if ResponseData is null
            else {
                this.datasource = null;
                this.setState({
                    totalRecord: 0,
                    frst: 1,
                    sale: null,
                    loading: false
                });
            }
        });
    }

    hideFunc() {
        this.setState({
            visible: false, buttonLabel: 'Submit',
            formHeading: 'Add Number Issue'
        })
        this.formReset()
    }
    submitForm(event) {
        event.preventDefault();

        let isfrmvalid = true;
        let checkerror = this.state.formError;

        Object.entries(checkerror).map(([key, value]) => {
            const val = this.state[key]
            let valid = this.validateForm(val, key);
            //form not valid
            if (!valid) {
                this.setState({ checkerror: isfrmvalid });
                isfrmvalid = false;
            }
        });
        //form valid
        if (isfrmvalid) {
            const formData = {
                FrmNo: this.state.FromNo === '' ? 0 : parseInt(this.state.FromNo),
                ToNo: this.state.ToNo === '' ? 0 : parseInt(this.state.ToNo),
                IssueQty: this.state.IssueQty === '' ? 0 : parseInt(this.state.IssueQty),
                StockId: this.state.StockId
            }

            setTimeout(() => {
                this.StockManagementService.CreateStockManagement(formData).then(data => {
                    const resdata = data.data;
                    //if ResponseCode is 200 success else error or warning message
                    if (resdata.ResponseCode === '200') {
                        this.refs.child.showSuccess(resdata.ResponseMessage);
                        this.setState({
                            loading: true,
                            visible: false
                        });
                        this.formReset();
                        this.GetIssueNumbers();
                        this.GetStock(); ///KREP-44

                    } else if (resdata.ResponseCode === '400') {
                        this.refs.child.showWarn(resdata.ResponseMessage);
                    } else {
                        this.refs.child.showError(resdata.ResponseMessage);
                    }
                });
            }, 1000);
        }
    }
    // set image
    brandTemplate(rowData, column) {
        var src = rowData.ProductPic;
        return <img src={src} width="100" alt={rowData.ProductPic} />;
    }

    // save stock
    StockManagement(issuedata) {
        setTimeout(() => {
            this.StockManagementService.CreateStockManagement(issuedata).then(data => {
                const resdata = data.data;
                //if ResponseCode is 200 success else error or warning message
                if (resdata.ResponseCode === '200') {
                    this.refs.child.showSuccess(resdata.ResponseMessage);
                    this.GetIssueNumbers();
                } else {
                    this.refs.child.showError(resdata.ResponseMessage);
                }
            });
        }, 1000);
    }

    // set lazy loading values
    getLazyLoadingValues(event) {
        this.setState({ rows: event.rows });
        this.tblResponse.PageSize = event.rows;
        this.tblResponse.First = event.first + 1;
        this.tblResponse.IsActive = '1';
        this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
        //this.tblResponse.SortColumn = event.sortField;
        this.tblResponse.SortOrder = event.sortOrder === 1 ? 'ASC' : 'DESC';
    }

    // seach filter table
    searchFilter(e) {
        this.setState({
            loading: true
        });
        this.tblResponse.SearchValue = e.target.value;
        this.GetIssueNumbers();
        this.GetStock();

    }


    // handle form input
    handleChange(event) {

        const { name, value } = event.target;
        let errors = this.state.formError;

        const isValid = this.validateForm(value, name);
        this.setState({ errors, [name]: value });
    }

    // validate form
    //resolved KREP-17
    validateForm(value, name) {
        let errors = this.state.formError;
        let IsValid = true;
        ;
        //to validate each form field with required conditions
        switch (name) {

            case 'FromNo':

                if (value.toString().trim().length < 1) {
                    IsValid = false;
                    errors.FromNo = "Please enter your from number.";
                } else {

                    if (!value.toString().match(/^[0-9]+$/)) {
                        IsValid = false;
                        errors.FromNo = "Please enter only number.";
                        this.setState({ IssueQty: 0 })
                    } else {
                        errors.ToNo = null;
                        errors.IssueQty = null;
                        errors.FromNo = null;
                        const fromNumber = value.toString().trim() === '' ? 0 : parseInt(value.toString().trim());
                        const toNumber = this.state.ToNo.toString().trim() === '' ? 0 : parseInt(this.state.ToNo.toString().trim());
                        if (fromNumber >= 1 && toNumber >= 1) {
                            if (fromNumber <= toNumber) {
                                const qty = (toNumber - fromNumber) + 1;
                                if (qty >= 0) {
                                    this.setState({ IssueQty: qty })
                                } else {
                                    IsValid = false;
                                    errors.FromNo = "From number should be less than to number.";
                                    this.setState({ IssueQty: 0 })
                                }
                            } else {
                                IsValid = false;
                                errors.FromNo = "From number should be less than to number.";
                                this.setState({ IssueQty: 0 })
                            }
                        } else {
                            // IsValid = false;
                            // errors.FromNo = "From number should be less than to number.";
                            this.setState({ IssueQty: 0 })
                        }
                    }
                }
                break;
            case 'ToNo':
                if (value.toString().trim().length < 1) {
                    IsValid = false;
                    errors.ToNo = "Please enter your to number.";
                } else {
                    if (!value.toString().match(/^[0-9]+$/)) {
                        IsValid = false;
                        errors.ToNo = "Please enter only number.";
                        this.setState({ Qty: 0 })
                    } else {

                        errors.ToNo = null;
                        errors.IssueQty = null;
                        errors.FromNo = null;
                        const toNumber = value.toString().trim() === '' ? 0 : parseInt(value.toString().trim());
                        const fromNumber = this.state.FromNo.toString().trim() === '' ? 0 : parseInt(this.state.FromNo.toString().trim());
                        if (fromNumber >= 1 && toNumber >= 1) {
                            if (fromNumber <= toNumber) {
                                const qty = (toNumber - fromNumber) + 1;
                                if (qty >= 0) {
                                    this.setState({ IssueQty: qty })
                                } else {
                                    IsValid = false;
                                    errors.ToNo = "To number should be greater than from number.";
                                    this.setState({ IssueQty: 0 })
                                }
                            } else {
                                IsValid = false;
                                errors.ToNo = "To number should be greater than from number.";
                                this.setState({ IssueQty: 0 })
                            }
                        } else {
                            // IsValid = false;
                            // errors.ToNo = "To number should be greater than from number.";
                            this.setState({ IssueQty: 0 })
                        }
                    }
                }
                break;
            case 'IssueQty':
                if (value < 1) {
                    IsValid = false;
                    errors.IssueQty = "Please enter your quantity";
                }
                else errors.IssueQty = null;
                break;
            default:
                break;
        }

        return IsValid;
    }


    // Reset form
    formReset() {
        this.setState({
            StockId: 0,
            FrmNo: '',
            ToNo: '',
            Qty: '',
            // Remarks: ''
        });

        let errors = this.state.formError;
        Object.entries(errors).map(([key, value]) => {
            errors[key] = '';
        });
    }

    // edit form
    actionTemplate(rowData, column) {
        // const DeMapping = (e) => {
        //     this.DeMapData(rowData);
        // }

        const editMode = (e) => {
            this.setState({
                buttonLabel: 'Accept', formHeading: 'Accept Stock',
                visible: true,
                FromNo: rowData.FrmNo,
                ToNo: rowData.ToNo,
                IssueQty: rowData.IssueQty,
                StockId: rowData.StockId
            });
        }

        return <span>
            <Button type="button" onClick={editMode} disabled={rowData.IsActive == 0 ? false : true} label={rowData.IsActive == 0 ? 'Accept' : 'Accepted'} className="p-button-warning" style={{ marginRight: '3px' }}></Button>
        </span>;
    }

    // Sort  table data
    onSort(event) {
        this.setState({ sortOrder: this.state.sortOrder === 1 ? 2 : 1 });
        const sortField = event.sortField;
        this.tblResponse.SortColumn = sortField ? sortField : "";
        this.tblResponse.SortOrder = this.state.sortOrder === 1 ? 'DESC' : 'ASC';
        this.GetIssueNumbers();
        this.GetStock();
    }

    // render HTML
    //resolved KREP-19
    render() {
        let header = (
            <div style={{ 'textAlign': 'right' }}>
                <i className="pi pi-search" style={{ margin: '4px 4px 0 0' }}></i>
                <InputText type="search" onInput={this.searchFilter} placeholder="Global Search" size="30" />
            </div>
        );


        return (
            <>
                <MessageComponent ref="child" />
                <div className="heading">
                    <h1>Stock Management</h1>
                </div>

                <DataTable responsive={true} header={header} globalFilter={this.state.globalFilter}
                    emptyMessage={this.state.emptyMessage} value={this.state.sale} sortMode="multiple" paginator={true}
                    rows={this.state.rows} rowsPerPageOptions={[5, 10, 20]} totalRecords={this.state.totalRecords} lazy={true} first={this.state.first}
                    onPage={this.onPage} onSort={this.onSort} loading={this.state.loading}>
                    <Column field="FrmNo" header="From No." sortable={true} />
                    <Column field="ToNo" header="To No." sortable={true} />
                    <Column field="IssueQty" header="IssueQty" sortable={true} />
                    <Column body={this.actionTemplate} header="Action" />
                </DataTable>

                <Dialog className="popup-modal" header={this.state.formHeading} visible={this.state.visible} modal={true} onHide={this.hideFunc}>
                    <form onSubmit={this.submitForm}>
                        <div className="row">

                            <div className="col-md-4 text-right"><label>From No.<span>*</span>:</label></div>
                            <div className="col-md-8 mb-2">
                                <input type="text" id="txtProductId" value={this.state.FromNo} onChange={this.handleChange} className="form-control" name="FromNo" />
                                {this.state.formError.FromNo !== null ?
                                    <div className='error'>{this.state.formError.FromNo}</div> : null}
                            </div>
                            <div className="col-md-4 text-right"><label>To No.<span>*</span>:</label></div>
                            <div className="col-md-8 mb-2">
                                <input type="text" value={this.state.ToNo} onChange={this.handleChange} className="form-control" name="ToNo" />
                                {this.state.formError.ToNo !== null ?
                                    <div className='error'>{this.state.formError.ToNo}</div> : null}
                            </div>
                            <div className="col-md-4 text-right"><label>Quantity<span>*</span>:</label></div>
                            <div className="col-md-8 mb-2">
                                <input type="text" readOnly value={this.state.IssueQty} onChange={this.handleChange} className="form-control" name="IssueQty" />
                                {this.state.formError.IssueQty !== null ?
                                    <div className='error'>{this.state.formError.IssueQty}</div> : null}
                            </div>
                            <div className="col-md-12 text-center">
                                <button type="submit" className="btn btn-primary">{this.state.buttonLabel}</button>
                            </div>

                        </div>
                        <div  >
                            <label>Note: Fields marked as (<span style={{ color: 'red' }}>*</span>) are required</label>
                        </div>
                    </form>
                </Dialog>
                {/* <div className="heading">
                    <h1>Accepted Stock </h1>
                </div> */}
                {/* <DataTable responsive={true} header={header} globalFilter={this.state.globalFilter}
                    emptyMessage={this.state.emptyMessage} value={this.state.sale} sortMode="multiple" 
                    rows={this.state.rows} totalRecords={this.state.totalRecord} first={this.state.frst}
                    onPage={this.onPage}  >
                    <Column field="FrmNo" header="From No." sortable={true} />
                    <Column field="ToNo" header="To No." sortable={true} />
                    <Column field="IssueQty" header="IssueQty" sortable={true} />
                </DataTable> */}
            </>
        )
    }
}