import instance from './DefaultApi';

export default class CountryService {
    ControllerName = 'Country';


    // Save Countries 
    GetCountries(cCode) {
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetCountries?cCode=` + cCode).then(res => res)
    }


    // Get States 
    GetStates(cCode) {
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetStates?cCode=` + cCode, null).then(res => res)
    }

    // Get Cities 
    GetCities(sCode) {
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetCities?sCode=` + sCode, null).then(res => res)
    }


}