import React, { Component } from "react";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { DataTable } from "primereact/datatable";
import { CSVLink, CSVDownload } from "react-csv";
import { Calendar } from "primereact/calendar";
import tableRequest from "../../models/tableRequest.js";
import UserService from "../../services/UserService.js";
import ItemLedgerReportService from "../../services/ItemLedgerReportService.js";
import WareHouseMaterService from "../../services/WareHouseMaterService.js";
import ProductService from "../../services/ProductService.js";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import MessageComponent from "../../template/Message.jsx";
import { Dropdown } from "primereact/dropdown";
import $ from "jquery";
import Loaders from "../../template/Loaders.js";
import { DriveEtaSharp } from "@material-ui/icons";
import { withWidth } from "@material-ui/core";

export default class ItemStockDetail extends Component {
  constructor(props) {
    super(props);
    this.surveyLink = React.createRef();
    this.UserService = new UserService();
    this.ProductService = new ProductService();
    this.WareHouseMaterService = new WareHouseMaterService();
    this.ItemLedgerReportService = new ItemLedgerReportService();
    this.tblResponse = new tableRequest();
    this.tblResponse.WareHouseId = 0;
    this.state = {
      warehouseVisible: true,
      WareHouseId: 0,
      ddid: "",
      FromDate: new Date(),
      ToDate: new Date(),
      SendTo: "",
      success: false,
      updateLoader: false,
      visible: false,
      sortOrder: "",
      sales: [],
      loading: true,
      first: 0,
      rows: 10,
      totalRecords: 0,
      globalFilter: null,
      emptyMessage: "No Data found",
      allData: [],
      wareHouseDDL: [],
      UserDDL: [],
      SelectedDDL: [],
      ProductDDL: [],
    };
    this.onPage = this.onPage.bind(this);
    this.FilterData = this.FilterData.bind(this);
    this.GetReportExport = this.GetReportExport.bind(this);
    this.searchFilter = this.searchFilter.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onSort = this.onSort.bind(this);
  }
  //to trigger api function on page call
  componentDidMount() {
    setTimeout(() => {
      var date = new Date();
      date.setDate(date.getDate() - 7);
      this.tblResponse.FromDate = date.toLocaleDateString();
      this.tblResponse.ToDate = new Date().toLocaleDateString();
      this.setState({
        FromDate: date,
        ToDate: new Date(),
      });

      this.GetReport();

      // this.GetReportExport();
    }, 100);

    $(".p-column-title").on("click", function () {
      $(this)
        .next()
        .removeClass("pi-sort")
        .toggleClass("pi-sort-up")
        .addClass("pi-sort-down");
    });
  }
  //to search
  searchFilter(e) {
    this.setState({
      loading: true,
    });
    this.tblResponse.SearchValue = e.target.value;
    this.GetReport();
  }

  onChange = (date) => this.setState({ FromDate: "", ToDate: "" });
  //on page loading
  onPage(event) {
    this.setState({
      loading: true,
    });

    //imitate delay of a backend call
    setTimeout(() => {
      this.getLazyLoadingValues(event);
      this.GetReport();
    }, 250);
  }

  // get data list from api
  GetReportExport() {
    this.tblResponse.FromDate = this.state.FromDate.toLocaleDateString();
    this.tblResponse.ToDate = this.state.ToDate.toLocaleDateString();

    if (this.state.WareHouseId)
      this.tblResponse.WareHouseId = this.state.WareHouseId.ID;
    else this.tblResponse.WareHouseId = 0;

    if (this.state.ddid) this.tblResponse.UserId = this.state.ddid.UserId;
    else this.tblResponse.UserId = 0;

    this.ItemLedgerReportService.GetStockExport(this.tblResponse).then(
      (data) => {
        const resdata = data.data;
        console.log(this.state.SelectedDDL);
        //if response data not null
        if (
          resdata !== null &&
          resdata.ResponseData !== null &&
          resdata.ResponseData.length > 0
        ) {
          this.setState({
            allData: resdata.ResponseData,
          });

          this.setState({ allData: resdata.ResponseData }, () => {
            this.surveyLink.link.click();
          });
        } else {
          // this.setState({
          //     allData: null
          // });
        }
      }
    );
  }

  //to get warehouse data for ddl
  GetWareHouse(compId) {
    this.WareHouseMaterService.GetWareHouseDDL(compId).then((data) => {
      const resdata = data.data;
      //if response data not null
      if (
        resdata !== null &&
        resdata.ResponseData !== null &&
        resdata.ResponseData.length > 0
      ) {
        //const filteredWareHouse = resdata.ResponseData.filter(x=>x.ID != this.ApiService.getWareHouseId())

        this.setState({
          wareHouseDDL: resdata.ResponseData,
        });
      }
    });
  }

  //to get active users
  GetActiveUsers() {
    this.UserService.GetAllActiveUsers().then((data) => {
      const resdata = data.data;
      //if response data not null

      if (resdata !== null && resdata.ResponseCode === "200") {
        this.setState({
          UserDDL: resdata.ResponseData,
        });
      }
    });
  }

  //to handle change
  handleChange(event) {
    let user = sessionStorage.getItem("username");
    let userid = sessionStorage.getItem("userid");
    const { name, value } = event.target;
    //to validate each form field with required conditions
    switch (name) {
      case "SendTo":
        if (this.state.warehouseVisible) {
          if (this.state.SendTo != null && value != null) {
            let req = {
              WareHouseId: value.ID,
              IsWareHouse: true,
            };
            this.GetProductDDL(req);
          }
        } else {
          if (this.state.SendTo != null && value != null) {
            let req = {
              UserId: value.UserId,
              IsWareHouse: false,
            };
            this.GetProductDDL(req);
          }
        }
        break;
      case "FromDate":
        if (value == null || !(value instanceof Date)) {
          this.formIsValid = false;
          this.refs.Messchild.showError("Please select from date.");
          return;
        } else if (
          !value.toLocaleDateString().match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/)
        ) {
          this.formIsValid = false;
          this.refs.Messchild.showError("Please enter only date.");
          return;
        } else if (value instanceof Date && this.state.ToDate instanceof Date) {
          const startDate = value;
          const endDate = this.state.ToDate;
          if (!(Date.parse(startDate) <= Date.parse(endDate))) {
            this.formIsValid = false;
            this.refs.Messchild.showError(
              "From date must be less than to date."
            );
            return;
          }
        }

        break;
      case "ToDate":
        if (value == null || !(value instanceof Date)) {
          this.formIsValid = false;
          this.refs.Messchild.showError("Please select to date.");
          return;
        } else if (
          !value.toLocaleDateString().match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/)
        ) {
          this.formIsValid = false;
          this.refs.Messchild.showError("Please enter only date.");
          return;
        } else if (
          value instanceof Date &&
          this.state.FromDate instanceof Date
        ) {
          const startDate = this.state.FromDate;
          const endDate = value;
          if (!(Date.parse(startDate) <= Date.parse(endDate))) {
            this.formIsValid = false;
            this.refs.Messchild.showError(
              "To date must be greater than from date."
            );
            return;
          }
        }

        break;
      default:
        break;
    }

    this.setState({ [name]: value });
  }
  //to filter data
  FilterData() {
    debugger;
    this.tblResponse.FromDate = this.state.FromDate.toLocaleDateString();
    this.tblResponse.ToDate = this.state.ToDate.toLocaleDateString();
    this.tblResponse.ToDate = this.state.ToDate.toLocaleDateString();
    this.tblResponse.WareHouseId = this.state.SendTo;
    this.setState({ sales: [] });
    this.GetReport();
  }

  getLazyLoadingValues(event) {
    this.setState({ rows: event.rows });
    this.tblResponse.PageSize = event.rows;
    this.tblResponse.First = event.first + 1;
    this.tblResponse.IsActive = "1";
    this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
    //this.tblResponse.SortColumn = event.sortField;
    this.tblResponse.SortOrder = event.sortOrder === 1 ? "ASC" : "DESC";
    this.tblResponse.FromDate = this.state.FromDate;
    this.tblResponse.ToDate = this.state.ToDate;
  }
  //to get report data
  GetReport() {
    debugger;
    if (sessionStorage.getItem("uid") == 1) {
      this.GetProductDDL({
        UserId: sessionStorage.getItem("uid"),
        IsWareHouse: false,
      });
      this.GetWareHouse(0);
      this.GetActiveUsers();
      if (this.state.SendTo != null && this.state.SendTo != "")
        this.tblResponse.WareHouseId = this.state.SendTo.ID;
      else if (this.state.SendTo == "") this.tblResponse.WareHouseId = 0;
      else this.tblResponse.WareHouseId = 0;

      this.tblResponse.UserId = 0;
      this.tblResponse.IsWareHouse = true;

      this.tblResponse.ProductId = this.state.ProductId;
    } else {
      this.GetProductDDL({
        UserId: sessionStorage.getItem("uid"),
        IsWareHouse: false,
      });
      let wid = sessionStorage.getItem("wid");
      this.tblResponse.UserId = sessionStorage.getItem("uid");
      this.tblResponse.WareHouseId = wid == null ? 0 : wid;
      this.tblResponse.ProductId = this.state.ProductId;
    }
    // if (this.state.WareHouseId)
    // this.tblResponse.WareHouseId = this.state.WareHouseId.ID;
    // else
    // this.tblResponse.WareHouseId=0;

    // if (this.state.ddid)
    // this.tblResponse.UserId =  this.state.ddid.UserId;
    // else
    // this.tblResponse.UserId =   0 ;

    this.ItemLedgerReportService.GetNewStockReport(this.tblResponse).then(
      (data) => {
        const resdata = data.data;
        this.setState({ updateLoader: true });
        //if response data not null
        if (
          resdata !== null &&
          resdata.ResponseData !== null &&
          resdata.ResponseData.length > 0
        ) {
          this.datasource = resdata.ResponseData;
          this.setState({
            totalRecords: resdata.ResponseData[0].TotalRows,
            first: this.tblResponse.First - 1,
            sales: this.datasource.slice(
              0,
              this.tblResponse.First - 1 + this.tblResponse.PageSize
            ),
            loading: false,
          });
        }
        //if response data null
        else {
          this.datasource = null;
          this.setState({
            totalRecords: 0,
            first: 1,
            sales: null,
            loading: false,
          });
        }
      }
    );
    // this.datasource = null;
    // this.setState({
    //     totalRecords: 0,
    //     first: 1,
    //     sales: null,
    //     loading: false
    // });
  }

  //get Product drop down list
  GetProductDDL(req) {
    this.ProductService.GetMapProductsDDLBySelectionWU(req).then((data) => {
      const resdata = data.data;
      //if response data not null
      if (resdata !== null && resdata.ResponseCode === "200") {
        this.setState({
          ProductDDL: resdata.ResponseData,
        });
      }
    });
  }
  //to sort
  onSort(event) {
    this.setState({ sortOrder: this.state.sortOrder === 1 ? 2 : 1 });
    const sortField =
      event.sortField == "CreatedDate" ? "CreatedDt" : event.sortField;
    this.tblResponse.SortColumn = sortField ? sortField : "";
    this.tblResponse.SortOrder = this.state.sortOrder === 1 ? "DESC" : "ASC";
    this.GetReport();
  }

  //to render html part
  render() {
    let header = (
      <div className="row">
        <div className="col-md-12">
          <form className="form-inline">
            {sessionStorage.getItem("uid") == 1 ? (
              <>
                <div className="form-group ml-4 mr-4">
                  <div
                    hidden={!this.state.warehouseVisible}
                    className="custom-drop2 text-left"
                  >
                    <Dropdown
                      filter
                      optionLabel="FirmName"
                      onChange={this.handleChange}
                      value={this.state.SendTo}
                      placeholder="Choose Entity"
                      name="SendTo"
                      options={this.state.wareHouseDDL}
                      showClear={true}
                    />
                  </div>
                </div>
              </>
            ) : null}
            <div className="form-group">
              <div className="ml-4">Product</div>
              <div className="custom-drop2 ml-4 text-left">
                <Dropdown
                  filter
                  onChange={(e) => {
                    this.handleChange(e);
                  }}
                  value={this.state.ProductId}
                  className="w-60"
                  placeholder="All Products"
                  name="ProductId"
                  options={this.state.ProductDDL}
                  showClear={true}
                />
              </div>
            </div>

            <div className="ml-3" style={{ visibility: "hidden" }}>
              From
            </div>

            <div
              className="form-group ml-3 custom-cal"
              style={{ visibility: "hidden" }}
            >
              <Calendar
                value={this.state.FromDate}
                name="FromDate"
                maxDate={this.state.ToDate}
                onChange={this.handleChange}
                appendTo={document.getElementById("root")}
                placeholder="From Date"
              />
            </div>

            <div className="ml-3" style={{ visibility: "hidden" }}>
              To
            </div>
            <div
              className="form-group ml-3 mr-3 custom-cal"
              style={{ visibility: "hidden" }}
            >
              <Calendar
                value={this.state.ToDate}
                name="ToDate"
                minDate={this.state.FromDate}
                onChange={this.handleChange}
                appendTo={document.getElementById("root")}
                placeholder="To Date"
              />
            </div>

            <button
              type="button"
              onClick={this.FilterData}
              className="btn btn-primary ml-3"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    );
    return (
      <>
        <MessageComponent ref="Messchild" />
        {<Loaders loader={this.state.updateLoader} />}
        <div className="heading">
          <h1>Stock Report</h1>
        </div>
        <DataTable
          exportFilename="Stock Report"
          responsive={true}
          header={header}
          globalFilter={this.state.globalFilter}
          emptyMessage={this.state.emptyMessage}
          value={this.state.sales}
          sortMode="multiple"
          paginator={true}
          rows={this.state.rows}
          rowsPerPageOptions={[5, 10, 20]}
          totalRecords={this.state.totalRecords}
          lazy={true}
          first={this.state.first}
          onPage={this.onPage}
          onSort={this.onSort}
          ref={(el) => {
            this.dt = el;
          }}
        >
          <Column field="ProductName" header="Product" sortable={true} />
          <Column field="DDName" header="Batch No." sortable={true} />
          <Column field="TotalQty" header="Total Mapped Stock(Qty's)" />
          <Column field="PackedStock" header="Packed Stock(Qty's)" />
          <Column field="DisptachQty" header="Dispatch Stock(Qty's)" />
          <Column
            field="stock"
            header="Available Stock(Qty's)"
            sortable={true}
          />
        </DataTable>
      </>
    );
  }
}
