import React, { Component } from 'react';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { DataTable } from 'primereact/datatable';
import { CSVLink, CSVDownload } from "react-csv";
import { Calendar } from 'primereact/calendar';
import tableRequest from '../../models/tableRequest.js';
import MappingReportService from '../../services/MappingReportService';
import { InputText } from 'primereact/inputtext';
import { Column } from 'primereact/column';
import MessageComponent from '../../template/Message';
import $ from 'jquery';

export default class PrimaryPackingReport extends Component {
    constructor(props) {
        super(props);
        this.MappingReportService = new MappingReportService();
        this.tblResponse = new tableRequest();
        this.state = {
            FromDate: new Date(),
            ToDate: new Date(),
            success: false,
            visible: false,
            sortOrder:'',
            sales: [],
            loading: true,
            first: 0,
            rows: 5,
            totalRecords: 0,
            globalFilter: null,
            emptyMessage: 'No Data found',
            allData: []

        }
        this.onPage = this.onPage.bind(this);
        this.FilterData = this.FilterData.bind(this);
        this.searchFilter = this.searchFilter.bind(this);
        this.handleChange = this.handleChange.bind(this);

        this.onSort = this.onSort.bind(this);
    }

    componentDidMount() {
        setTimeout(() => {
            var date = new Date();
            date.setDate(date.getDate() - 7);
            this.tblResponse.FromDate = date.toLocaleDateString();
            this.tblResponse.ToDate = new Date().toLocaleDateString();
            this.setState({
                FromDate: date,
                ToDate: new Date()
            });
            this.GetMapping();
            this.GetMappingExport();
        }, 1000);
        $('.p-column-title').on('click', function(){
            $(this).next().removeClass('pi-sort').toggleClass('pi-sort-up').addClass('pi-sort-down');
        });
    }

    searchFilter(e) {
        this.setState({
            loading: true
        });
        this.tblResponse.SearchValue = e.target.value;
        this.GetMapping();
    }

    onChange = date => this.setState({ FromDate: '', ToDate: '' })

    onPage(event) {
        this.setState({
            loading: true
        });

        //imitate delay of a backend call
        setTimeout(() => {
            this.getLazyLoadingValues(event);
            this.GetMapping();
        }, 250);
    }

    // get data list from api
    GetMappingExport() {
        this.MappingReportService.GetMappingExport(this.tblResponse).then(data => {
            const resdata = data.data;
            if (resdata !== null && resdata.ResponseData !== null && resdata.ResponseData.length > 0) {
                this.datasource = resdata.ResponseData;
                this.setState({
                    allData: resdata.ResponseData
                });
            } else {
                // this.setState({
                //     allData: null
                // });
            }
        });
    }

    handleChange(event) {
        const { name, value } = event.target;
        switch (name) {
            case 'FromDate':
                if (value == null || !(value instanceof Date)) {
                    this.formIsValid = false;
                    this.refs.Messchild.showError("Please select from date.");
                    return;
                }
                else if (!(value.toLocaleDateString().match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/))) {
                    this.formIsValid = false;
                    this.refs.Messchild.showError("Please enter only date.");
                    return;
                }
                else if ((value instanceof Date) && (this.state.ToDate instanceof Date)) {
                    const startDate = value;
                    const endDate = this.state.ToDate;
                    if (!(Date.parse(startDate) <= Date.parse(endDate))) {
                        this.formIsValid = false;
                        this.refs.Messchild.showError("From date must be less than to date.");
                        return;
                    }
                }

                break;
            case 'ToDate':
                if (value == null || !(value instanceof Date)) {
                    this.formIsValid = false;
                    this.refs.Messchild.showError("Please select to date.");
                    return;
                }
                else if (!(value.toLocaleDateString().match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/))) {
                    this.formIsValid = false;
                    this.refs.Messchild.showError("Please enter only date.");
                    return;
                }
                else if ((value instanceof Date) && (this.state.FromDate instanceof Date)) {
                    const startDate = this.state.FromDate;
                    const endDate = value;
                    if (!(Date.parse(startDate) <= Date.parse(endDate))) {
                        this.formIsValid = false;
                        this.refs.Messchild.showError("To date must be greater than from date.");
                        return;
                    }
                }

                break;
            default:
                break;
        }

        this.setState({ [name]: value });
    }

    FilterData() {
        this.tblResponse.FromDate = this.state.FromDate.toLocaleDateString();
        this.tblResponse.ToDate = this.state.ToDate.toLocaleDateString();
        this.GetMapping();
    }

    getLazyLoadingValues(event) {
        this.setState({ rows: event.rows });
        this.tblResponse.PageSize = event.rows;
        this.tblResponse.First = event.first + 1;
        this.tblResponse.IsActive = '1';
        this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
        //this.tblResponse.SortColumn = event.sortField;
        this.tblResponse.SortOrder = event.sortOrder === 1 ? 'ASC' : 'DESC';
        this.tblResponse.FromDate = this.state.FromDate;
        this.tblResponse.ToDate = this.state.ToDate;
    }
    // get data list from api

    GetMapping() {
        this.MappingReportService.GetMappingReport(this.tblResponse).then(data => {
            const resdata = data.data;
            if (resdata !== null && resdata.ResponseData !== null && resdata.ResponseData.length > 0) {
                this.datasource = resdata.ResponseData;
                this.setState({
                    totalRecords: resdata.ResponseData[0].TotalRows,
                    first: (this.tblResponse.First - 1),
                    sales: this.datasource.slice(0, ((this.tblResponse.First - 1) + this.tblResponse.PageSize)),
                    loading: false
                });
            } else {
                this.datasource = null;
                this.setState({
                    totalRecords: 0,
                    first: 1,
                    sales: null,
                    loading: false
                });
            }
        });
        // this.datasource = null;
        // this.setState({
        //     totalRecords: 0,
        //     first: 1,
        //     sales: null,
        //     loading: false
        // });
    }

    onSort(event) {
        this.setState({ sortOrder: this.state.sortOrder === 1 ? 2 : 1 });
        const sortField = event.sortField == "CreatedDate" ? "CreatedDt" : event.sortField;
        this.tblResponse.SortColumn = sortField ? sortField : "";
        this.tblResponse.SortOrder = this.state.sortOrder === 1 ? 'DESC' : 'ASC';
        this.GetMapping();
    }
//to render html part
    render() {

        let header = (
            <div className="row">
                <div className="col-md-8">
                    <form className="form-inline">
                        <div className="form-group mr-2">
                            <Calendar value={this.state.FromDate} name="FromDate" onChange={this.handleChange} appendTo={document.getElementById('root')} placeholder="From Date" />

                        </div>
                        <div className="form-group">
                            <Calendar value={this.state.ToDate} name="ToDate" onChange={this.handleChange} appendTo={document.getElementById('root')} placeholder="To Date" />
                        </div>
                        <button type="button" onClick={this.FilterData} className="btn btn-primary ml-3">Submit</button>
                    </form>
                </div>
                <div className="text-right col-md-4">
                    <i className="pi pi-search" style={{ margin: '4px 4px 0 0' }}></i>
                    <InputText type="search" onInput={this.searchFilter} placeholder="Global Search" size="22" />
                    <CSVLink data={this.state.allData} filename={"Verification report.csv"}
                        className="btn btn-primary ml-2"
                        target="_blank">Export</CSVLink>
                </div>
            </div>
        );
        return (
            <>
                <MessageComponent ref="Messchild" />
                <div className="heading">
                    <h1>Primary Packing Report</h1>
                </div>
                <DataTable exportFilename="Mapping Report" responsive={true} header={header} globalFilter={this.state.globalFilter}
                    emptyMessage={this.state.emptyMessage} value={this.state.sales} sortMode="multiple" paginator={true}
                    rows={this.state.rows} rowsPerPageOptions={[10, 50, 200,500]} totalRecords={this.state.totalRecords} lazy={true} first={this.state.first}
                    onPage={this.onPage} onSort={this.onSort} loading={this.state.loading} ref={(el) => { this.dt = el; }}>
                    <Column field="FromNo" header="From No" sortable={true} />
                    <Column field="ToNo" header="To No" sortable={true} />
                    <Column field="Qty" header="Quantity" sortable={true} />
                    <Column field="CreatedDt" header="Created Date" sortable={true} />
                </DataTable>
            </>
        )
    }
}