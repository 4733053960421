import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import DispatchService from "../../services/DispatchService";
import MessageComponent from "../../template/Message";
export default class QrCodeReport extends Component {
  constructor(props) {
    super(props);
    this.DispatchService = new DispatchService();
    this.state = {
      isDetail: false,
      journeyList: [],
      scanTxt: "",
      did: false,
      tid: false,
      qrs: true,
      sid: false,
      TIDproducts: [],
      iddata: [],
      expandedRows: "",
      iddata2: [],
      expandedRows2: "",
      activeIndex: 0,
    };
    this.items = [];
    this.ScanItem = this.ScanItem.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.rowExpansionTemplate = this.rowExpansionTemplate.bind(this);
    this.rowExpansionTemplate2 = this.rowExpansionTemplate2.bind(this);
    this.toggle = this.toggle.bind(this);
    this.toggle2 = this.toggle2.bind(this);
  }

  // call every change of form and validation part also
  handleChange(event) {
    const { name, value } = event.target;
    let errors = this.state.formError;

    // const isValid = this.validateForm(value, name);
    this.setState({ errors, [name]: value });
  }
  toggle(e) {
    console.log(this.state.expandedRows);
    if (e.length == 0 || this.state.expandedRows.length > 0) {
      this.setState({ expandedRows: "" });
      this.setState({
        iddata: "",
      });
    } else {
      let uid = e.data[0].Id;
      this.DispatchService.GetScanInfo(uid).then((res) => {
        const resp = res.data;
        let qr = [];
        let val;
        resp.ResponseData.forEach((element) => {
          if (val == element.Id) {
          } else {
            val = element.Id;
            qr.push(element);
          }
        });
        this.setState({
          iddata: qr,
        });
      });
      this.setState({ expandedRows: e.data });
    }
  }
  toggle2(e) {
    console.log(this.state.expandedRows);
    if (e.length == 0 || this.state.expandedRows2.length > 0) {
      this.setState({ expandedRows2: "" });
      this.setState({
        iddata2: "",
      });
    } else {
      let uid = e.data[0].Id;
      this.DispatchService.GetScanInfo(uid).then((res) => {
        const resp = res.data;
        let qr = [];
        let val;
        resp.ResponseData.forEach((element) => {
          if (val == element.SeqNo) {
          } else {
            val = element.Id;
            qr.push(element);
          }
        });
        this.setState({
          iddata2: qr,
        });
      });
      this.setState({ expandedRows2: e.data });
    }
  }
  // scan qr code
  ScanItem(e) {
    if (e.key === "Enter") {
      let qr = e.target.value;

      // if (qr.includes("iil")||qr.includes("104")|| (qr.includes("SID")||qr.includes("TID")))
      //  {
      if (qr.includes("DID")) {
        this.DispatchService.GetScanInfo(qr).then((data) => {
          const res = data.data;
          if (res.ResponseCode === "200") {
            //if (qr.includes('DID')) {
            this.setState({
              qrs: false,
              TIDproducts: res.ResponseData,
              did: true,
              tid: false,
              sid: false,
            });
            //}
            // else if (qr.includes('TID')) {
            //   this.rowExpansionTemplate(res.ResponseData)
            //   this.setState({ qrs:false,
            //     iddata: res.ResponseData,
            //     tid: true,
            //     sid: false,
            //     did: false

            //   })
            // }
            // else if (qr.includes('SID')) {
            //   this.rowExpansionTemplate(res.ResponseData)
            //   this.setState({

            //     qrs:false,
            //     iddata2: res.ResponseData,
            //     sid: true,
            //     did: false,
            //     tid: false

            //   })
            // }

            //console.log(this.state.TIDproducts);
          }
        });
        //this.refs.Messchild.showWarn("Invalid QR code scanned");
      } else {
        qr = qr.includes("=") ? qr.split("=")[1] : qr;

        this.DispatchService.GetJourney(qr).then((data) => {
          const resdata = data.data;
          //if response data not null
          if (resdata !== null && resdata.ResponseCode === "200") {
            //  this.items = [] = [];
            this.setState({
              journeyList: resdata.ResponseData,
              qrs: true,
              did: false,
              tid: false,
              sid: false,
            });
          } else this.refs.Messchild.showWarn(resdata.ResponseMessage);
        });
      }
      this.setState({
        scanTxt: "",
      });
    }
  }
  rowExpansionTemplate(data) {
    if (data.Id.includes("SID")) {
      return (
        <div className="orders-subtable">
          <h4>Items In {data.Id}</h4>
          <DataTable value={this.state.iddata}>
            <Column field="ProductName" header="Product Name" sortable></Column>
            <Column field="Qty" header="Total Qty" sortable></Column>
            <Column field="date" header="Date of Packing" sortable></Column>
          </DataTable>
        </div>
      );
    } else if (data.Id.includes("TID")) {
      return (
        <div className="orders-subtable">
          <h4>Items In {data.Id}</h4>
          <DataTable
            value={this.state.iddata}
            expandedRows={this.state.expandedRows2}
            onRowToggle={(e) => {
              this.toggle2(e);
            }}
            rowExpansionTemplate={this.rowExpansionTemplate2}
          >
            <Column expander style={{ width: "3em" }} />
            <Column field="Id" header="Id" sortable></Column>
            <Column field="Qty" header="Total Product Qty"></Column>
            <Column field="date" header="Date of Packing" sortable></Column>
          </DataTable>
        </div>
      );
    }
  }
  rowExpansionTemplate2(data) {
    return (
      <div className="orders-subtable">
        <h4>Items In {data.Id}</h4>
        <DataTable value={this.state.iddata2}>
          <Column field="ProductName" header="Product Name" sortable></Column>
          <Column field="Qty" header="Total Qty" sortable></Column>
          <Column field="date" header="Date of Packing" sortable></Column>
        </DataTable>
      </div>
    );
  }

  render() {
    const item = [];
    for (const [index, value] of this.state.journeyList.entries()) {
      item.push(
        <li key={index}>
          {value.activity}{" "}
          {value.date ? (
            <span>
              <i className="fa fa-clock-o"></i> {value.date}
            </span>
          ) : null}{" "}
        </li>
      );
    }
    const itemLength = 60 * item.length + "px";
    itemLength.toString();
    const mystyle = {
      height: itemLength,
    };

    return (
      <>
        <MessageComponent ref="Messchild" />

        <div className="heading mb-1">
          <h1>QR Code Tracking Report</h1>
        </div>

        {/* <div className="p-field-checkbox col-md-3">
          <input type="checkbox"  name="hello" value="isdetail" onChange={()=>this.setState(pre=>({isDetail:!pre.isDetail}))} checked={this.state.isDetail} />
                    <label htmlFor="de">To see tracking details</label>
                </div> */}
        <div className="col-md-9">
          <input
            style={{ width: "400px" }}
            onChange={(e) => {
              this.handleChange(e);
            }}
            name="scanTxt"
            value={this.state.scanTxt}
            type="search"
            autoComplete="off"
            className="form-control ml-2"
            id="scan"
            onKeyDown={(e) => {
              this.ScanItem(e);
            }}
            autoFocus
          />
        </div>

        <div className="col-md-12 mt-5 " style={{ marginBottom: "10vh" }}>
          {this.state.qrs && (
            <ul className="timeline" style={mystyle}>
              {item}
            </ul>
          )}
        </div>
        <div>
          .
          {this.state.did && (
            <DataTable
              value={this.state.TIDproducts}
              expandedRows={this.state.expandedRows}
              onRowToggle={(e) => {
                this.toggle(e);
              }}
              rowExpansionTemplate={this.rowExpansionTemplate}
            >
              <Column expander style={{ width: "3em" }} />
              <Column field="Id" header="Name" sortable />
              <Column field="Qty" header="Total Qty" />
              <Column field="date" header="Date" sortable />
            </DataTable>
          )}
          {this.state.tid && (
            <DataTable
              value={this.state.iddata}
              expandedRows={this.state.expandedRows2}
              onRowToggle={(e) => {
                this.toggle2(e);
              }}
              rowExpansionTemplate={this.rowExpansionTemplate2}
            >
              <Column expander style={{ width: "3em" }} />
              <Column field="Id" header="Name" sortable />
              <Column field="Qty" header="Total Product Qty"></Column>
              <Column field="date" header="Date" sortable />
            </DataTable>
          )}
          {this.state.sid && (
            <DataTable value={this.state.iddata2}>
              <Column
                field="ProductName"
                header="Product Name"
                sortable
              ></Column>
              <Column field="SeqNo" header="Id" sortable></Column>
              <Column field="date" header="Date of Packing" sortable></Column>
            </DataTable>
          )}
        </div>
      </>
    );
  }
}
