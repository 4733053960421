import instance from './DefaultApi';

export default class DispatchReportService {
    ControllerName = 'DispatchReport';

    // get all data
    GetDispatchReport(tableRequest) {
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetDispatchReport`, tableRequest).then(res => res)
    }
    //get export for Dispatch report
    GetReportExportDispatch(tableRequest) {
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetReportExportDispatch`, tableRequest).then(res => res)
    }
    GetDispatchRecReport(tableRequest) {
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetDispatchRecReport`, tableRequest).then(res => res)
    } 
    GetDamageReport(tableRequest) {
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetDamageReport`, tableRequest).then(res => res)
    }
    //get export for Dispatch report

    GetReportFrac(tableRequest) {
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetFractionalReport`, tableRequest).then(res => res)
    }
    //To Print Dispatch detail
    GetDispatchToPrint(d) {
        
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetDispatchToPrint`, d).then(res => res)
    }

}