import instance from './DefaultApi';

export default class PriceMasterService {
    ControllerName = 'PriceMaster';

    //Get Price list
    GetPrice(tableRequest) {
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetPrice`, tableRequest).then(res => res)
    }

    //save Price List
    CreatePrice(PriceData) {
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/CreatePrice`, PriceData).then(res => res)
    }

    // get Product Drop Down List
    GetProductDDL() {
        return instance.get(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetProductDDL`).then(res => res)
    }


}