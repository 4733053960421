import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
  

export default class TnC extends Component {
  constructor(props) {
    super(props);
 
    this.state = {
 
      formError: {
 
      },
 
    }
     
  }
 
// render HTML
  render() {
    
    
    return (
      
      <html xmlns="http://www.w3.org/1999/xhtml">
      <head>
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
       
      <title>Terms and Conditions</title>
      </head>
      
      <body style={{fontFamily: "Verdana, Geneva, sans-serif",margin:0,
	          padding:"10px",fontSize:"12px",textAlign:"justify",lineHeight:"20px" }}>

      <h2>Mobile App Terms and Conditions</h2>
      <h4>1. GENERAL</h4>
      <ol>
        <li>
          By installing the Application (as defined below), you agree to be bound by these terms of use. Please review them carefully before installation and/or acceptance. 
          </li>
      </ol>
      
      <h4>2. DEFINITIONS</h4>
      
        <p>
         The “Application” shall mean the software provided by krishi Rasayan Exports Pvt Ltd to offer services on Apple iOS and Android OS devices and any upgrades from time to time and any other software or documentation which enables the use of the Application.
          </p>
      <h4>3. DATA PROTECTION</h4>
      
        <p>
        Any personal information you supply to krishi Rasayan Exports Pvt Ltd when using the Application will be used by krishi Rasayan Exports Pvt Ltd in accordance with its Privacy Policy.
          </p>
      
      <h4>4. krepl.in </h4>
      
        <p>
      The Application allows you to access certain functionality available on the krepl.in website. Such access will be governed by the krishi Rasayan Exports Pvt Ltd Standard Website Terms & Conditions.
          </p>
      
      <h4>5. PROPRIETARY RIGHTS AND LICENCE</h4>
      <ol>
        <li>
        All trade marks, copyright, database rights and other intellectual property rights of any nature in the Application together with the underlying software code are owned either directly by krishi Rasayan Exports Pvt Ltd or by krishi Rasayan Exports Pvt Ltd's licensors.
          </li>
          <li>
      .    krishi Rasayan Exports Pvt Ltd hereby grants you a worldwide, non-exclusive, royalty-free revocable licence to use the Application for your personal use in accordance with these appterms.
          </li>
      </ol>
      
      
      <h4>6. CONDITIONS OF USE</h4>
      <ol>
        <li>
         You will not, nor allow third parties on your behalf to 
         <ol type="i">
           <li>
             make and distribute copies of the Application
          </li>
          <li>
         attempt to copy, reproduce, alter, modify, reverse engineer, disassemble, decompile, transfer, exchange or translate the Application; or
          </li>
          <li>
         create derivative works of the Application of any kind whatsoever.
          </li>
         </ol>
         
         
         
          </li>
          <li>
      The Application is currently made available to you free of charge for your personal, non-commercial use. krishi Rasayan Exports Pvt Ltd reserves the right to amend or withdraw the Application,in accordance with these appterms, at any time and for any reason.
          </li>
          <li>
         You acknowledge that the terms of agreement with your respective mobile network provider (‘Mobile Provider’) will continue to apply when using the Application. As a result, you may be charged by the Mobile Provider for access to network connection services for the duration of the connection while accessing the Application or any such third party charges as may arise. You accept responsibility for any such charges that arise.
          </li>
          <li>
       If you are not the bill payer for the mobile telephone or handheld device being used to access the Application, you will be assumed to have received permission from the bill payer for using the Application.
          </li>
      </ol>
      
      
      <h4>7. AVAILABILITY</h4>
      <ol>
        <li>
         This Application is available to handheld mobile devices running Apple iOS and Android OS Operating Systems. krishi Rasayan Exports Pvt Ltd will use reasonable efforts to make the Application available at all times. However you acknowledge the Application is provided over the internet and mobile networks and so the quality and availability of the Application may be affected by factors outside krishi Rasayan Exports Pvt Ltd's reasonable control.
          </li>
          <li>
      krishi Rasayan Exports Pvt Ltd, its group of companies and sub-contractors do not accept any responsibility whatsoever for unavailability of the Application, or any difficulty or inability to download or access content or any other communication system failure which may result in the Application being unavailable.
          </li>
          <li>
       krishi Rasayan Exports Pvt Ltd will not be responsible for any support or maintenance for the Application.
          </li>
         
      </ol>
      
      <h4>8. SYSTEM REQUIREMENTS</h4>
      <ol>
        <li>
      In order to use the Application, you are required to have a compatible mobile telephone or handheld device, internet access, and the necessary minimum specifications ('Software Requirements').
          </li>
          <li>
       The Software Requirements are as follows: Apple iOS devices running iOS 4 or iOS 5, and Android OS devices running Android OS 2.1 up to OS 2.3; Language: English, Italian, German, Spanish, French.
          </li>
          <li>
       The version of the Application software may be upgraded from time to time to add support for new functions and services.
          </li>
         
      </ol>
      <h4>9. TERMINATION</h4>
      <ol>
        <li>
        krishi Rasayan Exports Pvt Ltd may terminate use of the Application at any time by giving notice of termination to you.
          </li>
          <li>
       Upon any termination,
        <ol type="a">
           <li>
           the rights and licenses granted to you herein shall terminate;
          </li>
          <li>
          you must cease all use of the Software; 
          </li>
          
         </ol>
       
       
        
          </li>
         
         
      </ol>
      <h4>10. LIMITATION OF LIABILITY</h4>
      <ol>
        <li>
      In no event will krishi Rasayan Exports Pvt Ltd be liable for any direct, indirect, special, punitive, exemplary or consequential losses or damages of whatsoever kind arising out of your use or access to the Application, including loss of profit or the like whether or not in the contemplation of the parties, whether based on breach of contract, tort (including negligence), product liability or otherwise.
          </li>
          <li>
      krishi Rasayan Exports Pvt Ltd is not liable to you for any damage or alteration to your equipment including but not limited to computer equipment, handheld device or mobile telephones as a result of the installation or use of the Application.
          </li>
          <li>
      Nothing in these Appterms shall exclude or limit krishi Rasayan Exports Pvt Ltd's liability for death or personal injury caused by negligence or for fraud or fraudulent misrepresentation or any other liability which cannot be excluded or limited under applicable law.
          </li>
         
      </ol>
      <h4>11. DISCLAIMER OF WARRANTIES</h4>
      
        <p>
        To the maximum extent permitted by law, and for the avoidance of doubt, save for section 2 of the Supply of Goods and Services Act 1982, krishi Rasayan Exports Pvt Ltd hereby disclaims all implied warranties with regard to the Application. The Application and software are provided "as is" and "as available" without warranty of any kind.
          </p>
      
      
      
      
      
      
      </body>
      </html>
          
       
    )
  }
}
