import React, { Component } from 'react'
import UserService from '../services/UserService';
import MessageComponent from '../template/Message';

// Manage password
export default class ChangePassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            UserName: '',
            OldPassword: '',
            NewPassword: '',
            UserId: 0,

            formError: {
                OldPassword: null,
                NewPassword: null
            }
        }

        this.UserService = new UserService();
        this.handleChange = this.handleChange.bind(this);
        this.submitForm = this.submitForm.bind(this);
    }
    //trigger api function on page call
    componentDidMount() {
        this.getUser();
    }

    //get user list
    getUser() {
        this.UserService.getUser().then(data => {
            const resdata = data.data;
            //if ResponseCode is 200
            if (resdata.ResponseCode === '200') {
                const usr = resdata.ResponseData;
                this.setState({
                    UserName: usr.Username,
                    UserId: usr.UserId
                });
            }
        });
    }

    // submit form and create or update user
    submitForm(event) {
        event.preventDefault();

        let isfrmvalid = true;
        let checkerror = this.state.formError;

        Object.entries(checkerror).map(([key, value]) => {
            const val = this.state[key]
            let valid = this.validateForm(val, key);
            //form not valid
            if (!valid) {
                this.setState({ checkerror: isfrmvalid });
                isfrmvalid = false;
            }
        });
        //form valid
        if (isfrmvalid) {
            const formData = {
                UserName: this.state.UserName,
                OldPassword: this.state.OldPassword,
                NewPassword: this.state.NewPassword,
                UserId: this.state.UserId
            }

            this.UserService.ChangePassword(formData).then(data => {
                const resdata = data.data;
              //if ResponsCode is 200 success else error or warning message
                if (resdata !== null && resdata.ResponseCode === '200') {
                    this.setState({
                        loading: true,
                        visible: false
                    });
                    const res = this.refs.Messchild.showSuccess(resdata.ResponseMessage);
                    if (res) {
                        setTimeout(() => {
                            sessionStorage.clear();
                            window.location = '/login';
                        }, 2000);
                    }

                } else if (resdata.ResponseCode === '400') {
                    this.refs.Messchild.showWarn(resdata.ResponseMessage);
                } else {
                    this.refs.Messchild.showError(resdata.ResponseMessage);
                }
            });
        }
    }

    // form Reset
    formReset() {
        this.setState({
            UserName: '',
            OldPassword: '',
            NewPassword: ''
        });
    }

    // call every change of form and validation part also
    handleChange(event) {

        const { name, value } = event.target;
        let errors = this.state.formError;

        const isValid = this.validateForm(value, name);
        this.setState({ errors, [name]: value });
    }

    // validate Form
    validateForm(value, name) {
        let errors = this.state.formError;
        let IsValid = true;
        //to validate each form field with required conditions
        switch (name) { 
            case 'OldPassword':
                if (value.length < 1) {
                    IsValid = false;
                    errors.OldPassword = "Please enter old password.";
                } else if (value.length >= 1 && this.state.NewPassword.length >= 1) {
                    errors.OldPassword = '';
                    errors.NewPassword = '';
                    if (value == this.state.NewPassword) {
                        IsValid = false;
                        errors.OldPassword = "Old password and new password should not be same.";
                    }
                } else errors.OldPassword = ''
                break;

            case 'NewPassword':
                if (value.length < 1) {
                    IsValid = false;
                    errors.NewPassword = "Please enter new password.";
                } else if (value.length >= 1 && this.state.OldPassword.length >= 1) {
                    errors.OldPassword = '';
                    errors.NewPassword = '';
                    if (value == this.state.OldPassword) {
                        IsValid = false;
                        errors.NewPassword = "New password and old password should not be same.";
                    }
                } else errors.NewPassword = ''
                break;
            default:
                break;
        }

        return IsValid;
    }

    // return html
    render() {
        return (
            <>
                <MessageComponent ref="Messchild" />
                <form onSubmit={this.submitForm}>
                    <div className="heading">
                        <h1>Change Password</h1>
                    </div>
                    <div className="row profile-frm">
                        <div className="col-md-3">Name</div>
                        <div className="col-md-9"><input type="text" value={this.state.UserName} disabled /></div>
                        {/* <div className="col-md-3">Role</div>
                    <div className="col-md-9"><input type="text" value="User" disabled /></div>
                    <div className="col-md-3">Created Date</div>
                    <div className="col-md-9"><input type="text" value="01-02-2020" disabled /></div> */}
                        <div className="col-md-3">Old Password</div>
                        <div className="col-md-9">
                            <input type="password" name="OldPassword" onChange={this.handleChange} value={this.state.OldPassword} />
                            {this.state.formError.OldPassword !== '' ?
                                <div className='error'>{this.state.formError.OldPassword}</div> : ''}
                        </div>
                        <div className="col-md-3">New Password</div>
                        <div className="col-md-9">
                            <input type="password" name="NewPassword" onChange={this.handleChange} value={this.state.NewPassword} />
                            {this.state.formError.NewPassword !== '' ?
                                <div className='error'>{this.state.formError.NewPassword}</div> : ''}
                        </div>
                        <div className="col-md-3"></div>
                        <div className="col-md-9">
                            <button className="btn btn-primary">Update</button>
                        </div>
                    </div>
                </form>
            </>
        )
    }
}
