import axios from 'axios';

export default class ApiService {

    //Get Api call
    getApi(endPoint) {
        return axios.get(`${process.env.REACT_APP_API_URL}${endPoint}`).then(res => res)
    }

    //post api call
    postApi(endPoint, Payload) {
        return axios.post(`${process.env.REACT_APP_API_URL}${endPoint}`, Payload).then(res => res)
    }

    // get Geo Location
    reverseGeoCode(lat, long) {
        // tslint:disable-next-line:max-line-length
        return axios.get(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${long}&zoom=18&addressdetails=1`).then(res => res)
    }

    //get user type
    getUserType() {
        return sessionStorage.getItem("userType")
    }

    //get wareHouseId
    getWareHouseId() {
        return sessionStorage.getItem("wareHouseId")
    }

    //get CompanyId
    getCompanyId() {
        return sessionStorage.getItem("CompanyId")
    }

    //get isAdmin
    getIsAdmin() {
        return sessionStorage.getItem("isAdmin")
    }

    //get wareHouseId
    getFactoryPrefix() {
        return sessionStorage.getItem("Prefix")
    }

}