import instance from './DefaultApi';

export default class SchemeReportService {
    ControllerName = 'SchemeReport';

    // get all data list Scheme Report
    GetSchemeReport(tableRequest) {
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetSchemeReport`, tableRequest).then(res => res)
    }

    // Excel Scheme Export
    GetSchemeExport(tableRequest) {
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetSchemeExport`,tableRequest).then(res => res)
    }
}