import instance from './DefaultApi';

export default class NumberGenerationService {
    ControllerName = 'NumberGeneration';

    // get all product Get Generated Numbers data
    GetGeneratedNumbers(tableRequest) {
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetGeneratedNumbers`, tableRequest).then(res => res)
    }

    // create new product Create Generated Number  
    CreateGeneratedNumber(issueData) {
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/CreateGeneratedNumber`, issueData).then(res => res)
    }

    //get max from number to set new number issue
    GetFromNumbers(f) {
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetFromNumbers`,f).then(res => res)
    }

    DownloadNumber(data)
    {
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/DownloadNumber`, data).then(res => res) 
    }
}