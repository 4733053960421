import instance from './DefaultApi';

export default class ProductService {
    ControllerName = 'Product';

    // get all products
    GetProducts(tableRequest) {
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetProducts`, tableRequest).then(res => res)
    }
    GetIds(prefix){
        return instance.get(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetIds/${prefix}`).then(res => res)
 
    } 
    UpdateIds(prefix,type,value){
         ;
        return instance.get(`${process.env.REACT_APP_API_URL}${this.ControllerName}/UpdateIds/${prefix},${type},${value}`).then(res => res)
    }
    // create new product and update existing product 
    CreateUpdateProduct(productData) {
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/CreateUpdateProduct`, productData).then(res => res)
    }
    // delete product
    DeleteProduct(productId) {
        return instance.get(`${process.env.REACT_APP_API_URL}${this.ControllerName}/DeleteProduct/${productId}`).then(res => res)
    }
    // get all products for ddl
    GetProductsDDL() {
        return instance.get(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetProductsDDL`).then(res => res)
    }
    GetProductUOM() {
        return instance.get(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetProductUOM`).then(res => res)
    }
    GetProductSize(uom) {
        return instance.get(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetProductsSize/${uom}`).then(res => res)
    }
    GetProductLife(pid) {
        return instance.get(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetProductsLife/${pid}`).then(res => res)
    }

    //Get Mapped Products Drop Down
    GetMappedProductsDDL(type) {

        return instance.get(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetMappedProductsDDL/${type}`).then(res => res)
    } 
    GetMapping(id ,type) {
        

        return instance.get(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetMapping/${id},${type}`).then(res => res)
    }

    GetMappings(d ,type) {
        
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetMappings?type=${type}`, d).then(res => res)
    }

    GetMappedProductsDDLByType(type) {

        return instance.get(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetMappedProductsDDLByType?type=${type}`).then(res => res)
    }

    // GetMapProductsDDLBySelection WareHouse or User
    GetMapProductsDDLBySelectionWU(req) {
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetMapProductsDDLBySelectionWU`, req).then(res => res)
    }

    GetMapping(id,type){
        return instance.get(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetMapping/${id},${type}`).then(res => res)
    }

}