import React, { Component } from "react";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import UserService from "../../services/UserService";
import DispatchService from "../../services/DispatchService";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import tableRequest from "../../models/tableRequest.js";
import { confirmAlert } from "react-confirm-alert"; // Import
import { ConfirmationModal } from "../../template/ConfirmationModal";
import MessageComponent from "../../template/Message";
import $ from "jquery";
import PrintDispatchService from "../../services/PrintDispatchService";
import ApiService from "../../services/apiService";
import WareHouseMaterService from "../../services/WareHouseMaterService";
import Loaders from "../../template/Loaders";
import { BreadCrumb } from "primereact/breadcrumb";
import { Card } from "primereact/card";
import { TextField } from "@material-ui/core";
export default class Dispatch extends Component {
  constructor(props) {
    super(props);
    this.UserService = new UserService();
    this.DispatchService = new DispatchService();
    this.PrintDispatchService = new PrintDispatchService();
    this.ApiService = new ApiService();
    this.WareHouseMaterService = new WareHouseMaterService();
    this.tblResponse = new tableRequest();
    this.state = {
      TransportModeId: null,
      Mode: "",
      scanTxt: "",
      warehouseVisible: false,
      visible: false,
      radio: false,
      isold: false,
      cartoonLoader: false,
      scanItemLoader: true,
      remarkVisible: false,
      olderstockVisible: false,
      eventBlock: false,
      sortIcon: false,
      sortOrder: "1",
      formError: {
        TransportModeId: "",
        LR: "",
      },
      UserDDL: [],
      SelectedDDL: [],
      wareHouseDDL: [],
      WareHouseFactoryDDL: [],
      SendTo: [],
      buttonLabel: "Submit",
      sales: [],
      scanItems: [],
      scanDisp: [],
      olderdata: [],
      loading: true,
      first: 0,
      rows: 10,
      totalRecords: 0,
      globalFilter: null,
      emptyMessage: "No Data found",
      bill_Address1: "",
      bill_Address2: "",
      bill_City: "",
      bill_Country: "",
      bill_Pincode: "",
      bill_State: "",
      ship_Address1: "",
      ship_Address2: "",
      ship_City: "",
      ship_Country: "",
      ship_Pincode: "",
      ship_State: "",
      IsOldProduct: false,
      Remark: "",
      globalFilter: null,
      needtoSaveDispatch: "",
      ModeDDL: [],
      LR: "",
    };
    this.hideFunc = this.hideFunc.bind(this);
    this.actionTemplate = this.actionTemplate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    // this.handleChange1 = this.handleChange1.bind(this);
    //this.sendToEvent = this.sendToEvent.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.submitRemark = this.submitRemark.bind(this);
    this.onPage = this.onPage.bind(this);
    this.searchFilter = this.searchFilter.bind(this);
    this.onSort = this.onSort.bind(this);
    this.ScanItem = this.ScanItem.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
  }
  //trigger api function on page call
  componentDidMount() {
    setTimeout(() => {
      this.GetWareHouse(0);
      this.GetDispatch();
      const objtoprint = {
        CartonId: "DID-2020-21-00001",
        CreatedDt: "14/07/20",
        Qty: "2",
        userDetail: {
          UserId: 30,
          Token: null,
          RefreshToken: null,
          ReferenceID: "CE1215F5-44DE-46F0-B9B7-72E1D1B24172",
          Username: "ajaytestdealer",
          Password: null,
          FirstName: "new",
          LastName: "gupta",
          Email: null,
          MobileNo: "8181818181",
          UserType: 2,
          ProfileImage: null,
          DeviceType: null,
          IsActive: true,
          InActiveReason: null,
          CreatedBy: null,
          CreatedDt: "2020-07-10T13:57:11.46",
          UpdatedBy: null,
          UpdatedDt: null,
          Salt: null,
          CreatedDate: null,
          IsResetPassword: false,
          TotalRows: 0,
          userId: 0,
          Id: 0,
          ship_Country: "101",
          ship_State: "1",
          ship_City: "93",
          ship_CountryName: "India",
          ship_StateName: "Andaman & Nicobar Islands",
          ship_CityName: "Carnicobar",
          ship_Address1: "Add1",
          ship_Address2: "Add2",
          ship_Pincode: "1902929",
          bill_Country: "101",
          bill_State: "4",
          bill_City: "91",
          bill_CountryName: "India",
          bill_StateName: "Assam",
          bill_CityName: "Cachar",
          bill_Address1: "Add1",
          bill_Address2: "Add2",
          bill_Pincode: "1902929",
        },
        cartonList: [
          {
            ProductName: "KROSIN-AG",
            TertiaryCartonId: "TID-2020-21-00001",
            TotalSecondaryCarton: null,
            CartonId: null,
            TotalQty: "2",
            ProductUOM: "Pieces",
          },
          {
            ProductName: "Keetnashak",
            TertiaryCartonId: "TID-2020-21-00002",
            TotalSecondaryCarton: null,
            CartonId: null,
            TotalQty: "3",
            ProductUOM: "Pieces",
          },
        ],
      };
      this.GetModeDDL();

      // const objtoprint = {
      //     "CartonId":"DID-2020-21-00002",
      //     "CreatedDt":"10/07/20",
      //     "Qty":"1",
      //     "userDetail":{
      //        "UserId":30,
      //        "Token":null,
      //        "RefreshToken":null,
      //        "ReferenceID":"CE1215F5-44DE-46F0-B9B7-72E1D1B24172",
      //        "Username":"ajaytestdealer",
      //        "Password":null,
      //        "FirstName":"new",
      //        "LastName":"gupta",
      //        "Email":null,
      //        "MobileNo":"8181818181",
      //        "UserType":2,
      //        "ProfileImage":null,
      //        "DeviceType":null,
      //        "IsActive":true,
      //        "InActiveReason":null,
      //        "CreatedBy":null,
      //        "CreatedDt":"2020-07-10T13:57:11.46",
      //        "UpdatedBy":null,
      //        "UpdatedDt":null,
      //        "Salt":null,
      //        "CreatedDate":null,
      //        "IsResetPassword":false,
      //        "TotalRows":0,
      //        "userId":0,
      //        "Id":0,
      //        "ship_Country":"101",
      //        "ship_State":"1",
      //        "ship_City":"93",
      //        "ship_CountryName":"India",
      //        "ship_StateName":"Andaman & Nicobar Islands",
      //        "ship_CityName":"Carnicobar",
      //        "ship_Address1":"Add1",
      //        "ship_Address2":"Add2",
      //        "ship_Pincode":"1902929",
      //        "bill_Country":"101",
      //        "bill_State":"4",
      //        "bill_City":"91",
      //        "bill_CountryName":"India",
      //        "bill_StateName":"Assam",
      //        "bill_CityName":"Cachar",
      //        "bill_Address1":"Add1",
      //        "bill_Address2":"Add2",
      //        "bill_Pincode":"1902929"
      //     },
      //     "cartonList":[
      //        {
      //           "TertiaryCartonId":"TID-2020-21-00002",
      //           "TotalSecondaryCarton":"1",
      //           "CartonId":null,
      //           "TotalQty":12,
      //           "ProductUOM" : "PCS",
      //           "ProductName":"New Product"

      //        }
      //     ]
      //  };

      //this.PrintDispatchService.Printpdf(objtoprint);
    }, 1000);

    $(".p-column-title").on("click", function () {
      $(this)
        .next()
        .removeClass("pi-sort")
        .toggleClass("pi-sort-up")
        .addClass("pi-sort-down");
    });

    this.GetActiveUsers();
    //if user type is 1 state of visible will be set to true
    if (this.ApiService.getUserType() == 1) {
      this.setState({
        visible: true,
      });
    }
  }

  // PAGE EVENT CALL
  onPage(event) {
    this.setState({
      loading: true,
    });

    //imitate delay of a backend call
    setTimeout(() => {
      this.getLazyLoadingValues(event);
      this.GetDispatch();
    }, 250);
  }
  hideFunc() {
    this.setState({
      remarkVisible: false,
      olderstockVisible: false,
      // formHeading: 'Add User'
    });
  }
  // set lazy loading values
  getLazyLoadingValues(event) {
    this.setState({ rows: event.rows });
    this.tblResponse.PageSize = event.rows;
    this.tblResponse.First = event.first + 1;
    this.tblResponse.IsActive = true;
    this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
    //this.tblResponse.SortColumn = event.sortField;
    this.tblResponse.SortOrder = event.sortOrder === 1 ? "ASC" : "DESC";
  }

  // get users data list from api
  GetDispatch() {
    this.setState({ cartoonLoader: false });
    this.tblResponse.SortColumn = "DispatchId";
    this.DispatchService.GetDispatch(this.tblResponse).then((data) => {
      const resdata = data.data;
      this.setState({ cartoonLoader: true });
      //if ResponseData is not null sales state will be set with ResponseData
      if (
        resdata !== null &&
        resdata.ResponseData !== null &&
        resdata.ResponseData.length > 0
      ) {
        this.datasource = resdata.ResponseData;
        this.setState({
          totalRecords: resdata.ResponseData[0].TotalRows,
          first: this.tblResponse.First - 1,
          sales: this.datasource.slice(
            0,
            this.tblResponse.First - 1 + this.tblResponse.PageSize
          ),
          loading: false,
        });
      }
      //if ResponseData is  null sales state will be set null
      else {
        this.datasource = null;
        this.setState({
          totalRecords: 0,
          first: 1,
          sales: null,
          loading: false,
        });
      }
    });
  }
  GetModeDDL() {
    ;
    this.DispatchService.GetModeDDL().then((data) => {
      const resdata = data.data;
      console.log("DATA of DDL-", resdata);
      //if resdata is not null
      if (resdata !== null && resdata.ResponseCode === "200") {
        this.setState({
          ModeDDL: resdata.ResponseData,
        });
      }
    });
  }
  // call every change of form and validation part also
  handleChange(event) {
    const { name, value } = event.target;
    let errors = this.state.formError;
    if (name == "SendTo") {
      this.setState({ scanTxt: "" });
    }
    if (name == "ID") {
      this.setState({
        ID: event.target.checked,
      });
    }
    const isValid = this.validateForm(value, name);
    this.setState({ errors, [name]: value });
  }

  // handleChange1(event) {
  //   const { name, value } = event.target;
  //   let errors = this.state.formError;

  //   const isValid = this.validateForm(value, name);
  //   this.setState({ errors, [name]: value });
  //   //to set state of IsActive
  //   if (name == "ID")
  //     this.setState({
  //       ID: event.target.checked,
  //     });
  // }
  // validate form
  validateForm(value, name) {
    let errors = this.state.formError;
    let IsValid = true;
    //to validate each form field with required conditions
    switch (name) {
      case "UserName":
        if (value.length < 1) {
          IsValid = false;
          errors.UserName = "Please enter your username.";
        } else errors.UserName = "";
        break;
      case "LR":
        if (value.length < 1) {
          IsValid = false;
          errors.LR = "Please enter LR Number.";
        } else errors.LR = "";
        break;
      case "SendTo":
        console.log(value);
        this.state.bill_Address1 = value.bill_Address1;
        this.state.bill_Address2 = value.bill_Address2;
        this.state.bill_Pincode = value.bill_Pincode;
        this.state.bill_Country = value.bill_CountryName;
        this.state.bill_State = value.bill_StateName;
        this.state.bill_City = value.bill_CityName;
        this.state.ship_Address1 = value.ship_Address1;
        this.state.ship_Address2 = value.ship_Address2;
        this.state.ship_Pincode = value.ship_Pincode;
        this.state.ship_Country = value.ship_CountryName;
        this.state.ship_State = value.ship_StateName;
        this.state.ship_City = value.ship_CityName;
        this.setState({
          eventBlock: true,
        });
        break;
      case "TransportModeId":
        if (value == null) {
          IsValid = false;
          errors.TransportModeId = "Please select Transport Mode.";
        } else {
          errors.TransportModeId = null;
        }
        break;
      default:
        break;
    }
    return IsValid;
  }

  // reset form text value
  formReset() {
    this.setState({
      ReferenceID: "",
      UserName: "",
      FirstName: "",
      LastName: "",
      Email: "",
      MobileNo: "",
      Password: "",
      ConfirmPassword: "",
      TransportModeId: null,
      LR: "",
      IsActive: true,
    });
    let errors = this.state.formError;
    Object.entries(errors).map(([key, value]) => {
      errors[key] = "";
    });
  }

  // open Dialog
  openDialog() {
    this.setState({ visible: true });
    this.formReset();
  }

  // submit packing
  submitForm(event) {
    ;
    event.preventDefault();
    //if SendTo length is 0
    if (this.state.SendTo.length == 0) {
      this.refs.Messchild.showWarn("Select Send To");
      return;
    }
    //if scanItems length is 0
    else if (this.state.scanItems.length == 0) {
      this.refs.Messchild.showWarn("Scan First");
      return;
    }
    //if(this.state.CartonId.ca)
    else {
      let formData = {};
      let isfrmvalid = true;
      let checkerror = this.state.formError;

      Object.entries(checkerror).map(([key, value]) => {
        const val = this.state[key];
        let valid = this.validateForm(val, key);
        //form is not valid

        if (!valid) {
          this.setState({ checkerror: isfrmvalid });
          isfrmvalid = false;
        }
      });
      if (isfrmvalid) {
        if (!this.state.warehouseVisible) {
          formData = {
            //createdBy
            WareHouseId: this.state.SendTo.ID,
            SendTo: this.state.SendTo.UserId,
            scanned: this.state.scanItems,
            TransportModeId: this.state.TransportModeId,
            LR: this.state.LR,
          };
        } else {
          formData = {
            SendTo: this.state.SendTo.UserId,
            scanned: this.state.scanItems,
            TransportModeId: this.state.TransportModeId,
            LR: this.state.LR,
          };
        }
        // if old stock is greater than 0
        if (this.state.isold) {
          //open Dialog to add remark
          this.setState({
            remarkVisible: true,
            needtoSaveDispatch: formData,
          });
        } else {
          this.SaveDispatch(formData);
        }
      }
    }
  }

  submitRemark(event) {
    event.preventDefault();
    let formD = { ...this.state.needtoSaveDispatch };
    formD.Remark = this.state.Remark;
    this.SaveDispatch(formD);
  }

  SaveDispatch(formData) {
    this.setState({ scanItemLoader: false });

    setTimeout(() => {
      ;
      this.DispatchService.Save(formData).then((data) => {
        this.setState({ scanItemLoader: true });
        const resdata = data.data;
        //if ResponseCode is 200 success message will be shown else will move to 'else statement'
        if (resdata !== null && resdata.ResponseCode === "200") {
          var options = {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
          };
          this.refs.Messchild.showSuccess(resdata.ResponseMessage);
          this.PrintDispatchService.Printpdf(
            resdata.ResponseData,
            new Date().toLocaleDateString("en-US", options)
          );
          this.state.scanItems = [];
          this.setState({
            scanItems: [],
            scanDisp: [],
            SendTo: [],
            //KREP-55
            remarkVisible: false,
            needtoSaveDispatch: "",
            radio: false,
            TransportModeId: null,
            LR: "",
          });
          this.GetDispatch();
        } else if (resdata.ResponseCode === "400") {
          this.refs.Messchild.showWarn(resdata.ResponseMessage);
        } else {
          this.refs.Messchild.showError(
            "Seems Scanned Items already Dispatched"
          );
          this.formReset();
          this.GetDispatch();
          this.GetWareHouse(0);
        }
      });
    }, 100);
  }

  // get active carton drop down list
  GetActiveUsers() {
    this.UserService.GetAllActiveUsers().then((data) => {
      const resdata = data.data;
      //if ResponseCode is 200 UserDDL state will be set to ResponseData
      if (resdata !== null && resdata.ResponseCode === "200") {
        const dataRes = resdata.ResponseData.map((x) => ({
          UserId: x.UserId,
          SAPCustomerCode: x.SAPCustomerCode,
          Username: x.FirstName + " " + x.LastName + " " + x.SAPCustomerCode,
          FirstName: x.FirstName,
          LastName: x.LastName,
          UserType: x.UserType,
          IsFactory: x.IsFactory,
          bill_Address1: x.bill_Address1,
          bill_Address2: x.bill_Address2,
          bill_CountryName: x.bill_CountryName,
          bill_StateName: x.bill_StateName,
          bill_CityName: x.bill_CityName,
        }));

        const Dealer = dataRes.filter((x) => x.UserType == 2);
        this.setState({
          SelectedDDL: Dealer,
          //warehouseVisible: true,
        });
        this.setState({
          UserDDL: dataRes,
        });
      }
    });
  }

  // scan qr code
  ScanItem(e) {
    if (e.key === "Enter") {
      let qr = e.target.value;

      if (this.state.SendTo.length == 0) {
        this.refs.Messchild.showWarn("Select Send To");
        return;
      } else if (qr.includes("TID") || qr.includes("SID")) {
        this.setCartonDetails(qr, true);
      } else {
        this.refs.Messchild.showWarn("Invalid Scanned");
      }
      this.setState({
        scanTxt: "",
      });
    }
  }

  // set tertiary details in grid

  setCartonDetails(qr, checkItemDate) {
    let id = 1;
    const scanlist = this.state.scanItems;
    this.state.scanItems.forEach((element) => {
      if (element.CartonId == qr) {
        this.refs.Messchild.showWarn("Already Scanned..!");
        id = 2;
        return;
      }
    });

    setTimeout(() => {
      if (id == 1) {
        this.DispatchService.GetTertiaryPackingScan(
          qr,
          checkItemDate,
          scanlist
        ).then((data) => {
          const resdata = data.data;
          //if ResponseCode is 200 else will move to 'else statement'
          if (resdata !== null && resdata.ResponseCode === "200") {
            //disable

            const options = { dateStyle: "short" };
            resdata.ResponseData.forEach((element) => {
              element.MFGDate =
                element.MFGDate != null || undefined
                  ? new Date(element.MFGDate).toLocaleString("en-GB", options)
                  : element.MFGDate;
              element.EXPDate =
                element.EXPDate != null || undefined
                  ? new Date(element.EXPDate).toLocaleString("en-GB", options)
                  : element.EXPDate;

              if (this.state.scanItems.length > 0) {
                let check = this.state.scanItems.filter(
                  (y) => y.Id == element.Id
                );

                if (check.length > 0) {
                  //   this.refs.Messchild.showWarn("already Scanned");
                }
              }

              this.state.scanItems.push(element);

              if (this.state.scanDisp.length > 0) {
                let checkseqNo = this.state.scanDisp.filter(
                  (y) => y.CartonId == element.CartonId
                );

                if (checkseqNo.length > 0) {
                  return;
                }
              }
              this.state.scanDisp.push(element);

              this.setState({
                scanItems: this.state.scanItems,
                scanTxt: "",
                radio: true,
              });
            });
          } else if (resdata !== null && resdata.ResponseCode === "500") {
            let checkseqNo = [];
            resdata.ResponseData.forEach((element) => {
              if (this.state.scanItems.length > 0) {
                let chk = this.state.scanItems.filter(
                  (y) => y.CartonId == element.CartonId
                );
                if (chk.length != 0) {
                  checkseqNo.push(chk);
                }
                if (checkseqNo.length == resdata.ResponseData.length) {
                  this.setCartonDetails(qr, true);
                  return;
                }
              }
            });
            /// will show list and input yes or no
            ///this.refs.Messchild.showWarn(resdata.ResponseMessage);
            // if no
            //resolved KREP-132,KREP-121(CR)
            if (
              checkseqNo == undefined ||
              checkseqNo.length != resdata.ResponseData.length
            ) {
              confirmAlert({
                title: resdata.ResponseMessage,
                message:
                  "Are you sure you want to continue scanning the latest stock.",
                buttons: [
                  {
                    label: "Yes",
                    onClick: () => {
                      this.setCartonDetails(qr, false);
                      this.setState({ isold: true });
                    },
                  },
                  {
                    label: "No",
                    onClick: () => {
                      let olddata = [];
                      resdata.ResponseData.forEach((e1) => {
                        let scanned;
                        this.state.scanItems.forEach((e2) => {
                          if (e1.CartonId == e2.CartonId) {
                            scanned = 1;
                          }
                        });
                        if (scanned == undefined) {
                          e1.EXPDate =
                            e1.EXPDate != null || undefined
                              ? e1.EXPDate.substring(0, 10)
                              : e1.EXPDate;

                          olddata.push(e1);
                        }
                      });
                      this.setState({
                        olderstockVisible: true,

                        olderdata: olddata,
                      });
                    },
                  },
                ],
              });
            }
          } else {
            this.refs.Messchild.showWarn(resdata.ResponseMessage);
          }
        });
      }
    }, 50);
  }
  // delete user data
  deleteScanned(TertiaryCartonId) {
    let arrayfilter = this.state.scanItems.filter(
      (x) => x.CartonId != TertiaryCartonId
    );

    this.setState({
      scanItems: arrayfilter,
    });
    let array = this.state.scanDisp.filter(
      (x) => x.CartonId != TertiaryCartonId
    );

    this.setState({
      scanDisp: array,
    });
  }

  GetWareHouse(compId) {
    this.WareHouseMaterService.GetWareHouseDDL(compId).then((data) => {
      const resdata = data.data;
      //if ResponseData is not null
      if (
        resdata !== null &&
        resdata.ResponseData !== null &&
        resdata.ResponseData.length > 0
      ) {
        const filteredWareHouse = resdata.ResponseData.filter(
          (x) => x.ID != this.ApiService.getWareHouseId()
        );

        this.setState({
          wareHouseDDL: filteredWareHouse,
        });
      }
    });
  }

  // seach table
  searchFilter(e) {
    this.setState({
      loading: true,
    });
    this.tblResponse.SearchValue = e.target.value;
    this.GetDispatch();
  }

  callbackFunction = (childData) => {
    this.setState({ message: childData });
  };

  onValueChange(event) {
    const val = event.target.value;

    if (val == "Dealer") {
      const Dealer = this.state.UserDDL.filter((x) => x.UserType == 1);
      this.setState({
        SelectedDDL: Dealer,
        warehouseVisible: true,
      });
    }
    // if (val == "Distributor") {
    //   const Distributor = this.state.UserDDL.filter((x) => x.UserType == 2);

    //   this.setState({
    //     SelectedDDL: Distributor,
    //     warehouseVisible: true,
    //   });
    // }
    if (val == "WareHouse") {
      const wareHouse = this.state.WareHouseFactoryDDL.filter(
        (x) => x.IsFactory == 0
      );
      this.setState({
        wareHouseDDL: wareHouse,
      });

      this.setState({
        warehouseVisible: false,
      });
    }

    if (val == "Factory") {
      const fact = this.state.WareHouseFactoryDDL.filter(
        (x) => x.IsFactory == 1
      );
      this.setState({
        wareHouseDDL: fact,
        warehouseVisible: false,
      });
    }
  }

  // disabled checkbox
  activeTemplate(rowData, column) {
    return <input type="checkbox" disabled checked={rowData.IsActive} />;
  }

  // Sort table
  onSort(event) {
    this.setState({
      sortOrder: this.state.sortOrder === 1 ? 2 : 1,
      sortIcon: !this.state.sortIcon,
    });
    const sortField =
      event.sortField == "CreatedDt" ? "CreatedDt" : event.sortField;
    this.tblResponse.SortColumn = sortField ? sortField : "";
    this.tblResponse.SortOrder = this.state.sortOrder === 1 ? "DESC" : "ASC";
    this.GetDispatch();
    const clickData = document.getElementsByClassName(
      "p-sortable-column-icon pi pi-fw pi-sort"
    );
    console.log(event);
  }

  // edit form
  actionTemplate(rowData, column) {
    const deleteFunc = () => {
      confirmAlert({
        title: "Confirm to delete",
        message: "Are you sure to do this.",
        buttons: [
          {
            label: "Yes",
            onClick: () => this.deleteScanned(rowData.CartonId),
          },
          {
            label: "No",
            onClick: () => null,
          },
        ],
      });
    };

    return (
      <span>
        <Button
          type="button"
          icon="pi pi-trash"
          onClick={deleteFunc}
          className="p-button-danger"
        ></Button>
      </span>
    );
  }

  // create html part
  render() {
    const items = [{ label: "Transaction" }, { label: "Dispatch" }];

    const home = { icon: "pi pi-home", url: "/dashboard" };
    let header = (
      <div className="row">
        <div className="col-md-8 text-left"></div>
        <div className="col-md-4 text-right">
          <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>
          <InputText
            type="search"
            onInput={(e) => this.setState({ globalFilter: e.target.value })}
            placeholder="Global Search"
            size="30"
          />
        </div>
      </div>
    );

    let header2 = (
      <div className="row">
        <div className="col-md-4 text-left">
          <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>
          <InputText
            type="search"
            onInput={this.searchFilter}
            placeholder="Global Search"
            size="30"
          />
        </div>
      </div>
    );

    return (
      <>
        <MessageComponent ref="Messchild" />
        <ConfirmationModal parentCallback={this.callbackFunction} ref="child" />
        <Card>
          <div className="row mb-3">
            <div className="col-md-6 heading">
              <h1>Dispatch</h1>
            </div>
            <div className="col-md-6 custom-breadcrumb">
              <BreadCrumb model={items} home={home} />
            </div>
          </div>
          <div>
            <form className="form-inline dispatch-label row mb-3">
              <div
                className="radio col-md-4"
                style={this.state.radio ? { pointerEvents: "none" } : {}}
              >
                {/* <input type="radio" value="Factory" name="ddlType" className="mr-2" onChange={this.onValueChange} />
                Factory */}
                <input
                  type="radio"
                  value="WareHouse"
                  name="ddlType"
                  defaultChecked
                  className="mr-2 ml-3"
                  onChange={this.onValueChange}
                />
                WareHouse
                {/* <input
                  type="radio"
                  value="Distributor"
                  className="mr-2 ml-3"
                  name="ddlType"
                  onChange={this.onValueChange}
                />
                Distributor */}
                <input
                  type="radio"
                  value="Dealer"
                  className="mr-2 ml-3"
                  name="ddlType"
                  onChange={this.onValueChange}
                />
                Dealer
              </div>
              <div className="form-group col-md-4">
                <label className="ml-2 mr-2">Send To:</label>
                <div
                  hidden={!this.state.warehouseVisible}
                  className="custom-drop"
                  style={this.state.radio ? { pointerEvents: "none" } : {}}
                >
                  <Dropdown
                    filter
                    style={this.state.radio ? { pointerEvents: "none" } : {}}
                    optionLabel="Username"
                    onChange={this.handleChange}
                    value={this.state.SendTo}
                    className="form-control"
                    placeholder="Select User"
                    name="SendTo"
                    options={this.state.SelectedDDL}
                  />
                  {this.state.formError.SendTo !== null ? (
                    <div className="error">{this.state.formError.SendTo}</div>
                  ) : null}
                </div>

                <div
                  hidden={this.state.warehouseVisible}
                  className="custom-drop"
                >
                  <Dropdown
                    filter
                    style={this.state.radio ? { pointerEvents: "none" } : {}}
                    optionLabel="FirmName"
                    onChange={this.handleChange}
                    value={this.state.SendTo}
                    className="form-control"
                    placeholder="-Select-"
                    name="SendTo"
                    options={this.state.wareHouseDDL}
                  />
                  {this.state.formError.SendTo !== null ? (
                    <div className="error">{this.state.formError.SendTo}</div>
                  ) : null}
                </div>
              </div>
              {/* For Scan Item */}
              <div className="form-group col-md-4">
                <label>Scan items:</label>
                <input
                  onChange={(e) => {
                    this.handleChange(e);
                  }}
                  name="scanTxt"
                  value={this.state.scanTxt}
                  type="search"
                  className="form-control ml-2"
                  id="scanTxt"
                  onKeyDown={(e) => {
                    this.ScanItem(e);
                  }}
                />
              </div>
              <label className="ml-5 mr-2">Transport Mode:</label>
              <div>
                <Dropdown
                  filter
                  options={this.state.ModeDDL}
                  onChange={this.handleChange}
                  value={this.state.TransportModeId}
                  className="form-control"
                  style={{ width: "280px" }}
                  placeholder={
                    this.state.Mode ? this.state.Mode : "Select Transport Mode"
                  }
                  name="TransportModeId"
                />
                {this.state.formError.TransportModeId !== null ? (
                  <div className="error">
                    {this.state.formError.TransportModeId}
                  </div>
                ) : null}
              </div>
              <div className="form-group col-md-4 ml-5">
                <label>LR No:</label>
                <input
                  type="text"
                  name="LR"
                  // style={{ width: "calc(100% - 90px)" }}
                  id="LR"
                  className="form-control ml-2"
                  value={this.state.LR}
                  onChange={(e) => {
                    this.handleChange(e);
                  }}
                />
                {this.state.formError.LR !== null ? (
                  <div className="error">{this.state.formError.LR}</div>
                ) : null}
              </div>
            </form>
            <div
              className="address-block"
              style={{ display: this.state.eventBlock ? "block" : "none" }}
            >
              <h4>Billing Address</h4>
              <div className="row dispatch-label">
                <div className="col-md-3">
                  <label>
                    <strong>Address 1 : </strong>
                  </label>

                  {this.state.bill_Address1}
                </div>
                <div className="col-md-3">
                  <label>
                    <strong>Address 2 : </strong>
                  </label>

                  {this.state.bill_Address2}
                </div>
                <div className="col-md-3">
                  <label>
                    <strong>PinCode : </strong>
                  </label>

                  {this.state.bill_Pincode}
                </div>
                <div className="col-md-3">
                  <label>
                    <strong>City : </strong>
                  </label>

                  {this.state.bill_City}
                </div>
                <div className="col-md-3">
                  <label>
                    <strong>State : </strong>
                  </label>

                  {this.state.bill_State}
                </div>
                <div className="col-md-3">
                  <label>
                    <strong>Country : </strong>
                  </label>

                  {this.state.bill_Country}
                </div>
              </div>
              {/* <h4 className="mt-3">Shipping address</h4>
              <div className="row dispatch-label mb-3">
                <div className="col-md-3">
                  <label><strong>Address 1 : </strong></label>

                  {this.state.ship_Address1}
                </div>
                <div className="col-md-3">
                  <label><strong>Address 2 : </strong></label>

                  {this.state.ship_Address2}
                </div>
                <div className="col-md-3">
                  <label><strong>PinCode : </strong></label>

                  {this.state.ship_Pincode}
                </div>
                <div className="col-md-3">
                  <label><strong>City : </strong></label>

                  {this.state.ship_City}
                </div>
                <div className="col-md-3">
                  <label><strong>State : </strong></label>

                  {this.state.ship_State}
                </div>
                <div className="col-md-3">
                  <label><strong>Country : </strong></label>

                  {this.state.ship_Country}
                </div>
              </div> */}
            </div>
          </div>
          <Loaders loader={this.state.scanItemLoader} />
          <DataTable
            responsive={true}
            header={header}
            emptyMessage={this.state.emptyMessage}
            value={this.state.scanDisp}
            paginator={true}
            currentPageReportTemplate="Total Items Added: {totalRecords}, Showing {first} to {last}"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            sortMode="multiple"
            totalRecords={this.state.scanDisp.length}
            rowsPerPageOptions={[1, 10, 50, 200, 500]}
            rows={this.state.rows}
            className="tblAuto"
          >
            <Column field="CartonId" header="Carton Id" />
            <Column field="ProductName" header="Product Name" />
            <Column field="BatchNo" header="Batch No." />
            <Column field="CartonName" header="Carton Name" />
            {/* <Column field="Qty" header="Total Product Qty" /> */}
            <Column field="MFGDate" header="MFG Date" />
            <Column field="EXPDate" header="Expire Date" />
            <Column body={this.actionTemplate} header="Action" />
          </DataTable>

          <div className="text-center">
            <button className="btn btn-primary mt-4" onClick={this.submitForm}>
              Submit
            </button>
          </div>
        </Card>
        <Card className="mt-4">
          <div>
            <Loaders loader={this.state.cartoonLoader} classType="small" />
            <DataTable
              responsive={true}
              header={header2}
              globalFilter={this.state.globalFilter}
              emptyMessage={this.state.emptyMessage}
              value={this.state.sales}
              sortMode="multiple"
              paginator={true}
              rows={this.state.rows}
              rowsPerPageOptions={[10, 50, 200, 500]}
              totalRecords={this.state.totalRecords}
              lazy={true}
              first={this.state.first}
              onPage={this.onPage}
              onSort={this.onSort}
              className="tblAuto"
            >
              <Column field="DispatchId" header="Dispatch Id" sortable={true} />

              <Column
                field="TotalCarton"
                header="Total Carton"
                sortable={true}
              />
              <Column field="CreatedDt" header="Created Date" sortable={true} />
              <Column field="userName" header="Sent" sortable={true} />
            </DataTable>
          </div>
        </Card>
        <Dialog
          className="popup-modal"
          style={{ width: "30%" }}
          header="Add Approval remarks"
          visible={this.state.remarkVisible}
          modal={true}
          onHide={this.hideFunc}
        >
          <form onSubmit={this.submitRemark}>
            <div className="row">
              <div className="col-md-12">
                <label>
                  Remark<span>*</span>:
                </label>
                <input
                  type="text"
                  style={{ width: "95%", height: "30px" }}
                  value={this.state.Remark}
                  onChange={this.handleChange}
                  className="form-control"
                  name="Remark"
                />
              </div>
            </div>
            <div className="col-md-12 text-center mt-4">
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
          </form>
        </Dialog>
        <Dialog
          className="popup-modal"
          style={{ width: "60%" }}
          header="Older Stock List"
          visible={this.state.olderstockVisible}
          modal={true}
          onHide={this.hideFunc}
        >
          <DataTable
            responsive={true}
            emptyMessage={this.state.emptyMessage}
            value={this.state.olderdata}
            sortMode="multiple"
            rowsPerPageOptions={[10, 50, 200, 500]}
            className="tblAuto"
          >
            <Column field="CartonId" header="Carton Id" />
            <Column field="Qty" header="Quantity" />
            <Column field="EXPDate" header="Expire Date" />
          </DataTable>
        </Dialog>
      </>
    );
  }
}
