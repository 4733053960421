import instance from './DefaultApi';

export default class CompanyMasterService {
    ControllerName = 'CompanyMaster';

    //Get Company List
    GetCompany(tableRequest) {
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetCompany`, tableRequest).then(res => res)
    }

    // Save Company Master Data
    CreateCompany(CartonData) {
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/CreateCompany`, CartonData).then(res => res)
    }

    //Get all company for ddl
    GetCompanyDDL() {
        return instance.get(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetCompanyDDL`).then(res => res);
    }




}