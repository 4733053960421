import React, { Component } from 'react';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { BreadCrumb } from 'primereact/breadcrumb';
import $ from "jquery";
import RedemptionReportService from '../../services/RedemptionReportService';
import MessageComponent from '../../template/Message';
import tableRequest from '../../models/tableRequest';
import ApiService from '../../services/apiService';
import Loaders from '../../template/Loaders';
// TO Generate NUmber
export default class LoyaltyRedeemRequests extends Component {
    constructor(props) {
        
        super(props);
        this.RedemptionReportService = new RedemptionReportService();
        this.ApiService = new ApiService();
        this.tblResponse = new tableRequest();
        this.state = {
            visible: false,
            Tvisible: false,
            rowD:[],
            Remark:'',
            updateLoader: false,
            sortOrder: '',
            FromNo: '',
            ToNo: '',
            Qty: '',
            formError: {
                FromNo: null,
                ToNo: null,
                Qty: null,

            },
            prefix: '',
            downloadLink: '',
            requestsData: [],
            acceptedData: [],
            loading: true,
            first: 0,
            rows: 10,
            totalRecords: 0,
            globalFilter: null,
            emptyMessage: 'No Data found'
        }

        this.actionTemplate = this.actionTemplate.bind(this);
        this.handleChnage = this.handleChnage.bind(this);
        this.onPage = this.onPage.bind(this);
        this.searchFilter = this.searchFilter.bind(this);
        this.onSort = this.onSort.bind(this);
    }
    //trigger api function on page call
    componentDidMount() {
        setTimeout(() => {
            this.setState({
                prefix: this.ApiService.getFactoryPrefix()
            });
            this.GetLoyaltyRequests();
            setTimeout(() => {
                this.GetAcceptedRequests();
            }, 2000);
        }, 1000);
        $(".p-column-title").on("click", function () {
            $(this)
                .next()
                .removeClass("pi-sort")
                .toggleClass("pi-sort-up")
                .addClass("pi-sort-down");
        });
    }

    // call page event
    onPage(event) {
        this.setState({
            loading: true
        });

        //imitate delay of a backend call
        setTimeout(() => {

            this.getLazyLoadingValues(event);
            this.GetLoyaltyRequests();
            setTimeout(() => {
                this.GetAcceptedRequests();
            }, 2000);
        }, 250);
    }

    // get product issue number list data
    GetLoyaltyRequests() {
        
        this.tblResponse.SortColumn = 'RequestDate';
        this.tblResponse.IsAccepted = 0;
        this.RedemptionReportService.GetLoyaltyRequests(this.tblResponse).then(data => {
            const resdata = data.data;
            
            //if response data is not null
            if (resdata !== null && resdata.ResponseData !== null && resdata.ResponseData.length > 0) {
                this.datasource = resdata.ResponseData;
                this.setState({
                    totalRecords: resdata.ResponseData[0].TotalRows,
                    first: (this.tblResponse.First - 1),
                    requestsData: this.datasource.slice(0, ((this.tblResponse.First - 1) + this.tblResponse.PageSize)),
                    loading: false,
                    updateLoader: true

                });
            }
            ///if response data is null
            else {
                this.datasource = null;
                this.setState({
                    totalRecords: 0,
                    first: 1,
                    requestsData: null,
                    loading: false, updateLoader: true
                });
            }
        });
    }

    // To get accepted loyalty requests
    GetAcceptedRequests() {

        this.tblResponse.SortColumn = 'RequestDate';
        this.tblResponse.IsAccepted = 1;
        this.RedemptionReportService.GetLoyaltyRequests(this.tblResponse).then(data => {
            const resdata = data.data;
            
            //if response data is not null
            if (resdata !== null && resdata.ResponseData !== null && resdata.ResponseData.length > 0) {
                this.datasource = resdata.ResponseData;
                this.setState({
                    totalRecords: resdata.ResponseData[0].TotalRows,
                    first: (this.tblResponse.First - 1),
                    acceptedData: this.datasource.slice(0, ((this.tblResponse.First - 1) + this.tblResponse.PageSize)),
                    loading: false,
                    updateLoader: true
                });
            }
            ///if response data is null
            else {
                this.datasource = null;
                this.setState({
                    totalRecords: 0,
                    first: 1,
                    acceptedData: null,
                    loading: false, updateLoader: true
                });
            }
        });
    }
    
    acceptRequest(){
        
        this.RedemptionReportService.AcceptPointsRequest(this.state.rowD.Id, this.state.Remark).then(data => {
            
            const resdata = data.data
            if (resdata.ResponseCode === '200'){
                
                this.refs.child.showSuccess(resdata.ResponseMessage);
                this.setState({
                    loading: true,
                    visible: false,
                    Tvisible: false,
                    Remark:''
                });
                this.GetLoyaltyRequests();
                setTimeout(() => {
                    this.GetAcceptedRequests();
                }, 2000);
            } else if (resdata.ResponseCode === '400') {
                this.refs.child.showWarn(resdata.ResponseMessage);
            } else {
                this.refs.child.showError(resdata.ResponseMessage);
            }

        });
    }

    actionTemplate(rowData, column) {
        return <span>
            <Button type="button" label="Accept" onClick={() => {this.setState({Tvisible:true, rowD:rowData})}} className="p-button-warning" style={{ marginRight: '3px' }}></Button>
        </span>;
    }


    //validate form
    //resolved KREP-17

    // call when input data on form
    handleChnage(event) {
        const { name, value } = event.target;
        let errors = this.state.formError;

        this.setState({ errors, [name]: value });
    }



    // submit form to api

    ś   
    // Get Next fromNO


    // set Lazy Loading Values
    getLazyLoadingValues(event) {
        this.setState({ rows: event.rows });
        this.tblResponse.PageSize = event.rows;
        this.tblResponse.First = event.first + 1;
        this.tblResponse.IsActive = '1';
        this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
        this.tblResponse.SortOrder = event.sortOrder === 1 ? 'ASC' : 'DESC';
    }

    // search Filter table 
    searchFilter(e) {
        this.setState({
            loading: true
        });
        this.tblResponse.SearchValue = e.target.value;
        this.GetLoyaltyRequests();
    }

    // Sort table
    onSort(event) {
        this.setState({ sortOrder: this.state.sortOrder === 1 ? 2 : 1 });
        const sortField = event.sortField == "CreatedDt" ? "CreatedDt" : event.sortField;
        this.tblResponse.SortColumn = sortField ? sortField : "";
        this.tblResponse.SortOrder = this.state.sortOrder === 1 ? 'DESC' : 'ASC';
        this.GetLoyaltyRequests();
    }

    // Render html
    //resolved KREP-19
    render() {
        
        const TabView=(
           
            <Dialog className="popup-modal py-3 text-center" style={{ width: '30%' }} header="Enter Remark(If any)" visible={this.state.Tvisible} modal={true} 
            onHide={()=>{this.setState({Tvisible:false}); 
            // this.acceptRequest();
             }}>
                <div className='py-3 text-center' > 
                <input
                type="text"
                value={this.state.Remark}
                name="Remark"
                onChange={(e)=>{this.setState({Remark:e.target.value})} }
                onKeyDown={(e)=> e.key =='Enter' && this.acceptRequest()} autoFocus/>
                </div>
            </Dialog>
        );

        const items = [
            { label: 'Transaction ' },
            { label: 'Redemption Requests' }
        ];

        const home = { icon: 'pi pi-home', url: '/dashboard' }
        let header = (
            <div style={{ 'textAlign': 'right' }}>
                <i className="pi pi-search" style={{ margin: '4px 4px 0 0' }}></i>
                <InputText type="search" onInput={this.searchFilter} placeholder="Global Search" size="30" />
            </div>
        );
        return (
            <>
                <MessageComponent ref="child" />
                <div className="row mb-3">
                    <div className="col-md-6 heading">
                        <h1>Loyalty Points Redemption Requests</h1>
                    </div>
                    <div className="col-md-6 custom-breadcrumb">
                        <BreadCrumb model={items} home={home} />
                    </div>
                </div>
                {<Loaders loader={this.state.updateLoader} />}


                <DataTable responsive={true} header={header} globalFilter={this.state.globalFilter}
                    emptyMessage={this.state.emptyMessage} value={this.state.requestsData} sortMode="multiple" paginator={true}
                    rows={this.state.rows} rowsPerPageOptions={[10, 50, 200,500]} totalRecords={this.state.totalRecords} lazy={true} first={this.state.first}
                    onPage={this.onPage} onSort={this.onSort}>
                    <Column field="UserName" header="Customer Name" sortable={true} />
                    <Column field="MobileNo" header="Mobile No." sortable={true} />
                    <Column field="Point" header="Points" sortable={true} />
                    <Column field="CreatedDt" header="Request Date" sortable={true} />
                    <Column body={this.actionTemplate} header="Action" />
                </DataTable>

                
                <div className="heading mt-5">
                    <h1>Accepted Requests</h1>
                </div>
                
                <DataTable responsive={true} globalFilter={this.state.globalFilter}
                    emptyMessage={this.state.emptyMessage} value={this.state.acceptedData} sortMode="multiple" paginator={true}
                    rows={this.state.rows} rowsPerPageOptions={[10, 50, 200,500]} totalRecords={this.state.totalRecords} lazy={true} first={this.state.first}
                    onPage={this.onPage} onSort={this.onSort}>
                    <Column field="UserName" header="Customer Name" sortable={true} />
                    <Column field="MobileNo" header="Mobile No." sortable={true} />
                    <Column field="Point" header="Points" sortable={true} />
                    <Column field="Remark" header="Remark" sortable={true} />
                    <Column field="CreatedDt" header="Request Date" sortable={true} />
                    <Column field="ApprovedDate" header="Approved Date" sortable={true} />
                </DataTable>

                {TabView}
            </>
        )
    }
}