import React, { Component } from "react";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";

import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { FileUpload } from "primereact/fileupload";
import { Dropdown } from "primereact/dropdown";
import { ConfirmationModal } from "../../template/ConfirmationModal";
import ProductService from "../../services/ProductService";
import tableRequest from "../../models/tableRequest";
import CategoryService from "../../services/CategoryService";
import MessageComponent from "../../template/Message";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import $ from "jquery";
import CompanyMasterService from "../../services/CompanyMasterService";
// import {Steps} from 'primereact/steps';
// import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
// import "react-tabs/style/react-tabs.css";
import { TabView, TabPanel } from "primereact/tabview";
import ApiService from "../../services/apiService";
import Loaders from "../../template/Loaders";
import { CSVLink } from "react-csv";
// export default () => (
//   <Tabs>
//     <TabList>
//       <Tab>Title 1</Tab>
//       <Tab>Title 2</Tab>
//     </TabList>

//     <TabPanel>
//       <h2>Any content 1</h2>
//     </TabPanel>
//     <TabPanel>
//       <h2>Any content 2</h2>
//     </TabPanel>
//   </Tabs>
// );

// Product Master
var allData = [];
export default class ProductMaster extends Component {
  constructor(props) {
    super(props);
    this.ApiService = new ApiService();
    this.CompanyMasterService = new CompanyMasterService();
    this.ProductService = new ProductService();
    this.CategoryService = new CategoryService();
    this.tblResponse = new tableRequest();
    this.tblResponseExport = new tableRequest();
    let today = new Date();

    this.invalidDates = [today];
    this.state = {
      heads: [
        "ProductName",
        "ProductCd",
        "TechnicalName",
        "ProductDscrptn",
        "ProductUOM",
        "ProductValue",
        //"cartonSize",
        "CategoryName",
        // "NameMG2",
        // "NameMG3",
        "CreatedDt",
      ],
      exportData: [],
      visible: false,
      mrp: 0,
      prodDes: true,
      updateLoader: false,
      formIsValid: true,
      CompId: "",
      ProductId: "",
      ProductName: "",
      ProductCd: "",
      ProductDsrcptn: "",
      ProductImg: "",
      GenuineAudio: "",
      ProductUOM: "",
      NameMG2: "",
      NameMG3: "",
      activeIndex: 0,
      ProductValue: "",
      CategoryId: null,
      MG2Id: null,
      MG3Id: null,
      IsActive: false,
      ontime: false,
      sortOrder: "",
      items: "",
      Product: "",
      ManufactureName: "",
      RegistrationNo: "",
      CautionarySymbol: "",
      AntidoteStatement: "",
      MarketedBy: "",
      ExpDate: "",
      GTIN: null,
      // CartonSize:null,
      CustomerCareDetails: "",
      WarrantyPeriodInMonth: 0,
      formError: {
        ProductName: null,
        Product: null,
        mrp: null,
        GTIN: null,
        //CartonSize:null,
        NameMG2: "",
        NameMG3: "",
        ProductCd: null,
        ProductDsrcptn: null,
        CategoryId: null,
        MG2Id: null,
        MG3Id: null,
        ExpDate: null,
        // SubCategoryId: null,
        ProductImg: null,
        GenuineAudio: null,
        ProductUOM: null,
        ProductValue: null,
        WarrantyPeriodInMonth: null,
        uploadImageLabel: null,
        uploadImagePamphlet: null,
        TechnicalName: null,
        SapProductCd: null,
      },
      uploadImagPath: "",
      uploadImagDescPath: "",
      uploadImagDescAntiPath: "",
      uploadAudioPath: "",
      uploadImageLabel: "",
      uploadImagePamphlet: "",
      SapProductCd: "",
      TechnicalName: "",
      confirm: false,
      formHeading: "Add Product",
      buttonLabel: "Submit",

      sales: [],
      companyDDL: [],
      loading: true,
      first: 0,
      rows: 5,
      totalRecords: 0,
      globalFilter: null,
      emptyMessage: "No Data found",
      CategoryDDL: [],
      MG2DDL: [],
      MG3DDL: [],
      compDisabled: false,
      checkerror: true,
      //  SubCategoryDDL: []
    };

    this.items = [
      {
        label: "Step 1",
        command: (event) => {
          this.activeIndex = 0;
        },
      },
      {
        label: "Step 2",
        command: (event) => {
          this.activeIndex = 1;
        },
      },
      {
        label: "Step 3",
        command: (event) => {
          this.activeIndex = 2;
        },
      },
    ];

    this.brandTemplate = this.brandTemplate.bind(this);

    this.brandDescTemplate = this.brandDescTemplate.bind(this);
    this.brandDescAntiTemplate = this.brandDescAntiTemplate.bind(this);
    this.GetProductExport = this.GetProductExport.bind(this);
    this.actionTemplate = this.actionTemplate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChange1 = this.handleChange1.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.submitDescForm = this.submitDescForm.bind(this);
    this.deleteFunc = this.deleteFunc.bind(this);
    this.setImagePath = this.setImagePath.bind(this);
    this.setImageDescPath = this.setImageDescPath.bind(this);
    this.setImageDescAntiPath = this.setImageDescAntiPath.bind(this);
    this.setLabel = this.setLabel.bind(this);
    this.setPamphlet = this.setPamphlet.bind(this);
    this.hideFunc = this.hideFunc.bind(this);
    this.setAudioPath = this.setAudioPath.bind(this);

    this.onPage = this.onPage.bind(this);
    this.searchFilter = this.searchFilter.bind(this);
    this.openDialog = this.openDialog.bind(this);

    this.onSort = this.onSort.bind(this);
  }
  handleChange1(event) {
    const { name, value } = event.target;
    let errors = this.state.formError;
    this.validateForm1(value, name);
    this.setState({ errors, [name]: value });

    if (name === "IsActive")
      this.setState({
        IsActive: event.target.checked,
      });
  }
  // call when handle
  handleChange(event) {
    const { name, value } = event.target;

    let errors = this.state.formError;

    const isValid = this.validateForm(value, name);
    this.setState({ errors, [name]: value });

    if (!isValid) {
      this.setState({ activeIndex: 0 });
      this.setState({ prodDes: true });
    }
    if (name == "IsActive")
      this.setState({
        IsActive: event.target.checked,
      });
  }

  // get current date
  getCurrentDate(dt) {
    var today = new Date(dt);
    var dd = today.getDate();

    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }

    if (mm < 10) {
      mm = "0" + mm;
    }
    // today = mm + '-' + dd + '-' + yyyy;
    // today = mm + '/' + dd + '/' + yyyy;
    // today = dd + '-' + mm + '-' + yyyy;
    today = mm + "/" + dd + "/" + yyyy;
    return today;
  }

  // use for image
  brandTemplate(rowData, column) {
    var src = rowData.ProductImg;
    return <img src={src} width="100" alt={rowData.ProductImg} />;
  }
  // use for image
  brandDescTemplate(rowData, column) {
    var src = rowData.CautionarySymbol;
    return <img src={src} width="100" alt={rowData.CautionarySymbol} />;
  }
  // use for image
  brandDescAntiTemplate(rowData, column) {
    var src = rowData.AntidoteStatement;
    return <img src={src} width="100" alt={rowData.AntidoteStatement} />;
  }

  // set Image Path
  setImagePath(event) { 
    let errors = this.state.formError;
    errors.ProductImg = "";
    if (event.xhr) {
      if (event.xhr.response) {
        const response = JSON.parse(event.xhr.response);
        if (response) {
          if (response.ResponseCode === "200") {
            this.setState({
              uploadImagPath: response.Source,
            });
          } else if (response.ResponseCode === "400") {
            errors.ProductImg = "Only image format is accepted.";
            this.setState({
              uploadImagPath: "",
            });
          } else {
            // this.messageService.add({ severity: 'error', summary: 'Oops', detail: response.ResponseMessage });
          }
        } else {
          //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
        }
      } else {
        //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
      }
    } else {
      //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
    }
  }

  setImageDescPath(event) {
    let errors = this.state.formError;
    errors.CautionarySymbol = "";
    if (event.xhr) {
      if (event.xhr.response) {
        const response = JSON.parse(event.xhr.response);
        if (response) {
          if (response.ResponseCode === "200") {
            this.setState({
              uploadImagDescPath: response.Source,
            });
          } else if (response.ResponseCode === "400") {
            errors.CautionarySymbol = "Only image format is accepted.";
            this.setState({
              uploadImagDescPath: "",
            });
          } else {
            // this.messageService.add({ severity: 'error', summary: 'Oops', detail: response.ResponseMessage });
          }
        } else {
          //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
        }
      } else {
        //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
      }
    } else {
      //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
    }
  }

  setImageDescAntiPath(event) {
    let errors = this.state.formError;
    errors.AntidoteStatement = "";
    if (event.xhr) {
      if (event.xhr.response) {
        const response = JSON.parse(event.xhr.response);
        if (response) {
          if (response.ResponseCode === "200") {
            this.setState({
              uploadImagDescAntiPath: response.Source,
            });
          } else if (response.ResponseCode === "400") {
            errors.AntidoteStatement = "Only image format is accepted.";
            this.setState({
              uploadImagDescAntiPath: "",
            });
          } else {
            // this.messageService.add({ severity: 'error', summary: 'Oops', detail: response.ResponseMessage });
          }
        } else {
          //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
        }
      } else {
        //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
      }
    } else {
      //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
    }
  }
  // set Audio path
  setAudioPath(event) {
    let errors = this.state.formError;
    errors.GenuineAudio = "";
    if (event.xhr) {
      if (event.xhr.response) {
        const response = JSON.parse(event.xhr.response);
        if (response) {
          if (response.ResponseCode === "200") {
            this.setState({
              uploadAudioPath: response.Source,
            });
          } else if (response.ResponseCode === "400") {
            errors.GenuineAudio = "Only audio files are accepted.";
            this.setState({
              uploadAudioPath: "",
            });
          } else {
            // this.messageService.add({ severity: 'error', summary: 'Oops', detail: response.ResponseMessage });
          }
        } else {
          //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
        }
      } else {
        //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
      }
    } else {
      //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
    }
  }

  // set lable path
  setLabel(event) {
    let errors = this.state.formError;
    errors.uploadImageLabel = "";
    if (event.xhr) {
      if (event.xhr.response) {
        const response = JSON.parse(event.xhr.response);
        if (response) {
          if (response.ResponseCode === "200") {
            this.setState({
              uploadImageLabel: response.Source,
            });
          } else if (response.ResponseCode === "400") {
            errors.uploadImageLabel = "Only image format is accepted.";
            this.setState({
              uploadImageLabel: "",
            });
          } else {
            // this.messageService.add({ severity: 'error', summary: 'Oops', detail: response.ResponseMessage });
          }
        } else {
          //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
        }
      } else {
        //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
      }
    } else {
      //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
    }
  }

  // set pamphlet path
  setPamphlet(event) {
    let errors = this.state.formError;
    errors.uploadImagePamphlet = "";
    if (event.xhr) {
      if (event.xhr.response) {
        const response = JSON.parse(event.xhr.response);
        if (response) {
          if (response.ResponseCode === "200") {
            this.setState({
              uploadImagePamphlet: response.Source,
            });
          } else if (response.ResponseCode === "400") {
            errors.uploadImagePamphlet = "Only image format is accepted.";
            this.setState({
              uploadImagePamphlet: "",
            });
          } else {
            // this.messageService.add({ severity: 'error', summary: 'Oops', detail: response.ResponseMessage });
          }
        } else {
          //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
        }
      } else {
        //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
      }
    } else {
      //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
    }
  }

  // delete
  deleteFunc() {
    this.refs.child.confirmModal();
  }

  onErrorImage() {}

  onErrorAudio() {}

  //  call back Function
  callbackFunction = (childData) => {
    this.setState({ message: childData });
  };

  componentDidMount() {
    setTimeout(() => {
      this.GetCompany();
      this.GetCategoryDDL();
      this.GetProducts();
    }, 1000);
    $(".p-column-title").on("click", function () {
      $(this)
        .next()
        .removeClass("pi-sort")
        .toggleClass("pi-sort-up")
        .addClass("pi-sort-down");
    });

    this.tblResponseExport.PageSize = -1;
    this.tblResponseExport.First = 1;
    this.tblResponseExport.IsActive = true;
    this.tblResponseExport.SearchValue = "";
    this.tblResponseExport.SortOrder = "ASC";
    this.GetProductExport();
  }

  // get product data list from api
  GetProductExport() {
    this.tblResponseExport.SortColumn = "CreatedDate";
    this.ProductService.GetProducts(this.tblResponseExport).then((data) => {
      const resdata = data.data;
      //when response data is not null
      if (
        resdata !== null &&
        resdata.ResponseData !== null &&
        resdata.ResponseData.length > 0
      ) {
        this.setState({
          exportData: resdata.ResponseData,
        });
      }
    });
  }

  // initialize page
  onPage(event) {
    this.setState({
      loading: true,
    });

    //imitate delay of a backend call
    setTimeout(() => {
      this.getLazyLoadingValues(event);
      this.GetProducts();
    }, 100);
  }

  // get list of data
  getLazyLoadingValues(event) {
    this.setState({ rows: event.rows });
    this.tblResponse.PageSize = event.rows;
    this.tblResponse.First = event.first + 1;
    this.tblResponse.IsActive = true;
    this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
    //this.tblResponse.SortColumn = event.sortField;
    this.tblResponse.SortOrder = event.sortOrder === 1 ? "ASC" : "DESC";
  }

  // Get Category Drop Down list
  GetCategoryDDL() {
    this.CategoryService.GetCategoryDDLMG1().then((data) => {
      const resdata = data.data;
      if (resdata !== null && resdata.ResponseCode === "200") {
        this.setState({
          CategoryDDL: resdata.ResponseData == null ? [] : resdata.ResponseData,
        });
      }
    });
    this.CategoryService.GetCategoryDDLMG2(1).then((data) => {
      const resdata = data.data;
      if (resdata !== null && resdata.ResponseCode === "200") {
        this.setState({
          MG2DDL: resdata.ResponseData == null ? [] : resdata.ResponseData,
        });
      }
    });

    this.CategoryService.GetCategoryDDLMG3(1).then((data) => {
      const resdata = data.data;
      if (resdata !== null && resdata.ResponseCode === "200") {
        this.setState({
          MG3DDL: resdata.ResponseData == null ? [] : resdata.ResponseData,
        });
      }
    });
  }

  // Get Sub Category Drop Down List
  // GetSubCategoryDDL(categoryId) {
  //   this.CategoryService.GetSubCategoryDDLMG1(categoryId).then((data) => {
  //     const resdata = data.data;
  //     if (resdata !== null && resdata.ResponseCode === "200") {
  //       this.setState({
  //         SubCategoryDDL: resdata.ResponseData,
  //       });
  //     }
  //   });
  // }

  GetCompany() {
    this.CompanyMasterService.GetCompanyDDL().then((data) => {
      const resdata = data.data;
      if (
        resdata !== null &&
        resdata.ResponseData !== null &&
        resdata.ResponseData.length > 0
      ) {
        this.setState({
          companyDDL: resdata.ResponseData,
        });

        if (this.ApiService.getIsAdmin() == "false") {
          const CompanyObj = this.state.companyDDL.filter(
            (x) => x.CompId == this.ApiService.getCompanyId()
          );

          this.setState({
            CompId: CompanyObj[0],
            compDisabled: true,
          });
        }
      }
    });
  }

  // Get Products list
  GetProducts() {
    this.tblResponse.SortColumn = "CreatedDt";

    this.ProductService.GetProducts(this.tblResponse).then((data) => {
      const resdata = data.data;
      this.setState({ updateLoader: true });
      if (
        resdata !== null &&
        resdata.ResponseData !== null &&
        resdata.ResponseData.length > 0
      ) {
        this.datasource = resdata.ResponseData;
        this.setState({
          totalRecords: resdata.ResponseData[0].TotalRows,
          first: this.tblResponse.First - 1,
          sales: this.datasource.slice(
            0,
            this.tblResponse.First - 1 + this.tblResponse.PageSize
          ),
          loading: false,
        });
      } else {
        this.datasource = null;
        this.setState({
          totalRecords: 0,
          first: 1,
          sales: null,
          loading: false,
        });
      }
    });
  }
  validateForm1(value, name) {
    let errors = this.state.formError;
    let IsValid = true;
    switch (name) {
      // case "ManufactureName":
      //   if (value.trim().length < 1) {
      //     IsValid = false;
      //     errors.ManufactureName = "Please enter Manufacture name.";
      //   } else errors.Product = "";
      //   break;

      //  case "MarketedBy":
      //         if (value.trim().length < 1) {
      //           IsValid = false;
      //           errors.MarketedBy = "Please enter MarketedBy .";
      //         } else errors.MarketedBy = "";
      //         break;

      // case "RegistrationNo":
      // if (value.trim().length < 1) {
      //   IsValid = false;
      //   errors.RegistrationNo = "Please enter RegistrationNo .";
      // } else errors.RegistrationNo = "";
      // break;

      // case "CustomerCareDetails":
      // if (value.trim().length < 1) {
      //   IsValid = false;
      //   errors.CustomerCareDetails = "Please enter CustomerCareDetails .";
      // } else errors.CustomerCareDetails = "";
      // break;
      // case 'ExpDate':
      //         if (value == null || !(value instanceof Date)) {
      //             IsValid = false;
      //             errors.ExpDate = "Please select Expiry date";
      //         }
      //         else if (!(value.toLocaleDateString().match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/))) {
      //             IsValid = false;
      //             errors.ExpDate = "Please enter only date.";
      //         } else if ((Date.parse(value) < Date.parse(new Date(this.getCurrentDate())))) {
      //             IsValid = false;
      //             errors.ExpDate = "Expiry date must be greater than or equal to today date.";
      //         }

      //         else errors.ExpDate = null;

      //         break;

      case "WarrantyPeriodInMonth":
        if (value == null || value < 1) {
          IsValid = false;
          errors.WarrantyPeriodInMonth =
            "Please enter Best Before Days, Should be above 1";
        }

        break;
      // case "mrp":
      //   if (value == null || value < 1) {
      //     IsValid = false;
      //     errors.mrp = "Please enter MRP, Should be above 1";
      //   }
      //   break;
      default:
        break;
    }
    return IsValid;
  }
  // validate form
  validateForm(value, name) {
    let errors = this.state.formError;
    let IsValid = true;
    switch (name) {
      case "ProductName":
        if (value.trim().length < 1) {
          IsValid = false;
          errors.ProductName = "Please enter product name.";
        } else errors.ProductName = "";
        break;
      case "ProductCd":
        if (value.trim().length < 1) {
          IsValid = false;
          errors.ProductCd = "Please enter product code.";
        } else if (!value.match(/^[0-9a-zA-Z]+$/)) {
          IsValid = false;
          errors.ProductCd = "Please enter only alphanumeric.";
        } else errors.ProductCd = "";
        break;

      // case "SapProductCd":
      //   if (value == null || value.trim().length < 1) {
      //     IsValid = false;
      //     errors.SapProductCd = "Please enter Sap Product code.";
      //   } else if (!value.match(/^[0-9a-zA-Z]+$/)) {
      //     IsValid = false;
      //     errors.SapProductCd = "Please enter only alphanumeric.";
      //   } else errors.SapProductCd = "";
      //   break;
      // case "GTIN":
      // if (value == null || value.trim().length < 12) {
      //   IsValid = false;
      //   errors.GTIN = "Please enter 12 Digit GTIN Number.";
      // }
      // else if (!value.match(/^[0-9]+$/)) {
      //   IsValid = false;
      //   errors.GTIN = "Please enter only numeric.";
      // }
      // else errors.GTIN = "";
      // break;
      // case "CartonSize":
      // if (value == null || value.trim().length < 1) {
      //   IsValid = false;
      //   errors.CartonSize = "Please enter carton size.";
      // }
      // else if (!value.match(/^[0-9]+$/)) {
      //   IsValid = false;
      //   errors.CartonSize = "Please enter only numeric.";
      // }
      // else errors.CartonSize = "";
      // break;
      case "ProductDsrcptn":
        if (value == null || value.trim().length < 1) {
          IsValid = false;
          errors.ProductDsrcptn = "Please enter product description.";
        } else errors.ProductDsrcptn = "";
        break;

      // case "TechnicalName":
      //   if (value.trim().length < 1) {
      //     IsValid = false;
      //     errors.TechnicalName = "Please enter product TechnicalName.";
      //   } else errors.TechnicalName = "";
      //   break;

      // case "CategoryId":
      //   if (value == undefined || value == "") {
      //     IsValid = false;
      //     errors.CategoryId = "Please select category.";
      //   } else errors.CategoryId = null;
      //   break;
      // case "MG2Id":
      //   if (value == undefined || value == "") {
      //     IsValid = false;
      //     errors.MG2Id = "Please select category.";
      //   } else errors.MG2Id = null;
      //   break;
      // case "MG3Id":
      //   if (value == undefined || value == "") {
      //     IsValid = false;
      //     errors.MG3Id = "Please select category.";
      //   } else errors.MG3Id = null;
      //   break;
      // case "SubCategoryId":
      //   if (value == null) {
      //     IsValid = false;
      //     errors.SubCategoryId = "Please select sub category.";
      //   } else errors.SubCategoryId = null;
      //   break;
      case "ProductUOM":
        if (value.trim().length < 1) {
          IsValid = false;
          errors.ProductUOM = "Please enter  product UOM.";
        } else errors.ProductUOM = "";
        break;
      case "ProductValue":
        if (value.trim().length < 1) {
          IsValid = false;
          errors.ProductValue = "Please enter product pack.";
        } else if (!value.match(/^[0-9]+$/)) {
          IsValid = false;
          errors.ProductValue = "Please enter only number.";
          this.setState({ Qty: 0 });
        } else {
          errors.ProductValue = "";
          const ProductValue = value.trim() === "" ? 0 : parseInt(value.trim());
          if (ProductValue > 0) {
            errors.ProductValue = "";
          } else {
            IsValid = false;
            errors.ProductValue = "Product pack should be greater than 0.";
            this.setState({ Qty: 0 });
          }
        }
        break;

      case "CompanyId":
        if (value == undefined || value == "") {
          IsValid = false;
          errors.CompanyId = "Please select Company";
        } else {
          errors.CompanyId = "";
          this.GetWareHouse(value.CompId);
        }
        break;
      // case 'IsActive':
      //     this.setState({
      //         IsActive: value

      //     });
      //     break;

      default:
        break;
    }

    return IsValid;
  }

  // open Dialog
  openDialog() {
    this.setState({
      visible: true,
      uploadImagPath: "",
      uploadAudioPath: "",
      uploadImagDescPath: "",
      uploadImagDescAntiPath: "",
      WarrantyPeriodInMonth: "",
    });
    this.formReset();
  }

  // Reset form
  formReset() {
    this.setState({
      ProductName: "",
      ProductCd: "",
      GTIN: "",
      WarrantyPeriodInMonth: 730,
      //CartonSize:'',
      ProductDsrcptn: "",
      ProductImg: "",
      CautionarySymbol: "",
      AntidoteStatement: "",
      RegistrationNo: "",
      MarketedBy: "",
      ExpDate: "",
      ManufactureName: "",
      CustomerCareDetails: "",
      uploadImagDescPath: "",
      uploadImagDescAntiPath: "",
      GenuineAudio: "",
      ProductUOM: "",
      ProductValue: "",
      CategoryId: "",
      MG2Id: "",
      MG3Id: "",
      SapProductCd: "",
      uploadImageLabel: "",
      uploadImagePamphlet: "",
      // CompId: "",
      // SubCategoryId: '',
      ProductId: "",
      IsActive: true,
      TechnicalName: "",
    });

    let errors = this.state.formError;
    Object.entries(errors).map(([key, value]) => {
      errors[key] = "";
    });
  }

  hideFunc() {
    this.setState({
      visible: false,
      buttonLabel: "Submit",
      formHeading: "Add Product",
    });
    this.setState({ activeIndex: 0 });
    this.setState({ prodDes: true });
    this.formReset();
  }

  // submit form

  submitDescForm(event) {
    event.preventDefault();
    let isfrmvalid = true;
    let isValidForm = true;
    let checkerror = this.state.formError;

    Object.entries(checkerror).map(([key, value]) => {
      const val = this.state[key];
      isfrmvalid = this.validateForm(val, key);
      if (!isfrmvalid) {
        this.setState(
          {
            checkerror: isfrmvalid,
          },
          () => {
            isfrmvalid = this.state.checkerror;
          }
        );
        isfrmvalid = false;
      }
    });

    Object.entries(checkerror).map(([key, value]) => {
      const val = this.state[key];
      isfrmvalid = this.validateForm1(val, key);
      console.log(isfrmvalid);
      if (!isfrmvalid) {
        this.setState(
          {
            checkerror: isfrmvalid,
          },
          () => {
            isfrmvalid = this.state.checkerror;
            // if(this.state.checkerror)
            // this.saveProuduct(this.state.checkerror);
          }
        );
        isfrmvalid = false;
        isValidForm = false;
      }
    });

    this.saveProuduct(isValidForm);
  }

  saveProuduct(isfrmvalid) {
    if (isfrmvalid) {
      allData = {
        ...allData,
        ManufactureName: this.state.ManufactureName,
        RegistrationNo: this.state.RegistrationNo,
        MarketedBy: this.state.MarketedBy,
        //CustomerCareDetails:this.state.CustomerCareDetails,
        // ExpDate: this.state.ExpDate.toLocaleDateString(),
        //CustomerCareDetails:this.state.CustomerCareDetails,
        CautionarySymbol: this.state.uploadImagDescPath,
        AntidoteStatement: this.state.uploadImagDescAntiPath,
        WarrantyPeriodInMonth: this.state.WarrantyPeriodInMonth,
        mrp: this.state.mrp,
        label: this.state.uploadImageLabel,
        Pamphlet: this.state.uploadImagePamphlet,
        // CategoryIdMG2: this.state.MG2Id,
        // CategoryIdMG3: this.state.MG3Id,
        //CompId: this.state.CompId.CompId,
      };
      this.setState({ updateLoader: false });
      setTimeout(() => {
        this.ProductService.CreateUpdateProduct(allData).then((data) => {
          ;
          const resdata = data.data;
          this.setState({ updateLoader: true });
          if (resdata !== null && resdata.ResponseCode === "200") {
            this.refs.Messchild.showSuccess(resdata.ResponseMessage);
            this.setState({
              loading: true,
              visible: false,
            });
            this.formReset();
            this.GetProducts();
            this.setState({ activeIndex: 0 });
            this.setState({ prodDes: true });
          } else if (resdata.ResponseCode === "400") {
            this.refs.Messchild.showWarn(resdata.ResponseMessage);
          } else {
            this.refs.Messchild.showError(resdata.ResponseMessage);
          }
        });
      }, 100);
    }
  }

  submitForm(event) {
    event.preventDefault();
    let isfrmvalid = true;
    let checkerror = this.state.formError;

    Object.entries(checkerror).map(([key, value]) => {
      const val = this.state[key];
      let valid = this.validateForm(val, key);
      if (!valid) {
        this.setState({
          checkerror: isfrmvalid,
          loader: false,
        });
        isfrmvalid = false;
      }
    });

    if (isfrmvalid) {
      //  this.setState({ updateLoader: false });
      var formData = {
        ProductId:
          this.state.ProductId == "" ? 0 : parseInt(this.state.ProductId),
        ProductName: this.state.ProductName,
        ProductCd: this.state.ProductCd,
        gtin: this.state.GTIN,
        // cartonSize:this.state.CartonSize,
        SapProductCd: this.state.SapProductCd,
        TechnicalName: this.state.TechnicalName,
        ProductDscrptn: this.state.ProductDsrcptn,
        ProductImg: this.state.uploadImagPath,
        GenuineAudio: this.state.uploadAudioPath,
        ProductUOM: this.state.ProductUOM,
        ProductValue: parseInt(this.state.ProductValue),
        CategoryId: parseInt(this.state.CategoryId),
        MG2Id: parseInt(this.state.MG2Id),
        MG3Id: parseInt(this.state.MG3Id),
        // SubCategoryId: parseInt(this.state.SubCategoryId),
        IsActive: this.state.IsActive,
        CompId: this.state.CompId.CompId,
      };

      allData = formData;

      this.setState({ activeIndex: this.state.activeIndex + 1 });

      this.setState({ prodDes: false });
      //   setTimeout(() => {
      //     this.ProductService.CreateUpdateProduct(formData).then((data) => {
      //       const resdata = data.data;
      //       if (resdata !== null && resdata.ResponseCode === "200") {
      //         this.refs.Messchild.showSuccess(resdata.ResponseMessage);
      //         this.setState({
      //           loading: true,
      //           visible: false,
      //         });
      //         this.formReset();
      //         this.GetProducts();
      //       } else if (resdata.ResponseCode === "400") {
      //         this.refs.Messchild.showWarn(resdata.ResponseMessage);
      //       } else {
      //         this.refs.Messchild.showError(resdata.ResponseMessage);
      //       }
      //     });
      //   }, 1000);
    }
  }

  // search filter
  searchFilter(e) {
    this.setState({
      loading: true,
    });
    this.tblResponse.SearchValue = e.target.value;

    this.GetProducts();
  }

  //delete Product
  deleteProduct(productId) {
    let isValid = false;
    this.ProductService.DeleteProduct(productId).then((data) => {
      const resdata = data.data;
      if (resdata !== null && resdata.ResponseCode === "200") {
        this.refs.Messchild.showSuccess(resdata.ResponseMessage);
        const totlrecord = this.state.totalRecords - 1;
        const row = this.state.rows;
        if (this.tblResponse.First >= totlrecord) {
          const _curntCursor = Math.round(totlrecord / row);
          this.tblResponse.First = _curntCursor + 1;
        }
        this.GetProducts();
        isValid = true;
      } else if (resdata.ResponseCode === "400") {
        this.setState({
          updateLoader: true,
        });
        this.refs.Messchild.showWarn(resdata.ResponseMessage);
      } else {
        this.setState({
          updateLoader: true,
        });
        this.refs.Messchild.showError(resdata.ResponseMessage);
      }
    });

    return isValid;
  }

  // disabled Template
  activeTemplate(rowData, column) {
    return <input type="checkbox" disabled checked={rowData.IsActive} />;
  }

  // edit data
  actionTemplate(rowData, column) {
    const filterCatDDL = this.state.CategoryDDL.filter(
      (x) => x.value == rowData.CategoryId
    );
    const filterCatDDLMG2 = this.state.MG2DDL.filter(
      (x) => x.value == rowData.CategoryIdMG2
    );
    const filterCatDDLMG3 = this.state.MG3DDL.filter(
      (x) => x.value == rowData.CategoryIdMG3
    );

    const editMode = () => {
      this.setState({
        visible: true,
        formHeading: "Update Product",
        buttonLabel: "Update",
        ontime: true,
        ProductId: rowData.ProductId,
        ProductName: rowData.ProductName,
        ProductCd: rowData.ProductCd,
        GTIN: rowData.gtin,
        // CartonSize:rowData.cartonSize,
        SapProductCd: rowData.SapProductCd,
        ProductDsrcptn: rowData.ProductDscrptn,
        uploadImagPath: rowData.ProductImg,
        uploadAudioPath: rowData.GenuineAudio,
        ProductUOM: rowData.ProductUOM,
        ProductValue: "" + rowData.ProductValue,
        CategoryId:
          filterCatDDL.length != 0 ? rowData.CategoryId.toString() : "",
        MG2Id:
          filterCatDDLMG2.length != 0 ? rowData.CategoryIdMG2.toString() : "",
        MG3Id:
          filterCatDDLMG3.length != 0 ? rowData.CategoryIdMG3.toString() : "",
        IsActive: rowData.IsActive,
        uploadImagDescPath: rowData.CautionarySymbol,
        uploadImagDescAntiPath: rowData.AntidoteStatement,
        uploadImageLabel: rowData.Label,
        uploadImagePamphlet: rowData.Pamphlet,
        RegistrationNo: rowData.RegistrationNo,
        ManufactureName: rowData.ManufactureName,
        // ExpDate: new Date(rowData.ExpireDate),
        // CustomerCareDetails: rowData.CustomerCareDetails,
        MarketedBy: rowData.MarketedBy,
        WarrantyPeriodInMonth: rowData.WarrantyPeriodInMonth,
        mrp: rowData.mrp,
        TechnicalName: rowData.TechnicalName,
      });

      // const MG2Id = rowData.MG2Id === (undefined || null) ? 0 : parseInt(rowData.MG2Id);
      // if (MG2Id > 0) {
      //   this.GetMG2(MG2Id);
      //   this.setState({
      //     MG2Id: rowData.MG2Id.toString()
      //   });
      // } const MG3Id = rowData.MG3Id === (undefined || null) ? 0 : parseInt(rowData.MG3Id);
      // if (MG3Id > 0) {
      //   this.GetMG3(MG3Id);
      //   this.setState({
      //     MG3Id: rowData.MG3Id.toString()
      //   });
      // }
      // let checkbox = document.getElementsByName('IsActive');
      // checkbox[0].checked = rowData.IsActive;

      const CompanyObj = this.state.companyDDL.filter(
        (x) => x.CompId == rowData.CompId
      );

      this.setState({
        CompId: CompanyObj[0],
      });
    };
    const deleteMode = (e) => {
      e.preventDefault();
      // var element = document.getElementById("myDIV");
      // element.classList.remove("mystyle");
      e.target.className =
        e.target.className == "fa fa-toggle-off fa-2x"
          ? "fa fa-toggle-on fa-2x"
          : "fa fa-toggle-off fa-2x";
      let evnId = e.target.id;
      confirmAlert({
        title: "Confirm to inactive",
        message: "Are you sure to do this.",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              const isValid = this.deleteProduct(rowData.ProductId);
              if (!isValid) {
                var element = document.getElementById(evnId);
                element.className =
                  element.className == "fa fa-toggle-off fa-2x"
                    ? "fa fa-toggle-on fa-2x"
                    : "fa fa-toggle-off fa-2x";
              }
            },
          },
          {
            label: "No",
            onClick: () => null,
          },
        ],
      });
    };
    return (
      <span>
        <Button
          type="button"
          icon="pi pi-pencil"
          onClick={editMode}
          className="p-button-warning"
          style={{ marginRight: "3px" }}
        ></Button>
        {/* <Button type="button" icon="pi pi-trash" onClick={deleteMode} className="p-button-danger"></Button> */}
        {/* {rowData.IsActive == true ? (
          <a href="#">
            <i
              id={"iconDelete_" + column.rowIndex}
              onClick={deleteMode}
              className="fa fa-toggle-on fa-2x"
              style={{ color: "#ffba01" }}
            ></i>
          </a>
        ) : (
          <a href="#">
            <i
              id={"iconDelete_" + column.rowIndex}
              onClick={deleteMode}
              className="fa fa-toggle-off fa-2x"
              style={{ color: "#ffba01" }}
            ></i>
          </a>
        )} */}
      </span>
    );
  }

  // on sort table
  onSort(event) {
    this.setState({ sortOrder: this.state.sortOrder === 1 ? 2 : 1 });
    const sortField = event.sortField;
    this.tblResponse.SortColumn = sortField ? sortField : "";
    this.tblResponse.SortOrder = this.state.sortOrder === 1 ? "DESC" : "ASC";
    this.GetProducts();
  }

  // return HTML
  //resolved KREP-129,KREP-111
  render() {
    const items = [{ label: "Masters" }, { label: "Product Master" }];

    const home = { icon: "pi pi-home", url: "/dashboard" };
    let header = (
      <div style={{ textAlign: "right" }}>
        <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>
        <InputText
          type="search"
          onInput={this.searchFilter}
          placeholder="Global Search"
          size="30"
        />
      </div>
    );

    return (
      <>
        <MessageComponent ref="Messchild" />
        <Loaders loader={this.state.updateLoader} />
        <ConfirmationModal parentCallback={this.callbackFunction} ref="child" />
        <div className="row mb-3">
          <div className="col-md-6 heading">
            <h1>Product Master</h1>
          </div>
          <div className="col-md-6 custom-breadcrumb">
            <BreadCrumb model={items} home={home} />
          </div>
        </div>
        <div style={{ textAlign: "right", marginBottom: "10px" }}>
          <Button
            label="Add Product"
            icon="pi pi-plus-circle"
            onClick={this.openDialog}
          />
          <CSVLink
            data={this.state.exportData}
            headers={this.state.heads}
            filename={"Product Data.csv"}
            target="_blank"
          >
            <button
              disabled={this.state.exportData.length == 0 ? true : false}
              className="btn btn-success p-2 ml-2 buttone1"
            >
              <i className="pi pi-file-excel mr-2"></i>
              Export
            </button>
          </CSVLink>
        </div>

        <DataTable
          responsive={true}
          header={header}
          globalFilter={this.state.globalFilter}
          emptyMessage={this.state.emptyMessage}
          value={this.state.sales}
          sortMode="multiple"
          paginator={true}
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          rows={this.state.rows}
          rowsPerPageOptions={[5, 10, 20]}
          totalRecords={this.state.totalRecords}
          lazy={true}
          first={this.state.first}
          onPage={this.onPage}
          onSort={this.onSort}
          className="tblAuto"
        >
          {/* <Column field="CompanyName" header="Company Name" sortable={true} /> */}
          <Column
            field="ProductImg"
            style={{ width: "100px" }}
            header="Product Image"
            body={this.brandTemplate}
          />
          <Column field="ProductCd" header="Product Code" sortable={true} />

          <Column field="ProductName" header="Product Name" sortable={true} />

          {/* <Column field="productCd" header="Product Code" sortable={true} />
                    <Column field="prodSpecification" header="Product Specification" sortable={true} /> */}
          <Column field="ProductValue" header="Product Pack" sortable={true} />
          <Column field="ProductUOM" header="UOM" sortable={true} />
          <Column
            field="ProductDscrptn"
            header="Product Description"
            style={{ width: "400px" }}
          />
          <Column field="CreatedDt" header="Created Date" sortable={true} />
          {/* <Column body={this.activeTemplate} header="Status" /> */}
          <Column body={this.actionTemplate} header="Action" />
        </DataTable>

        <Dialog
          header={this.state.formHeading}
          blockScroll="true"
          visible={this.state.visible}
          className="popup-modal"
          modal={true}
          onHide={this.hideFunc}
        >
          <TabView
            activeIndex={this.state.activeIndex}
            onTabChange={(e) => this.setState({ activeIndex: e.index })}
          >
            <TabPanel header="Add Product">
              {this.state.buttonLabel == "Update" ? (
                <h5>
                  * Update in Categories May cause Unexpected error for Inline
                  Production
                </h5>
              ) : (
                ""
              )}
              <form onSubmit={this.submitForm} style={{ overflow: "hidden" }}>
                <div className="row">
                  <div className="col-md-4 text-right">
                    <label>
                      Company<span>*</span>:
                    </label>
                  </div>
                  <div className="col-md-8 mb-2">
                    <Dropdown
                      disabled={this.state.compDisabled}
                      optionLabel="CompanyName"
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                      value={this.state.CompId}
                      className="form-control"
                      placeholder="Select Company"
                      name="CompId"
                      options={this.state.companyDDL}
                    />
                    {this.state.formError.CompId !== null ? (
                      <div className="error">{this.state.formError.CompId}</div>
                    ) : null}
                  </div>
                  <div className="col-md-4 text-right">
                    <label>
                      Product Name<span>*</span>:
                    </label>
                  </div>
                  <div className="col-md-8 mb-2">
                    <input
                      type="text"
                      value={this.state.ProductName}
                      onChange={this.handleChange}
                      className="form-control"
                      name="ProductName"
                    />
                    {this.state.formError.ProductName !== null ? (
                      <div className="error">
                        {this.state.formError.ProductName}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-md-4 text-right">
                    <label>
                      Product Code<span>*</span>:
                    </label>
                  </div>
                  <div className="col-md-8 mb-2">
                    <input
                      type="text"
                      value={this.state.ProductCd}
                      onChange={this.handleChange}
                      className="form-control"
                      name="ProductCd"
                    />
                    {this.state.formError.ProductCd !== null ? (
                      <div className="error">
                        {this.state.formError.ProductCd}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-md-4 text-right">
                    <label>
                      SAP Product Code:
                    </label>
                  </div>
                  <div className="col-md-8 mb-2">
                    <input
                      type="text"
                      value={this.state.SapProductCd}
                      onChange={this.handleChange}
                      className="form-control"
                      name="SapProductCd"
                    />
                    {this.state.formError.SapProductCd !== null ? (
                      <div className="error">
                        {this.state.formError.SapProductCd}
                      </div>
                    ) : null}
                  </div>
                  {/* <div className="col-md-4 text-right">
                    <label>
                      GTIN Number<span>*</span>:
                    </label>
                  </div>
                  <div className="col-md-8 mb-2">
                    <input
                      type="text"
                      value={this.state.GTIN}
                      onChange={this.handleChange}
                      className="form-control"
                      name="GTIN"
                    />
                    {this.state.formError.GTIN !== null ? (
                      <div className="error">
                        {this.state.formError.GTIN}
                      </div>
                    ) : null}
                    </div> */}
                  {/* <div className="col-md-4 text-right">
                    <label>
                      Carton Size<span>*</span>:
                    </label>
                  </div>
                   <div className="col-md-8 mb-2">
                    <input
                      type="text"
                      value={this.state.CartonSize}
                      onChange={this.handleChange}
                      className="form-control"
                      name="CartonSize"
                    />
                    {this.state.formError.CartonSize !== null ? (
                      <div className="error">
                        {this.state.formError.CartonSize}
                      </div>
                    ) : null}
                  </div> */}
                  <div className="col-md-4 text-right">
                    <label>
                      Product Description<span>*</span>:
                    </label>
                  </div>
                  <div className="col-md-8 mb-2">
                    <input
                      type="text"
                      value={this.state.ProductDsrcptn}
                      onChange={this.handleChange}
                      className="form-control"
                      name="ProductDsrcptn"
                    />
                    {this.state.formError.ProductDsrcptn !== null ? (
                      <div className="error">
                        {this.state.formError.ProductDsrcptn}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-md-4 text-right">
                    <label>
                      Technical Name:
                    </label>
                  </div>
                  <div className="col-md-8 mb-2">
                    <input
                      type="text"
                      value={this.state.TechnicalName}
                      onChange={this.handleChange}
                      className="form-control"
                      name="TechnicalName"
                    />
                    {this.state.formError.TechnicalName !== null ? (
                      <div className="error">
                        {this.state.formError.TechnicalName}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-md-4 text-right">
                    <label>
                      Product Category<span>*</span>:
                    </label>
                  </div>
                  <div className="col-md-8 mb-2">
                    {/* onChange={e => { this.CategoryDDLEvent(e); this.handleChange(e) }} */}
                    <Dropdown
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                      value={this.state.CategoryId}
                      className="form-control"
                      placeholder="Select Category"
                      name="CategoryId"
                      options={this.state.CategoryDDL}
                    />
                    {this.state.formError.CategoryId !== null ? (
                      <div className="error">
                        {this.state.formError.CategoryId}
                      </div>
                    ) : null}
                  </div>
                  {/* <div className="col-md-4 text-right">
                    <label>
                      Sub Category<span>*</span>:
                    </label>
                  </div>
                  <div className="col-md-8 mb-2">
                    <Dropdown
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                      value={this.state.MG2Id}
                      className="form-control"
                      placeholder="Sub Category"
                      name="MG2Id"
                      options={this.state.MG2DDL}
                    />
                    {this.state.formError.MG2Id !== null ? (
                      <div className="error">{this.state.formError.MG2Id}</div>
                    ) : null}
                  </div>{" "}
                  <div className="col-md-4 text-right">
                    <label>
                      Sub Divison Category<span>*</span>:
                    </label>
                  </div>
                  <div className="col-md-8 mb-2">
                    <Dropdown
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                      value={this.state.MG3Id}
                      className="form-control"
                      placeholder="Sub Divison Category"
                      name="MG3Id"
                      options={this.state.MG3DDL}
                    />
                    {this.state.formError.MG3Id !== null ? (
                      <div className="error">{this.state.formError.MG3Id}</div>
                    ) : null}
                  </div> */}
                  {/* <div className="col-md-4 text-right">
                                <label>Product Sub Category<span>*</span>:</label>
                            </div>
                            <div className="col-md-8 mb-2">
                                <Dropdown options={this.state.SubCategoryDDL} placeholder="Select Sub Category" className="form-control" value={this.state.SubCategoryId} onChange={this.handleChange} name="SubCategoryId" />
                                {this.state.formError.SubCategoryId !== null ?
                                    <div className='error'>{this.state.formError.SubCategoryId}</div> : null}
                            </div> */}
                  <div className="col-md-4 text-right">
                    <label>
                      Product Pack
                      <span>*</span>:
                    </label>
                  </div>
                  <div className="col-md-8 mb-2">
                    <input
                      type="text"
                      value={this.state.ProductValue}
                      onChange={this.handleChange}
                      className="form-control"
                      name="ProductValue"
                    />
                    {this.state.formError.ProductValue !== null ? (
                      <div className="error">
                        {this.state.formError.ProductValue}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-md-4 text-right">
                    <label>
                      Product UOM<span>*</span>:
                    </label>
                  </div>
                  <div className="col-md-8 mb-2">
                    <input
                      type="text"
                      value={this.state.ProductUOM}
                      onChange={this.handleChange}
                      className="form-control"
                      name="ProductUOM"
                    />
                    {this.state.formError.ProductUOM !== null ? (
                      <div className="error">
                        {this.state.formError.ProductUOM}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-md-4 text-right">
                    <label>Upload Product:</label>
                  </div>
                  <div className="col-md-8 mb-2">
                    <FileUpload
                      name="productfile"
                      url={process.env.REACT_APP_API_URL + "Upload/FileUpload"}
                      auto={true}
                      onUpload={this.setImagePath}
                      onError={this.onErrorImage}
                      accept="image/*"
                    />

                    {this.state.uploadImagPath ? (
                      <div className="p-fileupload-row">
                        <div>
                          <img
                            alt="pro.jfif"
                            role="presentation"
                            src={this.state.uploadImagPath}
                            width="50"
                          />
                        </div>
                      </div>
                    ) : null}

                    {this.state.formError.ProductImg !== null ? (
                      <div className="error">
                        {this.state.formError.ProductImg}
                      </div>
                    ) : null}
                  </div>
                  {/* <div className="col-md-4 text-right">
                    <label> Genuine Audio File:</label>
                  </div>
                  <div className="col-md-8 mb-2">
                    <FileUpload
                      name="audiofile"
                      url={process.env.REACT_APP_API_URL + "Upload/AudioUpload"}
                      auto={true}
                      onUpload={this.setAudioPath}
                      onError={this.onErrorAudio}
                      accept="mp3/*"
                    />

                    {this.state.uploadAudioPath ? (
                      <div className="p-fileupload-row">
                        <div>
                          <audio
                            ref="audio_tag"
                            src={this.state.uploadAudioPath}
                            controls
                          />
                        </div>
                      </div>
                    ) : null}
                    {this.state.formError.GenuineAudio !== null ? (
                      <div className="error">
                        {this.state.formError.GenuineAudio}
                      </div>
                    ) : null}
                  </div> */}
                  <div className="col-md-4 text-right">
                    <label>IsActive :</label>
                  </div>
                  <div className="col-md-8 mb-2">
                    <input
                      type="checkbox"
                      checked={this.state.IsActive}
                      className="ml-2"
                      name="IsActive"
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="col-md-12 text-center">
                    <button type="submit" className="btn btn-primary">
                      Next
                    </button>
                  </div>
                </div>

                <div>
                  <label>
                    Note: Fields marked as (
                    <span style={{ color: "red" }}>*</span>) are required
                  </label>
                </div>
              </form>
            </TabPanel>
            <TabPanel
              header="Add Product Description"
              disabled={this.state.prodDes}
            >
              <form
                onSubmit={this.submitDescForm}
                style={{ overflow: "hidden" }}
              >
                <div className="row">
                  {/* <div className="col-md-4 text-right">
                    <label>
                      Name of the Manufacturer:
                    </label>
                  </div>
                  <div className="col-md-8 mb-2">
                    <input
                      type="text"
                      value={this.state.ManufactureName}
                      onChange={this.handleChange1}
                      className="form-control"
                      name="ManufactureName"
                    />
                    {this.state.formError.ManufactureName !== null ? (
                      <div className="error">
                        {this.state.formError.ManufactureName}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-md-4 text-right">
                    <label>
                      Registration No.:
                    </label>
                  </div>
                  <div className="col-md-8 mb-2">
                    <input
                      type="text"
                      value={this.state.RegistrationNo}
                      onChange={this.handleChange1}
                      className="form-control"
                      name="RegistrationNo"
                    />
                    {this.state.formError.RegistrationNo !== null ? (
                      <div className="error">
                        {this.state.formError.RegistrationNo}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-md-4 text-right">
                    <label>
                      Marketed By:
                    </label>
                  </div>
                  <div className="col-md-8 mb-2">
                    <input
                      type="text"
                      value={this.state.MarketedBy}
                      onChange={this.handleChange1}
                      className="form-control"
                      name="MarketedBy"
                    />
                    {this.state.formError.MarketedBy !== null ? (
                      <div className="error">
                        {this.state.formError.MarketedBy}
                      </div>
                    ) : null}
                  </div> */}
                  {/* <div className="col-md-4 text-right">
                    <label>
                      Customer Care Details:<span>*</span>:
                    </label>
                  </div>
                  <div className="col-md-8 mb-2">
                      <input
                      type="text"
                      value={this.state.CustomerCareDetails}
                      onChange={this.handleChange1}
                      className="form-control"
                      name="CustomerCareDetails"
                    />
                    {this.state.formError.CustomerCareDetails !== null ? (
                      <div className="error">
                        {this.state.formError.CustomerCareDetails}
                      </div>
                    ) : null}
                  </div> */}
                  <div className="col-md-4 text-right">
                    <label>
                      Best Before (In Days) :<span>*</span>
                    </label>
                  </div>
                  <div className="col-md-8 mb-2">
                    {/* <Calendar  minDate={minDate} className="custom-calender" value={this.state.ExpDate} appendTo={document.getElementById('root')} onChange={this.handleChange1} showIcon={true} name="ExpDate"  /> */}
                    <input
                      type="text"
                      value={this.state.WarrantyPeriodInMonth}
                      onChange={this.handleChange1}
                      className="form-control"
                      name="WarrantyPeriodInMonth"
                    />
                    {this.state.formError.WarrantyPeriodInMonth !== null ? (
                      <div className="error">
                        {this.state.formError.WarrantyPeriodInMonth}
                      </div>
                    ) : null}
                  </div>
                  {/* <div className="col-md-4 text-right">
                    <label>MRP :<span>*</span></label>
                  </div>
                  <div className="col-md-8 mb-2">
                     <input type="number" value={this.state.mrp} onChange={this.handleChange1} className="form-control" name="mrp" />
                    {this.state.formError.mrp !== null ?
                      <div className='error'>{this.state.formError.mrp}</div> : null}
                  </div> */}

                  {/* <div className="col-md-4 text-right">
                    <label>Upload Cautionary Symbol:</label>
                  </div>
                  <div className="col-md-8 mb-2">
                    <FileUpload
                      name="productfile"
                      url={process.env.REACT_APP_API_URL + "Upload/FileUpload"}
                      auto={true}
                      onUpload={this.setImageDescPath}
                      onError={this.onErrorImage}
                      accept="image/*"
                    />

                    {this.state.uploadImagDescPath ? (
                      <div className="p-fileupload-row">
                        <div>
                          <img
                            alt="pro.jfif"
                            role="presentation"
                            src={this.state.uploadImagDescPath}
                            width="50"
                          />
                        </div>
                      </div>
                    ) : null}

                    {this.state.formError.CautionarySymbol !== null ? (
                      <div className="error">
                        {this.state.formError.CautionarySymbol}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-md-4 text-right">
                    <label>Upload Antidote Statement:</label>
                  </div>
                  <div className="col-md-8 mb-2">
                    <FileUpload
                      name="productfile"
                      url={process.env.REACT_APP_API_URL + "Upload/FileUpload"}
                      auto={true}
                      onUpload={this.setImageDescAntiPath}
                      onError={this.onErrorImage}
                      accept=".pdf"
                    />

                    {this.state.uploadImagDescAntiPath ? (
                      <div className="p-fileupload-row">
                        <div>
                          <a

                            href={this.state.uploadImagDescAntiPath}
                            target="_blank"
                          >Download</a>
                        </div>
                      </div>
                    ) : null}


                    {this.state.formError.AntidoteStatement !== null ? (
                      <div className="error">
                        {this.state.formError.AntidoteStatement}
                      </div>
                    ) : null}
                  </div> */}

                  <div className="col-md-4 text-right">
                    <label>Upload Label:</label>
                  </div>
                  <div className="col-md-8 mb-2">
                    <FileUpload
                      name="productfile"
                      url={process.env.REACT_APP_API_URL + "Upload/FileUpload"}
                      auto={true}
                      onUpload={this.setLabel}
                      onError={this.onErrorImage}
                      accept=".pdf"
                    />

                    {this.state.uploadImageLabel ? (
                      <div className="p-fileupload-row">
                        <div>
                          <a href={this.state.uploadImageLabel} target="_blank">
                            Download
                          </a>
                        </div>
                      </div>
                    ) : null}

                    {this.state.formError.uploadImageLabel !== null ? (
                      <div className="error">
                        {this.state.formError.uploadImageLabel}
                      </div>
                    ) : null}
                  </div>

                  <div className="col-md-4 text-right">
                    <label>Upload Leaflet:</label>
                  </div>
                  <div className="col-md-8 mb-2">
                    <FileUpload
                      name="productfile"
                      url={process.env.REACT_APP_API_URL + "Upload/FileUpload"}
                      auto={true}
                      onUpload={this.setPamphlet}
                      onError={this.onErrorImage}
                      accept=".pdf"
                    />

                    {this.state.uploadImagePamphlet ? (
                      <div className="p-fileupload-row">
                        <div>
                          <a
                            href={this.state.uploadImagePamphlet}
                            target="_blank"
                          >
                            Download{" "}
                          </a>
                        </div>
                      </div>
                    ) : null}

                    {this.state.formError.uploadImagePamphlet !== null ? (
                      <div className="error">
                        {this.state.formError.uploadImagePamphlet}
                      </div>
                    ) : null}
                  </div>

                  <div className="col-md-12 text-center">
                    <button type="submit" className="btn btn-primary">
                      {this.state.buttonLabel}
                    </button>
                  </div>
                </div>
              </form>
            </TabPanel>
          </TabView>
        </Dialog>
      </>
    );
  }
}
