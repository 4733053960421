import React, { Component } from "react";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import $ from "jquery";
import NumberGenerationService from "../../services/NumberGenerationService";
import MessageComponent from "../../template/Message";
import tableRequest from "../../models/tableRequest";
import ApiService from "../../services/apiService";
import Loaders from "../../template/Loaders";
// TO Generate NUmber
export default class AdminNumberGenerated extends Component {
  constructor(props) {
    super(props);
    this.NumberGenerationService = new NumberGenerationService();
    this.ApiService = new ApiService();
    this.tblResponse = new tableRequest();
    this.state = {
      visible: false,
      buttonLabel: "Submit",
      updateLoader: false,
      formHeading: "Add Number Generation",
      sortOrder: "",
      FromNo: "",
      ToNo: "",
      Qty: "",
      formError: {
        FromNo: null,
        ToNo: null,
        Qty: null,
      },
      prefix: "",
      downloadLink: "",
      sales: [],
      loading: true,
      first: 0,
      rows: 10,
      totalRecords: 0,
      globalFilter: null,
      emptyMessage: "No Data found",
    };

    this.actionTemplate = this.actionTemplate.bind(this);
    this.handleChnage = this.handleChnage.bind(this);
    //this.submitForm = this.submitForm.bind(this);
    this.hideFunc = this.hideFunc.bind(this);
    this.onPage = this.onPage.bind(this);
    this.searchFilter = this.searchFilter.bind(this);
    // this.GetFromNo = this.GetFromNo.bind(this);
    this.onSort = this.onSort.bind(this);
  }
  //trigger api function on page call
  componentDidMount() {
    setTimeout(() => {
      this.GetGeneratedNumbers();
    }, 1000);
    $(".p-column-title").on("click", function () {
      $(this)
        .next()
        .removeClass("pi-sort")
        .toggleClass("pi-sort-up")
        .addClass("pi-sort-down");
    });
  }

  // call page event
  onPage(event) {
    this.setState({
      loading: true,
    });

    //imitate delay of a backend call
    setTimeout(() => {
      this.getLazyLoadingValues(event);
      this.GetGeneratedNumbers();
    }, 250);
  }

  // show when edit record
  actionTemplate(rowData) {
    const editMode = () => {
      this.Downlaod(rowData);
    };
    return (
      <div>
        <Button
          type="button"
          icon="pi pi-download"
          onClick={editMode}
          className="p-button-info"
        ></Button>
      </div>
    );
  }

  //validate form
  //resolved KREP-17
  validateForm(value, name) {
    let errors = this.state.formError;
    let IsValid = true;
    //to validate each form field with required conditions
    switch (name) {
      case "FromNo":
        if (value < 1) {
          IsValid = false;
          errors.FromNo = "Please enter your from number.";
        } else {
          if (!value.match(/^[0-9]+$/)) {
            IsValid = false;
            errors.FromNo = "Please enter only number.";
            this.setState({ Qty: 0 });
          } else {
            errors.ToNo = null;
            errors.Qty = null;
            errors.FromNo = null;
            const fromNumber = value.trim() === "" ? 0 : parseInt(value.trim());
            const toNumber =
              this.state.ToNo.toString().trim() === ""
                ? 0
                : parseInt(this.state.ToNo.toString().trim());
            if (fromNumber >= 1 && toNumber >= 1) {
              if (fromNumber <= toNumber) {
                const qty = toNumber - fromNumber + 1;
                if (qty >= 0) {
                  this.setState({ Qty: qty });
                } else {
                  IsValid = false;
                  errors.FromNo = "From number should be less than to number.";
                  this.setState({ Qty: 0 });
                }
              } else {
                IsValid = false;
                errors.FromNo = "From number should be less than to number.";
                this.setState({ Qty: 0 });
              }
            } else {
              // IsValid = false;
              // errors.FromNo = "From number should be less than to number.";
              this.setState({ Qty: 0 });
            }
          }
        }
        break;
      case "ToNo":
        if (value < 1) {
          IsValid = false;
          errors.ToNo = "Please enter To Number.";
        } else errors.ToNo = null;
        break;
      case "Qty":
        if (value < 1) {
          IsValid = false;
          errors.Qty = "Please enter your quantity.";
        } else {
          if (!value.match(/^[0-9]+$/)) {
            IsValid = false;
            errors.Qty = "Please enter only number.";
            this.setState({ Qty: 0 });
          } else {
            errors.ToNo = null;
            errors.Qty = null;
            errors.FromNo = null;
            const qty = value.trim() === "" ? 0 : parseInt(value.trim());
            const fromNumber =
              this.state.FromNo.toString().trim() === ""
                ? 0
                : parseInt(this.state.FromNo.toString().trim());
            if (fromNumber >= 1 && qty >= 1) {
              const toNumber = qty + fromNumber - 1;
              if (toNumber >= 0) {
                this.setState({ ToNo: toNumber });
              } else {
                IsValid = false;
                errors.Qty =
                  "To number should be greater than or equal to  from number.";
                this.setState({ ToNo: 0 });
              }
            } else {
              // IsValid = false;
              // errors.ToNo = "To number should be greater than from number.";
              this.setState({ ToNo: 0 });
            }
          }
        }
        break;

      default:
        break;
    }

    return IsValid;
  }

  // call when input data on form
  handleChnage(event) {
    const { name, value } = event.target;
    let errors = this.state.formError;

    const isValid = this.validateForm(value, name);
    this.setState({ errors, [name]: value });
  }
  hideFunc() {
    this.setState({
      visible: false,
      buttonLabel: "Submit",
      formHeading: "Add Number Generation",
    });
    this.formReset();
  }

  // form Reset
  formReset() {
    this.setState({
      FromNo: "",
      ToNo: "",
      Qty: "",
    });

    let errors = this.state.formError;
    Object.entries(errors).map(([key, value]) => {
      errors[key] = "";
    });
  }
  // submit form to api
  Downlaod(rowData) {
    const formData = {
      FromNo: rowData.FromNo,
      ToNo: rowData.ToNo,
      Qty: rowData.Qty,
      Prefix: rowData.Prefix,
      ProductName: rowData.ProductName,
    };
    setTimeout(() => {
      this.NumberGenerationService.DownloadNumber(formData).then((data) => {
        const resdata = data.data;
        //if response code is 200 sucess else error or warning message
        if (resdata.ResponseCode === "200") {
          this.refs.child.showSuccess(resdata.ResponseMessage);
          this.setState({
            loading: true,
            visible: false,
            //  downloadLink : resdata.downloadLinK
          });
          this.formReset();
          this.GetGeneratedNumbers();

          const link = document.createElement("a");
          link.href = resdata.downloadLinK;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else if (resdata.ResponseCode === "400") {
          this.refs.child.showWarn(resdata.ResponseMessage);
        } else {
          this.refs.child.showError(resdata.ResponseMessage);
        }
      });
    }, 1000);
  }

  // get product issue number list data
  GetGeneratedNumbers() {
    //  this.tblResponse.SortColumn='CreatedDt';
    this.tblResponse.Prefix = "";
    this.tblResponse.isAdmin = true;
    this.tblResponse.SortColumn = "FromNo";
    this.tblResponse.sortOrder = "Desc";
    this.NumberGenerationService.GetGeneratedNumbers(this.tblResponse).then(
      (data) => {
        const resdata = data.data;
        //if response data is not null
        if (
          resdata !== null &&
          resdata.ResponseData !== null &&
          resdata.ResponseData.length > 0
        ) {
          this.datasource = resdata.ResponseData;
          this.setState({
            totalRecords: resdata.ResponseData[0].TotalRows,
            first: this.tblResponse.First - 1,
            sales: this.datasource.slice(
              0,
              this.tblResponse.First - 1 + this.tblResponse.PageSize
            ),
            loading: false,
            updateLoader: true,
          });
        }
        ///if response data is null
        else {
          this.datasource = null;
          this.setState({
            totalRecords: 0,
            first: 1,
            sales: null,
            loading: false,
          });
        }
      }
    );
  }

  // set Lazy Loading Values
  getLazyLoadingValues(event) {
    this.setState({ rows: event.rows });
    this.tblResponse.PageSize = event.rows;
    this.tblResponse.First = event.first + 1;
    this.tblResponse.IsActive = "1";
    this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
    this.tblResponse.SortOrder = event.sortOrder === 1 ? "ASC" : "DESC";
  }

  // search Filter table
  searchFilter(e) {
    this.setState({
      loading: true,
    });
    this.tblResponse.SearchValue = e.target.value;
    this.GetGeneratedNumbers();
  }

  // Sort table
  onSort(event) {
    this.setState({ sortOrder: this.state.sortOrder === 1 ? 2 : 1 });
    const sortField = event.sortField; //== "CreatedDate" ? "CreatedDate" : event.sortField;
    this.tblResponse.SortColumn = sortField ? sortField : "";
    this.tblResponse.SortOrder = this.state.sortOrder === 1 ? "DESC" : "ASC";
    this.GetGeneratedNumbers();
  }

  // Render html
  //resolved KREP-19
  render() {
    let header = (
      <div style={{ textAlign: "right" }}>
        <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>
        <InputText
          type="search"
          onInput={this.searchFilter}
          placeholder="Global Search"
          size="30"
        />
      </div>
    );
    return (
      <>
        <MessageComponent ref="child" />
        {<Loaders loader={this.state.updateLoader} />}
        <div className="heading">
          <h1>Number Download</h1>
        </div>

        <DataTable
          responsive={true}
          header={header}
          globalFilter={this.state.globalFilter}
          emptyMessage={this.state.emptyMessage}
          value={this.state.sales}
          sort
          sortMode="single"
          paginator={true}
          rows={this.state.rows}
          rowsPerPageOptions={[10, 50, 200, 500]}
          totalRecords={this.state.totalRecords}
          lazy={true}
          first={this.state.first}
          onPage={this.onPage}
          onSort={this.onSort}
        >
          <Column field="ProductName" header="Product Name" sortable={true} />
          <Column field="FromNo" header="From No." sortable={true} />
          <Column field="BatchNo" header="Batch No" sortable={true} />
          <Column field="Qty" header="Quantity" sortable={true} />
          <Column field="MFGDate" header="MFG Date" sortable={true} />
          <Column field="EXPDate" header="EXP Date" sortable={true} />
          <Column field="FirmName" header="Firm Name" sortable={true} />
          <Column
            body={this.actionTemplate}
            header="Download"
            sortable={false}
          />
        </DataTable>
      </>
    );
  }
}
