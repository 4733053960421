import React, { Component } from "react";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "react-bootstrap";
import { Dropdown } from "primereact/dropdown";
import { BreadCrumb } from "primereact/breadcrumb";
import ProductService from "../../services/ProductService";
import SchemeService from "../../services/SchemeService";
import tableRequest from "../../models/tableRequest.js";
import { Calendar } from "primereact/calendar";
import MessageComponent from "../../template/Message";
import Loaders from "../../template/Loaders";
import $ from "jquery";

/// scheme master

var minDate = new Date();
export default class SchemeMaster extends Component {
  constructor(props) {
    super(props);
    this.ProductService = new ProductService();
    this.state = {
      visible: false,
      buttonLabel: "Submit",
      SchemeId: 0,
      updateLoader: false,
      SchemeName: "",
      Schemedescription: "",
      StartDate: "",
      EndDate: "",
      LoyaltyPoints: 0,
      IsActive: false,
      sortOrder: "",
      ProductId: null,
      ProductName: '',
      Utype: null,
      TypeName: '',
      formError: {
        ProductId: null,
        ProductName: '',
        Utype: null,
        TypeName: '',
        SchemeName: null,
        Schemedescription: null,
        StartDate: null,
        EndDate: null,
        LoyaltyPoints: null,
      },

      formHeading: "Add Scheme",

      sales: [],
      loading: true,
      first: 0,
      rows: 5,
      totalRecords: 0,
      globalFilter: null,
      emptyMessage: "No Data found",
    };

    //this.brandTemplate = this.brandTemplate.bind(this);
    this.actionTemplate = this.actionTemplate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.activeTemplate = this.activeTemplate.bind(this);
    this.SchemeService = new SchemeService();
    this.tblResponse = new tableRequest();
    this.hideFunc = this.hideFunc.bind(this);
    this.onPage = this.onPage.bind(this);
    this.searchFilter = this.searchFilter.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.onSort = this.onSort.bind(this);
  }
  //trigger api function on page call
  componentDidMount() {
    setTimeout(() => {
      this.GetProductDDL();
      this.GetSchemes();
      this.GetuserDDL();
    }, 1000);
    $(".p-column-title").on("click", function () {
      $(this)
        .next()
        .removeClass("pi-sort")
        .toggleClass("pi-sort-up")
        .addClass("pi-sort-down");
    });
  }

  // on page loading
  onPage(event) {
    this.setState({
      loading: true,
    });

    //imitate delay of a backend call
    setTimeout(() => {
      this.getLazyLoadingValues(event);
      this.GetSchemes();
    }, 250);
  }
  // Get Product List
  GetProductDDL() {
    this.ProductService.GetProductsDDL().then(data => {
      const resdata = data.data;

      //if resdata is not null 
      if (resdata !== null && resdata.ResponseCode === '200') {
        this.setState({
          ProductDDL: resdata.ResponseData

        });
      }
    });
  }
  GetuserDDL() {
    this.SchemeService.GetuserDDL().then(data => {
      const resdata = data.data;

      //if resdata is not null 
      if (resdata !== null && resdata.ResponseCode === '200') {
        this.setState({
          userDDL: resdata.ResponseData

        });
      }
    });
  }

  // get list of scheme
  GetSchemes() {
    this.tblResponse.SortColumn = "CreatedDt";
    this.SchemeService.GetSchemes(this.tblResponse).then((data) => {
      const resdata = data.data;
      console.log(resdata);
      //response data not null sales state will be set with response data
      if (resdata.ResponseCode === "200") {
        if (
          resdata !== null &&
          resdata.ResponseData !== null &&
          resdata.ResponseData.length > 0
        ) {
          this.datasource = resdata.ResponseData;
          this.setState({
            totalRecords: resdata.ResponseData[0].TotalRows,
            first: this.tblResponse.First - 1,
            sales: this.datasource.slice(
              0,
              this.tblResponse.First - 1 + this.tblResponse.PageSize
            ),
            loading: false,
          });
        }
        //response data is null sales state will be set  null
        else {
          this.datasource = null;
          this.setState({
            totalRecords: 0,
            first: 1,
            sales: null,
            loading: false,
          });
        }
        this.setState({ updateLoader: true });
      }
    });
  }

  // get current date
  getCurrentDate() {
    var today = new Date();
    var dd = today.getDate();

    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }

    if (mm < 10) {
      mm = "0" + mm;
    }
    // today = mm + '-' + dd + '-' + yyyy;
    // today = mm + '/' + dd + '/' + yyyy;
    // today = dd + '-' + mm + '-' + yyyy;
    today = mm + "/" + dd + "/" + yyyy;
    return today;
  }

  // validate form
  validateForm(value, name) {
    let errors = this.state.formError;
    let IsValid = true;
    //to validate each form field with required conditions
    switch (name) {
      case "SchemeName":
        if (value == null || value.trim().length < 1) {
          IsValid = false;
          errors.SchemeName = "Please enter scheme.";
        } else errors.SchemeName = null;
        break;
      case "ProductId":
        if (value == null) {

          IsValid = false;
          errors.ProductId = "Please select product.";
        } else {
          errors.ProductId = null;
        }
        break;
      case "Utype":
        if (value == null) {

          IsValid = false;
          errors.Utype = "Please select User Type.";
        } else {
          errors.Utype = null;
        }
        break;
      case "StartDate":
        if (value == null || !(value instanceof Date)) {
          IsValid = false;
          errors.StartDate = "Please select from date";
        } else if (
          !value.toLocaleDateString().match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/)
        ) {
          IsValid = false;
          errors.StartDate = "Please enter only date.";
        }
        //KREP-51
        else if (
          this.state.SchemeId == 0 &&
          Date.parse(value) < Date.parse(new Date(this.getCurrentDate()))
        ) {
          IsValid = false;
          errors.StartDate =
            "From date must be greater than or equal to today date.";
        } else if (
          value instanceof Date &&
          this.state.EndDate instanceof Date
        ) {
          errors.StartDate = null;
          errors.EndDate = null;

          const startDate = value;
          const endDate = this.state.EndDate;
          if (!(Date.parse(startDate) <= Date.parse(endDate))) {
            IsValid = false;
            errors.StartDate = "From date must be less than to date.";
          }
        } else errors.StartDate = null;
        break;
      case "EndDate":
        if (value == null || !(value instanceof Date)) {
          IsValid = false;
          errors.EndDate = "Please select to date";
        } else if (
          !value.toLocaleDateString().match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/)
        ) {
          IsValid = false;
          errors.EndDate = "Please enter only date.";
        } else if (
          Date.parse(value) < Date.parse(new Date(this.getCurrentDate()))
        ) {
          IsValid = false;
          errors.EndDate =
            "To date must be greater than or equal to today date.";
        } else if (
          value instanceof Date &&
          this.state.StartDate instanceof Date
        ) {
          errors.StartDate = null;
          errors.EndDate = null;

          const startDate = this.state.StartDate;
          const endDate = value;
          if (!(Date.parse(startDate) <= Date.parse(endDate))) {
            IsValid = false;
            errors.EndDate = "To date must be greater than from date.";
          }
        } else errors.EndDate = null;

        break;
      case "LoyaltyPoints":
        if (value == "" || parseFloat(value) < 1) {
          IsValid = false;
          errors.LoyaltyPoints = "Loyalty points must be minimum 1";
        } else errors.LoyaltyPoints = null;
        break;
      // case 'IsActive':
      //     this.setState({
      //         IsActive: value
      //     });
      //     break;
      default:
        break;
    }

    return IsValid;
  }

  // call when input
  handleChange(event) {
    const { name, value } = event.target;
    let errors = this.state.formError;

    const isValid = this.validateForm(value, name);
    this.setState({ errors, [name]: value });
    //to set state of IsActive
    if (name == "IsActive")
      this.setState({
        IsActive: event.target.checked,
      });
  }

  // open dialog
  openDialog() {
    this.setState({ visible: true });
    this.formReset();
  }

  // form Reset
  formReset() {
    this.setState({
      SchemeId: 0,
      SchemeName: "",
      Schemedescription: "",
      StartDate: "",
      EndDate: "",
      LoyaltyPoints: 0,
      IsActive: true,
    });

    let errors = this.state.formError;
    Object.entries(errors).map(([key, value]) => {
      errors[key] = "";
    });
  }

  hideFunc() {
    this.setState({
      visible: false,
      buttonLabel: "Submit",
      formHeading: "Add Scheme",
    });
    this.formReset();
  }

  // submit form
  submitForm(event) {
  
    ;
    event.preventDefault();

    let isfrmvalid = true;
    let checkerror = this.state.formError;

    Object.entries(checkerror).map(([key, value]) => {
      const val = this.state[key];
      let valid = this.validateForm(val, key);
      //form is not valid
      if (!valid) {
        this.setState({ checkerror: isfrmvalid });
        isfrmvalid = false;
      }
    });

    //form is valid
    if (isfrmvalid) {
      this.setState({ updateLoader: false });
    
      const formData = {
        ProductId: this.state.ProductId === "" ? 0 : parseInt(this.state.ProductId),
        Utype: this.state.Utype === "" ? 0 : parseInt(this.state.Utype),
        SchemeId: this.state.SchemeId,
        SchemeName: this.state.SchemeName,
        Schemedescription: this.state.Schemedescription,
        StartDate: this.state.StartDate.toLocaleDateString(),
        EndDate: this.state.EndDate.toLocaleDateString(),
        LoyaltyPoints: this.state.LoyaltyPoints,
        IsActive: this.state.IsActive,
      };

      //console.log(formData);

      setTimeout(() => {
        this.SchemeService.CreateScheme(formData).then((data) => {
          const resdata = data.data;
          //if responsecode is 200 success message will shown else it will move to 'else statement'
          if (resdata !== null && resdata.ResponseCode === "200") {
            this.refs.Messchild.showSuccess(resdata.ResponseMessage);
            this.setState({
              loading: true,
              visible: false,
              updateLoader: true,
            });
            this.formReset();
            this.GetSchemes();
          } else if (resdata !== null && resdata.ResponseCode === "400") {
            this.setState({
              loading: true,
              visible: false,
              updateLoader: true,
            });
            this.refs.Messchild.showWarn(resdata.ResponseMessage);
          } else {
            this.refs.Messchild.showError(resdata.ResponseMessage);
          }
        });
      }, 100);
    }
  }

  // serch filter event
  //resolved KREP-9
  searchFilter(e) {
    this.setState({
      loading: true,
    });
    this.tblResponse.SearchValue = e.target.value;
    this.GetSchemes();
  }

  // call lazy loading
  getLazyLoadingValues(event) {
    this.setState({ rows: event.rows });
    this.tblResponse.PageSize = event.rows;
    this.tblResponse.First = event.first + 1;
    this.tblResponse.IsActive = true;
    this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
    //this.tblResponse.SortColumn = event.sortField;
    this.tblResponse.SortOrder = event.sortOrder === 1 ? "ASC" : "DESC";
  }

  // disabled check box
  activeTemplate(rowData, column) {
    return <input type="checkbox" disabled checked={rowData.IsActive} />;
  }

  // edit record
  actionTemplate(rowData, column) {
    const editMode = () => { 
    
      this.setState({
        visible: true,
        formHeading: "Update Scheme",
        buttonLabel: "Update",
        SchemeId: rowData.SchemeId,
        SchemeName: rowData.SchemeName,
        Schemedescription: rowData.Schemedescription,
        ProductName: rowData.ProductName,
        ProductId: rowData.ProductId,
        TypeName: rowData.TypeName,
        Utype: rowData.Utype,
        StartDate: new Date(rowData.StartDate),
        EndDate: new Date(rowData.EndDate),
        LoyaltyPoints: rowData.LoyaltyPoints,
        IsActive: rowData.IsActive,
      });
    };
    return (
      <span>
        <Button
          type="button"
          icon="pi pi-pencil"
          onClick={editMode}
          className="p-button-warning"
          style={{ marginRight: "3px" }}
        ></Button>
      </span>
    );
  }

  // sort data
  onSort(event) {
    this.setState({ sortOrder: this.state.sortOrder === 1 ? 2 : 1 });

    const sortField = event.sortField;
    this.tblResponse.SortColumn = sortField ? sortField : "";
    this.tblResponse.SortOrder = this.state.sortOrder === 1 ? "DESC" : "ASC";
    this.GetSchemes();
  }

  // return HTML
  //resolved KREP-16,KREP-110
  render() {
    const items = [{ label: "Masters" }, { label: "Scheme Master" }];

    const home = { icon: "pi pi-home", url: "/dashboard" };
    let header = (
      <div style={{ textAlign: "right" }}>
        <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>
        <InputText
          type="search"
          onInput={this.searchFilter}
          placeholder="Global Search"
          size="30"
        />
      </div>
    );

    return (
      <>
        {<MessageComponent ref="Messchild" />}
        {<Loaders loader={this.state.updateLoader} />}
        <div className="row mb-3">
          <div className="col-md-6 heading">
            <h1>Scheme Master</h1>
          </div>
          <div className="col-md-6 custom-breadcrumb">
            <BreadCrumb model={items} home={home} />
          </div>
        </div>
        <div className="heading">
          <Button
            label="Add Scheme"
            icon="pi pi-plus-circle"
            onClick={this.openDialog}
          />
        </div>

        <DataTable
          responsive={true}
          header={header}
          globalFilter={this.state.globalFilter}
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          emptyMessage={this.state.emptyMessage}
          value={this.state.sales}
          sortMode="multiple"
          paginator={true}
          rows={this.state.rows}
          rowsPerPageOptions={[5, 10, 20]}
          totalRecords={this.state.totalRecords}
          lazy={true}
          first={this.state.first}
          onPage={this.onPage}
          onSort={this.onSort}
        >
          <Column field="ProductName" header="Product Name" sortable={true} />
          <Column field="SchemeName" header="Scheme Name" sortable={true} />
          <Column
            field="Schemedescription"
            header="Scheme Description"
            sortable={true}
          />
          <Column
            field="LoyaltyPoints"
            header="Loyalty Points"
            sortable={true}
          />
          <Column
            field="StartDate"
            header="Valid From"
            filterFunction={this.filterDate}
            sortable={true}
          />
          <Column field="EndDate" header="Valid To" sortable={true} />
          <Column field="TypeName" header="User Type" sortable={true} />
          <Column body={this.activeTemplate} header="Is Active" />
          <Column body={this.actionTemplate} header="Action" />
        </DataTable>

        <Dialog
          className="popup-modal"
          header={this.state.formHeading}
          visible={this.state.visible}
          modal={true}
          onHide={this.hideFunc}
        >
          <form onSubmit={this.submitForm}>
            <div className="form-padding">
              <div className="row">
                <div className="col-md-4 text-right">
                  <label>Product Name <span>*</span>:</label>
                </div>
                <div className="col-md-8 mb-2">
                  <Dropdown filter disabled={this.state.edit} options={this.state.ProductDDL} placeholder={this.state.ProductName ? this.state.ProductName : "Select Product"} className="form-control" value={this.state.ProductId} onChange={this.handleChange} name="ProductId" />
                  {this.state.formError.ProductId !== null ?
                    <div className='error'>{this.state.formError.ProductId}</div> : null}
                </div>
                <div className="col-md-4 text-right">
                  <label>User Type <span>*</span>:</label>
                </div>
                <div className="col-md-8 mb-2">
                  <Dropdown filter disabled={this.state.edit} options={this.state.userDDL} placeholder={this.state.TypeName ? this.state.TypeName : "Select User"} className="form-control" value={this.state.Utype} onChange={this.handleChange} name="Utype" />
                  {this.state.formError.Utype !== null ?
                    <div className='error'>{this.state.formError.Utype}</div> : null}
                </div>
                <div className="col-md-4 text-right">
                  <label>
                    Scheme Name<span>*</span>:
                  </label>
                </div>
                <div className="col-md-8 mb-2">
                  <input
                    type="text"
                    value={this.state.SchemeName}
                    onChange={this.handleChange}
                    className="form-control"
                    name="SchemeName"
                  />
                  {this.state.formError.SchemeName !== null ? (
                    <div className="error">
                      {this.state.formError.SchemeName}
                    </div>
                  ) : null}
                </div>
                <div className="col-md-4 text-right">
                  <label>Scheme Description:</label>
                </div>
                <div className="col-md-8 mb-2">
                  <input
                    type="text"
                    value={this.state.Schemedescription}
                    onChange={this.handleChange}
                    className="form-control"
                    name="Schemedescription"
                  />
                </div>
                <div className="col-md-4 text-right">
                  <label>
                    Valid From<span>*</span>:
                  </label>
                </div>
                <div className="col-md-8 mb-2">
                  <Calendar
                    minDate={minDate}
                    className="custom-calender"
                    value={this.state.StartDate}
                    onChange={this.handleChange}
                    showIcon={true}
                    appendTo={document.getElementById("root")}
                    name="StartDate"
                  />
                  {/* <input type="text" value={this.state.StartDate} onChange={this.handleChange} className="form-control" name="StartDate" /> */}
                  {this.state.formError.StartDate !== null ? (
                    <div className="error">{this.state.formError.StartDate}</div>
                  ) : null}
                </div>
                <div className="col-md-4 text-right">
                  <label>
                    Valid To<span>*</span>:
                  </label>
                </div>
                <div className="col-md-8 mb-2">
                  <Calendar
                    minDate={minDate}
                    className="custom-calender"
                    value={this.state.EndDate}
                    appendTo={document.getElementById("root")}
                    onChange={this.handleChange}
                    showIcon={true}
                    name="EndDate"
                  />
                  {/* <input type="text" value={this.state.EndDate} onChange={this.handleChange} className="form-control" name="EndDate" /> */}
                  {this.state.formError.EndDate !== null ? (
                    <div className="error">{this.state.formError.EndDate}</div>
                  ) : null}
                </div>
                <div className="col-md-4 text-right">
                  <label>
                    Loyalty Points<span>*</span>:
                  </label>
                </div>
                <div className="col-md-8 mb-2">
                  <input
                    type="number"
                    value={this.state.LoyaltyPoints}
                    onChange={this.handleChange}
                    className="form-control"
                    name="LoyaltyPoints"
                  />
                  {this.state.formError.LoyaltyPoints !== null ? (
                    <div className="error">
                      {this.state.formError.LoyaltyPoints}
                    </div>
                  ) : null}
                </div>
                <div className="col-md-4 text-right">
                  <label>IsActive :</label>
                </div>
                <div className="col-md-8 mb-2">
                  <input
                    type="checkbox"
                    checked={this.state.IsActive}
                    className="ml-2"
                    name="IsActive"
                    onChange={this.handleChange}
                  />
                </div>
                <div className="col-md-12 text-center">
                  <button type="submit" className="btn btn-primary">
                    {this.state.buttonLabel}
                  </button>
                </div>
              </div>
              <div>
                <label>
                  Note: Fields marked as (
                  <span style={{ color: "red" }}>*</span>) are required
                </label>
              </div>
            </div>
          </form>
        </Dialog>
      </>
    );
  }
}
