import instance from './DefaultApi';

export default class SchemeService {
    ControllerName = 'UserPointTransaction';


    // Create UserPoint Transaction
    CreateUserPointTransaction(userPoint) {
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/CreateUserPointTransaction`, userPoint).then(res => res)
    }



}