import React, { Component } from "react";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import NumberIssuedService from "../../services/NumberIssuedService";
import CompanyMasterService from "../../services/CompanyMasterService";
import MessageComponent from "../../template/Message";
import tableRequest from "../../models/tableRequest";
import Loaders from "../../template/Loaders";
// Number Issued
export default class NumberIssued extends Component {
  constructor(props) {
    super(props);
    this.CompanyMasterService = new CompanyMasterService();
    this.IssuedService = new NumberIssuedService();
    this.tblResponse = new tableRequest();
    this.state = {
      visible: false,
      buttonLabel: "Submit",
      formHeading: "Add Number Issue",
      sortOrder: "",
      updateLoader: false,
      FromNo: "",
      ToNo: "",
      IssueQty: "",
      SalesOrderNo: "",
      CompanyId: "",
      formError: {
        CompanyId: null,
        FromNo: null,
        ToNo: null,
        IssueQty: null,
        SalesOrderNo: null,
      },

      sales: [],
      loading: true,
      first: 0,
      rows: 10,
      totalRecords: 0,
      globalFilter: null,
      emptyMessage: "No Data found",
    };

    this.handleChnage = this.handleChnage.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.hideFunc = this.hideFunc.bind(this);
    this.onPage = this.onPage.bind(this);
    this.searchFilter = this.searchFilter.bind(this);
    this.GetFromNo = this.GetFromNo.bind(this);
    this.onSort = this.onSort.bind(this);
  }
  componentDidMount() {
    setTimeout(() => {
      this.GetIssueNumbers();
      this.GetCompany();
    }, 1000);
  }

  // call on Page Event
  onPage(event) {
    this.setState({
      loading: true,
    });

    //imitate delay of a backend call
    setTimeout(() => {
      this.getLazyLoadingValues(event);
      this.GetIssueNumbers();
    }, 250);
  }

  // get product issue number list data
  GetIssueNumbers() {
    this.tblResponse.SortColumn = "CreatedDt";
    this.IssuedService.GetIssueNumbers(this.tblResponse).then((data) => {
      const resdata = data.data;
      //if ResponseData is not null
      if (
        resdata !== null &&
        resdata.ResponseData !== null &&
        resdata.ResponseData.length > 0
      ) {
        this.datasource = resdata.ResponseData;
        this.setState({
          totalRecords: resdata.ResponseData[0].TotalRows,
          first: this.tblResponse.First - 1,
          sales: this.datasource.slice(
            0,
            this.tblResponse.First - 1 + this.tblResponse.PageSize
          ),
          loading: false,
          updateLoader: true,
        });
      }
      //if ResponseData is null
      else {
        this.datasource = null;
        this.setState({
          totalRecords: 0,
          first: 1,
          sales: null,
          loading: false,
        });
      }
    });
  }

  // validate form
  //resolved KREP-17
  validateForm(value, name) {
    let errors = this.state.formError;
    let IsValid = true;
    //to validate each form field with required conditions
    switch (name) {
      case "CompanyId":
        if (value == undefined || value == "") {
          IsValid = false;
          errors.CompanyId = "Please select Company";
        } else {
          errors.CompanyId = "";
        }
        break;
      case "FromNo":
        if (value.trim().length < 1) {
          IsValid = false;
          errors.FromNo = "Please enter your from number.";
        } else {
          if (!value.match(/^[0-9]+$/)) {
            IsValid = false;
            errors.FromNo = "Please enter only number.";
            this.setState({ IssueQty: 0 });
          } else {
            errors.ToNo = null;
            errors.IssueQty = null;
            errors.FromNo = null;
            const fromNumber = value.trim() === "" ? 0 : parseInt(value.trim());
            const toNumber =
              this.state.ToNo.toString().trim() === ""
                ? 0
                : parseInt(this.state.ToNo.toString().trim());
            if (fromNumber >= 1 && toNumber >= 1) {
              if (fromNumber <= toNumber) {
                const qty = toNumber - fromNumber + 1;
                if (qty >= 0) {
                  this.setState({ IssueQty: qty });
                } else {
                  IsValid = false;
                  errors.FromNo = "From number should be less than to number.";
                  this.setState({ IssueQty: 0 });
                }
              } else {
                IsValid = false;
                errors.FromNo = "From number should be less than to number.";
                this.setState({ IssueQty: 0 });
              }
            } else {
              // IsValid = false;
              // errors.FromNo = "From number should be less than to number.";
              this.setState({ IssueQty: 0 });
            }
          }
        }
        break;
      case "ToNo":
        if (value < 1) {
          IsValid = false;
          errors.ToNo = "Please enter To Number.";
        } else errors.ToNo = null;
        break;
      case "IssueQty":
        if (value < 1) {
          IsValid = false;
          errors.IssueQty = "Please enter your quantity.";
        } else {
          // if (!value.match(/^[0-9]+$/)) {
          //     IsValid = false;
          //     errors.IssueQty = "Please enter only number.";
          //     this.setState({ IssueQty: 0 })
          // } else {

          errors.ToNo = null;
          errors.IssueQty = null;
          errors.FromNo = null;
          const qty = parseInt(value);
          const fromNumber =
            this.state.FromNo.toString().trim() === ""
              ? 0
              : parseInt(this.state.FromNo.toString().trim());
          if (fromNumber >= 1 && qty >= 1) {
            const toNumber = qty + fromNumber - 1;
            if (toNumber >= 0) {
              this.setState({ ToNo: toNumber });
            } else {
              IsValid = false;
              errors.IssueQty =
                "To number should be greater than or equal to  from number.";
              this.setState({ ToNo: 0 });
            }
          } else {
            // IsValid = false;
            // errors.ToNo = "To number should be greater than from number.";
            this.setState({ ToNo: 0 });
          }
          // }
        }
        break;
      case "SalesOrderNo":
        if (value < 1) {
          IsValid = false;
          errors.SalesOrderNo = "Please enter Sales order No";
        } else errors.SalesOrderNo = null;
        break;
      default:
        break;
    }

    return IsValid;
  }

  // handle input
  handleChnage(event) {
    const { name, value } = event.target;
    let errors = this.state.formError;

    const isValid = this.validateForm(value, name);
    this.setState({ errors, [name]: value });
  }
  // hide Func
  hideFunc() {
    this.setState({
      visible: false,
      buttonLabel: "Submit",
      formHeading: "Add Number Issue",
    });
    this.formReset();
  }

  // Reset Form
  formReset() {
    this.setState({
      FromNo: "",
      ToNo: "",
      IssueQty: "",
      SalesOrderNo: "",
    });

    let errors = this.state.formError;
    Object.entries(errors).map(([key, value]) => {
      errors[key] = "";
    });
  }

  // Submit Form
  submitForm(event) {
    event.preventDefault();
    let isfrmvalid = true;
    let checkerror = this.state.formError;

    Object.entries(checkerror).map(([key, value]) => {
      const val = this.state[key];
      let valid = this.validateForm(val, key);
      //form not valid
      if (!valid) {
        this.setState({ checkerror: isfrmvalid });
        isfrmvalid = false;
      }
    });
    //form valid
    if (isfrmvalid) {
      const formData = {
        FrmNo: this.state.FromNo === "" ? 0 : parseInt(this.state.FromNo),
        ToNo: this.state.ToNo === "" ? 0 : parseInt(this.state.ToNo),
        IssueQty:
          this.state.IssueQty === "" ? 0 : parseInt(this.state.IssueQty),
        SalesOrderNo: this.state.SalesOrderNo,
        CompanyId: this.state.CompanyId.CompId,
      };
      this.setState({ updateLoader: false });
      setTimeout(() => {
        this.IssuedService.CreateIssueNumber(formData).then((data) => {
          const resdata = data.data;
          //if responsecode is 200 sucess else error or warning message
          if (resdata.ResponseCode === "200") {
            this.refs.child.showSuccess(resdata.ResponseMessage);
            this.setState({
              loading: true,
              visible: false,
              updateLoader: true,
            });
            this.formReset();
            this.GetIssueNumbers();
          } else if (resdata.ResponseCode === "400") {
            this.refs.child.showWarn(resdata.ResponseMessage);
          } else {
            this.refs.child.showError(resdata.ResponseMessage);
          }
        });
      }, 1000);
    }
  }
  //Get Company list
  GetCompany() {
    this.CompanyMasterService.GetCompanyDDL().then((data) => {
      const resdata = data.data;
      //if resdata is not null state of companyDDL will be set with ResponseData
      if (
        resdata !== null &&
        resdata.ResponseData !== null &&
        resdata.ResponseData.length > 0
      ) {
        this.setState({
          companyDDL: resdata.ResponseData,
        });
      }
    });
  }
  // Get Next FromNo
  GetFromNo() {
    this.formReset();
    this.setState({ visible: true });
    this.IssuedService.GetFromNumbers().then((data) => {
      const resdata = data.data;
      //if ResponseData is not null
      if (resdata.ResponseCode === "200" && resdata.ResponseData != null) {
        let frmNumber = resdata.ResponseData.FrmNo;
        frmNumber = frmNumber ?? 0 + 1;
        this.setState({ FromNo: "" + frmNumber });
      }
    });
  }

  // set lazy loading value
  getLazyLoadingValues(event) {
    this.setState({ rows: event.rows });
    this.tblResponse.PageSize = event.rows;
    this.tblResponse.First = event.first + 1;
    this.tblResponse.IsActive = "1";
    this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
    //this.tblResponse.SortColumn = event.sortField;
    this.tblResponse.SortOrder = event.sortOrder === 1 ? "ASC" : "DESC";
  }

  // search Filter Data
  searchFilter(e) {
    this.setState({
      loading: true,
    });
    this.tblResponse.SearchValue = e.target.value;
    this.GetIssueNumbers();
  }

  // Sort Table
  onSort(event) {
    this.setState({ sortOrder: this.state.sortOrder === 1 ? 2 : 1 });
    const sortField =
      event.sortField == "CreatedDate" ? "CreatedDt" : event.sortField;
    this.tblResponse.SortColumn = sortField ? sortField : "";
    this.tblResponse.SortOrder = this.state.sortOrder === 1 ? "DESC" : "ASC";
    this.GetIssueNumbers();
  }

  // render HTML
  //resolved KREP-19
  //resolved KREP-37
  render() {
    let header = (
      <div style={{ textAlign: "right" }}>
        <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>
        <InputText
          type="search"
          onInput={this.searchFilter}
          placeholder="Global Search"
          size="30"
        />
      </div>
    );
    return (
      <>
        <MessageComponent ref="child" />
        {<Loaders loader={this.state.updateLoader} />}
        <div className="heading">
          <h1>Number Issued</h1>
          <Button
            label="Create Number Issued"
            icon="pi pi-plus-circle"
            onClick={this.GetFromNo}
          />
        </div>

        <DataTable
          responsive={true}
          header={header}
          globalFilter={this.state.globalFilter}
          emptyMessage={this.state.emptyMessage}
          value={this.state.sales}
          sortMode="multiple"
          paginator={true}
          rows={this.state.rows}
          rowsPerPageOptions={[5, 10, 20]}
          totalRecords={this.state.totalRecords}
          lazy={true}
          first={this.state.first}
          onPage={this.onPage}
          onSort={this.onSort}
        >
          <Column field="CompanyName" header="Company" sortable={true} />
          <Column field="FrmNo" header="From No." sortable={true} />
          <Column field="ToNo" header="To No." sortable={true} />
          <Column field="IssueQty" header="Quantity" sortable={true} />
          <Column
            field="SalesOrderNo"
            header="Sales Order No"
            sortable={true}
          />
          <Column field="CreatedDate" header="Created Date" sortable={true} />
        </DataTable>

        <Dialog
          className="popup-modal"
          header={this.state.formHeading}
          visible={this.state.visible}
          modal={true}
          onHide={this.hideFunc}
        >
          <form onSubmit={this.submitForm} className="form-padding">
            <div className="row">
              <div className="col-md-4 text-right">
                <label>
                  Company<span>*</span>:
                </label>
              </div>
              <div className="col-md-8 mb-2">
                <Dropdown
                  optionLabel="CompanyName"
                  onChange={this.handleChnage}
                  value={this.state.CompanyId}
                  className="form-control"
                  placeholder="Select Company"
                  name="CompanyId"
                  options={this.state.companyDDL}
                />
                {this.state.formError.CompanyId !== null ? (
                  <div className="error">{this.state.formError.CompanyId}</div>
                ) : null}
              </div>

              <div className="col-md-4 text-right">
                <label>
                  From No.<span>*</span>:
                </label>
              </div>
              <div className="col-md-8 mb-2">
                <input
                  type="text"
                  value={this.state.FromNo}
                  onChange={this.handleChnage}
                  className="form-control"
                  name="FromNo"
                />
                {this.state.formError.FromNo !== null ? (
                  <div className="error">{this.state.formError.FromNo}</div>
                ) : null}
              </div>

              <div className="col-md-4 text-right">
                <label>
                  IssueQty<span>*</span>:
                </label>
              </div>
              <div className="col-md-8 mb-2">
                <input
                  type="text"
                  value={this.state.IssueQty}
                  onChange={this.handleChnage}
                  className="form-control"
                  name="IssueQty"
                />
                {this.state.formError.IssueQty !== null ? (
                  <div className="error">{this.state.formError.IssueQty}</div>
                ) : null}
              </div>
              <div className="col-md-4 text-right">
                <label>
                  To No.<span>*</span>:
                </label>
              </div>
              <div className="col-md-8 mb-2">
                <input
                  type="text"
                  readOnly
                  value={this.state.ToNo}
                  onChange={this.handleChnage}
                  className="form-control"
                  name="ToNo"
                />
                {this.state.formError.ToNo !== null ? (
                  <div className="error">{this.state.formError.ToNo}</div>
                ) : null}
              </div>

              <div className="col-md-4 text-right">
                <label>
                  SalesOrderNo<span>*</span>:
                </label>
              </div>
              <div className="col-md-8 mb-2">
                <input
                  type="text"
                  value={this.state.SalesOrderNo}
                  onChange={this.handleChnage}
                  className="form-control"
                  name="SalesOrderNo"
                />
                {this.state.formError.SalesOrderNo !== null ? (
                  <div className="error">
                    {this.state.formError.SalesOrderNo}
                  </div>
                ) : null}
              </div>
              <div className="col-md-12 text-center">
                <button type="submit" className="btn btn-primary">
                  {this.state.buttonLabel}
                </button>
              </div>
            </div>
            <div>
              <label>
                Note: Fields marked as (<span style={{ color: "red" }}>*</span>)
                are required
              </label>
            </div>
          </form>
        </Dialog>
      </>
    );
  }
}
