import React, { Component, createRef } from "react";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { BreadCrumb } from "primereact/breadcrumb";
import Multiselect from "multiselect-react-dropdown";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "react-bootstrap";
import SchemeService from "../../services/SchemeService";
import ProductService from "../../services/ProductService";
import tableRequest from "../../models/tableRequest.js";
import { Dropdown } from "primereact/dropdown";
import MessageComponent from "../../template/Message";
import Loaders from "../../template/Loaders";
import $ from "jquery";

// Scheme Configuration
var minDate = new Date();
export default class SchemeConfiguration extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.LocRef = React.createRef();
    this.state = {
      isEdit: false,
      DynamicDDL: [],
      visible: false,
      updateLoader: false,
      buttonLabel: "Submit",
      sortOrder: "",
      stateID: [],
      SchemeType: 0,
      DynamicId: 0,
      SelectedDynamicId: [],
      SchemeName: "",
      viewmapping: false,
      mappingData: [],
      selectedMapping: [],
      select: [],
      SchemeBeneficiary: "",
      SchemeId: 0,
      // SchemeDesc: '',
      ValidFrm: "",
      ValidTo: "",
      LoyaltyPoints: 0,
      IsActive: true,
      formError: {
        points: null,
        SchemeName: null,
        SchemeBeneficiary: "",
        ValidFrm: null,
        ValidTo: null,
        //LoyaltyPoints: null,
        SchemeType: null,
        DynamicId: null,
        SelectedDynamicId: null,
      },
      SchemeDDL: [
        { name: "Product Based", value: "PB" },
        //{ name: 'Batch Based', value: 'BB' },
        { name: "Main Category ", value: "CM" },
        { name: "Sub Category", value: "SC" },
        { name: "Sub Division", value: "SD" },
      ],
      SchemeBeneficiaryDDL: [
        { name: "Dealer", value: 2 },
        { name: "Distributor", value: 2 },
        { name: "Farmer", value: 3 },
        { name: "All", value: 9 },
      ],
      formHeading: "Design Scheme",
      stateDDL: [],
      sales: [],
      loading: true,
      first: 0,
      rows: 5,
      totalRecords: 0,
      globalFilter: null,
      emptyMessage: "No Data found",
    };
    this.LocationSelectAll = this.LocationSelectAll.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.onSelectState = this.onSelectState.bind(this);
    this.onRemoveState = this.onRemoveState.bind(this);
    //this.brandTemplate = this.brandTemplate.bind(this);
    this.actionTemplate = this.actionTemplate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.activeTemplate = this.activeTemplate.bind(this);
    this.hideFunc = this.hideFunc.bind(this);
    this.SchemeService = new SchemeService();
    this.ProductService = new ProductService();
    this.tblResponse = new tableRequest();
    this.onPage = this.onPage.bind(this);
    this.searchFilter = this.searchFilter.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.getAllMappings = this.getAllMappings.bind(this);
    this.onSort = this.onSort.bind(this);
  }
  //trigger api function on page call
  componentDidMount() {
    setTimeout(() => {
      this.GetSchemesConfiguration();
      // this.GetSchemesDDL();
      this.GetstateDDL();
    }, 1000);
    $(".p-column-title").on("click", function () {
      $(this)
        .next()
        .removeClass("pi-sort")
        .toggleClass("pi-sort-up")
        .addClass("pi-sort-down");
    });
  }

  // call on Page
  onPage(event) {
    this.setState({
      loading: true,
    });

    //imitate delay of a backend call
    setTimeout(() => {
      this.getLazyLoadingValues(event);
      this.GetSchemesConfiguration();
    }, 250);
  }

  // search filter table
  searchFilter(e) {
    this.setState({
      loading: true,
    });
    this.tblResponse.SearchValue = e.target.value;
    this.GetSchemesConfiguration();
  }

  // open dialog
  openDialog() {
    this.setState({ visible: true });
    this.formReset();
  }

  // set lazy loading values
  getLazyLoadingValues(event) {
    this.setState({ rows: event.rows });
    this.tblResponse.PageSize = event.rows;
    this.tblResponse.First = event.first + 1;
    this.tblResponse.IsActive = "1";
    this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
    //this.tblResponse.SortColumn = event.sortField;
    this.tblResponse.SortOrder = event.sortOrder === 1 ? "ASC" : "DESC";
  }

  // get scheme Drop down list
  // GetSchemesDDL() {
  //     this.SchemeService.GetSchemeDDL().then(data => {
  //         const resdata = data.data;
  //         //if ResponseCode is 200
  //         if (resdata !== null && resdata.ResponseCode === '200') {
  //             this.setState({
  //                 SchemeDDL: resdata.ResponseData
  //             });
  //         }
  //     });
  // }

  //get Product drop down list
  GetDynamicDDL(value, DynamicId, flag) {
    this.setState({
      SelectedDynamicId: [],
    });
    if (flag != 0) {
      this.myRef.current.resetSelectedValues();
    }
    ;
    this.ProductService.GetMappedProductsDDLByType(value).then((data) => {
      const resdata = data.data;
      //if ResponseCode is 200
      if (resdata !== null && resdata.ResponseCode === "200") {
        this.setState({
          DynamicDDL: resdata.ResponseData,
        });

        if (DynamicId) {
          this.setState({
            DynamicId: DynamicId,
          });
        }
      }
    });
  }
  GetstateDDL() {
    this.ProductService.GetMappedProductsDDLByType("LB").then((data) => {
      const resdata = data.data;
      //if ResponseCode is 200

      if (resdata !== null && resdata.ResponseCode === "200") {
        this.setState({
          stateDDL: resdata.ResponseData,
        });
      }
    });
  }

  // get scheme configuration
  GetSchemesConfiguration() {
    this.tblResponse.SortColumn = "ValidFrm";
    this.SchemeService.GetSchemes(this.tblResponse).then((data) => {
      const resdata = data.data;
      this.setState({ updateLoader: true });
      //if ResponseData is not null
      if (
        resdata !== null &&
        resdata.ResponseData !== null &&
        resdata.ResponseData.length > 0
      ) {
        this.datasource = resdata.ResponseData;
        this.setState({
          totalRecords: resdata.ResponseData[0].TotalRows,
          first: this.tblResponse.First - 1,
          sales: this.datasource.slice(
            0,
            this.tblResponse.First - 1 + this.tblResponse.PageSize
          ),
          loading: false,
        });
      }
      //if ResponseData is null
      else {
        this.datasource = null;
        this.setState({
          totalRecords: 0,
          first: 1,
          sales: null,
          loading: false,
        });
      }
    });
  }

  // validate form
  validateForm(value, name) {
    let errors = this.state.formError;
    let IsValid = true;
    ;
    //to validate each form field with required conditions
    switch (name) {
      case "SchemeName":
        if (value == null || value.trim().length < 1) {
          IsValid = false;
          errors.SchemeName = "Please enter scheme.";
        } else errors.SchemeName = null;
        break;
      case "SchemeType":
        if (value == 0 || value.trim().length < 1) {
          IsValid = false;
          errors.SchemeType = "Please select scheme type.";
        } else if (value == "Scheme Type") {
          IsValid = false;
          errors.SchemeType = "Please select scheme type.";
        } else errors.SchemeType = null;
        break;
      case "SchemeBeneficiary":
        if (value == "Select Beneficiary" || value == null) {
          IsValid = false;
          errors.SchemeBeneficiary = "Please scheme beneficiary.";
        } else errors.SchemeBeneficiary = null;
        break;
      case "ValidFrm":
        if (value == null || !(value instanceof Date)) {
          IsValid = false;
          errors.ValidFrm = "Please select from date";
        } else if (
          !value.toLocaleDateString().match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/)
        ) {
          IsValid = false;
          errors.ValidFrm = "Please enter only date.";
        }
        //KREP-51
        else if (
          this.state.SchemeType == 0 &&
          Date.parse(value) < Date.parse(new Date(this.getCurrentDate()))
        ) {
          IsValid = false;
          errors.ValidFrm =
            "From date must be greater than or equal to today date.";
        } else if (
          value instanceof Date &&
          this.state.ValidTo instanceof Date
        ) {
          errors.ValidFrm = null;
          errors.ValidTo = null;

          const startDate = value;
          const endDate = this.state.ValidTo;
          if (!(Date.parse(startDate) <= Date.parse(endDate))) {
            IsValid = false;
            errors.ValidFrm = "From date must be less than to date.";
          }
        } else errors.ValidFrm = null;
        break;
      case "ValidTo":
        if (value == null || !(value instanceof Date)) {
          IsValid = false;
          errors.ValidTo = "Please select to date";
        } else if (
          !value.toLocaleDateString().match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/)
        ) {
          IsValid = false;
          errors.ValidTo = "Please enter only date.";
        } else if (
          Date.parse(value) < Date.parse(new Date(this.getCurrentDate()))
        ) {
          IsValid = false;
          errors.ValidTo =
            "To date must be greater than or equal to today date.";
        } else if (
          value instanceof Date &&
          this.state.ValidFrm instanceof Date
        ) {
          errors.ValidFrm = null;
          errors.ValidTo = null;

          const startDate = this.state.ValidFrm;
          const endDate = value;
          if (!(Date.parse(startDate) <= Date.parse(endDate))) {
            IsValid = false;
            errors.ValidTo = "To date must be greater than from date.";
          }
        } else errors.ValidTo = null;

        break;
      case "LoyaltyPoints":
        if (value == "" || parseFloat(value) < 1) {
          IsValid = false;
          errors.LoyaltyPoints = "Loyalty points must be minimum 1";
        } else errors.LoyaltyPoints = null;
        break;

      case "SelectedDynamicId":
        if (value.length < 1) {
          IsValid = false;
          errors.SelectedDynamicId = "Please Select Category.";
        } else errors.SelectedDynamicId = null;
        break;
      default:
        break;
    }
    return IsValid;
  }

  // handle change use for form input
  handleChange(event) {
    const { name, value } = event.target;
    let errors = this.state.formError;

    const isValid = this.validateForm(value, name);
    this.setState({ errors, [name]: value });
  }

  // form Reset
  formReset() {
    ;
    this.setState({
      isEdit: false,
      stateID: [],
      SchemeBeneficiary: "Select Beneficiary",
      SchemeType: "Scheme Type",
      SchemeId: 0,
      DynamicId: 0,
      DynamicDDL: [],
      SelectedDynamicId: [],
      SchemeName: "",
      // SchemeDesc: '',
      ValidFrm: "",
      ValidTo: "",
      LoyaltyPoints: 0,
      IsActive: true,
      selectedMapping: null,
    });

    let errors = this.state.formError;
    Object.entries(errors).map(([key, value]) => {
      errors[key] = "";
    });
  }

  // add user
  hideFunc() {
    this.setState({
      visible: false,
      buttonLabel: "Submit",
      formHeading: "Design Scheme",
    });
    this.formReset();
  }

  getCurrentDate() {
    var today = new Date();
    var dd = today.getDate();

    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }

    if (mm < 10) {
      mm = "0" + mm;
    }
    // today = mm + '-' + dd + '-' + yyyy;
    // today = mm + '/' + dd + '/' + yyyy;
    // today = dd + '-' + mm + '-' + yyyy;
    today = mm + "/" + dd + "/" + yyyy;
    return today;
  }

  // submit form
  submitForm(event) {
    ;
    this.setState({ viewmapping: false });
    event.preventDefault();
    let isfrmvalid = true;
    let checkerror = this.state.formError;

    Object.entries(checkerror).map(([key, value]) => {
      const val = this.state[key];
      let valid = this.validateForm(val, key);
      //form not valid
      if (!valid) {
        this.setState({ checkerror: isfrmvalid });
        isfrmvalid = false;
      }
    });
    // if (this.state.buttonLabel.includes("Submit")) {
    //   isfrmvalid = this.state.mappingData.length < 1 ? false : true;
    //   if (!isfrmvalid) {
    //     let error = this.state.formError;
    //     error.DynamicId = "Checklist in table for loyalty is null";
    //   }
    // }
    //form valid
    if (isfrmvalid) {
      // let val=[];
      // if(this.state.mappingData.length>0){
      // (this.state.mappingData).forEach(ele=>{
      //     val.push({id:ele["MappingId"]})
      // })
      //}
      ;
      const formData = {
        SchemeId: this.state.SchemeId,
        SchemeType: this.state.SchemeType,
        SchemeBeneficiary:
          typeof this.state.SchemeBeneficiary != "number"
            ? 0
            : this.state.SchemeBeneficiary,
        DynamicIds: this.state.SelectedDynamicId,
        SchemeName: this.state.SchemeName,
        // SchemeDesc: this.state.SchemeDesc,
        locationCodes: this.state.stateID == null ? [] : this.state.stateID,
        ValidFrm: this.state.ValidFrm.toLocaleDateString(),
        ValidTo: this.state.ValidTo.toLocaleDateString(),
        //LoyaltyPoints: this.state.LoyaltyPoints,
        IsActive: this.state.IsActive,
        mappingData:
          this.state.selectedMapping == null ? [] : this.state.selectedMapping,
      };
      this.setState({ updateLoader: false });
      setTimeout(() => {
        this.SchemeService.CreateScheme(formData).then((data) => {
          ;
          const resdata = data.data;
          //if ResponseCode is 200 success else error or warning message
          if (resdata !== null && resdata.ResponseCode === "200") {
            this.refs.child.showSuccess(resdata.ResponseMessage);
            this.setState({
              loading: true,
              visible: false,
              updateLoader: true,
            });
            this.formReset();

            this.GetSchemesConfiguration();
          } else {
            this.setState({
              loading: true,
              visible: false,
              updateLoader: true,
            });
            this.formReset();
            this.refs.child.showWarn(resdata.ResponseMessage);
          }

          // if (resdata.ResponseCode === '200') {
          //     this.refs.child.showSuccess(resdata.ResponseMessage);
          //     this.GetSchemes();
          // } else {
          //     this.refs.child.showError(resdata.ResponseMessage);
          // }
        });
      }, 1000);
    }
  }

  actionTemplate(rowData, column) {
    const editMode = (e) => {
      ;
      var schemtyp = this.state.SchemeDDL.find(
        (x) => x.name == rowData.SchemeType
      )?.value;
      if (schemtyp) {
        this.GetDynamicDDL(schemtyp, null, 0);
      }
      setTimeout(() => {
        this.SchemeService.GetSchemeDataById(rowData.SchemeId).then((data) => {
          ;
          const resdata = data.data;
          if (resdata !== null && resdata.ResponseCode === "200") {
            this.setState({
              SelectedDynamicId: resdata.ResponseData.DynamicIds,
              stateID: resdata.ResponseData.locationCodes,
            });
            this.myRef.current.state.selectedValues =
              resdata.ResponseData.DynamicIds;
          }
        });
      }, 1000);
      this.minDate = new Date(rowData.ValidFrm);

      this.setState({
        isEdit: true,
        visible: true,
        buttonLabel: "Update",
        formHeading: "Extend or Change Status of Scheme",
        SchemeId: rowData.SchemeId,
        SchemeName: rowData.SchemeName,
        SchemeType: rowData.SchemeType,
        SchemeBeneficiary: rowData.SchemeBeneficiary,
        //DynamicId: rowData.DynamicId,
        //SchemeDesc: this.state.SchemeDesc,
        //stateID: rowData.stateID,
        ValidFrm: new Date(rowData.ValidFrm),
        ValidTo: new Date(rowData.ValidTo),
        LoyaltyPoints: rowData.LoyaltyPoints,
        IsActive: rowData.IsActive,
      });
      // let checkbox = document.getElementsByName('IsActive');
      // checkbox[0].checked = rowData.IsActive;
    };

    return (
      <span>
        <Button
          type="button"
          icon="pi pi-pencil"
          onClick={editMode}
          className="p-button-warning"
          style={{ marginRight: "3px" }}
        ></Button>
        {/* <Button type="button" icon="pi pi-trash" onClick={deleteFunc} className="p-button-danger"></Button> */}
      </span>
    );
  }

  // add checkbox function
  activeTemplate(rowData, column) {
    return (
      <input
        type="checkbox"
        name={rowData.SchemeMapingId}
        checked={rowData.IsActive}
        readOnly
      /> // onChange={e => { this.actionTemplate(e, rowData) }} />
    );
  }

  // // edit form
  // actionTemplate(e, rowData) {

  //     const formData = {

  //         IsActive: e.target.checked,
  //         SchemeMapingId: rowData.SchemeId
  //     }

  //     setTimeout(() => {
  //         this.SchemeService.InActiveSchemeConfig(formData).then(data => {
  //             const resdata = data.data;
  //                 //if ResponseCode is 200 success else error or warning message

  //             if (resdata !== null && resdata.ResponseCode === '200') {
  //                 this.refs.child.showSuccess(resdata.ResponseMessage);
  //                 this.GetSchemesConfiguration();

  //             } else {
  //                 this.refs.Messchild.showWarn('Someting went wrong');
  //                 console.log(`Error Encountered ${resdata.ResponseMessage}`);
  //             }

  //             // if (resdata.ResponseCode === '200') {
  //             //     this.refs.child.showSuccess(resdata.ResponseMessage);
  //             //     this.GetSchemes();
  //             // } else {
  //             //     this.refs.child.showError(resdata.ResponseMessage);
  //             // }
  //         });
  //     }, 1000);
  // }

  getAllMappings(event) {
    this.setState({
      mappingData: null,
      selectedMapping: null,
      select: null,
    });
    event.preventDefault();
    let isfrmvalid = true;
    let checkerror = this.state.formError;
    ;
    Object.entries(checkerror).map(([key, value]) => {
      const val = this.state[key];
      let valid = this.validateForm(val, key);
      //form not valid
      if (!valid) {
        this.setState({ checkerror: isfrmvalid });
        isfrmvalid = false;
      }
    });
    // if (this.state.buttonLabel.includes("Submit")) {
    //   isfrmvalid = this.state.mappingData.length < 1 ? false : true;
    //   if (!isfrmvalid) {
    //     let error = this.state.formError;
    //     error.DynamicId = "Checklist in table for loyalty is null";
    //   }
    // }
    if (this.state.SelectedDynamicId.length != 0 && isfrmvalid) {
      this.setState({ viewmapping: true });

      this.ProductService.GetMappings(
        this.state.SelectedDynamicId,
        this.state.SchemeType
      ).then((data) => {
        const resdata = data.data;
        ;
        //if ResponseCode is 200
        if (resdata !== null && resdata.ResponseCode === "200") {
          this.setState({
            mappingData: resdata.ResponseData,
          });
        }
      });
    } else {
      //this.refs.child.showWarn("Please Select Scheme On and Scheme Category first")
    }
  }

  getMappings(id) {
    // this.setState({
    //   mappingData: [],
    // });
    //this.setState({ viewmapping: true });
    this.ProductService.GetMapping(id, this.state.SchemeType).then((data) => {
      const resdata = data.data;
      ;
      //if ResponseCode is 200
      if (resdata !== null && resdata.ResponseCode === "200") {
        var tmp = this.state.mappingData;
        if (tmp == undefined || tmp.length == 0) {
          console.log(resdata.ResponseData);
          this.setState({
            mappingData: resdata.ResponseData,
          });
        } else {
          tmp.push(resdata.ResponseData);
          this.setState({
            mappingData: tmp,
          });
        }
      }
    });
  }
  // sort page event
  onSort(event) {
    this.setState({ sortOrder: this.state.sortOrder === 1 ? 2 : 1 });
    const sortField = event.sortField;
    this.tblResponse.SortColumn = sortField ? sortField : "";
    this.tblResponse.SortOrder = this.state.sortOrder === 1 ? "DESC" : "ASC";
    this.GetSchemesConfiguration();
  }

  onSelect(selectedList, selectedItem) {
    this.setState({
      SelectedDynamicId: [...this.state.SelectedDynamicId, selectedItem],
    });
    //this.getMappings(selectedItem.value);
  }

  onRemove(selectedList, removedItem) {
    this.setState({
      SelectedDynamicId: this.state.SelectedDynamicId.filter(
        (item) => item.value != removedItem.value
      ),
    });
    // if (this.state.selectedMapping != null) {
    //   this.setState({
    //     selectedMapping: this.state.selectedMapping.filter(
    //       (mapping) => mapping.Id != removedItem.value
    //     ),
    //   });
    // }
  }

  onSelectState(selectedList, selectedItem) {
    ;
    this.setState({
      stateID: [...this.state.stateID, selectedItem],
    });
  }

  onRemoveState(selectedList, removedItem) {
    ;
    this.setState({
      stateID: this.state.stateID.filter((id) => id.value != removedItem.value),
    });
  }

  LocationSelectAll(e) {
    ;
    if (e.target.checked) {
      this.setState({
        stateID: this.state.stateDDL,
      });
    } else {
      this.setState({
        stateID: [],
      });
      this.LocRef.current.resetSelectedValues();
    }
  }

  // return HTML
  //resolved KREP-102
  render() {
    const items = [{ label: "Masters" }, { label: "Scheme Design" }];

    const home = { icon: "pi pi-home", url: "/dashboard" };

    const note = (
      <label>
        Note: Fields marked as (<span style={{ color: "red" }}>*</span>) are
        required
      </label>
    );
    const updatednote = (
      <label>
        Note*:Only scheme extened duration and status change will be Saved
      </label>
    );
    const MappingSelector = (
      <Dialog
        className="popup-modal"
        header="Select the Product Group to exclude"
        maximizable="true"
        visible="true"
        modal={true}
        onHide={() => this.setState({ viewmapping: false })}
      >
        <h5>Select Rows to Avail the Offer</h5>
        <DataTable
          value={this.state.mappingData}
          selection={this.state.select}
          onSelectionChange={(e) => {
            ;
            if (this.state.selectedMapping == null) {
              this.setState({ selectedMapping: e.value });
            } else {
              for (var i = 0; i < e.value.length; i++)
                this.setState({
                  selectedMapping: [...this.state.selectedMapping, e.value[i]],
                });
            }
            this.setState({ select: e.value });
          }}
          dataKey="MappingId"
          responsiveLayout="scroll"
        >
          <Column
            selectionMode="multiple"
            headerStyle={{ width: "3em" }}
          ></Column>
          <Column field="ProductName" header="Product Name"></Column>
          <Column field="BatchNo" header="Batch No"></Column>
          <Column field="Qty" header="Quantity"></Column>
          <Column field="MFGDate" header="Mfg Date"></Column>
          <Column field="EXPDate" header="Expire Date"></Column>
        </DataTable>
        <button className="btn btn-primary" onClick={this.submitForm}>
          Done
        </button>
      </Dialog>
    );
    return (
      <>
        <MessageComponent ref="child" />
        <Loaders loader={this.state.updateLoader} />
        <div className="heading">
          <div className="row mb-3">
            <div className="col-md-6 heading">
              <h1>Scheme Design</h1>
            </div>
            <div className="col-md-6 custom-breadcrumb">
              <BreadCrumb model={items} home={home} />
            </div>
          </div>
          <div className="heading">
            <Button
              label="Add Scheme"
              icon="pi pi-plus-circle"
              onClick={this.openDialog}
            />
          </div>
        </div>
        <DataTable
          responsive={true}
          globalFilter={this.state.globalFilter}
          emptyMessage={this.state.emptyMessage}
          value={this.state.sales}
          sortMode="multiple"
          paginator={true}
          rows={this.state.rows}
          rowsPerPageOptions={[5, 10, 20]}
          totalRecords={this.state.totalRecords}
          lazy={true}
          first={this.state.first}
          onPage={this.onPage}
          onSort={this.onSort}
        >
          <Column
            field="SchemeName"
            header="Scheme Name"
            sortable={true}
            style={{ width: "120px" }}
          />
          <Column
            field="ValidFrm"
            header="Valid From"
            filterFunction={this.filterDate}
            sortable={true}
            style={{ width: "90px" }}
          />
          <Column
            field="ValidTo"
            header="Valid To"
            sortable={true}
            style={{ width: "90px" }}
          />
          {/* <Column
            field="LoyaltyPoints"
            header="Points"
            sortable={true}
            style={{ width: "60px" }}
          /> */}
          <Column
            field="SchemeBeneficiary"
            header="Payee "
            sortable={true}
            style={{ width: "90px" }}
          />
          <Column
            field="SchemeType"
            header="Scheme On"
            sortable={true}
            style={{ width: "120px" }}
          />
          {/* <Column
            field="DynamicId"
            header="Tier"
            sortable={true}
            style={{ width: "150px" }}
          />
          <Column
            field="locationCode"
            header="Location"
            style={{ width: "150px" }}
          /> */}
          <Column
            body={this.activeTemplate}
            header="Status"
            style={{ width: "50px" }}
          />

          <Column
            body={this.actionTemplate}
            header="Action"
            style={{ width: "50px" }}
          />
        </DataTable>
        {this.state.viewmapping ? MappingSelector : null}
        <Dialog
          className="popup-modal"
          header={this.state.formHeading}
          visible={this.state.visible}
          modal={true}
          onHide={this.hideFunc}
          style={{ width: "50vw" }}
        >
          <form
            onSubmit={
              this.state.buttonLabel.includes("Submit")
                ? this.getAllMappings
                : this.submitForm
            }
            className="form-padding"
          >
            <div className="row">
              <div className="col-md-4 text-right">
                <label>
                  Scheme Name<span>*</span>:
                </label>
              </div>
              <div className="col-md-8 mb-2">
                <input
                  type="text"
                  value={this.state.SchemeName}
                  onChange={this.handleChange}
                  className="form-control"
                  name="SchemeName"
                />
                {this.state.formError.SchemeName !== null ? (
                  <div className="error">{this.state.formError.SchemeName}</div>
                ) : null}
              </div>
              {/* <div className="col-md-4 text-right">
                                    <label>Scheme Description:</label>
                                </div>
                                <div className="col-md-8 mb-2">
                                    <input type="text" value={this.state.SchemeDesc} onChange={this.handleChange} className="form-control" name="SchemeDesc" />
                                          </div>
                                */}
              {/* <div className="col-md-4 text-right">
                <label>
                  Loyalty Points<span>*</span>:
                </label>
              </div>
              <div className="col-md-8 mb-2">
                <input
                  type="number"
                  value={this.state.LoyaltyPoints}
                  onChange={this.handleChange}
                  className="form-control"
                  name="LoyaltyPoints"
                />
                {this.state.formError.LoyaltyPoints !== null ? (
                  <div className="error">
                    {this.state.formError.LoyaltyPoints}
                  </div>
                ) : null}
              </div> */}
              <div className="col-md-4 text-right">
                <label>Location :</label>
              </div>
              <div className="col-md-8 mb-2">
                <Multiselect
                  hidePlaceholder="true"
                  disable={this.state.isEdit}
                  ref={this.LocRef}
                  onSelect={this.onSelectState}
                  onRemove={this.onRemoveState}
                  displayValue="label"
                  placeholder="Select Location"
                  name="stateID"
                  options={this.state.stateDDL}
                  selectedValues={this.state.stateID}
                  //value={this.state.stateID}
                  //className="form-control"
                />
                <input
                  className="mt-4"
                  id="all"
                  disabled={this.state.isEdit}
                  onChange={this.LocationSelectAll}
                  type="checkbox"
                />
                <label className="ml-2" for="all">
                  {" "}
                  Select All Locations
                </label>
              </div>
              <div className="col-md-4 text-right">
                <label>
                  Scheme On<span>*</span>:
                </label>
              </div>
              <div className="col-md-8 mb-2">
                <Dropdown
                  optionLabel="name"
                  disabled={this.state.isEdit}
                  appendTo={document.body}
                  onChange={(e) => {
                    this.setState({ SchemeType: e.target.value });
                    this.GetDynamicDDL(e.target.value);
                  }}
                  value={this.state.SchemeType}
                  className="form-control"
                  placeholder={this.state.SchemeType}
                  name="SchemeType"
                  options={this.state.SchemeDDL}
                />
                {this.state.formError.SchemeType !== null ? (
                  <div className="error">{this.state.formError.SchemeType}</div>
                ) : null}
              </div>
              <div className="col-md-4 text-right">
                <label>
                  Scheme Beneficiary<span>*</span>:
                </label>
              </div>
              <div className="col-md-8 mb-2">
                <Dropdown
                  optionLabel="name"
                  disabled={this.state.isEdit}
                  appendTo={document.body}
                  onChange={(e) => {
                    this.setState({ SchemeBeneficiary: e.target.value });
                  }}
                  value={this.state.SchemeBeneficiary}
                  className="form-control"
                  placeholder={this.state.SchemeBeneficiary}
                  name="SchemeBeneficiary"
                  options={this.state.SchemeBeneficiaryDDL}
                />
                {this.state.formError.SchemeBeneficiary !== null ? (
                  <div className="error">
                    {this.state.formError.SchemeBeneficiary}
                  </div>
                ) : null}
              </div>
              <div className="col-md-4 text-right">
                <label>
                  Select Category<span>*</span>:
                </label>
              </div>
              <div className="col-md-8 mb-2">
                <Multiselect
                  id="category"
                  disable={this.state.isEdit}
                  hidePlaceholder="true"
                  ref={this.myRef}
                  onSelect={this.onSelect}
                  onRemove={this.onRemove}
                  placeholder="Select Category"
                  displayValue="label"
                  name="SelectedDynamicId"
                  options={this.state.DynamicDDL}
                  appendto={document.body}
                  selectedValues={this.state.SelectedDynamicId}
                  //value={this.state.DynamicId}
                  //className="form-control"
                />
                {this.state.formError.SelectedDynamicId !== null ? (
                  <div className="error">
                    {this.state.formError.SelectedDynamicId}
                  </div>
                ) : null}
              </div>
              <div className="col-md-4 text-right"></div>
              <div className="col-md-8 mb-2">
                <DataTable rowData value={this.state.SelectedDynamicId}>
                  <Column
                    field="label"
                    header="Category Items"
                    style={{ width: "50px" }}
                  />
                  <Column
                    rowSpan="row"
                    body={(rowdata) => {
                      ;
                      return (
                        <input
                          type="number"
                          disabled={this.state.isEdit}
                          onChange={(event) => {;
                            if(parseInt(event.target.value) < 1){
                              this.refs.child.showWarn("Enter Points Greater than 0")
                            }
                            var ele = event.target.parentNode.parentNode.textContent;
                            var tmp = this.state.SelectedDynamicId;
                            tmp = tmp.map((element) => {
                              if (element.label == ele) {
                                element.Points = event.target.value;
                              }
                              return element;
                            });
                            this.setState({
                              SelectedDynamicId: tmp,
                            });
                          }}
                          value={rowdata.Points}
                          placeholder="Enter Points"
                        />
                      );
                    }}
                    header="Loyalty Points"
                    style={{ width: "50px" }}
                  />
                </DataTable>
              </div>
              <div className="col-md-4 text-right">
                <label>
                  Valid From<span>*</span>:
                </label>
              </div>
              <div className="col-md-8 mb-2">
                <Calendar
                  minDate={minDate}
                  disabled={this.state.isEdit}
                  className="custom-calender"
                  value={this.state.ValidFrm}
                  onChange={this.handleChange}
                  placeholder={this.state.ValidFrm}
                  showIcon={true}
                  appendTo={document.getElementById("root")}
                  name="ValidFrm"
                />
                {/* <input type="text" value={this.state.ValidFrm} onChange={this.handleChange} className="form-control" name="ValidFrm" /> */}
                {this.state.formError.ValidFrm !== null ? (
                  <div className="error">{this.state.formError.ValidFrm}</div>
                ) : null}
              </div>
              <div className="col-md-4 text-right">
                <label>
                  Valid To<span>*</span>:
                </label>
              </div>
              <div className="col-md-8 mb-2">
                <Calendar
                  minDate={this.state.ValidFrm}
                  className="custom-calender"
                  value={this.state.ValidTo}
                  placeholder={this.state.ValidTo}
                  appendTo={document.getElementById("root")}
                  onChange={this.handleChange}
                  showIcon={true}
                  name="ValidTo"
                />
                {/* <input type="text" value={this.state.ValidTo} onChange={this.handleChange} className="form-control" name="ValidTo" /> */}
                {this.state.formError.ValidTo !== null ? (
                  <div className="error">{this.state.formError.ValidTo}</div>
                ) : null}
              </div>
              <div className="col-md-4 text-right">
                <label>IsActive :</label>
              </div>
              <div className="col-md-8 mb-2">
                <input
                  type="checkbox"
                  checked={this.state.IsActive}
                  className="ml-2"
                  name="IsActive"
                  onChange={(e) =>
                    this.setState({ IsActive: e.target.checked })
                  }
                />
              </div>

              <div className="col-md-12 text-center">
                <button type="submit" className="btn btn-primary">
                  {this.state.buttonLabel}
                </button>
              </div>
            </div>
            <div>
              {this.state.buttonLabel.includes("Submit") ? note : updatednote}{" "}
            </div>
          </form>
        </Dialog>
      </>
    );
  }
}
