import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "./views/Login";
import TnC from "./views/Tnc";
import PrivacyPolicy from "./views/PrivacyPolicy";
import Layout from "./template/Layout";
import ProductAuthentication from "./views/ProductAuthentication";

import HomePage from './views/HomePage';
export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: false,
      authPage: "other",
    };
  }

  componentDidMount() {
    
    this.setState({ urlParams: window.location.pathname });
    const urlParams = window.location.pathname;

    if (urlParams === "/P" || urlParams === "/p") {
      this.setState({
        authPage: "/P",
      });
    } else {
      this.setState({
        authPage: "other",
      });
    }
    if (this.state.authPage === "other") {
      let storeCollector = JSON.parse(sessionStorage.getItem("LoggedInUser"));
      if (storeCollector && storeCollector.login) {
        this.setState({
          login: true,
        });
      } else {
        this.setState({
          login: false,
        });
      }
    }
  }

  render() {
    return (
      <>
        <Router>
          <Switch>
            <Route path="/P" component={ProductAuthentication} />
            <Route path="/home" Component={HomePage} />
            <Route path="/login" Component={Login} />
            <Route path="/dashboard" Component={Layout} />
          </Switch>
        </Router>
        {
          this.state.login ?
            (this.state.urlParams === '/P' ? null : <Layout />)
            : (this.state.urlParams === '/P' ? null : this.state.urlParams === '/login' ? <Login /> : <HomePage />)
        }
      </>
    );
  }
}
