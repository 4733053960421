import instance from './DefaultApi';

export default class NumberIssuedService {
    ControllerName = 'IssueNumber';

    // get all product Issue Number data
    GetIssueNumbers(tableRequest) {
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetIssueNumbers`, tableRequest).then(res => res)
    }

    // create new product Issue Number  
    CreateIssueNumber(issueData) {
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/CreateIssueNumber`, issueData).then(res => res)
    }

    // delete product Issue Number data
    DeleteIssueNumber(issueId) {
        return instance.get(`${process.env.REACT_APP_API_URL}${this.ControllerName}/DeleteIssueNumber/${issueId}`).then(res => res)
    }

    //get max from number to set new number issue
    GetFromNumbers() {
        return instance.get(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetFromNumbers`).then(res => res)
    }
}