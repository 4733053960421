import React, { Component } from 'react';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import ProductService from '../../services/ProductService';
import { Dropdown } from 'primereact/dropdown';
import { BreadCrumb } from 'primereact/breadcrumb';
import ProductConfigurationService from '../../services/ProductConfigurationService';
import tableRequest from '../../models/tableRequest';
import { Thumbnail } from 'react-bootstrap';
import MessageComponent from '../../template/Message';

export default class ProductConfiguration extends Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: false,
            ProductId: null,
            ProductConfiguration: '',
            emptyMessage: 'No Data found',
            formHeading: 'Add Product Master',
            buttonLabel: 'Submit',
            sales: [
                // { Configuration: 'Share', Value: 'IsShare' },
                { Configuration: 'Feedback', Value: 'IsFeedback' },
                { Configuration: 'Product Image', Value: 'IsProductImage' },
                // { Configuration: 'Company Logo', Value: 'IsCompanyLogo' },
                // { Configuration: 'Rating', Value: 'IsRating' },
                // { Configuration: 'Product Catelogue', Value: 'IsProductCatelogue' }
            ],
            Products: [],
            formError: {
                ProductId: null
            }
        }

        this.ProductService = new ProductService();
        this.ProductConfService = new ProductConfigurationService();
        this.brandTemplate = this.brandTemplate.bind(this);
        this.actionTemplate = this.actionTemplate.bind(this);
        this.submitForm = this.submitForm.bind(this);

        this.getConfigrationData = this.getConfigrationData.bind(this);
        this.allcheck = this.allcheck.bind(this);
        this.singleCheck = this.singleCheck.bind(this);
    }
    //to trigger api function on page call
    componentDidMount() {
        setTimeout(() => {
            this.GetProductDDL();
        }, 1000);
    }
    //to get configuration data
    getConfigrationData(event) {
        const productId = event.value;
        this.setState({
            ProductId: event.value
        });

        this.ProductConfService.getConfigrationData(productId).then(res => {
            const resdata = res.data;

            let IsParentCheck = false;
            const checkboxes = document.getElementsByName('childCheck');
            if (resdata !== null && resdata.ResponseCode === '200') {
                const objdata = resdata.ResponseData;
                if (objdata.ProdConfiguration != null && objdata.ProdConfiguration.length > 0) {
                    const obj = JSON.parse(objdata.ProdConfiguration);
                    for (var checkbox of checkboxes) {
                        if (obj.hasOwnProperty(checkbox.value)) {
                            const keyval = obj[checkbox.value];
                            if (keyval) {
                                checkbox.checked = true;
                            } else {
                                checkbox.checked = false;
                                IsParentCheck = true;
                            }
                        } else {
                            checkbox.checked = false;
                            IsParentCheck = true;
                        }
                    }
                } else {
                    for (let checkbox of checkboxes) {
                        checkbox.checked = false;
                        IsParentCheck = true;
                    }
                }
            } else {
                for (let checkbox of checkboxes) {
                    checkbox.checked = false;
                    IsParentCheck = true;
                }
            }

            let parentCheckboxe = document.getElementsByName('parentCheck');
            if (!IsParentCheck) {
                parentCheckboxe[0].checked = true;
            } else {
                parentCheckboxe[0].checked = false;
            }
        });

        if (productId > 0) {
            let errors = this.state.formError;
            Object.entries(errors).map(([key, value]) => {
                errors[key] = '';
            });
        }
    }
    //to reset form
    formReset() {
        this.setState({
            productId: '',
            ProductConfiguration: '',
        })
    }
    //to validate form
    validateForm(value, name) {
        let errors = this.state.formError;
        let IsValid = true;

        switch (name) {
            case 'ProductId':
                if (value == null && value < 1) {
                    IsValid = false;
                    errors.ProductId = "Please select product.";
                } else errors.ProductId = ''
                break;
            default:
                break;
        }

        return IsValid;
    }
    //to submit form
    submitForm(event) {
        event.preventDefault();
        this.singleCheck();
        const formData = {
            ProductId: this.state.ProductId,
            ProdConfiguration: '' + this.state.ProductConfiguration,
            BtnType: event.currentTarget.value
        }

        let isfrmvalid = true;
        let checkerror = this.state.formError;

        Object.entries(checkerror).map(([key, value]) => {
            const val = this.state[key]
            let valid = this.validateForm(val, key);
            if (!valid) {
                this.setState({ checkerror: isfrmvalid });
                isfrmvalid = false;
            }
        });

        if (isfrmvalid) {
            this.ProductConfService.UpdateProductConfigration(formData).then((res) => {
                const resdata = res.data;
                if (resdata !== null && resdata.ResponseCode === '200') {
                    this.refs.Messchild.showSuccess(resdata.ResponseMessage);
                } else if (resdata.ResponseCode === '400') {
                    this.refs.Messchild.showWarn(resdata.ResponseMessage);
                } else {
                    this.refs.Messchild.showError(resdata.ResponseMessage);
                }
            });
        }
    }

    // get product ddl list data
    GetProductDDL() {
        this.ProductService.GetProductsDDL().then(data => {
            const resdata = data.data;
            if (resdata !== null && resdata.ResponseData !== null && resdata.ResponseData.length > 0) {
                const ProductsDDL = resdata.ResponseData;
                this.setState({
                    Products: ProductsDDL
                });
            } else {
                this.setState({
                    Products: null
                });
            }
        });
    }

    brandTemplate(rowData, column) {
        var src = rowData.ProductPic;
        return <img src={src} width="100" alt={rowData.ProductPic} />;
    }

    actionTemplate(rowData, column) {

        return <div>
            <input type="checkbox" onChange={this.singleCheck} value={rowData.Value} name="childCheck" />
        </div>;
    }

    allcheck(event) {

        let obj = {};
        const checkboxes = document.getElementsByName('childCheck');
        for (var checkbox of checkboxes) {
            let ischeck = false;
            if (event.currentTarget.checked == true) {
                ischeck = true;
            }

            if (ischeck) {
                checkbox.checked = true;
                obj[checkbox.value] = true;
            } else {
                checkbox.checked = false;
                obj[checkbox.value] = false;
            }
        }

        this.setState({
            ProductConfiguration: JSON.stringify(obj)
        })
    }

    singleCheck() {
        let obj = {};
        const checkboxes = document.getElementsByName('childCheck');
        for (var checkbox of checkboxes) {
            if (checkbox.checked) {
                obj[checkbox.value] = true;
            } else {
                obj[checkbox.value] = false;
            }
        }

        this.setState({
            ProductConfiguration: JSON.stringify(obj)
        })
    }
    //to render html part
    render() {
        const items = [
            { label: 'Masters' },
            { label: 'Product Configuration' }
        ];

        const home = { icon: 'pi pi-home', url: '/dashboard' }

        let header = (
            <div style={{ 'textAlign': 'right' }} id="ddlProdcutConfiguration">
                <Dropdown value={this.state.ProductId} name="ProductId" options={this.state.Products}
                    filter={true} filterPlaceholder="Select Product" filterBy="label,value" showClear={true}
                    onChange={this.getConfigrationData} style={{ 'padding-right': '25px' }} />

                {this.state.formError.ProductId !== null ?
                    <div className='error'>{this.state.formError.ProductId}</div> : null}
            </div>
        );

        let footer = (
            <div className="row">
                <div className="col-md-6" style={{ 'textAlign': 'right' }}>
                    <button className="btn btn-primary" value="btnsingle" onClick={this.submitForm}>Save</button>
                </div>

                <div className="col-md-6" style={{ 'textAlign': 'right' }}>
                    <button className="btn btn-info" value="btnall" onClick={this.submitForm}>Apply for all product</button>
                </div>
            </div>
        )

        let checkboxCol = (
            <div>
                <input type="checkbox" name="parentCheck" onChange={this.allcheck} /> Select All
            </div>
        )

        return (
            <>
                <MessageComponent ref="Messchild" />
                <div className="row mb-3">
                    <div className="col-md-6 heading">
                        <h1>Product Configuration</h1>
                    </div>
                    <div className="col-md-6 custom-breadcrumb">
                        <BreadCrumb model={items} home={home} />
                    </div>
                </div>
                <div className="heading">
                </div>

                <DataTable header={header} footer={footer} globalFilter={this.state.globalFilter}
                    emptyMessage={this.state.emptyMessage} value={this.state.sales} sortMode="multiple" rows={10}>
                    <Column field="Configuration" header="Configuration" sortable={true} />
                    <Column body={this.actionTemplate} header={checkboxCol} style={{ textAlign: 'left', width: '50%' }} />
                </DataTable>
            </>
        )
    }
}