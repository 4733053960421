import React, { Component, useRef } from "react";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import tableRequest from "../../models/tableRequest.js";
import qrData from "../../models/qrData.js";
import ProductService from "../../services/ProductService";
import MessageComponent from "../../template/Message";
import PrintSecondaryPackagingService from "../../services/PrintSecondaryPackagingService";
import { Button } from "primereact/button";
import { BreadCrumb } from "primereact/breadcrumb";
import { Card } from "primereact/card";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { CSVLink } from "react-csv";
import Loaders from "../../template/Loaders";
export default class CartonSlip extends Component {
  constructor(props) {
    super(props);
    this.ProductService = new ProductService();
    this.csv = React.createRef();
    this.PrintSecondaryPackagingService = new PrintSecondaryPackagingService();
    this.tblResponse = new tableRequest();
    this.qrData = new qrData();
    this.state = {
      FromNo: 1,
      Qty: null,
      type: "",
      ToNo: null,
      Prefix: "",
      Sgen: "",
      Tgen: "",
      qrData: [],
      fileType: 1,
    };
    this.createCSV = this.createCSV.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }
  //to trigger api function o page call
  componentDidMount() {
    this.GetLastIds(sessionStorage.getItem("Prefix"));
  }

  GetLastIds(prefix) {
    this.ProductService.GetIds(prefix).then((res) => {
      if (res.data.ResponseCode == "200") {
        const data = res.data.ResponseMessage.split("VAL");
        this.setState({
          Sgen: parseInt(data[0]) + 1,
          Tgen: parseInt(data[1]) + 1,
        });
      } else {
        this.refs.Messchild.showWarn(res.data.ResponseMessage);
      }
    });
  }
  submitForm() {
    if (
      this.state.FromNo == "" ||
      this.state.ToNo == "" ||
      this.state.Qty == "" ||
      this.state.FromNo == undefined ||
      this.state.ToNo == undefined ||
      this.state.Qty == undefined ||
      this.state.type == undefined ||
      this.state.type == null
    ) {
      this.refs.Messchild.showWarn("Invalid Arguments");
    } else {
      this.setState({
        FromNo: this.state.ToNo,
        Qty: "",
        ToNo: "",
      });
      if (this.state.fileType == 1)
        this.PrintSecondaryPackagingService.BulkData(
          this.state.type,
          this.state.FromNo,
          this.state.ToNo,
          this.state.Prefix
        );
      else if (this.state.fileType == 2)
        this.createCSV(
          this.state.type,
          this.state.FromNo,
          this.state.ToNo,
          this.state.Prefix
        );
      if (
        parseInt(this.state.Sgen) < parseInt(this.state.ToNo) ||
        this.state.Tgen < parseInt(this.state.ToNo)
      ) {
        this.ProductService.UpdateIds(
          this.state.Prefix,
          this.state.type,
          this.state.ToNo
        ).then((res) => {
          if (res.data.ResponseCode == "200") {
            this.refs.Messchild.showSuccess(res.data.ResponseMessage);
            this.GetLastIds(sessionStorage.getItem("Prefix"));
          } else {
            this.GetLastIds(sessionStorage.getItem("Prefix"));
            this.refs.Messchild.showWarn(res.data.ResponseMessage);
          }
        });
      } else {
        this.refs.Messchild.showSuccess("Download Succcess");
      }
    }
  }

  createCSV(type, from, to, prefix) {
    let year = new Date().getFullYear();
    let max = to + 1;
    let list = [];
    for (let i = from; i < max; i++) {
      var qr = new qrData();
      let val = i.toString().padStart(5, "0");
      qr.SrNo = i.toString().padStart(5, "0");
      qr.Qrdata = `${type}-${year}-${prefix}-${val}`;
      list.push(qr);
    }
    let date = new Date();
    this.setState({
      qrData: list,
      fileName:
        `${prefix}-${type}-${from}-${to} ${new Date().toLocaleString()}` +
        ".csv",
    });

    setTimeout(() => {
      this.csv.current.link.click();
    }, 500);
  }
  handleChange(e) {
    //const { name, value } = e.target;
    // this.setState({name: value });
    //      this.setState({FromNo:e})
  }
  onValueChange(e) {
    if (e.target.value == "TID") {
      this.setState({
        type: e.target.value,
        FromNo: this.state.Tgen,
        Qty: "",
        ToNo: "",
        Prefix: sessionStorage.getItem("Prefix"),
      });
    } else if (e.target.value == "SID") {
      this.setState({
        type: e.target.value,
        FromNo: this.state.Sgen,
        Qty: "",
        ToNo: "",
        Prefix: sessionStorage.getItem("Prefix"),
      });
    }
  }
  //sessionStorage.getItem("Prefix")
  render() {
    const items = [{ label: "Transactions" }, { label: ">Carton Slip" }];
    const home = { icon: "pi pi-home", url: "/dashboard" };

    return (
      <>
        <MessageComponent ref="Messchild" />
        {<Loaders loader={this.state.updateLoader} />}
        <div hidden>
          <DataTable rowData value={this.state.QrData}>
            <Column field="SrNo" header="Sr No." style={{ width: "50px" }} />
            <Column field="Qrdata" header="Qr Data" style={{ width: "50px" }} />
          </DataTable>
          <CSVLink
            data={this.state.qrData}
            enclosingCharacter={``}
            filename={this.state.fileName}
            className="hidden"
            ref={this.csv}
            target="_blank"
          />
        </div>
        <Card>
          <div className="row mb-3">
            <div className="col-md-6 heading">
              <h1>Carton Slip</h1>
            </div>
            <div className="col-md-6 custom-breadcrumb">
              <BreadCrumb model={items} home={home} />
            </div>
          </div>
          <div className="col-md-12 text-center">
            <input
              type="radio"
              value="SID"
              className="mr-3 ml-3"
              name="type"
              onChange={this.onValueChange}
            />
            Secondary Carton Slips{" "}
            {/* <input
              type="radio"
              value="TID"
              className="ml-3"
              name="type"
              onChange={this.onValueChange}
            />{" "}
            Tertiary Carton Slip */}
          </div>
          <br />
          <br />
          <div className="usearea">
            <div className="col-md-2 text-right mb-8 ">
              <label>
                From Number<span>*</span>:
              </label>
            </div>
            <div className="col-md-2 mb-2 ">
              <input
                type="number"
                value={this.state.FromNo}
                onChange={(e) =>
                  this.setState({ FromNo: e.target.value, Qty: "", ToNo: "" })
                }
                className="form-control"
                name="FromNo"
              />
            </div>
            <div className="col-md-1 text-right    mb-8">
              <label>
                Quantity<span>*</span>:
              </label>
            </div>
            <div className="col-md-2 mb-2">
              <input
                type="number"
                value={this.state.Qty}
                onChange={(e) =>
                  this.setState({
                    ToNo:
                      parseInt(e.target.value) +
                      parseInt(this.state.FromNo) -
                      1,
                    Qty: parseInt(e.target.value),
                  })
                }
                className="form-control"
                name="Qty"
              />
            </div>
            <div className="col-md-2 mb-2 ">
              <label>
                To Number. (Included)<span>*</span>:
              </label>
            </div>
            <div className="col-md-2 ">
              <input
                type="number"
                value={this.state.ToNo}
                className="form-control"
                name="ToNo"
                disabled
              />
            </div>
            <br />
            <div className="text-center my-5">
              <div className="col-md-2 mb-2" style={{ marginLeft: "30px" }}>
                <label>File Type:</label>
              </div>
              <select
                className="form-control"
                value={this.state.fileType}
                onChange={(e) => {
                  this.setState({ fileType: e.target.value });
                }}
                style={{ width: "200px" }}
              >
                <option value={1}>PDF</option>
                <option value={2}>CSV</option>
              </select>
            </div>
            <div className="text-center my-5 ">
              <button
                className="btn btn-primary mt-5"
                disabled={!this.state.type}
                onClick={this.submitForm}
              >
                Submit
              </button>
            </div>
          </div>
        </Card>
      </>
    );
  }
}
