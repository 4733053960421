import instance from './DefaultApi';

export default class DealerLoyaltyReportService {
    ControllerName = 'Scheme';

    // get all data DealerLoyaltyReport
    GetDealerLoyaltyReport(tableRequest) {
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetDispatchLoyaltyData`, tableRequest).then(res => res)
    }

    // Get Verification Export Excel
    GetDealerLoyaltyExport(tableRequest) {
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetDispatchLoyaltyExport`,tableRequest).then(res => res)
    }

    
}