import QRCode from 'qrcode'
import * as jsPDF from 'jspdf';


/// TO print cartonslip on secondary packing service
export default class PrintSecondaryPackagingService {

    genQR(text) {
        let qr = "";
        QRCode.toDataURL(text, function (err, url) {
            qr = url;
        });
        return qr;
    }
    formPreview1(resp) {
        let doc = new jsPDF({
            orientation: 'landscape',
            unit: 'mm',
            format: [90, 70],
            compressPdf: true
        });

        doc = this.printLblPrntContent1(doc, resp);
        doc.save(resp[0].CartonId + '.pdf');
        // window.open(doc.output('bloburl'), '_blank');
    }
    printLblPrntContent1(doc, resp) {

        let i = 1;
        let j = 0;

        for (; i <= resp.length; i++) {
            // doc.setFontSize(7)
            doc.setFontType('bold');
            doc.setFont('Times');
            var loc = 0,
                inc = 3,
                docW = 2;
            //loc += inc;
            //doc.text(50, loc, resp.rateCd);
            //loc += inc + 4;
            //doc.text(docW, loc, 'Item  ');
            //doc.text(9 + docW, loc, ': ' + resp[j].itemNm);
            //loc += inc + 2;
            //doc.text(docW, loc, 'M.R.P.  ');
            //doc.text(9 + docW, loc, ': INR ' + resp.mrp);
            //loc += inc + 1;
            //doc.text(docW, loc, 'Lot No ');
            //doc.text(9 + docW, loc, ': ' + resp.lotNo);
            //loc += inc + 1;
            //doc.text(docW, loc, 'Pack ');
            //doc.text(9 + docW, loc, ': ' + resp.mfgDt.MyDtFrmt('M yyyy', '-'));
            //loc += inc + 1;
            //
            //doc.text(9 + docW, loc, ': ' + resp.pktWt + ' ' + resp.unitId.unitNm);
            doc.setFontSize(10);

            let qrdata = this.genQR(resp[j].CartonId);
            ;
            doc.addImage(qrdata, 'JPEG', 0, 4, 15, 15, resp[j].CartonId, false);
            doc.text(resp[j].ProductName, 1, 4);

            // if (resp[j].Brand.length > 0)
            // doc.text("Date : " + resp[j].CreatedDt, 20, 10);
            doc.text(resp[j].CreatedDt, 15, 10);
            // if (resp[j].ModelNumber.length > 0)
            //     doc.text("Model : " + resp[j].ModelNumber, 20, 14);

            //doc.text("Qty : " + resp[j].Qty, 20, 18);
            doc.text(resp[j].Qty, 15, 16);
            doc.setFontSize(10);
            doc.text(resp[j].CartonId, 1, 22);


            // qrdata = '';
            j++;
            if (resp.length > i)
                doc.addPage();
        }
        return doc;
    }
    BulkData(type, from, to, pre) {
        
        let doc = new jsPDF({
            orientation: 'portrait',
            unit: 'mm',
            format: 'a4',
            compressPdf: true
        });
        doc = this.bulkPrint(doc, type, from, to, pre);
        doc.save(`${pre}-${type}-${from}-${to} ${new Date().toLocaleString()}` + '.pdf')

    }
    bulkPrint(doc, type, from, to, pre) {
        
        const pdfWidth = doc.internal.pageSize.getWidth();
        const pdfHeight = doc.internal.pageSize.getHeight();
        let year = new Date().getFullYear()
        const numberInFile = 32;
        let initial = parseInt(from);
        let fileCount = Math.floor(((to - from) + 1) / numberInFile);
        var remainder = ((to - from) + 1) % numberInFile;
        fileCount = remainder > 0 ? (fileCount + 1) : fileCount;

        for (let i = 0; i < fileCount; i++) {
            let _tonumber = ((initial + numberInFile) - 1);
            _tonumber = _tonumber > to ? to : _tonumber;
            let rowCount = 1;
            for (let j = initial; j <= _tonumber; j++) {
                doc.setFontType('bold');
                doc.setFont('Times');
                doc.setFontSize(10);

                let val = j.toString().padStart(5, "0")
                let CartonID = `${type}-${year}-${pre}-${val}`;
                let qrdata = this.genQR(CartonID);

                if (rowCount == 1) {
                    doc.addImage(qrdata, 'JPEG', 15, 15, 17, 17, CartonID, false);
                    //doc.text(new Date().toDateString(), 3, 5);
                    doc.setFontSize(10);
                    doc.text(CartonID, 10, 34);
                } else if (rowCount == 2) {
                    doc.addImage(qrdata, 'JPEG', 65, 15, 17, 17, CartonID, false);
                    // doc.text(resp[j].ProductName, 1, 4);

                    //doc.text(new Date().toDateString(), 38, 5);
                    //doc.text(resp[j].Qty, 15, 16);
                    doc.setFontSize(10);
                    doc.text(CartonID, 60, 34);
                } else if (rowCount == 3) {
                    doc.addImage(qrdata, 'JPEG', 125, 15, 17, 17, CartonID, false);
                    doc.setFontSize(10);
                    doc.text(CartonID, 120, 34);
                } else if (rowCount == 4) {
                    doc.addImage(qrdata, 'JPEG', 175, 15, 17, 17, CartonID, false);
                    doc.setFontSize(10);
                    doc.text(CartonID, 170, 34);
                }
                else if (rowCount == 5) {
                    doc.addImage(qrdata, 'JPEG', 15, 48, 17, 17, CartonID, false);
                    doc.setFontSize(10);
                    doc.text(CartonID, 10, 67);
                }
                else if (rowCount == 6) {
                    doc.addImage(qrdata, 'JPEG', 65, 48, 17, 17, CartonID, false);
                    doc.setFontSize(10);
                    doc.text(CartonID, 60, 67);
                }
                else if (rowCount == 7) {
                    doc.addImage(qrdata, 'JPEG', 125, 48, 17, 17, CartonID, false);
                    doc.setFontSize(10);
                    doc.text(CartonID, 120, 67);
                }
                else if (rowCount == 8) {
                    doc.addImage(qrdata, 'JPEG', 175, 48, 17, 17, CartonID, false);
                    doc.setFontSize(10);
                    doc.text(CartonID, 170, 67);
                }
                else if (rowCount == 9) {
                    doc.addImage(qrdata, 'JPEG', 15, 82, 17, 17, CartonID, false);
                    doc.setFontSize(10);
                    doc.text(CartonID, 10, 101);
                }
                else if (rowCount == 10) {
                    doc.addImage(qrdata, 'JPEG', 65, 82, 17, 17, CartonID, false);
                    doc.setFontSize(10);
                    doc.text(CartonID, 60, 101);
                }
                else if (rowCount == 11) {
                    doc.addImage(qrdata, 'JPEG', 125, 82, 17, 17, CartonID, false);
                    doc.setFontSize(10);
                    doc.text(CartonID, 120, 101);
                }
                else if (rowCount == 12) {
                    doc.addImage(qrdata, 'JPEG', 175, 82, 17, 17, CartonID, false);
                    doc.setFontSize(10);
                    doc.text(CartonID, 170, 101);
                }
                else if (rowCount == 13) {
                    doc.addImage(qrdata, 'JPEG', 15, 116, 17, 17, CartonID, false);
                    doc.setFontSize(10);
                    doc.text(CartonID, 10, 135);
                }
                else if (rowCount == 14) {
                    doc.addImage(qrdata, 'JPEG', 65, 116, 17, 17, CartonID, false);
                    doc.setFontSize(10);
                    doc.text(CartonID, 60, 135);
                }
                else if (rowCount == 15) {
                    doc.addImage(qrdata, 'JPEG', 125, 116, 17, 17, CartonID, false);
                    doc.setFontSize(10);
                    doc.text(CartonID, 120, 135);
                }
                else if (rowCount == 16) {
                    doc.addImage(qrdata, 'JPEG', 175, 116, 17, 17, CartonID, false);
                    doc.setFontSize(10);
                    doc.text(CartonID, 170, 135);                    
                }
               else if (rowCount == 17) {
                    doc.addImage(qrdata, 'JPEG', 15, 150, 17, 17, CartonID, false);
                    doc.setFontSize(10);
                    doc.text(CartonID, 10, 169);
                } else if (rowCount == 18) {
                    doc.addImage(qrdata, 'JPEG', 65, 150, 17, 17, CartonID, false);
                    doc.setFontSize(10);
                    doc.text(CartonID, 60, 169);
                } else if (rowCount == 19) {
                    doc.addImage(qrdata, 'JPEG', 125, 150, 17, 17, CartonID, false);
                    doc.setFontSize(10);
                    doc.text(CartonID, 120, 169);
                } else if (rowCount == 20) {
                    doc.addImage(qrdata, 'JPEG', 175, 150, 17, 17, CartonID, false);
                    doc.setFontSize(10);
                    doc.text(CartonID, 170, 169);
                }
                else if (rowCount == 21) {
                    doc.addImage(qrdata, 'JPEG', 15, 183, 17, 17, CartonID, false);
                    doc.setFontSize(10);
                    doc.text(CartonID, 10, 202);
                }
                else if (rowCount == 22) {
                    doc.addImage(qrdata, 'JPEG', 65, 183, 17, 17, CartonID, false);
                    doc.setFontSize(10);
                    doc.text(CartonID, 60, 202);
                }
                else if (rowCount == 23) {
                    doc.addImage(qrdata, 'JPEG', 125, 183, 17, 17, CartonID, false);
                    doc.setFontSize(10);
                    doc.text(CartonID, 120, 202);
                }
                else if (rowCount == 24) {
                    doc.addImage(qrdata, 'JPEG', 175, 183, 17, 17, CartonID, false);
                    doc.setFontSize(10);
                    doc.text(CartonID, 170, 202);
                }
                else if (rowCount == 25) {
                    doc.addImage(qrdata, 'JPEG', 15, 218, 17, 17, CartonID, false);
                    doc.setFontSize(10);
                    doc.text(CartonID, 10, 237);
                }
                else if (rowCount == 26) {
                    doc.addImage(qrdata, 'JPEG', 65, 218, 17, 17, CartonID, false);
                    doc.setFontSize(10);
                    doc.text(CartonID, 60, 237);
                }
                else if (rowCount == 27) {
                    doc.addImage(qrdata, 'JPEG', 125, 218, 17, 17, CartonID, false);
                    doc.setFontSize(10);
                    doc.text(CartonID, 120, 237);
                }
                else if (rowCount == 28) {
                    doc.addImage(qrdata, 'JPEG', 175, 218, 17, 17, CartonID, false);
                    doc.setFontSize(10);
                    doc.text(CartonID, 170, 237);
                }
                else if (rowCount == 29) {
                    doc.addImage(qrdata, 'JPEG', 15, 252, 17, 17, CartonID, false);
                    doc.setFontSize(10);
                    doc.text(CartonID, 10, 271);
                }
                else if (rowCount == 30) {
                    doc.addImage(qrdata, 'JPEG', 65, 252, 17, 17, CartonID, false);
                    doc.setFontSize(10);
                    doc.text(CartonID, 60, 271);
                }
                else if (rowCount == 31) {
                    doc.addImage(qrdata, 'JPEG', 125, 252, 17, 17, CartonID, false);
                    doc.setFontSize(10);
                    doc.text(CartonID, 120, 271);
                }
                else if (rowCount == 32) {
                    doc.addImage(qrdata, 'JPEG', 175, 252, 17, 17, CartonID, false);
                    doc.setFontSize(10);
                    doc.text(CartonID, 170, 271);
                    rowCount=0;
                }
                rowCount++;
            }

            // qrdata = '';

            initial += numberInFile;
            doc.addPage();

        }

        var pageCount = doc.internal.getNumberOfPages();
        doc.deletePage(pageCount)
        return doc;
    }

}