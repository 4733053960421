import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";
import ProductDamageService from "../../services/ProductDamageService";
import MessageComponent from "../../template/Message";
export default class damageCarton extends Component {
  constructor(props) {
    super(props);
    this.ProductDamageService = new ProductDamageService();
    this.state = {
      scanTxt: "",
      reason: ''
    };

    this.ScanItem = this.ScanItem.bind(this);
  }

  ScanItem(e) {
    if (e.key === 'Enter' && this.state.reason != null && this.state.reason.length > 4) {
      
      let qr = e.target.value;

      if (qr.includes('SID') || qr.includes('TID')) {
        let data = {}
        data.DamageCid = qr
        data.Reason = this.state.reason
        confirmAlert({
          title: 'Confirmation to Damage',
          message: 'Are you sure to do this Damage.',

          buttons: [
            {
              label: 'Yes',
              onClick: () =>
                this.ProductDamageService.CartonDamage(data).then((data) => {
                  const res = data.data
                  if (res.ResponseCode === "200") {
                    this.refs.Messchild.showSuccess("Damage Successfully")
                    this.setState({ reason: '' })
                  }
                  else {
                    this.refs.Messchild.showWarn(res.ResponseMessage);
                  }
                })

            },
            {
              label: 'No',
              onClick: () => null
            }
          ]
        });

      }
      else {
        this.refs.Messchild.showWarn("Invalid Scanned");
      }

      this.setState({
        scanTxt: ""
      });


    }
    else if (e.key === 'Enter') {
      this.refs.Messchild.showWarn("Please fill reason of damage before Scanned between 5 to 200 chars");
    }
  }

  render() {
    return (
      <>
        <MessageComponent ref="Messchild" />

        <div className="heading mb-2">
          <h1>Damage Carton</h1>
        </div>
        <div className="col-md-12">
        </div>
        <div className="col-md-12">
          <label>Damage Reason:</label>
          <input style={{ width: '400px' }} maxLength="200" onChange={(e) => { this.setState({ reason: e.target.value }) }} name="reason" value={this.state.reason} type="search" autoComplete='off' className="form-control ml-2" id="scan" />
        </div>
        <div className="col-md-12">
          <label>Scan Carton:</label>
          <input style={{ width: '400px' }} onChange={(e) => { this.setState({ scanTxt: e.target.value }) }} name="scanTxt" value={this.state.scanTxt} type="search" autoComplete='off' className="form-control ml-2" id="scan" onKeyDown={e => { this.ScanItem(e) }} />
        </div>
      </>
    );
  }
}
