import instance from './DefaultApi';

export default class ProductDamageService {
    ControllerName = 'ProductDamage';

    // get all product damag data
    GetProductDamages(tableRequest) {
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetDamages`, tableRequest).then(res => res)
    }
    // create new product damage  
    CreateDamage(damageData) {
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/CreateDamage`, damageData).then(res => res)
    }
    CartonDamage(damageData) {
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/CartonDamage`, damageData).then(res => res)
    }

    // delete product damage data
    DeleteProductDamage(damageId) {
        return instance.get(`${process.env.REACT_APP_API_URL}${this.ControllerName}/DeleteDamage/${damageId}`).then(res => res)
    }
}



