import React, { Component } from "react";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { DataTable } from "primereact/datatable";
import { CSVLink, CSVDownload } from "react-csv";
import { Calendar } from "primereact/calendar";
import tableRequest from "../../models/tableRequest.js";
import UserService from "../../services/UserService";
import ItemLedgerReportService from "../../services/ItemLedgerReportService";
import WareHouseMaterService from "../../services/WareHouseMaterService";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import MessageComponent from "../../template/Message";
import { Dropdown } from "primereact/dropdown";
import Loaders from "../../template/Loaders";
import $ from "jquery";

export default class ItemLedger extends Component {
  constructor(props) {
    super(props);
    this.surveyLink = React.createRef();
    this.UserService = new UserService();
    this.WareHouseMaterService = new WareHouseMaterService();
    this.ItemLedgerReportService = new ItemLedgerReportService();
    this.tblResponse = new tableRequest();
    this.state = {
      WareHouseId: "",
      ddid: "",
      FromDate: new Date(),
      ToDate: new Date(),
      SendTo: "",
      updateLoader: false,
      success: false,
      visible: false,
      sortOrder: "",
      sales: [],
      loading: true,
      first: 0,
      rows: 10,
      totalRecords: 0,
      globalFilter: null,
      emptyMessage: "No Data found",
      allData: [],
      wareHouseDDL: [],
      UserDDL: [],
    };
    this.onPage = this.onPage.bind(this);
    this.FilterData = this.FilterData.bind(this);
    this.GetReportExport = this.GetReportExport.bind(this);
    this.searchFilter = this.searchFilter.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.onSort = this.onSort.bind(this);
  }
  //to trigger api function on page call
  componentDidMount() {
    setTimeout(() => {
      var date = new Date();
      date.setDate(date.getDate() - 7);
      this.tblResponse.FromDate = date.toLocaleDateString();
      this.tblResponse.ToDate = new Date().toLocaleDateString();
      this.setState({
        FromDate: date,
        ToDate: new Date(),
      });
      this.GetReport();
      this.GetWareHouse(0);
      this.GetActiveUsers();
      // this.GetReportExport();
    }, 1000);
    $(".p-column-title").on("click", function () {
      $(this)
        .next()
        .removeClass("pi-sort")
        .toggleClass("pi-sort-up")
        .addClass("pi-sort-down");
    });
  }
  //to search
  searchFilter(e) {
    this.setState({
      loading: true,
    });
    this.tblResponse.SearchValue = e.target.value;
    this.GetReport();
  }

  onChange = (date) => this.setState({ FromDate: "", ToDate: "" });
  //on page loading
  onPage(event) {
    this.setState({
      loading: true,
    });

    //imitate delay of a backend call
    setTimeout(() => {
      this.getLazyLoadingValues(event);
      this.GetReport();
    }, 250);
  }

  // get data list from api
  GetReportExport() {
    this.tblResponse.FromDate = this.state.FromDate.toLocaleDateString();
    this.tblResponse.ToDate = this.state.ToDate.toLocaleDateString();

    //if WareHouseId is provided
    if (this.state.WareHouseId)
      this.tblResponse.WareHouseId = this.state.WareHouseId.ID;
    else this.tblResponse.WareHouseId = 0;
    //if UserId is provided
    if (this.state.ddid) this.tblResponse.UserId = this.state.ddid.UserId;
    else this.tblResponse.UserId = 0;

    this.ItemLedgerReportService.GetExport(this.tblResponse).then((data) => {
      const resdata = data.data;
      //if response data not null
      if (
        resdata !== null &&
        resdata.ResponseData !== null &&
        resdata.ResponseData.length > 0
      ) {
        this.setState({
          allData: resdata.ResponseData,
        });

        this.setState({ allData: resdata.ResponseData }, () => {
          this.surveyLink.link.click();
        });
      } else {
        // this.setState({
        //     allData: null
        // });
      }
    });
  }

  // get data list from api
  GetWareHouse(compId) {
    this.WareHouseMaterService.GetWareHouseDDL(compId).then((data) => {
      const resdata = data.data;
      //if response data not null
      if (
        resdata !== null &&
        resdata.ResponseData !== null &&
        resdata.ResponseData.length > 0
      ) {
        //const filteredWareHouse = resdata.ResponseData.filter(x=>x.ID != this.ApiService.getWareHouseId())

        this.setState({
          wareHouseDDL: resdata.ResponseData,
        });
      }
    });
  }
  //to get active users
  GetActiveUsers() {
    this.UserService.GetAllActiveUsers().then((data) => {
      const resdata = data.data;
      //if response data not null
      if (resdata !== null && resdata.ResponseCode === "200") {
        this.setState({
          UserDDL: resdata.ResponseData,
        });
      }
    });
  }
  //to handle change
  handleChange(event) {
    const { name, value } = event.target;
    //to validate each form field with required conditons
    switch (name) {
      case "FromDate":
        if (value == null || !(value instanceof Date)) {
          this.formIsValid = false;
          this.refs.Messchild.showError("Please select from date.");
          return;
        } else if (
          !value.toLocaleDateString().match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/)
        ) {
          this.formIsValid = false;
          this.refs.Messchild.showError("Please enter only date.");
          return;
        } else if (value instanceof Date && this.state.ToDate instanceof Date) {
          const startDate = value;
          const endDate = this.state.ToDate;
          if (!(Date.parse(startDate) <= Date.parse(endDate))) {
            this.formIsValid = false;
            this.refs.Messchild.showError(
              "From date must be less than to date."
            );
            return;
          }
        }

        break;
      case "ToDate":
        if (value == null || !(value instanceof Date)) {
          this.formIsValid = false;
          this.refs.Messchild.showError("Please select to date.");
          return;
        } else if (
          !value.toLocaleDateString().match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/)
        ) {
          this.formIsValid = false;
          this.refs.Messchild.showError("Please enter only date.");
          return;
        } else if (
          value instanceof Date &&
          this.state.FromDate instanceof Date
        ) {
          const startDate = this.state.FromDate;
          const endDate = value;
          if (!(Date.parse(startDate) <= Date.parse(endDate))) {
            this.formIsValid = false;
            this.refs.Messchild.showError(
              "To date must be greater than from date."
            );
            return;
          }
        }

        break;
      default:
        break;
    }

    this.setState({ [name]: value });
  }
  //to filter data
  FilterData() {
    this.tblResponse.FromDate = this.state.FromDate.toLocaleDateString();
    this.tblResponse.ToDate = this.state.ToDate.toLocaleDateString();

    this.GetReport();
  }

  getLazyLoadingValues(event) {
    this.setState({ rows: event.rows });
    this.tblResponse.PageSize = event.rows;
    this.tblResponse.First = event.first + 1;
    this.tblResponse.IsActive = "1";
    this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
    //this.tblResponse.SortColumn = event.sortField;
    this.tblResponse.SortOrder = event.sortOrder === 1 ? "ASC" : "DESC";
    this.tblResponse.FromDate = this.state.FromDate;
    this.tblResponse.ToDate = this.state.ToDate;
  }

  //to get report data

  GetReport() {
    //if WareHouseId is provided
    if (this.state.WareHouseId)
      this.tblResponse.WareHouseId = this.state.WareHouseId.ID;
    else this.tblResponse.WareHouseId = 0;
    //if UserId is provided
    if (this.state.ddid) this.tblResponse.UserId = this.state.ddid.UserId;
    else this.tblResponse.UserId = 0;

    this.tblResponse.SortColumn = "CreatedDt";

    this.ItemLedgerReportService.GetReport(this.tblResponse).then((data) => {
      const resdata = data.data;
      this.setState({ updateLoader: true });
      //if response data not null
      if (
        resdata !== null &&
        resdata.ResponseData !== null &&
        resdata.ResponseData.length > 0
      ) {
        this.datasource = resdata.ResponseData;

        this.setState({
          totalRecords: resdata.ResponseData[0].TotalRows,
          first: this.tblResponse.First - 1,
          sales: this.datasource.slice(
            0,
            this.tblResponse.First - 1 + this.tblResponse.PageSize
          ),
          loading: false,
        });
      }
      //if response data null
      else {
        this.datasource = null;
        this.setState({
          totalRecords: 0,
          first: 1,
          sales: null,
          loading: false,
        });
      }
    });
    // this.datasource = null;
    // this.setState({
    //     totalRecords: 0,
    //     first: 1,
    //     sales: null,
    //     loading: false
    // });
  }

  //to sort
  onSort(event) {
    this.setState({ sortOrder: this.state.sortOrder === 1 ? 2 : 1 });
    const sortField =
      event.sortField == "CreatedDt" ? "CreatedDt" : event.sortField;
    this.tblResponse.SortColumn = sortField ? sortField : "";
    this.tblResponse.SortOrder = this.state.sortOrder === 1 ? "DESC" : "ASC";
    this.GetReport();
  }
  //to render html part
  //resolved KREP-140
  render() {
    let header = (
      <div className="row">
        <div className="col-md-8">
          <form className="form-inline">
            <div className="form-group custom-cal">
              <Calendar
                value={this.state.FromDate}
                name="FromDate"
                onChange={this.handleChange}
                appendTo={document.getElementById("root")}
                placeholder="From Date"
              />
            </div>
            <div className="form-group ml-2 custom-cal">
              <Calendar
                value={this.state.ToDate}
                name="ToDate"
                onChange={this.handleChange}
                appendTo={document.getElementById("root")}
                placeholder="To Date"
              />
            </div>
            {/* <div className="form-group ml-2 mr-2">
                  <Dropdown
                    optionLabel="FirmName"
                    showClear ={true}
                    filter={true} 
                    value={this.state.WareHouseId}
                    className="form-control"
                    placeholder="-WareHouse-"
                    name="WareHouseId"
                    options={this.state.wareHouseDDL}
                    onChange={this.handleChange}
                  />
                </div>
                OR 
                <div className="form-group ml-2">
                <Dropdown
                filter={true} 
                showClear ={true}
                optionLabel="Username"
                value={this.state.ddid}
                className="form-control"
                placeholder="-Dealer/Distributor-"
                name="ddid"
                options={this.state.UserDDL}
                 onChange={this.handleChange}
              />

                </div> */}

            <button
              type="button"
              onClick={this.FilterData}
              className="btn btn-primary ml-3"
            >
              Submit
            </button>
          </form>
        </div>
        <div className="text-right col-md-4">
          <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>
          <InputText
            type="search"
            onInput={this.searchFilter}
            placeholder="Global Search"
            size="22"
          />

          <button
            className="btn btn-primary ml-2 export-disabled"
            target="_blank"
            disabled={this.state.sales == null}
            onClick={this.GetReportExport}
          >
            Export
          </button>
          <CSVLink
            hidden="true"
            data={this.state.allData}
            ref={(r) => (this.surveyLink = r)}
            filename={"Item Ledger report.csv"}
          >
            Export
          </CSVLink>
        </div>
      </div>
    );
    return (
      <>
        <MessageComponent ref="Messchild" />
        {<Loaders loader={this.state.updateLoader} />}
        <div className="heading">
          <h1>Item ledger Reports</h1>
        </div>
        <DataTable
          exportFilename="Mapping Report"
          responsive={true}
          header={header}
          globalFilter={this.state.globalFilter}
          emptyMessage={this.state.emptyMessage}
          value={this.state.sales}
          sortMode="multiple"
          paginator={true}
          rows={this.state.rows}
          rowsPerPageOptions={[10, 50, 200, 500]}
          totalRecords={this.state.totalRecords}
          lazy={true}
          first={this.state.first}
          onPage={this.onPage}
          onSort={this.onSort}
          ref={(el) => {
            this.dt = el;
          }}
        >
          <Column field="ProductName" header="Product" sortable={true} />
          <Column
            field="TotalQty"
            header="Total Qty"
            sortable={true}
            style={{ width: "100px" }}
          />
          <Column field="TertiaryCartonId" header="Carton Id" sortable={true} />
          <Column field="CreatedDt" header="Dispatch Date" sortable={true} />
          <Column
            field="ReceivedDt"
            header="Received Date"
            sortable={true}
            sortOrder="-1"
          />
          <Column
            field="CurrentStatus"
            header="Status"
            sortable={true}
            style={{ width: "100px" }}
          />
          {/* <Column field="FromWareHouse" header="From WareHouse" sortable={true} />
                    <Column field="FromDlDR" header="From Dealer/Distributor" sortable={true} />
                     */}
          <Column field="Cfrom" header="Dispatched From" sortable={true} />
          <Column field="CTo" header="Dispatched To" sortable={true} />
          <Column field="Remark" header="Remark" />
        </DataTable>
      </>
    );
  }
}
