import instance from './DefaultApi';

export default class RedemptionReportService {
    ControllerName = 'RedemptionReport';

    // get all list data Redemption
    GetRedemptionReport(tableRequest) {
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetRedemptionReport`, tableRequest).then(res => res)
    }

    /// Get Excel Export Redemption
    GetRedemptionExport(tableRequest) {
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetRedemptionExport`,tableRequest).then(res => res)
    }

    /// Get Loyalty Points Redemption Requests
    GetLoyaltyRequests(tableRequest) {
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetLoyaltyRequests`,tableRequest).then(res => res)
    }

    /// Set loyalty status to accepted
    AcceptPointsRequest(Id,Remark) {
        return instance.get(`${process.env.REACT_APP_API_URL}${this.ControllerName}/AcceptPointsRequest/${Id}/${Remark}`).then(res => res)
    }


}