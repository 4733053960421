import instance from './DefaultApi';

export default class ReplaceService {
    ControllerName = 'Replace';

    // get all products
    GetQrCodeDetails(data) {
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetQrCodeDetails`, data).then(res => res)
    }

    ReplaceQrCode(data){
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/ReplaceQrCode`, data).then(res => res)
    }

}