import React, { Component } from "react";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { CSVLink, CSVDownload } from "react-csv";
import { DataTable } from "primereact/datatable";
import { Calendar } from "primereact/calendar";
import SchemeReportService from "../../services/SchemeReportService";
import tableRequest from "../../models/tableRequest";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { Column } from "primereact/column";
import MessageComponent from "../../template/Message";
import Loaders from "../../template/Loaders";
import $ from "jquery";

export default class SchemeReport extends Component {
  constructor(props) {
    super(props);

    this.surveyLink = React.createRef();

    this.SchemeReportService = new SchemeReportService();
    this.tblResponse = new tableRequest();
    this.state = {
      FromDate: new Date(),
      ToDate: new Date(),
      success: false,
      updateLoader: false,
      emptyMessage: "No Data found",
      visible: false,
      sortOrder: "",
      sales: [],
      loading: true,
      first: 0,
      rows: 10,
      totalRecords: 0,
      globalFilter: null,
      allData: [],
    };

    this.onPage = this.onPage.bind(this);
    this.FilterData = this.FilterData.bind(this);
    this.searchFilter = this.searchFilter.bind(this);
    this.handleChange = this.handleChange.bind(this);
    //onChange = date => this.setState({ FromDate,ToDate })
    this.GetSchemeExport = this.GetSchemeExport.bind(this);

    this.onSort = this.onSort.bind(this);
  }
  //to trigger api function on page call
  componentDidMount() {
    setTimeout(() => {
      var date = new Date();
      date.setDate(date.getDate() - 7);
      this.tblResponse.FromDate = date.toLocaleDateString();
      this.tblResponse.ToDate = new Date().toLocaleDateString();
      this.setState({
        FromDate: date,
        ToDate: new Date(),
      });
      this.GetScheme();
      //this.GetSchemeExport();
    }, 1000);
    $(".p-column-title").on("click", function () {
      $(this)
        .next()
        .removeClass("pi-sort")
        .toggleClass("pi-sort-up")
        .addClass("pi-sort-down");
    });
  }

  onChange = (date) => this.setState({ FromDate: "", ToDate: "" });
  //on page loading
  onPage(event) {
    this.setState({
      loading: true,
    });

    //imitate delay of a backend call
    setTimeout(() => {
      this.getLazyLoadingValues(event);
      this.GetScheme();
    }, 250);
  }
  //to search
  searchFilter(e) {
    this.setState({
      loading: true,
    });
    this.tblResponse.SearchValue = e.target.value;
    this.GetScheme();
  }
  //to filter data
  FilterData() {
    this.tblResponse.FromDate = this.state.FromDate.toLocaleDateString();
    this.tblResponse.ToDate = this.state.ToDate.toLocaleDateString();
    this.GetScheme();
  }

  // get data list from api
  GetSchemeExport() {
    this.SchemeReportService.GetSchemeExport(this.tblResponse).then((data) => {
      const resdata = data.data;
      //if response data not null
      if (
        resdata !== null &&
        resdata.ResponseData !== null &&
        resdata.ResponseData.length > 0
      ) {
        this.setState({
          allData: resdata.ResponseData,
        });
        this.setState({ allData: resdata.ResponseData }, () => {
          this.surveyLink.link.click();
        });
      }
    });
  }
  //to handle
  //resolved KREP-22
  handleChange(event) {
    const { name, value } = event.target;
    //to validate each  form field with required conditions
    switch (name) {
      case "FromDate":
        if (value == null || !(value instanceof Date)) {
          this.formIsValid = false;
          this.refs.Messchild.showError("Please select from date.");
          return;
        } else if (
          !value.toLocaleDateString().match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/)
        ) {
          this.formIsValid = false;
          this.refs.Messchild.showError("Please enter only date.");
          return;
        } else if (value instanceof Date && this.state.ToDate instanceof Date) {
          const startDate = value;
          const endDate = this.state.ToDate;
          if (!(Date.parse(startDate) <= Date.parse(endDate))) {
            this.formIsValid = false;
            this.refs.Messchild.showError(
              "From date must be less than to date."
            );
            return;
          }
        }

        break;
      case "ToDate":
        if (value == null || !(value instanceof Date)) {
          this.formIsValid = false;
          this.refs.Messchild.showError("Please select to date.");
          return;
        } else if (
          !value.toLocaleDateString().match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/)
        ) {
          this.formIsValid = false;
          this.refs.Messchild.showError("Please enter only date.");
          return;
        } else if (
          value instanceof Date &&
          this.state.FromDate instanceof Date
        ) {
          const startDate = this.state.FromDate;
          const endDate = value;
          if (!(Date.parse(startDate) <= Date.parse(endDate))) {
            this.formIsValid = false;
            this.refs.Messchild.showError(
              "To date must be greater than from date."
            );
            return;
          }
        }

        break;
      default:
        break;
    }

    this.setState({ [name]: value });
  }

  getLazyLoadingValues(event) {
    this.setState({ rows: event.rows });
    this.tblResponse.PageSize = event.rows;
    this.tblResponse.First = event.first + 1;
    this.tblResponse.IsActive = "1";
    this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
    //this.tblResponse.SortColumn = event.sortField;
    this.tblResponse.SortOrder = event.sortOrder === 1 ? "ASC" : "DESC";
    this.tblResponse.FromDate = this.state.FromDate;
    this.tblResponse.ToDate = this.state.ToDate;
  }

  // get data list from api
  GetScheme() {
    this.tblResponse.SortColumn = "CreatedDt";
    this.SchemeReportService.GetSchemeReport(this.tblResponse).then((data) => {
      const resdata = data.data;
      this.setState({ updateLoader: true });
      //if response data not null
      if (
        resdata !== null &&
        resdata.ResponseData !== null &&
        resdata.ResponseData.length > 0
      ) {
        this.datasource = resdata.ResponseData;
        this.setState({
          totalRecords: resdata.ResponseData[0].TotalRows,
          first: this.tblResponse.First - 1,
          sales: this.datasource.slice(
            0,
            this.tblResponse.First - 1 + this.tblResponse.PageSize
          ),
          loading: false,
        });
      }
      //if response data null
      else {
        this.datasource = null;
        this.setState({
          totalRecords: 0,
          first: 1,
          sales: null,
          loading: false,
        });
      }
    });

    // this.datasource = null;
    // this.setState({
    //     totalRecords: 0,
    //     first: 1,
    //     sales: null,
    //     loading: false
    // });
  }
  //to sort
  onSort(event) {
    this.setState({ sortOrder: this.state.sortOrder === 1 ? 2 : 1 });
    const sortField =
      event.sortField == "CreatedDt" ? "CreatedDt" : event.sortField;
    this.tblResponse.SortColumn = sortField ? sortField : "";
    this.tblResponse.SortOrder = this.state.sortOrder === 1 ? "DESC" : "ASC";
    this.GetScheme();
  }

  //to render html part
  render() {
    let header = (
      <div className="row">
        <div className="col-md-8">
          <form className="form-inline">
            <div className="form-group mr-2">
              <Calendar
                value={this.state.FromDate}
                name="FromDate"
                onChange={this.handleChange}
                appendTo={document.getElementById("root")}
                placeholder="From Date"
              />
            </div>
            <div className="form-group">
              <Calendar
                value={this.state.ToDate}
                name="ToDate"
                onChange={this.handleChange}
                appendTo={document.getElementById("root")}
                placeholder="To Date"
              />
            </div>
            <button
              type="button"
              onClick={this.FilterData}
              className="btn btn-primary ml-3"
            >
              Submit
            </button>
          </form>
        </div>
        <div className="text-right col-md-4">
          <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>
          <InputText
            type="search"
            onInput={this.searchFilter}
            placeholder="Global Search"
            size="22"
          />
          <button
            className="btn btn-primary ml-2 export-disabled"
            target="_blank"
            disabled={this.state.sales == null}
            onClick={this.GetSchemeExport}
          >
            Export
          </button>
          <CSVLink
            hidden={true}
            data={this.state.allData}
            ref={(r) => (this.surveyLink = r)}
            filename={"Scheme report.csv"}
          >
            Export
          </CSVLink>
        </div>
      </div>
    );
    return (
      <>
        <MessageComponent ref="Messchild" />
        {<Loaders loader={this.state.updateLoader} />}
        <div className="heading">
          <h1>Scheme Report</h1>
        </div>

        <DataTable
          exportFilename="Scheme Report"
          responsive={true}
          header={header}
          globalFilter={this.state.globalFilter}
          emptyMessage={this.state.emptyMessage}
          value={this.state.sales}
          sortMode="multiple"
          paginator={true}
          rows={this.state.rows}
          rowsPerPageOptions={[10, 50, 200, 500]}
          totalRecords={this.state.totalRecords}
          first={this.state.first}
          onPage={this.onPage}
          onSort={this.onSort}
        >
          <Column field="SchemeId" header="Scheme Id" sortable={true} />
          <Column field="SchemeName" header="Scheme Name" sortable={true} />
          <Column field="CreatedDate" header="Date" sortable={true} />
          <Column field="FromNo" header="From No" sortable={true} />
          <Column field="ToNo" header="To No" sortable={true} />
          <Column field="Qty" header="Qty " sortable={true} />
          <Column field="StartDate" header="Valid From" sortable={true} />
          <Column field="EndDate" header="Valid To" sortable={true} />
        </DataTable>
      </>
    );
  }
}
