import React, { Component } from "react";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import UserService from "../../services/UserService";
import CartonMasterService from "../../services/CartonMasterService";
import TertiaryPackingService from "../../services/TertiaryPackingService";
import DispatchService from "../../services/DispatchService";
import MappingService from "../../services/MappingService";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import tableRequest from "../../models/tableRequest.js";
import { confirmAlert } from "react-confirm-alert"; // Import
import { BreadCrumb } from "primereact/breadcrumb";
import { ConfirmationModal } from "../../template/ConfirmationModal";
import MessageComponent from "../../template/Message";
import $ from "jquery";
import PrintSecondaryPackagingService from "../../services/PrintSecondaryPackagingService";
import Loaders from "../../template/Loaders";
export default class TertiaryPacking extends Component {
  constructor(props) {
    super(props);
    this.autoref = React.createRef();
    // this.UserService = new UserService();
    this.handleChange.bind(this);
    this.CartonMasterService = new CartonMasterService();
    this.MappingService = new MappingService();
    this.TertiaryPackingService = new TertiaryPackingService();
    this.DispatchService = new DispatchService();
    this.PrintSecondaryPackagingService = new PrintSecondaryPackagingService();
    this.tblResponse = new tableRequest();
    this.state = {
      CartonName: "",
      reason: "",
      Tvisible: false,
      remark: "",
      older: false,
      globalFilter: null,
      visible: false,
      isold: false,

      olderstockVisible: false,

      olderdata: [],
      sortIcon: false,
      Loader1: true,
      Loader2: false,
      isSelected: false,
      preVal: "",
      rvisible: false,
      sortOrder: "1",
      formError: {},
      CartonDDL: [],
      CartonId: [],
      buttonLabel: "Submit",
      sales: [],
      scanItems: [],
      loading: true,
      first: 0,
      rows: 10,
      totalRecords: 0,
      globalFilter: null,
      emptyMessage: "No Data found",
      IsDisabledCartonDDL: false,
      scanTxt: "", //KREP-55 defect
    };

    this.actionTemplate = this.actionTemplate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.submitFormFinal = this.submitFormFinal.bind(this);

    this.hideFunc = this.hideFunc.bind(this);
    this.onPage = this.onPage.bind(this);
    this.searchFilter = this.searchFilter.bind(this);
    //  this.openDialog = this.openDialog.bind(this);
    this.onSort = this.onSort.bind(this);

    this.ScanItem = this.ScanItem.bind(this);
  }
  //trigger api function on page call
  componentDidMount() {
    setTimeout(() => {
      this.GetTertiaryPacking();
    }, 100);

    $(".p-column-title").on("click", function () {
      $(this)
        .next()
        .removeClass("pi-sort")
        .toggleClass("pi-sort-up")
        .addClass("pi-sort-down");
    });

    this.GetActiveCartonDDL();
  }

  // PAGE EVENT CALL
  onPage(event) {
    this.setState({
      loading: true,
    });

    //imitate delay of a backend call
    setTimeout(() => {
      ;

      this.getLazyLoadingValues(event);
      this.GetTertiaryPacking();
    }, 250);
  }

  // set lazy loading values
  getLazyLoadingValues(event) {
    this.setState({ rows: event.rows });
    this.tblResponse.PageSize = event.rows;
    this.tblResponse.First = event.first + 1;
    this.tblResponse.IsActive = true;
    this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
    //this.tblResponse.SortColumn = event.sortField;
    this.tblResponse.SortOrder = event.sortOrder === 1 ? "ASC" : "DESC";
  }

  // get users data list from api
  GetTertiaryPacking() {
    this.tblResponse.SortColumn = "TertiaryCartonId";
    ;
    this.TertiaryPackingService.GetTertiaryPacking(this.tblResponse).then(
      (data) => {
        this.setState({ Loader2: true });
        const resdata = data.data;
        //if responsedata is not null
        if (
          resdata !== null &&
          resdata.ResponseData !== null &&
          resdata.ResponseData.length > 0
        ) {
          this.datasource = resdata.ResponseData;
          this.setState({
            totalRecords: resdata.ResponseData[0].TotalRows,
            first: this.tblResponse.First - 1,
            sales: this.datasource.slice(
              0,
              this.tblResponse.First - 1 + this.tblResponse.PageSize
            ),
            loading: false,
          });
        }
        //if responsedata is null
        else {
          this.datasource = null;
          this.setState({
            totalRecords: 0,
            first: 1,
            sales: null,
            loading: false,
          });
        }
      }
    );
  }

  // call every change of form and validation part also
  handleChange(event) {
    const { name, value } = event.target;
    let errors = this.state.formError;

    const isValid = this.validateForm(value, name);
    this.setState({ errors, [name]: value });
  }

  // validate form
  validateForm(value, name) {
    let errors = this.state.formError;
    let IsValid = true;
    //to validate each form field with required conditions
    switch (name) {
      case "UserName":
        if (value.length < 1) {
          IsValid = false;
          errors.UserName = "Please enter your username.";
        } else errors.UserName = "";
        break;

      default:
        break;
    }

    return IsValid;
  }

  // reset form text value
  formReset() {
    this.setState({
      reason: "",
      CartonName: "",
      Tvisible: false,
      remark: "",
      older: false,
      globalFilter: null,
      visible: false,
      isold: false,

      olderstockVisible: false,

      olderdata: [],
      sortIcon: false,
      Loader1: true,
      Loader2: false,
      isSelected: false,
      preVal: "",
      rvisible: false,
      sortOrder: "1",
      formError: {},
      CartonDDL: [],
      CartonId: [],
      buttonLabel: "Submit",
      sales: [],
      scanItems: [],
      loading: true,
      first: 0,
      rows: 10,
      totalRecords: 0,
      globalFilter: null,
      emptyMessage: "No Data found",
      IsDisabledCartonDDL: false,
      scanTxt: "", //KREP-55 defect
    });
    let errors = this.state.formError;
    Object.entries(errors).map(([key, value]) => {
      errors[key] = "";
    });
  }

  // open Dialog
  openDialog() {
    this.setState({ visible: true });
    this.formReset();
  }
  submitForm(event) {
    if (this.state.scanItems.length == 0) {
      this.refs.Messchild.showWarn("Scan First");
      return;
    } else if (
      this.state.CartonId.CartonSize != this.state.scanItems.length &&
      this.state.reason.trim().length < 4
    ) {
      ; // let reason = prompt("Carton is not fully Packed. \n Please Specify the Reason in min 5 chars Max 500 Chars", "");

      // reason = reason != null ? reason.trim() : "";

      this.setState({ rvisible: true });
    } else {
      this.setState({ Tvisible: true, CartonName: "" });
    }
  }
  // submit packing
  submitFormFinal(event) {
    ;
    if (this.state.older && this.state.remark == "") {
      this.setState({ isold: true });
      return;
    }

    //  if (event != undefined) { event.preventDefault(); }

    if (this.state.CartonId.length == 0) {
      this.refs.Messchild.showWarn("Select Carton Size");
      this.setState({ scanTxt: "" });

      return;
    } else if (this.state.scanItems.length == 0) {
      this.refs.Messchild.showWarn("Scan First");
      return;
    }

    if (
      this.state.CartonId.CartonSize != this.state.scanItems.length &&
      this.state.reason.trim().length < 4
    ) {
      this.setState({ rvisible: true });
      return;
    } else {
      this.setState({ Loader1: false });
      const formData = {
        //createdBy
        cartonMasterId: this.state.CartonId.CartonId,
        scanned: this.state.scanItems,
        remark: this.state.remark,
        CartonName: this.state.CartonName,

        reason: this.state.reason,
      };

      this.setState({ Tvisible: false });
      setTimeout(() => {
        this.TertiaryPackingService.Save(formData).then((data) => {
          this.setState({ Loader1: true });
          const resdata = data.data;
          //if ResponseCode is 200 success else error or warning message
          if (resdata !== null && resdata.ResponseCode === "200") {
            ;
            if (
              resdata.ResponseData.CreatedDt == "410" &&
              resdata.ResponseData.ProductName == "Items Already Packed"
            ) {
              this.refs.Messchild.showError("Items Already Packed");
              this.formReset();
              this.GetActiveCartonDDL();
              this.GetTertiaryPacking();
            } else {
              this.refs.Messchild.showSuccess(resdata.ResponseMessage);
              // this.PrintSecondaryPackagingService.formPreview1([resdata.ResponseData]);
              // this.setState({
              //     loading: true,
              //     visible: false
              // });
              // this.formReset();
              this.setState({
                CartonName: "",
                IsDisabledCartonDDL: false,
                Tvisible: false,
              });

              this.state.scanItems = [];
              this.setState({
                scanItems: [],
              });
              this.GetTertiaryPacking();

              this.autoref.current.focus();
            }
          } else if (resdata.ResponseCode === "400") {
            this.refs.Messchild.showWarn(resdata.ResponseMessage);
          } else {
            this.setState({ CartonName: "" });
            this.refs.Messchild.showError(resdata.ResponseMessage);
          }
        });
        this.setState({ reason: "" });
      }, 100);
    }
  }

  // get active carton drop down list
  GetActiveCartonDDL() {
    ;
    this.CartonMasterService.GetActiveCartonDDL().then((data) => {
      const resdata = data.data;
      //if ResponseData is not null
      if (resdata !== null && resdata.ResponseCode === "200") {
        this.setState({
          CartonDDL: resdata.ResponseData,
        });
      }
    });
  }
  hideFunc() {
    this.setState({
      isold: false,
      olderstockVisible: false,
      // formHeading: 'Add User'
    });
  }
  // scan qr code
  ScanItem(e, flag) {
    let id = 1;
    if (e.key === "Enter" || !flag) {
      let qr = flag ? e.target.value : e;

      if (this.state.CartonId.length == 0) {
        this.refs.Messchild.showWarn("Select Carton Size");
        this.setState({ scanTxt: "" });
        return;
      } else if (!qr.includes("SID")) {
        this.refs.Messchild.showWarn("Invalid Carton");
        this.setState({ scanTxt: "" });
        return;
      } else if (
        this.state.CartonId.CartonSize == this.state.scanItems.length
      ) {
        this.refs.Messchild.showWarn("Carton Full");
        return;
      }

      if (qr.includes("SID")) {
        this.state.scanItems.forEach((element) => {
          if (element.CartonId == qr) {
            this.refs.Messchild.showWarn("Already Scanned..!");
            id = 2;
            return;
          }
        });
      }
      setTimeout(() => {
        if (id == 1) {
          //const fObj = {UID : qr}

          const scanlist = this.state.scanItems;
          this.DispatchService.GetTertiaryPackingScan(qr, flag, scanlist).then(
            (data) => {
              ;
              const resdata = data.data;
              //if ResponseCode is 200 success else error or warning message
              if (resdata !== null && resdata.ResponseCode === "200") {
                let id = 1;
                if (this.state.scanItems.length > 0) {
                  let pid = this.state.scanItems[0].ProductId;
                  resdata.ResponseData.forEach((element) => {
                    if (element.ProductId != pid) {
                      id = 2;
                    }
                  });
                  if (id == 2) {
                    this.refs.Messchild.showWarn(
                      "only Similar Items Can be packed"
                    );
                    return;
                  }
                }

                //disable
                setTimeout(() => {
                  if (id == 2) {
                    return;
                  } else {
                    resdata.ResponseData.forEach((element) => {
                      ;
                      element.MFGDate =
                        element.MFGDate != null || undefined
                          ? element.MFGDate.substring(0, 10)
                          : element.MFGDate;
                      element.EXPDate =
                        element.EXPDate != null || undefined
                          ? element.EXPDate.substring(0, 10)
                          : element.EXPDate;
                      if (this.state.scanItems.length > 0) {
                        ;
                        let checkseqNo = this.state.scanItems.filter(
                          (y) => y.Id == element.Id
                        );

                        if (checkseqNo.length > 0) {
                          this.refs.Messchild.showWarn("already Scanned");
                          return;
                        }
                      }

                      this.state.scanItems.push(element);

                      ;
                      this.setState({
                        IsDisabledCartonDDL: true,
                        scanItems: this.state.scanItems,

                        scanTxt: "",
                        radio: true,
                      });
                    });
                  }
                  if (
                    this.state.CartonId.CartonSize ==
                    this.state.scanItems.length
                  ) {
                    this.submitForm(e);
                  }
                }, 50);
              } else if (resdata !== null && resdata.ResponseCode === "500") {
                ;
                if (resdata.ResponseData.length == 0) {
                  this.ScanItem(qr, false);
                  return;
                }
                let checkseqNo = [];
                resdata.ResponseData.forEach((element) => {
                  if (this.state.scanItems.length > 0) {
                    ;
                    let chk = this.state.scanItems.filter(
                      (y) => y.Id == element.Id
                    );
                    if (chk.length != 0) {
                      checkseqNo.push(chk);
                    }
                    if (checkseqNo.length == resdata.ResponseData.length) {
                      this.ScanItem(qr, false);
                    }
                  }
                });

                ;
                if (
                  checkseqNo == undefined ||
                  checkseqNo.length != resdata.ResponseData.length
                ) {
                  let id = 1;
                  if (this.state.scanItems.length > 0) {
                    let pid = this.state.scanItems[0].ProductId;
                    resdata.ResponseData.forEach((element) => {
                      if (element.ProductId != pid) {
                        id = 2;
                      }
                    });
                    if (id == 2) {
                      this.refs.Messchild.showWarn(
                        "only Similar Items Can be packed"
                      );
                      return;
                    }
                  }
                  setTimeout(() => {
                    if (id == 2) {
                      return;
                    }

                    confirmAlert({
                      title: resdata.ResponseMessage,
                      message:
                        "Are you sure you want to continue scanning the latest stock.",
                      buttons: [
                        {
                          label: "Yes",
                          onClick: () => {
                            if (this.state.remark == "") {
                              this.setState({ older: true });
                            }

                            this.ScanItem(qr, false);
                          },
                        },
                        {
                          label: "No",
                          onClick: () => {
                            ;
                            let olddata = [];
                            resdata.ResponseData.forEach((e1) => {
                              let scanned;
                              this.state.scanItems.forEach((e2) => {
                                if (e1.Id == e2.Id) {
                                  scanned = 1;
                                }
                              });
                              if (scanned == undefined) {
                                e1.EXPDate =
                                  e1.EXPDate != null || undefined
                                    ? e1.EXPDate.substring(0, 10)
                                    : e1.EXPDate;

                                olddata.push(e1);
                              }
                            });
                            this.setState({
                              olderstockVisible: true,

                              olderdata: olddata,
                            });
                          },
                        },
                      ],
                    });
                  }, 100);
                }
              } //// else of response code 5000 ends here
              else this.refs.Messchild.showWarn(resdata.ResponseMessage);
            }
          );
        } else if (id != 2) {
          this.refs.Messchild.showWarn("Invalid Scanned");
        }
      }, 50);
      this.setState({
        scanTxt: "",
      });
    }
  }

  // delete user data
  deleteScanned(CartonId) {
    ;
    let arrayfilter = this.state.scanItems.filter(
      (x) => x.CartonId != CartonId
    );

    this.setState({
      scanItems: arrayfilter,
    });
  }

  // seach table
  searchFilter(e) {
    this.setState({
      loading: true,
    });
    this.tblResponse.SearchValue = e.target.value;
    this.GetTertiaryPacking();
  }

  callbackFunction = (childData) => {
    this.setState({ message: childData });
  };

  // disabled checkbox
  activeTemplate(rowData, column) {
    return <input type="checkbox" disabled checked={rowData.IsActive} />;
  }

  // Sort table
  onSort(event) {
    this.setState({
      sortOrder: this.state.sortOrder === 1 ? 2 : 1,
      sortIcon: !this.state.sortIcon,
    });
    const sortField =
      event.sortField == "CreatedDt" ? "CreatedDt" : event.sortField;
    this.tblResponse.SortColumn = sortField ? sortField : "";
    this.tblResponse.SortOrder = this.state.sortOrder === 1 ? "DESC" : "ASC";
    this.GetTertiaryPacking();
    const clickData = document.getElementsByClassName(
      "p-sortable-column-icon pi pi-fw pi-sort"
    );
    console.log(event);
  }

  // edit form
  actionTemplate(rowData, column) {
    const deleteFunc = () => {
      confirmAlert({
        title: "Confirm to delete",
        message: "Are you sure to do this.",
        buttons: [
          {
            label: "Yes",
            onClick: () => this.deleteScanned(rowData.CartonId),
          },
          {
            label: "No",
            onClick: () => null,
          },
        ],
      });
    };

    return (
      <span>
        <Button
          type="button"
          icon="pi pi-trash"
          onClick={deleteFunc}
          className="p-button-danger"
        ></Button>
      </span>
    );
  }

  // create html part
  render() {
    const items = [{ label: "Master" }, { label: "Tertiary Packing" }];
    const TabView = (
      <Dialog
        className="popup-modal py-3 text-center"
        style={{ width: "30%" }}
        header="Scan the Carton Slip For Tertiary Packing"
        visible={this.state.Tvisible}
        modal={true}
        onHide={() => {
          this.setState({ Tvisible: false });
        }}
      >
        <div className="py-3 text-center">
          {" "}
          <input
            type="text"
            value={this.state.CartonName}
            name="CartonName"
            onChange={(e) => {
              this.setState({ CartonName: e.target.value });
            }}
            onKeyDown={(e) => {
              if (e.key == "Enter") {
                if (
                  e.target.value.includes(sessionStorage.getItem("Prefix")) &&
                  e.target.value.includes("TID") &&
                  (e.target.value.match(/TID/g) || []).length == 1
                ) {
                  this.submitFormFinal(e);
                } else {
                  this.refs.Messchild.showWarn("Invalid Cartons");
                  this.setState({ CartonName: "" });
                }
              }
            }}
            autoFocus
          />
        </div>
      </Dialog>
    );
    const home = { icon: "pi pi-home", url: "/dashboard" };
    let header = (
      <div className="row">
        <div className="col-md-8 text-left">
          <form className="form-inline">
            <div className="form-group">
              <label className="ml-2 mr-2">Carton Size:</label>
              <Dropdown
                filter
                autoFocus
                disabled={this.state.IsDisabledCartonDDL}
                optionLabel="CartonName"
                onChange={(e) => {
                  this.handleChange(e);
                }}
                value={this.state.CartonId}
                className="w-60"
                placeholder="Select Carton"
                name="CartonId"
                options={this.state.CartonDDL}
              />
              {this.state.formError.CartonId !== null ? (
                <div className="error">{this.state.formError.CartonId}</div>
              ) : null}
            </div>
            <div className="ml-4 form-group">
              <label>Scan items:</label>
              {/* <input  value={this.state.scanTxt} type="search" className="form-control ml-2" id="scan" onChange={e => {this.ScanItem(e) }} /> */}
              <input
                ref={this.autoref}
                onChange={(e) => {
                  this.handleChange(e);
                }}
                name="scanTxt"
                value={this.state.scanTxt}
                type="search"
                className="form-control ml-2"
                id="scan"
                onKeyDown={(e) => {
                  this.ScanItem(e, true);
                }}
              />
            </div>
          </form>
        </div>
        <div className="col-md-4">
          <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>

          <InputText
            type="search"
            onInput={(e) => this.setState({ globalFilter: e.target.value })}
            placeholder="Global Search"
            size="30"
          />
        </div>
      </div>
    );

    let header2 = (
      <div className="row">
        <div className="col-md-4">
          <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>
          <InputText
            type="search"
            onInput={this.searchFilter}
            placeholder="Global Search"
            size="30"
          />
        </div>
      </div>
    );

    return (
      <>
        <MessageComponent ref="Messchild" />
        <ConfirmationModal parentCallback={this.callbackFunction} ref="child" />
        <div className="row mb-3">
          <div className="col-md-6 heading">
            <h1>Tertiary Packing</h1>
          </div>
          <div className="col-md-6 custom-breadcrumb">
            <BreadCrumb model={items} home={home} />
          </div>
        </div>
        <div className="heading">
          {/* <Button label="Add Packing" icon="pi pi-plus-circle" onClick={this.openDialog} /> */}
        </div>
        <Loaders loader={this.state.Loader1} classType="small" />
        <DataTable
          responsive={true}
          header={header}
          emptyMessage={this.state.emptyMessage}
          value={this.state.scanItems}
          sortMode="multiple"
          paginator={true}
          rows={this.state.rows}
          globalFilter={this.state.globalFilter}
          className="tblAuto"
        >
          <Column field="CartonId" header="Carton Id" />
          <Column field="Qty" header="Total Qty" sortable="custom" />

          <Column field="MFGDate" header="MFG Date" />
          <Column field="EXPDate" header="Expire Date" />
          <Column body={this.actionTemplate} header="Action" />
        </DataTable>

        <div className="col-md-12 text-center mt-4 mb-4">
          <button className="btn btn-primary mt-4" onClick={this.submitForm}>
            Submit
          </button>
        </div>

        <div className="col-md-12 p-0">
          <Loaders loader={this.state.Loader2} classType="small" />
          <DataTable
            responsive={true}
            header={header2}
            globalFilter={this.state.globalFilter}
            emptyMessage={this.state.emptyMessage}
            value={this.state.sales}
            sortMode="multiple"
            paginator={true}
            rows={this.state.rows}
            rowsPerPageOptions={[10, 50, 200, 500]}
            totalRecords={this.state.totalRecords}
            lazy={true}
            first={this.state.first}
            onPage={this.onPage}
            onSort={this.onSort}
            className="tblAuto"
          >
            <Column
              field="TertiaryCartonId"
              header="CartonId"
              headerStyle={{ width: "250px" }}
              sortable={true}
            />
            <Column
              field="TotalSecondaryCarton"
              header="Total Carton"
              headerStyle={{ width: "250px" }}
              sortable="custom"
            />
            <Column
              field="CreatedDt"
              header="Created Date"
              headerStyle={{ width: "250px" }}
              sortable={true}
            />
            <Column
              field="CartonName"
              headerStyle={{ width: "250px" }}
              header="Carton Size"
              sortable={true}
            />
            <Column
              field="reason"
              headerStyle={{ width: "250px" }}
              header="Reason of Fractional"
              style={{ width: "200px" }}
            />
          </DataTable>
          <br />
          <br />
          <br />
        </div>
        <Dialog
          className="popup-modal"
          style={{ width: "30%" }}
          header="Add approval remarks"
          visible={this.state.isold}
          modal={true}
          onHide={this.hideFunc}
        >
          <div className="row">
            <div className="col-md-12">
              <label>
                Remark<span>*</span>:
              </label>
              <input
                type="text"
                style={{ width: "95%", height: "30px" }}
                value={this.state.remark}
                onChange={this.handleChange}
                className="form-control"
                name="remark"
              />
            </div>
          </div>
          <div className="col-md-12 text-center mt-4">
            <button
              type="submit"
              className="btn btn-primary"
              onClick={() => {
                this.setState({ isold: false });
                this.submitForm();
              }}
            >
              Submit
            </button>
          </div>
        </Dialog>
        <Dialog
          className="popup-modal"
          style={{ width: "60%" }}
          header="Older Stock List"
          visible={this.state.olderstockVisible}
          modal={true}
          onHide={this.hideFunc}
        >
          <DataTable
            responsive={true}
            emptyMessage={this.state.emptyMessage}
            value={this.state.olderdata}
            sortMode="multiple"
            paginator={false}
            className="tblAuto"
          >
            <Column field="CartonId" header="Carton Id" />
            <Column field="Qty" header="Quantity" />
            <Column field="EXPDate" header="Expire Date" />
          </DataTable>
        </Dialog>
        <Dialog
          visible={this.state.rvisible}
          className="popup-modal"
          style={{ width: "30%" }}
          modal={true}
          header="Fractional Carton Remark"
          onHide={() => {
            this.setState({ rvisible: false });
          }}
        >
          <div className="row">
            <div className="col-md-12">
              <h5>Define in min 5 char</h5>
              <label>
                Submit Remark<span>*</span>:
              </label>
              <input
                type="text"
                style={{ width: "95%", height: "30px" }}
                name="reason"
                value={this.state.reason}
                onChange={this.handleChange}
                className="form-control"
              />
            </div>
          </div>
          <div className="col-md-12 text-center mt-4">
            <button
              type="button"
              onClick={(e) => {
                this.setState({ rvisible: false });
                this.submitForm();
              }}
              className="btn btn-primary"
            >
              Submit
            </button>
          </div>
        </Dialog>
        {TabView}
      </>
    );
  }
}
