import React, { Component } from "react";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { BreadCrumb } from "primereact/breadcrumb";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import MappingService from "../../services/MappingService";
import tableRequest from "../../models/tableRequest.js";
import ProductDamageService from "../../services/ProductDamageService";
import MessageComponent from "../../template/Message";
import $ from "jquery";
import { Calendar } from "primereact/calendar";
import { SelectButton } from "primereact/selectbutton";
import Loaders from "../../template/Loaders";
import { CSVLink } from "react-csv";

export default class ProductDamage extends Component {
  constructor(props) {
    super(props);
    this.MappingService = new MappingService();
    this.ProductDamageService = new ProductDamageService();
    this.tblResponse = new tableRequest();
    this.tblResponseDamaged = new tableRequest();
    this.tblResponseExport = new tableRequest();

    this.state = {
      heads: ["FromNo", "ToNo", "Qty", "Reason", "CreatedDate"],
      exportData: [],
      visible: false,
      formIsValid: true,
      sortOrder: "",
      refrenceFromNo: 0,
      damageProductLoader: false,
      productDamageLoader: false,
      updateLoader: true,
      refrenceToNo: 0,
      MappingId: 0,
      ProductId: null,
      ProductName: "",
      FromNo: 0,
      ToNo: 0,
      Qty: 0,
      Reason: "",
      editMapping: "",
      formError: {
        ProductName: null,
        FromNo: null,
        ToNo: null,
        Qty: null,
        Reason: null,
      },
      sales: [],
      loading: true,
      first: 0,
      rows: 10,
      totalRecords: 0,
      globalFilter: null,
      emptyMessage: "No Data found",
      sortOrderDamaged: "",
      loadingDamaged: true,
      firstDamaged: 0,
      rowsDamaged: 5,
      totalRecordsDamaged: 0,
      globalFilterDamaged: null,
      emptyMessageDamaged: "No Data found",
      damaged: [],
      isPreprintedValue: "Static Qr Mapping",
      isPreprinted: false,
    };

    this.brandTemplate = this.brandTemplate.bind(this);
    this.actionTemplate = this.actionTemplate.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.hideFunc = this.hideFunc.bind(this);
    this.onPage = this.onPage.bind(this);
    this.onPageDamaged=this.onPageDamaged.bind(this);
    this.searchFilter = this.searchFilter.bind(this);
    this.onSort = this.onSort.bind(this);
    this.setPreprintedValue = this.setPreprintedValue.bind(this);
    this.GetDamageExport = this.GetDamageExport.bind(this);
  }
  //trigger api function on page call
  componentDidMount() {
    setTimeout(() => {
      this.GetMappings();
      this.GetProductDamages();
      this.GetDamageExport();
    }, 1000);

    $(".p-column-title").on("click", function () {
      $(this)
        .next()
        .removeClass("pi-sort")
        .toggleClass("pi-sort-up")
        .addClass("pi-sort-down");
    });
  }

  // on page event call
  onPage(event) {
    this.setState({
      loading: true,
    });

    //imitate delay of a backend call
    setTimeout(() => {
      this.getLazyLoadingValues(event);
      this.GetMappings();
    }, 250);
  }

  onPageDamaged(event) {
    this.setState({
      loadingDamaged: true,
    });

    //imitate delay of a backend call
    setTimeout(() => {
      this.getLazyLoadingValues1(event);
      this.GetProductDamages();
      this.GetDamageExport();
    }, 250);
  }
  // get product mapping list data
  GetMappings() {
    this.tblResponse.SortColumn = "MFGDate";
    this.MappingService.GetMappings(this.tblResponse).then((data) => {
      const resdata = data.data;
      this.setState({ productDamageLoader: true });
      //if response data is not null sales state wiill be set to response data
      if (
        resdata !== null &&
        resdata.ResponseData !== null &&
        resdata.ResponseData.length > 0
      ) {
        this.datasource = resdata.ResponseData;
        this.setState({
          totalRecords: resdata.ResponseData[0].TotalRows,
          first: this.tblResponse.First - 1,
          sales: this.datasource.slice(
            0,
            this.tblResponse.First - 1 + this.tblResponse.PageSize
          ),
          loading: false,
        });
      }
      //if response data is  null sales state will be set null
      else {
        this.datasource = null;
        this.setState({
          totalRecords: 0,
          first: 1,
          sales: null,
          loading: false,
        });
      }
    });
  }
  hideFunc() {
    this.setState({
      visible: false,
      buttonLabel: "Submit",
      formHeading: "Add Number Issue",
    });
    this.formReset();
  }

  // submit form
  submitForm(event) {
    event.preventDefault();
    let isfrmvalid = true;
    let checkerror = this.state.formError;

    Object.entries(checkerror).map(([key, value]) => {
      const val = this.state[key];
      let valid = this.validateForm(val, key);
      //form is not valid
      if (!valid) {
        this.setState({ checkerror: isfrmvalid });
        isfrmvalid = false;
      }
    });
    // form is valid
    if (isfrmvalid) {
      this.setState({ updateLoader: false });
      const formData = {
        MappingId: this.state.MappingId,
        // ProductId: this.state.ProductId === '' ? 0 : parseInt(this.state.ProductId),
        FromNo: this.state.FromNo === "" ? 0 : parseInt(this.state.FromNo),
        ToNo: this.state.ToNo === "" ? 0 : parseInt(this.state.ToNo),
        Qty: this.state.Qty === "" ? 0 : parseInt(this.state.Qty),
        Reason: this.state.Reason,
        isPreprinted: this.state.isPreprinted,
      };

      setTimeout(() => {
        this.ProductDamageService.CreateDamage(formData).then((data) => {
          this.setState({ updateLoader: true });
          const resdata = data.data;
          //if ResponseCode is 200 success message will be shown else will move to 'else statement'
          if (resdata.ResponseCode === "200") {
            this.refs.child.showSuccess(resdata.ResponseMessage);
            this.setState({
              loading: true,
              visible: false,
              loadingDamaged: true,
            });
            this.formReset();
            this.GetMappings();
            this.GetProductDamages();
            this.GetDamageExport();
          } else if (resdata.ResponseCode === "400") {
            this.refs.child.showWarn(resdata.ResponseMessage);
          } else {
            this.refs.child.showError(resdata.ResponseMessage);
          }
        });
      }, 1000);
    }
  }

  // set product pic
  brandTemplate(rowData, column) {
    var src = rowData.ProductPic;
    return <img src={src} width="100" alt={rowData.ProductPic} />;
  }

  // set lazy loading
  getLazyLoadingValues(event) {
    this.setState({ rows: event.rows });
    this.tblResponse.PageSize = event.rows;
    this.tblResponse.First = event.first + 1;
    this.tblResponse.IsActive = "1";
    this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
    //this.tblResponse.SortColumn = event.sortField;
    this.tblResponse.SortOrder = event.sortOrder === 1 ? "ASC" : "DESC";
  }

  // search data filter
  searchFilter(e) {
    this.setState({
      loading: true,
    });
    this.tblResponse.SearchValue = e.target.value;
    this.GetMappings();
  }

  // call when input
  handleChange(event) {
    const { name, value } = event.target;
    let errors = this.state.formError;

    const isValid = this.validateForm(value, name);
    this.setState({ errors, [name]: value });
  }

  // validate form
  //resolved KREP-17,KREP-21
  validateForm(value, name) {
    let errors = this.state.formError;
    let IsValid = true;

    //to validate each form field with required conditions
    switch (name) {
      case "ProductName":
        if (value == null) {
          IsValid = false;
          errors.ProductName = "Please select product.";
        } else errors.ProductName = null;
        break;
      case "FromNo":
        this.setState({ Qty: 0 });

        value = parseInt(value);
        if (value < 1) {
          IsValid = false;
          errors.FromNo = "Please enter +ve from number.";
        } else {
          if (value === this.state.editMapping.ToNo) {
            IsValid = true;
            errors.FromNo = "From No. should be less than To no.";
            this.setState({ Qty: 1 });
          } else if (
            value < this.state.editMapping.FromNo ||
            value > this.state.editMapping.ToNo
          ) {
            IsValid = false;
            errors.FromNo = "Numbers must exist between mapped series";
            this.setState({ Qty: 0 });
          } else {
            errors.FromNo = null;
            errors.ToNo = null;
            errors.Qty = null;
            const fromNumber = parseInt(value); //value.trim() === '' ? 0 : parseInt(value.trim());
            const toNumber =
              this.state.ToNo === "" ? 0 : parseInt(this.state.ToNo);
            if (
              fromNumber >= this.state.refrenceFromNo &&
              fromNumber <= this.state.refrenceToNo &&
              toNumber >= this.state.refrenceFromNo &&
              toNumber <= this.state.refrenceToNo
            ) {
              if (fromNumber <= toNumber) {
                const qty = toNumber - fromNumber + 1;
                if (qty >= 0) {
                  this.setState({ Qty: qty });
                } else {
                  IsValid = false;
                  errors.FromNo = "From No. should be less than to number.";
                  this.setState({ Qty: 0 });
                }
              }
              // else {
              //     IsValid = false;
              //     errors.FromNo = "From No. should be less than to number.";
              //     this.setState({ Qty: 0 })
              // }
            } else {
              IsValid = false;
              errors.FromNo =
                "From No. should be less than To no. and numbers must exist between mapped series";
              this.setState({ Qty: 0 });
            }
          }
        }
        break;
      case "ToNo":
        if (value < 1) {
          IsValid = false;
          errors.ToNo = "Please enter To Number.";
        } else errors.ToNo = null;
        break;
      case "Qty":
        value = parseInt(value);

        if (value < 1) {
          IsValid = false;
          errors.Qty = "Please enter +ve quantity.";
        }
        // else
        // if(value.length>10){
        //    IsValid=false;
        //   errors.Qty="enter valid quantity.";
        // }
        else {
          if (value > this.state.editMapping.Qty) {
            errors.Qty =
              "Qty can not greater than " + this.state.editMapping.Qty;
            this.setState({ Qty: 0 });
          } else {
            // if (value < this.state.editMapping.FromNo || value > this.state.editMapping.ToNo)
            // {  IsValid = false;
            //     errors.Qty = "Numbers must exist between mapped series";
            //     this.setState({ Qty: 0 })
            // }
            //   else  if (this.state.FromNo > this.state.editMapping.FromNo)
            //     {
            //
            //         IsValid = false;
            //         errors.Qty = "From Number can not greater than "+this.state.editMapping.ToNo;
            //         this.setState({ Qty: 0 })
            //     }
            if (value > this.state.editMapping.Qty) {
              IsValid = false;
              errors.Qty =
                "Qty can not greater than " + this.state.editMapping.Qty;
              this.setState({ Qty: 0 });
            } else {
              errors.ToNo = null;
              errors.Qty = null;
              errors.FromNo = null;
              const qty = parseInt(value); //value.toString().trim() === '' ? 0 : parseInt(value.toString().trim());
              const fromNumber =
                this.state.FromNo.toString().trim() === ""
                  ? 0
                  : parseInt(this.state.FromNo.toString().trim());
              if (fromNumber >= 1 && qty >= 1) {
                const toNumber = qty + fromNumber - 1;

                if (toNumber > this.state.editMapping.ToNo) {
                  IsValid = false;
                  errors.Qty =
                    "To number should not be greater than." +
                    this.state.editMapping.ToNo;
                  this.setState({ Qty: 0 });
                  // this.setState({ ToNo: toNumber })
                } else if (toNumber >= 0) {
                  this.setState({ ToNo: toNumber });
                } else {
                  IsValid = false;
                  errors.Qty =
                    "To number should be greater than or equal to from number.";
                  this.setState({ ToNo: 0 });
                }
              } else {
                // IsValid = false;
                // errors.ToNo = "To number should be greater than from number.";
                this.setState({ ToNo: 0 });
              }
            }
          }
        }
        break;

      case "Reason":
        if (value.trim().length < 1) {
          IsValid = false;
          errors.Reason = "Please enter reason.";
        } else errors.Reason = null;
        break;

      default:
        break;
    }

    return IsValid;
  }

  // form reset
  formReset() {
    this.setState({
      MappingId: 0,
      ProductId: null,
      ProductName: "",
      FromNo: "",
      ToNo: "",
      Qty: "",
      Remarks: "",
      Reason: "",
    });

    let errors = this.state.formError;
    Object.entries(errors).map(([key, value]) => {
      errors[key] = "";
    });
  }

  // call when edit
  actionTemplate(rowData, column) {
    const editMapping = { ...rowData };
    const editMode = (e) => {
      this.setState({
        buttonLabel: "Damage",
        formHeading: "Add Product Damage",
        visible: true,
        MappingId: rowData.MappingId,
        ProductId: rowData.ProductID,
        ProductName: "" + rowData.ProductName,
        FromNo: "" + rowData.FromNo,
        ToNo: "" + rowData.FromNo,
        Qty: 0,
        refrenceFromNo: rowData.FromNo,
        refrenceToNo: rowData.ToNo,
        editMapping: rowData,
        Reason: "",
      });
    };

    return (
      <span>
        <Button
          type="button"
          onClick={editMode}
          label="Damage"
          className="p-button-warning"
          style={{ marginRight: "3px" }}
        ></Button>
      </span>
    );
  }

  // call when sort data
  onSort(event) {
    this.setState({ sortOrder: this.state.sortOrder === 1 ? 2 : 1 });
    const sortField = event.sortField;
    this.tblResponse.SortColumn = sortField ? sortField : "";
    this.tblResponse.SortOrder = this.state.sortOrder === 1 ? "DESC" : "ASC";
    this.GetMappings();
  }

  // bottom GRID

  getLazyLoadingValues1(event) {
    this.setState({ rowsDamaged: event.rows });
    this.tblResponseDamaged.PageSize = event.rows;
    this.tblResponseDamaged.First = event.first + 1;
    this.tblResponseDamaged.IsActive = "1";
    this.tblResponseDamaged.SearchValue = event.globalFilter
      ? event.globalFilter
      : "";
    //this.tblResponse.SortColumn = event.sortField;
    this.tblResponseDamaged.SortOrder = event.sortOrder === 1 ? "ASC" : "DESC";
  }

  searchFilter1(e) {
    this.setState({
      loadingDamaged: true,
    });
    this.tblResponseDamaged.SearchValue = e.target.value;
    this.GetProductDamages();
    this.GetDamageExport();
  }

  onSort1(event) {
    this.setState({
      sortOrderDamaged: this.state.sortOrderDamaged === 1 ? 2 : 1,
    });
    const sortField =
      event.sortField == "CreatedDt" ? "CreatedDt" : event.sortField;
    this.tblResponseDamaged.SortColumn = sortField ? sortField : "";
    this.tblResponseDamaged.SortOrder =
      this.state.sortOrderDamaged === 1 ? "DESC" : "ASC";
    this.GetProductDamages();
    this.GetDamageExport();
  }

  GetProductDamages() {
    this.tblResponseDamaged.SortColumn = "CreatedDt";
    this.ProductDamageService.GetProductDamages(this.tblResponseDamaged).then(
      (data) => {
        const resdata = data.data;
        this.setState({ damageProductLoader: true });
        //if ResponseData is not null
        if (
          resdata !== null &&
          resdata.ResponseData !== null &&
          resdata.ResponseData.length > 0
        ) {
          //  this.datasource = resdata.ResponseData;
          this.setState({
            totalRecordsDamaged: resdata.ResponseData[0].TotalRows,
            first: this.tblResponse.First - 1,
            damaged: resdata.ResponseData.slice(
              0,
              this.tblResponseDamaged.First -
                1 +
                this.tblResponseDamaged.PageSize
            ),
            loadingDamaged: false,
          });
        }
        //if ResponseData is null
        else {
          // this.datasource = null;
          this.setState({
            totalRecordsDamaged: 0,
            firstDamaged: 1,
            damaged: null,
            loadingDamaged: false,
          });
        }
      }
    );
  }
  /// to set isPreprinted
  setPreprintedValue(e) {
    this.setState(
      {
        isPreprintedValue: e.value,
        isPreprinted: e.value == "Static Qr Mapping" ? false : true,
      },
      () => {
        this.tblResponse.isPreprinted = this.state.isPreprinted;
        this.tblResponseDamaged.isPreprinted = this.state.isPreprinted;
        this.GetMappings();
        this.GetProductDamages();
        this.GetDamageExport();
      }
    );
  }

  GetDamageExport() {
    this.tblResponseExport.SortColumn = "CreatedDate";
    this.ProductDamageService.GetProductDamages(this.tblResponseExport).then(
      (data) => {
        const resdata = data.data;
        //when response data is not null
        if (
          resdata !== null &&
          resdata.ResponseData !== null &&
          resdata.ResponseData.length > 0
        ) {
          this.setState({
            exportData: resdata.ResponseData,
          });
        }
      }
    );
  }
  //return html data
  //resolved KREP-20,KREP-38
  render() {
    const items = [{ label: "Master" }, { label: "Product Damage" }];

    const home = { icon: "pi pi-home", url: "/dashboard" };
    const options = ["Static Qr Mapping"]; //, 'PrePrinted Qr Mapping'];
    let header = (
      <div className="row">
        <div className="col-md-6 text-left">
          {/* <SelectButton value={this.state.isPreprintedValue} className="custom-tab" options={options} onChange={this.setPreprintedValue} /> */}
        </div>
        <div className="col-md-6 text-right">
          <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>
          <InputText
            type="search"
            onInput={this.searchFilter}
            placeholder="Global Search"
            size="30"
          />
        </div>
      </div>
    );
    let headerDamaged = (
      <div style={{ textAlign: "right" }}>
        <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>
        <InputText
          type="search"
          onInput={this.searchFilter1}
          placeholder="Global Search"
          size="30"
        />
        <CSVLink
          data={this.state.exportData}
          headers={this.state.heads}
          filename={"Damaged Product.csv"}
          target="_blank"
        >
          <button
            disabled={this.state.exportData.length == 0 ? true : false}
            className="btn btn-success p-2 ml-2 buttone1"
          >
            <i className="pi pi-file-excel mr-2"></i>
            Export
          </button>
        </CSVLink>
      </div>
    );

    return (
      <>
        <MessageComponent ref="child" />
        <Loaders loader={this.state.updateLoader} />
        <div className="row mb-3">
          <div className="col-md-6 heading">
            <h1>Product Damage</h1>
          </div>
          <div className="col-md-6 custom-breadcrumb">
            <BreadCrumb model={items} home={home} />
          </div>
        </div>
        <div className="heading"></div>
        <Loaders loader={this.state.productDamageLoader} classType="small" />
        <DataTable
          responsive={true}
          header={header}
          globalFilter={this.state.globalFilter}
          emptyMessage={this.state.emptyMessage}
          value={this.state.sales}
          sortMode="multiple"
          paginator={true}
          rows={this.state.rows}
          rowsPerPageOptions={[5, 10, 20]}
          totalRecords={this.state.totalRecords}
          lazy={true}
          first={this.state.first}
          onPage={this.onPage}
         
          onSort={this.onSort}
        >
          <Column field="ProductName" header="Product Name" sortable={true} />
          <Column field="FromNo" header="From No." sortable={true} />
          <Column field="ToNo" header="To No." sortable={true} />
          <Column field="Qty" header="Quantity" sortable={true} />
          {/* <Column field="FirmName" header="WareHouse" sortable={false} /> */}
          <Column field="MFGDate" header="MFG Date" sortable={true} />
          <Column field="BatchNo" header="Batch No" sortable={true} />
          <Column body={this.actionTemplate} header="Action" />
        </DataTable>

        <div className="heading mt-5">
          <h1>Damaged Product</h1>
        </div>
        <Loaders loader={this.state.damageProductLoader} classType="small" />
        <DataTable
          responsive={true}
          header={headerDamaged}
          globalFilter={this.state.globalFilterDamaged}
          emptyMessage={this.state.emptyMessageDamaged}
          value={this.state.damaged}
          sortMode="multiple"
          paginator={true}
          rows={this.state.rowsDamaged}
          rowsPerPageOptions={[5, 10, 20]}
          totalRecords={this.state.totalRecordsDamaged}
          lazy={true}
          first={this.state.firstDamaged}
          onPage={this.onPageDamaged}
          onSort={this.onSort1}
        >
          {/* <Column field="ProductName" header="Product Name" sortable={true} /> */}
          <Column field="FromNo" header="From No." sortable={true} />
          <Column field="ToNo" header="To No." sortable={true} />
          <Column field="Qty" header="Quantity" sortable={true} />
          <Column field="Reason" header="Reason" sortable={true} />
          <Column field="CreatedDate" header="Created Date" sortable={true} />
        </DataTable>

        <Dialog
          className="popup-modal"
          header={this.state.formHeading}
          visible={this.state.visible}
          modal={true}
          onHide={this.hideFunc}
        >
          <form onSubmit={this.submitForm}>
            <div className="form-padding">
              <div className="row">
                <div className="col-md-4 text-right">
                  <label>
                    Product Name<span>*</span>:
                  </label>
                </div>
                <div className="col-md-8 mb-2">
                  <input
                    type="text"
                    readOnly
                    value={this.state.ProductName}
                    onChange={this.handleChange}
                    className="form-control"
                    name="ProductName"
                  />
                  {this.state.formError.ProductName !== null ? (
                    <div className="error">
                      {this.state.formError.ProductName}
                    </div>
                  ) : null}
                </div>
                <div className="col-md-4 text-right">
                  <label>
                    From No.<span>*</span>:
                  </label>
                </div>
                <div className="col-md-8 mb-2">
                  <input
                    type="number"
                    id="txtProductId"
                    value={this.state.FromNo}
                    onChange={this.handleChange}
                    className="form-control"
                    name="FromNo"
                  />
                  {this.state.formError.FromNo !== null ? (
                    <div className="error">{this.state.formError.FromNo}</div>
                  ) : null}
                </div>

                <div className="col-md-4 text-right">
                  <label>
                    Quantity<span>*</span>:
                  </label>
                </div>
                <div className="col-md-8 mb-2">
                  <input
                    type="number"
                    value={this.state.Qty}
                    onChange={this.handleChange}
                    className="form-control"
                    name="Qty"
                  />
                  {this.state.formError.Qty !== null ? (
                    <div className="error">{this.state.formError.Qty}</div>
                  ) : null}
                </div>

                <div className="col-md-4 text-right">
                  <label>
                    To No.<span>*</span>:
                  </label>
                </div>
                <div className="col-md-8 mb-2">
                  <input
                    type="number"
                    readOnly
                    value={this.state.ToNo}
                    onChange={this.handleChange}
                    className="form-control"
                    name="ToNo"
                  />
                  {this.state.formError.ToNo !== null ? (
                    <div className="error">{this.state.formError.ToNo}</div>
                  ) : null}
                </div>
                <div className="col-md-4 text-right">
                  <label>
                    Reason<span>*</span>:
                  </label>
                </div>
                <div className="col-md-8 mb-2">
                  <input
                    type="text"
                    className="form-control"
                    value={this.state.Reason}
                    onChange={this.handleChange}
                    name="Reason"
                  />
                  {this.state.formError.Reason !== null ? (
                    <div className="error">{this.state.formError.Reason}</div>
                  ) : null}
                </div>

                <div className="col-md-12 text-center">
                  <button type="submit" className="btn btn-primary">
                    {this.state.buttonLabel}
                  </button>
                </div>
              </div>
              <div>
                <label>
                  Note: Fields marked as (
                  <span style={{ color: "red" }}>*</span>) are required
                </label>
              </div>
            </div>
          </form>
        </Dialog>
      </>
    );
  }
}
// import React, { Component } from 'react';
// import 'primereact/resources/themes/nova-light/theme.css';
// import 'primereact/resources/primereact.min.css';
// import 'primeicons/primeicons.css';
// import { DataTable } from 'primereact/datatable';
// import { InputText } from 'primereact/inputtext';
// import { Column } from 'primereact/column';
// import { Dialog } from 'primereact/dialog';
// import { Button } from 'primereact/button';
// import ProductDamageService from '../../services/ProductDamageService';
// import tableRequest from '../../models/tableRequest.js';
// import MessageComponent from './../../template/Message';
// import $ from 'jquery';

// // Product Damage
// export default class ProductDamage extends Component {
//     constructor(props) {
//         super(props);
//         this.ProductDamageService = new ProductDamageService();
//         this.tblResponse = new tableRequest();
//         this.state = {
//             visible: false,
//             formIsValid: true,
//             FromNo: '',
//             ToNo: '',
//             Qty: '',
//             Reason: '',
//             sortOrder:'',

//             formError: {
//                 FromNo: null,
//                 ToNo: null,
//                 Qty: null,
//                 Reason: null
//             },

//             buttonLabel: 'Submit',
//             formHeading: 'Add Product Damage',

//             sales: [],
//             loading: true,
//             first: 0,
//             rows: 5,
//             totalRecords: 0,
//             globalFilter: null,
//             emptyMessage: 'No Data found'
//         }

//         this.SubmitForm = this.SubmitForm.bind(this);
//         this.handleChange = this.handleChange.bind(this);
//         this.hideFunc = this.hideFunc.bind(this);
//         this.onPage = this.onPage.bind(this);
//         this.searchFilter = this.searchFilter.bind(this);
//         this.openDialog = this.openDialog.bind(this);
//         this.onSort = this.onSort.bind(this);
//     }
// //trigger api function on page call
//     componentDidMount() {
//         setTimeout(() => {
//             this.GetProductDamages();
//         }, 1000);
//         $('.p-column-title').on('click', function(){
//             $(this).next().removeClass('pi-sort').toggleClass('pi-sort-up').addClass('pi-sort-down');
//         });
//     }

//     // call on  Page event
//     onPage(event) {
//         this.setState({
//             loading: true
//         });

//         //imitate delay of a backend call
//         setTimeout(() => {
//             this.getLazyLoadingValues(event);
//             this.GetProductDamages();
//         }, 250);
//     }

//     // show when add new record
//     hideFunc() {
//         this.setState({
//             visible: false, buttonLabel: 'Submit',
//             formHeading: 'Add Number Issue'
//         })
//         this.formReset()
//     }

//     // form Reset
//     formReset() {
//         this.setState({
//             FromNo: '', ToNo: '', Qty: '', Reason: ''
//         });
//         let errors = this.state.formError;
//         Object.entries(errors).map(([key, value]) => {
//             errors[key] = '';
//         });
//     }

//     // submit form
//     SubmitForm(event) {
//         event.preventDefault();

//         let isfrmvalid = true;
//         let checkerror = this.state.formError;

//         Object.entries(checkerror).map(([key, value]) => {
//             const val = this.state[key]
//             let valid = this.validateForm(val, key);
//             //form not valid
//             if (!valid) {
//                 this.setState({ checkerror: isfrmvalid });
//                 isfrmvalid = false;
//             }
//         });
// //form valid
//         if (isfrmvalid) {
//             const formData = {
//                 FromNo: this.state.FromNo == '' ? 0 : parseInt(this.state.FromNo),
//                 ToNo: this.state.ToNo == '' ? 0 : parseInt(this.state.ToNo),
//                 Qty: this.state.Qty == '' ? 0 : parseInt(this.state.Qty),
//                 Reason: this.state.Reason
//             }
//             setTimeout(() => {
//                 this.ProductDamageService.CreateDamage(formData).then(data => {
//                     const resdata = data.data;
//                     //if ResponseCode is 200 sucess else error or warning message
//                     if (resdata.ResponseCode === '200') {
//                         this.refs.child.showSuccess(resdata.ResponseMessage);
//                         this.setState({
//                             loading: true,
//                             visible: false
//                         });
//                         this.formReset();
//                         this.GetProductDamages();
//                     } else if (resdata.ResponseCode === '400') {
//                         this.refs.child.showWarn(resdata.ResponseMessage);
//                     } else {
//                         this.refs.child.showError(resdata.ResponseMessage);
//                     }
//                 });
//             }, 1000);
//         }
//     }

//     // set formData
//     getLazyLoadingValues(event) {
//         this.setState({ rows: event.rows });
//         this.tblResponse.PageSize = event.rows;
//         this.tblResponse.First = event.first + 1;
//         this.tblResponse.IsActive = '1';
//         this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
//         //this.tblResponse.SortColumn = event.sortField;
//         this.tblResponse.SortOrder = event.sortOrder === 1 ? 'ASC' : 'DESC';
//     }

//     // delete product damage data
//     DeleteProductDamage(referenceID) {
//         setTimeout(() => {
//             this.ProductDamageService.DeleteProductDamage(referenceID).then(data => {
//                 const resdata = data.data;
//              //if ResponseCode is 200 success else error or warning message
//                 if (resdata !== null && resdata.ResponseCode === '200') {
//                     this.refs.child.showSuccess(resdata.ResponseMessage);
//                     const totlrecord = this.state.totalRecords - 1;
//                     const row = this.state.rows;

//                     if (this.tblResponse.First >= totlrecord) {
//                         const _curntCursor = Math.round(totlrecord / row);
//                         this.tblResponse.First = _curntCursor + 1;
//                     }
//                     this.GetProductDamages();
//                 } else {
//                     this.refs.child.showError(resdata.ResponseMessage);
//                 }
//             });
//         }, 1000);
//     }

//     // get product damages list data
//     GetProductDamages() {
//         this.tblResponse.SortColumn='CreatedDt';
//         this.ProductDamageService.GetProductDamages(this.tblResponse).then(data => {
//             const resdata = data.data;
//         //if ResponseData is not null
//             if (resdata !== null && resdata.ResponseData !== null && resdata.ResponseData.length > 0) {
//                 this.datasource = resdata.ResponseData;
//                 this.setState({
//                     totalRecords: resdata.ResponseData[0].TotalRows,
//                     first: (this.tblResponse.First - 1),
//                     sales: this.datasource.slice(0, ((this.tblResponse.First - 1) + this.tblResponse.PageSize)),
//                     loading: false
//                 });
//             }
//             //if ResponseData is null
//              else {
//                 this.datasource = null;
//                 this.setState({
//                     totalRecords: 0,
//                     first: 1,
//                     sales: null,
//                     loading: false
//                 });
//             }
//         });
//     }

//     // search filter data
//     searchFilter(e) {
//         this.setState({
//             loading: true
//         });
//         this.tblResponse.SearchValue = e.target.value;
//         this.GetProductDamages();
//     }

//     //open Dialog
//     openDialog() {
//         this.setState({ visible: true });
//         this.formReset();
//     }

//     // validate form
//    //resolved KREP-17
//     validateForm(value, name) {
//         let errors = this.state.formError;
//         let IsValid = true;
// //to validate each form field with required conditions
//         switch (name) {
//             case 'FromNo':
//                 if (value < 1) {
//                     IsValid = false;
//                     errors.FromNo = "Please enter +ve from number.";
//                 } else
//                     // if (!value.match(/^[0-9]+$/)) {
//                     //     IsValid = false;
//                     //     errors.FromNo = "Please enter only number.";
//                     //     this.setState({ Qty: 0 })
//                     // } else
//                     {

//                         errors.ToNo = null;
//                         errors.Qty = null;
//                         errors.FromNo = null;
//                         const fromNumber = value == '' ? 0 : parseInt(value);
//                         const toNumber = this.state.ToNo == '' ? 0 : parseInt(this.state.ToNo);
//                         if (fromNumber >= 1 && toNumber >= 1) {
//                             if (fromNumber <= toNumber) {
//                                 const qty = (toNumber - fromNumber) + 1;
//                                 if (qty >= 0) {
//                                     this.setState({ Qty: qty })
//                                 } else {
//                                     IsValid = false
//                                     errors.FromNo = "From number should be less than to number.";
//                                     this.setState({ Qty: 0 })
//                                 }
//                             } else {
//                                 IsValid = false;
//                                 errors.FromNo = "From number should be less than to number.";
//                                 this.setState({ Qty: 0 })
//                             }
//                         } else {
//                             // IsValid = false;
//                             // errors.FromNo = "From number should be less than to number.";
//                             this.setState({ Qty: 0 })
//                         }
//                     }
//                 break;
//             case 'ToNo':
//                 if (value< 1) {
//                     IsValid = false;
//                     errors.ToNo = "Please enter +ve to number.";
//                 } else {
//                     // if (!value.match(/^[0-9]+$/)) {
//                     //     IsValid = false;
//                     //     errors.ToNo = "Please enter only number.";
//                     //     this.setState({ Qty: 0 })
//                     // } else

//                     {
//                         errors.ToNo = null;
//                         errors.Qty = null;
//                         errors.FromNo = null;
//                         const toNumber = value == '' ? 0 : parseInt(value.trim());
//                         const fromNumber = this.state.FromNo == '' ? 0 : parseInt(this.state.FromNo);
//                         if (fromNumber >= 1 && toNumber >= 1) {
//                             if (fromNumber <= toNumber) {
//                                 const qty = (toNumber - fromNumber) + 1;
//                                 if (qty >= 0) {
//                                     this.setState({ Qty: qty })
//                                 } else {
//                                     IsValid = false;
//                                     errors.ToNo = "To number should be greater than from number.";
//                                     this.setState({ Qty: 0 })
//                                 }
//                             } else {
//                                 IsValid = false;
//                                 errors.ToNo = "To number should be greater than from number.";
//                                 this.setState({ Qty: 0 })
//                             }
//                         } else {
//                             // IsValid = false;
//                             // errors.ToNo = "To number should be greater than from number.";
//                             this.setState({ Qty: 0 })
//                         }
//                     }
//                 }
//                 break;
//             case 'Qty':
//                 if (value < 1) {
//                     IsValid = false;
//                     errors.Qty = "Please enter your quantity";
//                 }
//                 else errors.Qty = null
//                 break;
//             case 'Reason':
//                 if (value.trim().length < 1) {
//                     IsValid = false;
//                     errors.Reason = "Please enter reason.";
//                 }
//                 else errors.Reason = null
//                 break;
//             default:
//                 break;
//         }

//         return IsValid;
//     }

//     // call every change of form and validation part also
//     handleChange(event) {

//         const { name, value } = event.target;
//         let errors = this.state.formError;

//         const isValid = this.validateForm(value, name);
//         this.setState({ errors, [name]: value });
//     }

//     // sort page event
//     onSort(event) {
//         this.setState({ sortOrder: this.state.sortOrder === 1 ? 2 : 1 });
//         const sortField = event.sortField == "CreatedDt" ? "CreatedDt" : event.sortField;
//         this.tblResponse.SortColumn = sortField ? sortField : "";
//         this.tblResponse.SortOrder = this.state.sortOrder === 1 ? 'DESC' : 'ASC';
//         this.GetProductDamages();
//     }

//     // render HTMl
//     //resolved KREP-19
//     render() {

//         let header = (
//             <div style={{ 'textAlign': 'right' }}>
//                 <i className="pi pi-search" style={{ margin: '4px 4px 0 0' }}></i>
//                 <InputText type="search" onInput={this.searchFilter} placeholder="Global Search" size="30" />
//             </div>
//         );
//         return (
//             <>
//                 <MessageComponent ref="child" />
//                 <div className="heading">
//                     <h1>Product Damage</h1>
//                     <Button label="Product Damage" icon="pi pi-plus-circle" onClick={this.openDialog} />
//                 </div>

//                 <DataTable responsive={true} header={header} globalFilter={this.state.globalFilter}
//                     emptyMessage={this.state.emptyMessage} value={this.state.sales} sortMode="multiple" paginator={true}
//                     rows={this.state.rows} rowsPerPageOptions={[5, 10, 20]} totalRecords={this.state.totalRecords} lazy={true} first={this.state.first}
//                     onPage={this.onPage} onSort={this.onSort} loading={this.state.loading}>
//                     <Column field="FromNo" header="From No." sortable={true} />
//                     <Column field="ToNo" header="To No." sortable={true} />
//                     <Column field="Qty" header="Quantity" sortable={true} />
//                     <Column field="Reason" header="Reason" sortable={true} />
//                     <Column field="CreatedDate" header="Created Date" sortable={true} />
//                 </DataTable>

//                 <Dialog className="popup-modal" header={this.state.formHeading} visible={this.state.visible} modal={true} onHide={this.hideFunc}>
//                     <form onSubmit={this.SubmitForm}>
//                         <div className="row">
//                             <div className="col-md-4 text-right"><label>From No.<span>*</span>:</label></div>
//                             <div className="col-md-8 mb-2"><input type="number" onChange={this.handleChange} value={this.state.FromNo} className="form-control" name="FromNo" />
//                                 {this.state.formError.FromNo !== null ?
//                                     <div className='error'>{this.state.formError.FromNo}</div> : null}</div>
//                             <div className="col-md-4 text-right"><label>To No.<span>*</span>:</label></div>
//                             <div className="col-md-8 mb-2"> <input type="number" className="form-control" value={this.state.ToNo} onChange={this.handleChange} name="ToNo" />
//                                 {this.state.formError.ToNo !== null ?
//                                     <div className='error'>{this.state.formError.ToNo}</div> : null}</div>
//                             <div className="col-md-4 text-right"><label>Quantity<span>*</span>:</label></div>
//                             <div className="col-md-8 mb-2">
//                                 <input type="text" className="form-control" readOnly value={this.state.Qty} onChange={this.handleChange} name="Qty" />
//                                 {this.state.formError.Qty !== null ?
//                                     <div className='error'>{this.state.formError.Qty}</div> : null}
//                             </div>
//                             <div className="col-md-4 text-right"><label>Reason<span>*</span>:</label></div>
//                             <div className="col-md-8 mb-2">
//                                 <input type="text" className="form-control" value={this.state.Reason} onChange={this.handleChange} name="Reason" />
//                                 {this.state.formError.Reason !== null ?
//                                     <div className='error'>{this.state.formError.Reason}</div> : null}
//                             </div>
//                             <div className="col-md-12 text-center">
//                                 <button type="submit" className="btn btn-primary">{this.state.buttonLabel}</button>
//                             </div>
//                         </div>
//                         <div  >
//                             <label>Note: Fields marked as (<span style={{ color: 'red' }}>*</span>) are required</label>
//                         </div>
//                     </form>
//                 </Dialog>
//             </>
//         )
//     }
// }
