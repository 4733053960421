import React, { Component } from "react";

export default class HomePage extends Component {
  render() {
    return (
      <>
        <div>
          <div className="wrapper">
            <div className="nav-bar holo">
              <div className="">
                <nav className="navbar navbar-expand-lg bg-dark navbar-dark">
                  <div className="col-lg-4 col-md-12">
                    <a href="index.html">
                      <img src="img/holologo.png" alt="logo" />
                    </a>
                  </div>

                  <button type="button" className=" btn-outline-hp">
                    <a href="/login" target="_blank">
                      LOGIN NOW
                    </a>
                  </button>
                  <button
                    type="button"
                    className="navbar-toggler"
                    data-toggle="collapse"
                    data-target="#navbarCollapse"
                  >
                    <span className="navbar-toggler-icon" />
                  </button>
                  <div
                    className="collapse navbar-collapse justify-content-between"
                    id="navbarCollapse"
                  >
                    <div className="navbar-nav mr-auto">
                      <a href="#index" className="nav-item nav-link active">
                        Home
                      </a>
                      <a href="#about" className="nav-item nav-link">
                        About us
                      </a>
                      <a href="#review" className="nav-item nav-link">
                        Customers Review
                      </a>
                      <a href="#contact" className="scroll nav-item nav-link">
                        Contact us
                      </a>
                    </div>
                    <div className="ml-auto">
                      <a className="login-btn mobile " href="/login">
                        LOGIN NOW
                      </a>
                    </div>
                  </div>
                </nav>
              </div>
            </div>

            <section id="venue">
              <div className="row slidere-bg animate-in-down">
                <div className="p-0 col-md-12">
                  <div
                    className="carousel slide"
                    data-ride="carousel"
                    id="carousel1"
                  >
                    <div className="carousel-inner" role="listbox">
                      <div className="carousel-item active">
                        {" "}
                        <img
                          className="d-block img-fluid w-100"
                          src="img/login-background.jpg"
                          data-holder-rendered="true"
                        />
                        <div className="carousel-caption"></div>
                      </div>
                      {/* <div className="carousel-item">
                        {" "}
                        <img
                          className="d-block img-fluid w-100"
                          src="img/carousel-2.jpg"
                          data-holder-rendered="true"
                        />
                        <div className="carousel-caption"></div>
                      </div>
                      <div className="carousel-item">
                        {" "}
                        <img
                          className="d-block img-fluid w-100"
                          src="img/carousel-3.jpg"
                          data-holder-rendered="true"
                        />
                        <div className="carousel-caption"></div>
                      </div>
                      <div className="carousel-item">
                        {" "}
                        <img
                          className="d-block img-fluid w-100"
                          src="img/carousel-4.jpg"
                          data-holder-rendered="true"
                        />
                        <div className="carousel-caption"></div>
                      </div>
                      <div className="carousel-item">
                        {" "}
                        <img
                          className="d-block img-fluid w-100"
                          src="img/carousel-5.jpg"
                          data-holder-rendered="true"
                        />
                        <div className="carousel-caption"></div>
                      </div>
                      <div className="carousel-item">
                        {" "}
                        <img
                          className="d-block img-fluid w-100"
                          src="img/carousel-6.jpg"
                          data-holder-rendered="true"
                        />
                        <div className="carousel-caption"></div>
                      </div> */}
                    </div>{" "}
                    <a
                      className="carousel-control-prev"
                      href="#carousel1"
                      role="button"
                      data-slide="prev"
                    >
                      {" "}
                      <span
                        className="carousel-control-prev-icon"
                        aria-hidden="true"
                      />{" "}
                      <span className="sr-only">Previous</span>{" "}
                    </a>{" "}
                    <a
                      className="carousel-control-next"
                      href="#carousel1"
                      role="button"
                      data-slide="next"
                    >
                      {" "}
                      <span
                        className="carousel-control-next-icon"
                        aria-hidden="true"
                      />{" "}
                      <span className="sr-only">Next</span>{" "}
                    </a>
                  </div>
                </div>
              </div>
            </section>

            <section
              id="about"
              className="ftco-section ftc-no-pb default-padding"
            >
              <div className="container-fluid">
                <div className="section-header text-center">
                  <h2>About MSRM Organics Pvt. Ltd.</h2>
                </div>
                <div className="row">
                  <section className="text-center about">
                    <div
                      className="col-lg-4 col-sm-4 col-ex-4 about-item wow lightSpeedIn"
                      data-wow-offset="200"
                    >
                      <span className="fa fa-cogs"></span>
                      <h2 className="heading_about my-5">Services</h2>
                      <p className="lead pl-3">
                        We offer no. of services to the farmers, educate them to
                        use right kind of inputs for their farming.
                      </p>
                    </div>

                    <div
                      className="col-lg-4 col-sm-4 col-ex-4 about-item wow lightSpeedIn"
                      data-wow-offset="200"
                    >
                      <span className="fa fa-users"></span>
                      <h2 className="heading_about my-5">Marketing</h2>
                      <p className="lead">
                        We are operating in 9 states, each state headed by state
                        head, des Area managers, sales officers.
                      </p>
                    </div>
                    <div
                      className="col-lg-4 col-sm-4 col-ex-4 about-item wow lightSpeedIn"
                      data-wow-offset="200"
                    >
                      <span className="fa fa-link"></span>
                      <h2 className="heading_about my-5">Industry Links</h2>
                      <p className="lead link">
                        Indian Council of Agriculture N G Ranga Agricultural
                        University Hissar, India
                        {/* <ul>
                             <li>Indian Council of Agriculture </li>
                             <li> N G Ranga Agricultural University </li>
                             <li> Hissar, India
                             </li>
                           </ul> */}
                      </p>
                    </div>
                  </section>
                </div>
              </div>
            </section>

            <section
              id="about"
              className="ftco-section ftc-no-pb default-padding"
            >
              <div className="section-header text-center">
                <h2>VISION & MISSION</h2>
              </div>
              <div className="container-fluid">
                <div className="row mb-5">
                  <div className="col-md-5">
                    <div className="about-img">
                      <img
                        src="img/vision.png"
                        alt="Image"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                  <div className="col-md-7">
                    <h2 className=" pl-md-5 mb-3 heading_about"></h2>

                    <div
                      className="col-lg-12 col-sm-12 col-ex-12 about-item wow lightSpeedIn vision"
                      data-wow-offset="200"
                    >
                      <p className="lead1">
                        To expand our activities to other areas, there by
                        offering our services to more no. of farmers​ for their
                        ​prosperity, and ​involving ourselves in​ building​​
                        the​ national wealth and prosperity of the country.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* Feature End*/}

            <div id="review">
              <link
                rel="stylesheet"
                type="text/css"
                href="https://cdn.jsdelivr.net/gh/kenwheeler/slick@1.8.1/slick/slick-theme.css"
              />
              <link
                rel="stylesheet"
                type="text/css"
                href="https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.css"
              />
              <div className="section-header text-center mt-5">
                <h2> What our customers say</h2>
              </div>
              <div className="items riview-content review-img">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">
                      <img src="https://img.icons8.com/ultraviolet/40/000000/quote-left.png" />
                    </h4>
                    <div className="template-demo">
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,'
                      </p>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-sm-4">
                        <img
                          className="profile-pic img-fluid"
                          src="img/r5.png"
                        />
                      </div>
                      <div className="col-sm-8">
                        <div className="profile">
                          <h4 className="cust-name">Vineet Gupta</h4>
                          <p className="cust-profession">CEO & Co-Founder</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">
                      <img src="https://img.icons8.com/ultraviolet/40/000000/quote-left.png" />
                    </h4>
                    <div className="template-demo">
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,'
                      </p>{" "}
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-sm-4">
                        <img
                          className="profile-pic img-fluid"
                          src="img/r1.jpg"
                        />
                      </div>
                      <div className="col-sm-8">
                        <div className="profile">
                          <h4 className="cust-name">Pooja</h4>
                          <p className="cust-profession">CEO </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">
                      <img src="https://img.icons8.com/ultraviolet/40/000000/quote-left.png" />
                    </h4>
                    <div className="template-demo">
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,'
                      </p>{" "}
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-sm-4">
                        <img
                          className="profile-pic img-fluid"
                          src="img/r4.png"
                        />
                      </div>
                      <div className="col-sm-8">
                        <div className="profile">
                          <h4 className="cust-name">Alok</h4>
                          <p className="cust-profession">Customer</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">
                      <img src="https://img.icons8.com/ultraviolet/40/000000/quote-left.png" />
                    </h4>
                    <div className="template-demo">
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,'
                      </p>{" "}
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-sm-4">
                        <img
                          className="profile-pic img-fluid"
                          src="img/r2.png"
                        />
                      </div>
                      <div className="col-sm-8">
                        <div className="profile">
                          <h4 className="cust-name">Versha Tyagi</h4>
                          <p className="cust-profession">Shop Kepper</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">
                      <img src="https://img.icons8.com/ultraviolet/40/000000/quote-left.png" />
                    </h4>
                    <div className="template-demo">
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,'
                      </p>{" "}
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-sm-4">
                        <img
                          className="profile-pic img-fluid"
                          src="img/r5.png"
                        />
                      </div>
                      <div className="col-sm-8">
                        <div className="profile">
                          <h4 className="cust-name">Vineet Gupta</h4>
                          <p className="cust-profession">CEO & Co-Founder</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Contact Start */}
            <div className="container-fluid">
              <div
                id="contact"
                className="contact wow fadeInUp default-padding"
              >
                <div className="section-header text-center">
                  <h2>GET IN TOUCH WITH US !</h2>
                </div>
                <div className="row query-form contact-dataa ">
                  <div className="col-md-6">
                    <div className="contact-info">
                      <div className="contact-item">
                        <i className="flaticon-address" />
                        <div className="contact-text">
                          <h2>Location</h2>
                          <p>
                            Flat#2-b, M.S. Nilayam, No 15, Hacp Colony,
                            Karkhana, Secunderabad - 500009, Telangana, India.
                          </p>
                        </div>
                      </div>
                      <div className="contact-item">
                        <i className="flaticon-call" />
                        <div className="contact-text">
                          <h2>Phone</h2>
                          <p>+91-40-27892790</p>
                        </div>
                      </div>

                      <div className="contact-item">
                        <i className="flaticon-send-mail" />
                        <div className="contact-text">
                          <h2>Email</h2>
                          <p>contact@pasuracropcare.com</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="contact-form contact">
                      <div id="success" />
                      <h1>Reach Out To Us</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Contact End */}

            {/* Footer Start */}
            <div className="footermy wow fadeIn" data-wow-delay="0.3s">
              <div className="container">
                <div className="row">
                  <div className="col-md-6 col-lg-6">
                    <div className="footermy-link">
                      <h2>Useful Pages</h2>
                      <a href="#index">Home</a>
                      <a href="#about">About Us</a>
                      <a href="#review"> Customers Review</a>
                      <a href="#contact">Contact Us</a>
                    </div>
                  </div>

                  <div className="col-lg-6 ol-md-6 footer-links  social-icone boxxe">
                    <h2>Follow us by</h2>

                    <div className="icons_container">
                      <div className="icon facebook">
                        <div className="tooltip">Facebook</div>
                        <a href="#" target="_blank">
                          {" "}
                          <span>
                            <i className="fa fa-facebook-f"></i>
                          </span>
                        </a>
                      </div>
                      <div className="icon twitter">
                        <div className="tooltip">Twitter</div>
                        <a href="#" target="_blank">
                          <span>
                            <i className="fa fa-twitter"></i>
                          </span>
                        </a>
                      </div>

                      <div className="icon github">
                        <div className="tooltip">Linkedin</div>
                        <a href="#" target="_blank">
                          <span>
                            {" "}
                            <i className="fa fa-linkedin"></i>
                          </span>
                        </a>
                      </div>
                      <br></br>

                      <div className="icon youtube">
                        <div className="tooltip">YouTube</div>
                        <a href="#" target="_blank">
                          <span>
                            <i className="fa fa-youtube"></i>
                          </span>
                        </a>
                      </div>
                      <div className="icon whatsapp">
                        <div className="tooltip">whatsapp</div>
                        <a href="#" target="_blank">
                          {" "}
                          <span>
                            <i className="fab fa-whatsapp"></i>
                          </span>
                        </a>
                      </div>
                      <div className="icon instagram">
                        <div className="tooltip">Instagram</div>
                        <a href="#" target="_blank">
                          {" "}
                          <span>
                            <i className="fab fa-instagram"></i>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container footermy-menu">
                <div className="f-menu"></div>
              </div>
              <div className="container copyright">
                <div className="row">
                  <div className="col-md-6 text-white">
                    {" "}
                    Copyright © 2023 MSRM Organics Private Limited - All Rights
                    Reserved.{" "}
                  </div>
                  <div className="col-md-6 text-right designedby">
                    Design & Developed by{" "}
                    <a href="https://holostik.com/" className="desinged-by">
                      Holostik India Limited
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* Footer End */}
            <a href="#" className="back-to-top">
              <i className="fa fa-chevron-up" />
            </a>
          </div>
        </div>
      </>
    );
  }
}
