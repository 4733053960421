import instance from './DefaultApi';

export default class FeedbackReportService {
    ControllerName = 'FeedbackReport';

    // get all data of Feedback Report
    GetFeedbackReport(tableRequest) {
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetFeedbackReport`, tableRequest).then(res => res)
    }

    // get Excel export Feedback Report
    GetFeedbackExport(tableRequest) {
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetFeedbackExport`,tableRequest).then(res => res)
    }
}