import React, { Component } from 'react';

export class ConfirmationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      confirm: false
    }
    this.confirmModal = this.confirmModal.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  // confirm Modal Show
  confirmModal() {
    this.setState({
      showModal: true
    })
  }

  // handle Confirm
  handleConfirm() {
    this.setState({
      confirm: true,
      showModal: false
    })
  }

  //on Close
  onClose() {
    this.setState({
      confirm: false,
      showModal: false
    })
  }

  // return HTML Modal
  render() {
    return (
      <>
        {this.state.showModal ?

          <div className="customModal" id="exampleModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Are you sure?</h5>
                  <button type="button" onClick={this.onClose} className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  You want to delete this file?
                </div>
                <div className="modal-footer">
                  <button className="btn btn-primary" onClick={this.handleConfirm}><i className="fa fa-check mr-1"></i>Yes</button>
                  <button type="button" className="btn btn-secondary" onClick={this.onClose}><i className="fa fa-times mr-1"></i>No</button>
                </div>
              </div>
            </div>
          </div>

          : null}
      </>
    )
  }
}

export default ConfirmationModal