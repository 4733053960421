import instance from './DefaultApi';

export default class VerificationReportService {
    ControllerName = 'VerificationReport';

    // get all data VerificationReport
    GetVerificationReport(tableRequest) {
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetVerificationReport`, tableRequest).then(res => res)
    }

    // Get Verification Export Excel
    GetVerificationExport(req) {
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetVerificationExport`,req).then(res => res)
    }

    // To pick n lucky users 
    PickLuckyUsers(req) {
        return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/PickLuckyUsers`,req).then(res => res)
    }
}